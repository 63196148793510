import { all, call, put, select, takeEvery } from "redux-saga/effects";
import get from "lodash/get";
import { deleteDatasetDetailsApi, getListDatasetDetailApi } from "./api";
import {
  DELETE_DATASET_DETAILS,
  getCurrentPricingPackage,
  GET_LIST_DATASET_DETAIL,
  setCurrentDataset,
  showNoti,
} from "redux/actions";
import { ERROR_CASE, SUCCESS_CASE } from "components/notificationModal/constants";
import {
  getListDatasetDetailError,
  getListDatasetDetailSuccess,
} from "./actions";
import { concat } from "lodash";
import { selectProjectType } from "redux/projects/selectors";

function* getListDatasetDetailListSaga({ payload }) {
  const {
    datasetId,
    pageSize,
    currentPage,
    keywords,
    query = {},
    versionId,
    projectId,
    isRefresh,
  } = payload;
  const errorMessage = "Get List Dataset Detail Error!";
  const errorMessageId = "notification.get-list-dataset-detail-error";
  const { benchmark, ...searchAdvancedQuery } = query;
  const searchAdvanced = Object.keys(searchAdvancedQuery).map((key) => ({
    field: key,
    keywords: [query[key]?.value],
  }));
  try {
    const response = yield call(getListDatasetDetailApi, {
      datasetId,
      pageSize,
      currentPage,
      keywords,
      searchAdvanced,
      versionId,
      isBenchmark: benchmark?.value,
      projectId,
    });
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status === true) {
        const { totalPage, totalItem, dataset_name, datasets_details } = get(
          response,
          "data.data",
          {
            totalItem: 0,
            totalPage: 0,
            dataset_name: "",
            datasets_details: [],
          },
        );
        if (pageSize === 10000) {
          const dataAssignMembers = JSON.parse(
            localStorage.getItem("dataAssignMembers"),
          )
            ? JSON.parse(localStorage.getItem("dataAssignMembers"))
            : [];
          const newDataAssignMembers = concat(
            dataAssignMembers,
            datasets_details,
          );
          localStorage.setItem(
            "dataAssignMembers",
            JSON.stringify(newDataAssignMembers),
          );
        }
        yield put(
          getListDatasetDetailSuccess({
            pagination: {
              totalPage,
              totalItem,
              currentPage: payload.currentPage,
              pageSize: payload.pageSize,
            },
            datasetDetails: datasets_details,
            datasetName: dataset_name,
            query,
            isRefresh
          }),
        );
        const dataset = datasets_details.find((item) => {
          return item.dataset_id === datasetId;
        });
        yield put(setCurrentDataset(dataset));
      } else {
        yield put(getListDatasetDetailError(message));
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: message,
            errorMessageId: null
          }),
        );
      }
    } else {
      yield put(getListDatasetDetailError(errorMessage));
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(getListDatasetDetailError(errorMessage));
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

function* deleteDatasetDetailsSaga({ payload, callback }) {
  const errorMessage = "Delete dataset detail Error!";
  const {
    labelSources,
  } = payload;
  const projectType = yield select(selectProjectType);
  try {
    const response = yield call(deleteDatasetDetailsApi, {
      labelSources,
      projectType,
    });
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      yield put(
        showNoti({
          type: SUCCESS_CASE,
          message: "Delete dataset detail successfully!",
        }),
      );
      yield put(getCurrentPricingPackage({}));
    
      callback && callback()
    } else {
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
      }),
    );
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(GET_LIST_DATASET_DETAIL, getListDatasetDetailListSaga),
    takeEvery(DELETE_DATASET_DETAILS, deleteDatasetDetailsSaga),
  ]);
}
