/* eslint-disable */
import {
    GET_AGENTS,
    GET_AGENTS_SUCCESS,
    GET_AGENTS_ERROR,
    GET_DATASETS,
    GET_DATASETS_SUCCESS,
    GET_DATASETS_ERROR,
    GET_TASKS,
    GET_TASKS_SUCCESS,
    GET_TASKS_ERROR,
    ADD_TASK,
} from "redux/actions";

const INIT_STATE = {
    agents: [],
    datasets: [],
    tasks: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_AGENTS:
            return {...state, error: ""};
        case GET_AGENTS_SUCCESS:
            return {
                ...state,
                agents: action.payload.agents,
                error: "",
            };
        case GET_AGENTS_ERROR:
            return {
                ...state,
                agents: [],
                error: action.payload.message,
            };
        case GET_DATASETS:
            return {...state, error: ""};
        case GET_DATASETS_SUCCESS:
            return {
                ...state,
                datasets: action.payload.datasets,
                error: "",
            };
        case GET_DATASETS_ERROR:
            return {
                ...state,
                datasets: [],
                error: action.payload.message,
            };
        case GET_TASKS:
            return {...state, error: ""};
        case GET_TASKS_SUCCESS:
            return {
                ...state,
                tasks: action.payload.tasks,
                error: "",
            };
        case GET_TASKS_ERROR:
            return {
                ...state,
                tasks: [],
                error: action.payload.message,
            };
        default:
            return {...state};
    }
}