import axios from "redux/api";

import { baseUrl, authEndpoint } from "constants/defaultValues";
import { setCookie, getCookie } from "helpers/Utils";

export function createUser({ fullName, email, password, companyName, yourTitle, countryName }) {
  return axios({
    method: "POST",
    url: `${authEndpoint}/users/register`,
    responseType: "json",
    data: {
      fullName,
      email,
      password,
      confirmationLink: `${baseUrl}/user`,
      yourTitle,
      companyName,
      countryName
    },
  })
    .then((response) => response)
    .catch((error) => error.response);
}

export function confirmUser(email, token) {
  axios.defaults.headers.Authorization = 'Bearer ' + token;
  return axios({
    method: "POST",
    url: `${authEndpoint}/users/confirmation`,
    data: {
      email,
      token,
    },
  })
    .then((response) => response)
    .catch((error) => error.response);
}

export function resendEmail(email) {
  return axios({
    method: "POST",
    url: `${authEndpoint}/users/resend`,
    data: {
      email,
      confirmationLink: `${baseUrl}/user`,
    },
  })
    .then((response) => response)
    .catch((error) => error.response);
}

export function login(email, password) {
  return axios({
    method: "POST",
    url: `${authEndpoint}/users/login`,
    data: {
      email,
      password,
    },
  })
    .then((response) => response)
    .catch((error) => error.response);
}

export async function validateEmailAsync(email) {
  return await axios({
    method: "POST",
    url: `${authEndpoint}/users/verify-email`,
    responseType: "json",
    data: { email },
  })
    .then((response) => response)
    .catch((error) => error.reponse);
}

export function authGoogle(access_token) {
  return axios({
    method: "POST",
    url: `${authEndpoint}/users/auth/google`,
    data: {
      access_token,
    },
  })
    .then((response) => response)
    .catch((error) => error.response);
}

export function authGithub(code) {
  return axios({
    method: "POST",
    url: `${authEndpoint}/users/auth/github`,
    data: {
      code,
    },
  })
    .then((response) => response)
    .catch((error) => error.response);
}

export function forgotPassword(email) {
  return axios({
    method: "POST",
    url: `${authEndpoint}/users/forgot-password`,
    data: {
      email,
      confirmationLink: `${baseUrl}/user`,
    },
  })
    .then((response) => response)
    .catch((error) => error.response);
}

export function checkTokenApi(token) {
  let eraserBearerCookie = false
  if(!getCookie("Bearer")){  // don't change token if user is login
    eraserBearerCookie = true
    setCookie("Bearer", token, 1)
  }
  return axios({
    method: "GET",
    url: `${authEndpoint}/users/check-token-expire`,
    params: {
      token,
    },
  })
    .then((response) => response)
    .catch((error) => error.response)
    .finally(() => {
      if(eraserBearerCookie){
        setCookie("Bearer", "") // eraser bearer cookie if user don't log in before
      }
    });
}

export function checkTokenNewMember(token) {
  return axios({
    method: "POST",
    url: `${authEndpoint}/users/check-token-new-member`,
    data: {
      token,
    },
  })
    .then((response) => response)
    .catch((error) => error.response);
}

export function resetPassword(email, password, resetToken) {
  axios.defaults.headers.Authorization = 'Bearer ' + resetToken;
  return axios({
    method: "POST",
    url: `${authEndpoint}/users/reset-password`,
    data: {
      password,
    },
  })
    .then((response) => response)
    .catch((error) => error.response);
}

export function setupNewMember(password, token) {
  return axios({
    method: "POST",
    url: `${authEndpoint}/users/setup-new-member`,
    data: {
      password,
      token: token,
    },
  })
    .then((response) => response)
    .catch((error) => error.response);
}

export function updatePassword({ oldPassword, newPassword }) {
  return axios({
    method: "POST",
    url: `${authEndpoint}/users/update-password`,
    data: {
      oldPassword,
      newPassword
    }
  })
}

export function updateProfile(data) {
  return axios({
    method: "POST",
    url: `${authEndpoint}/users/update-profile`,
    data
  });
}
