import {
  datasetDetailPageSizes,
  datasetDetailsViewTypes,
} from "constants/defaultValues";

import {
  GET_DATA_BY_ADVANCED_FILTERS,
  GET_DATA_BY_ADVANCED_FILTERS_SUCCESS,
  GET_DATA_BY_ADVANCED_FILTERS_ERROR,
  GET_LIST_DATASET_DETAIL,
  GET_LIST_DATASET_DETAIL_ERROR,
  GET_LIST_DATASET_DETAIL_SUCCESS,
  SET_DATASET_DETAIL_VIEW_TYPE,
  SET_SELECTED_DATASET_DETAILS,
} from "redux/actions";

const INIT_STATE = {
  datasetDetails: [],
  previousPagesDetails: [],
  pagination: {
    pageSize: datasetDetailPageSizes[0],
    totalPage: 0,
    totalItem: 0,
    currentPage: 1,
  },
  query: {},
  datasetName: "",
  isLoading: false,
  selectedDatasetDetails: [],
  viewType: datasetDetailsViewTypes[0].value,
};

export default (state = INIT_STATE, action) => {
  let previousPagesDetails = [...state.previousPagesDetails];
  switch (action.type) {
    case GET_LIST_DATASET_DETAIL:
      return {
        ...state,
        isLoading: true,
      };
    case GET_LIST_DATASET_DETAIL_SUCCESS:
      
      if (!action.payload.isRefresh) {
        for (let currentSource of state.datasetDetails) {
          const existingSourceIndex = previousPagesDetails.findIndex(source => source.source_id === currentSource.source_id);
          if (existingSourceIndex < 0) {
            previousPagesDetails.push({source_id: currentSource.source_id, dataset_id: currentSource.dataset_id})
          }
        }
      } else {
        previousPagesDetails = [];
      }
      
      return {
        ...state,
        isLoading: false,
        datasetDetails: action.payload.datasetDetails,
        pagination: action.payload.pagination,
        datasetName: action.payload.datasetName,
        query: action.payload.query,
        selectedDatasetDetails: action.payload.isRefresh
          ? []
          : state.selectedDatasetDetails,
        previousPagesDetails,
      };
    case GET_LIST_DATASET_DETAIL_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case SET_DATASET_DETAIL_VIEW_TYPE:
      return {
        ...state,
        viewType: action.payload,
      };
    case SET_SELECTED_DATASET_DETAILS:
      return {
        ...state,
        selectedDatasetDetails: action.payload,
      };
    case GET_DATA_BY_ADVANCED_FILTERS:
      return {
        ...state,
        isLoading: true,
      }
    case GET_DATA_BY_ADVANCED_FILTERS_SUCCESS:
      if (!action.payload.isRefresh) {
        for (let currentSource of state.datasetDetails) {
          const existingSourceIndex = previousPagesDetails.findIndex(source => source.source_id === currentSource.source_id);
          if (existingSourceIndex < 0) {
            previousPagesDetails.push({source_id: currentSource.source_id, dataset_id: currentSource.dataset_id})
          }
        }
      } else {
        previousPagesDetails = [];
      }
      return {
        ...state,
        isLoading: false,
        datasetDetails: action.payload.data,
        pagination: action.payload.pagination,
        selectedDatasetDetails: action.payload.isRefresh
          ? []
          : state.selectedDatasetDetails,
        previousPagesDetails,
        query: action.payload.query ? action.payload.query : {},
      }
    case GET_DATA_BY_ADVANCED_FILTERS_ERROR:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return { ...state };
  }
};
