import { handler } from "./generateModel";

export function startCloneDataset(socket, payload) {
  socket.emit("clone_dataset", payload);
}

export function cloneDatasetResponse(socket, emitter) {
  socket.off("clone_dataset_response", (data) =>
    handler(data, emitter, "clone_dataset_response"),
  );
}

export function cloneDatasetSuccess(socket, emitter) {
  socket.off("clone_dataset_success", (data) =>
    handler(data, emitter, "clone_dataset_success"),
  );
}

export function cloneDatasetFailed(socket, emitter) {
  socket.off("clone_dataset_failure", (data) =>
    handler(data, emitter, "clone_dataset_failure"),
  );
}