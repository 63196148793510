import {
    GET_AGENTS,
    GET_AGENTS_SUCCESS,
    GET_AGENTS_ERROR,
    GET_DATASETS,
    GET_DATASETS_SUCCESS,
    GET_DATASETS_ERROR,
    GET_TASKS,
    GET_TASKS_SUCCESS,
    GET_TASKS_ERROR,
    ADD_TASK,
    ADD_TASK_SUCCESS,
    ADD_TASK_ERROR,
} from "redux/actions";

export const getAgents = (agents) => ({
    type: GET_AGENTS,
    payload: { agents },
});

export const getAgentsSuccess = (agents) => ({
    type: GET_AGENTS_SUCCESS,
    payload: { agents },
});

export const getAgentsError = (message) => ({
    type: GET_AGENTS_ERROR,
    payload: { message },
});

export const getDatasets = (datasets) => ({
    type: GET_DATASETS,
    payload: { datasets },
});

export const getDatasetsSuccess = (datasets) => ({
    type: GET_DATASETS_SUCCESS,
    payload: { datasets },
});

export const getDatasetsError = (message) => ({
    type: GET_DATASETS_ERROR,
    payload: { message },
});

export const getTasks = (tasks) => ({
    type: GET_TASKS,
    payload: { tasks },
});

export const getTasksSuccess = (tasks) => ({
    type: GET_TASKS_SUCCESS,
    payload: { tasks },
});

export const getTasksError = (message) => ({
    type: GET_TASKS_ERROR,
    payload: { message },
});

export const addTask = (typeId, agentId, datasetId, config) => ({
    type: ADD_TASK,
    payload: { typeId, agentId, datasetId, config },
});

export const addTaskSuccess = (message) => ({
    type: ADD_TASK_SUCCESS,
    payload: { message },
});
export const addTaskError = (message) => ({
    type: ADD_TASK_ERROR,
    payload: { message },
});