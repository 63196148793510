import axios from "../api";
import { payloadToQuery } from "helpers/Utils";

export function createOrganization(org_name, org_description, postal_code, street, district, city, country, phone_number) {
  return axios.post(`/organization/add-new-organization`, {
    org_name: org_name,
    org_description: org_description,
    postal_code: postal_code,
    street: street,
    district: district,
    city: city,
    country: country,
    phone_number: phone_number,
  });
}
export function getGroupUsers() {
  return axios.get(`/users/getGroupUsers`);
}

export function getOrgById(orgId) {
  return axios.get(`/organization/get-org-by-id?orgId=${orgId}`);
}

export function switchOrganization(ActiveCode) {
  return axios.post(`/users/loginWithActiveCode`, {
    ActiveCode
  });
}

export function updateOrganization(orgInfo) {
  return axios.post(`/organization/update-organization`, {
    org_id: orgInfo.org_id,
    org_name: orgInfo.org_name,
    org_description: orgInfo.org_description,
    postal_code: orgInfo.postal_code,
    street: orgInfo.street,
    district: orgInfo.district,
    city: orgInfo.city,
    country: orgInfo.country,
    phone_number: orgInfo.phone_number,
    timezone: orgInfo.timezone
  });
}

export function getListOrganization(selectedPageSize = 10, currentPage = 1) {
  return axios.get(`/organization/get-all-organization?pageSize=${selectedPageSize}&currentPage=${currentPage}`);
}

export function getListOrganizationByUserId(selectedPageSize = 10, currentPage = 1, userId) {
  return axios.get(`/organization/get-org-by-site-admin-id?pageSize=${selectedPageSize}&currentPage=${currentPage}&userId=${userId}`);
}

export function deleteOrganizationbyId({ org_id }) {
  return axios.post(`/organization/delete-organization`, {
    org_id
  });
}


export function addOrganizationAPI(payload) {
  return axios.post(`/organization/add-new-organization`, payload);
}

export function updateOrganizationAPI(payload) {
  return axios.post(`/organization/update-organization`, payload);
}

export function getStorageSettingAPI({ orgId }) {
  return axios.get(`/organization/get-data-storage?orgId=${orgId}`);
}

export function addStorageSettingAPI(payload) {
  return axios.post('/organization/add-new-data-storage', payload);
}

export function getListOrganizationForBusinessAdminApi(payload) {
  const query = payloadToQuery(payload);
  return axios.get(`/organization/get-organization-list-business-admin${query}`);
}

export function getChartOrganizationForBusinessAdminApi(payload) {
  const query = payloadToQuery(payload);
  return axios.get(`/organization/get-organization-chart-business-admin${query}`);
}
