import {
  LOGOUT_USER,
  ACTION_ERROR,
  SETUP_ORGANIZATION,
  SETUP_ORGANIZATION_SUCCESS,
  SETUP_ORGANIZATION_ERROR,
  GET_ORGANIZATION_BY_ID,
  GET_ORGANIZATION_BY_ID_SUCCESS,
  GET_ORGANIZATION_BY_ID_ERROR,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_ERROR,
  GET_LIST_ORGANIZATION,
  GET_LIST_ORGANIZATION_SUCCESS,
  GET_LIST_ORGANIZATION_ERROR,
  GET_LIST_ORGANIZATION_BY_USER_ID,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_SUCCESS,
  GET_STORAGE_SETTING_BY_ORGANIZATION_ID_SUCCESS,
  GET_USER_GROUP_USER_SUCCESS,
  GET_LIST_ORGANIZATION_FOR_BUSINESS_ADMIN,
  GET_LIST_ORGANIZATION_SUCCESS_FOR_BUSINESS_ADMIN,
  GET_LIST_ORGANIZATION_ERROR_FOR_BUSINESS_ADMIN,
  GET_CHART_ORGANIZATION_FOR_BUSINESS_ADMIN,
  GET_CHART_ORGANIZATION_FOR_BUSINESS_ADMIN_SUCCESS,
  GET_CHART_ORGANIZATION_FOR_BUSINESS_ADMIN_FAILURE,
} from "redux/actions";

// const namespace = "orgData";

const INIT_STATE = {
  user: null,
  isVerified: false,
  isValidToken: null,
  loading: false,
  error: "",
  errorId: "",
  message: "",
  path: "",
  orgInfo: null,
  organizationList: {
    organizations: [],
    totalPage: 0,
    totalItem: 0,
  },
  storageData: {},
  userOrgList: [],
  organizationBusinessAdminList: {
    organizations: [],
    totalPage: 0,
    totalItem: 0,
  },
  organizationsBusinessAdminChartData: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      return INIT_STATE;
    case ACTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case SETUP_ORGANIZATION:
      return { ...state, loading: true, error: "", errorId: "", path: "/organization/add-new-organization" };
    case SETUP_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        errorId: "",
        message: "Setup Organization Success!",
      };
    case SETUP_ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload.message,
        errorId: action.payload.messageId,
        message: "",
      };
    case GET_ORGANIZATION_BY_ID:
      return { ...state, loading: true, message: "", error: "", errorId: "" };
    case GET_ORGANIZATION_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        orgInfo: action.payload.data.summary[0],
        message: action.payload.message,
      };
    case GET_ORGANIZATION_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        orgInfo: null,
        message: action.payload.message,
      };
    case UPDATE_ORGANIZATION:
      return { ...state, loading: true, message: "", error: "", errorId: "" };
    case UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        orgInfo: action.payload.orgInfo,
        message: action.payload.message,
      };
    case UPDATE_ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case GET_LIST_ORGANIZATION:
      return {
        ...state,
        loading: true,
        error: "",
        errorId: "",
        message: "",
      };
    case GET_LIST_ORGANIZATION_BY_USER_ID:
      return {
        ...state,
        loading: true,
        error: "",
        errorId: "",
        message: "",
      };
    case GET_LIST_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        organizationList: {
          organizations: action.payload.organizations,
          totalPage: parseInt(action.payload.totalPage),
          totalItem: parseInt(action.payload.totalItem),
        }
      };
    case GET_LIST_ORGANIZATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        errorId: action.payload.messageId,
        organizationList: {
          organizations: [],
          totalPage: 0,
          totalItem: 0,
        }
      };
    case DELETE_ORGANIZATION:
      return {
        ...state,
        loading: true,
        error: "",
        errorId: "",
        message: "",
      };
    case DELETE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    case GET_STORAGE_SETTING_BY_ORGANIZATION_ID_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        storageData: {
          ...data
        }
      }
    }
    case GET_USER_GROUP_USER_SUCCESS:
      return {
        ...state,
        userOrgList: action.payload,
      };
    case GET_LIST_ORGANIZATION_FOR_BUSINESS_ADMIN:
      return {
        ...state,
        loading: true,
        error: "",
        errorId: "",
        message: "",
      };
    case GET_LIST_ORGANIZATION_SUCCESS_FOR_BUSINESS_ADMIN:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        organizationBusinessAdminList: {
          organizations: action.payload.organizations,
          totalPage: parseInt(action.payload.totalPage),
          totalItem: parseInt(action.payload.totalItem),
        }
      };
    case GET_LIST_ORGANIZATION_ERROR_FOR_BUSINESS_ADMIN:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        organizationBusinessAdminList: {
          organizations: [],
          totalPage: 0,
          totalItem: 0,
        }
      };
    case GET_CHART_ORGANIZATION_FOR_BUSINESS_ADMIN:
      return {
        ...state,
        loading: true,
        error: "",
        errorId: "",
        message: "",
      };
    case GET_CHART_ORGANIZATION_FOR_BUSINESS_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        organizationsBusinessAdminChartData: action.payload.data,
      };
    case GET_CHART_ORGANIZATION_FOR_BUSINESS_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        organizationsBusinessAdminChartData: {}
      };
    default:
      return { ...state };
  }
};
