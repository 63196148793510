/* eslint-disable */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import get from "lodash/get";

import {
  GET_MODEL,
  GET_MODEL_CHECKPOINT,
} from "redux/actions";

import {
  getModelCheckpointSuccess,
  getModelSuccess
} from './actions'

import {
  getModelApi,
  getModelCheckpointApi,
} from './api'

export function* watchGetModelCheckpoint() {
  yield takeEvery(GET_MODEL_CHECKPOINT, getModelCheckpointData);
}

export function* watchGetModel() {
  yield takeEvery(GET_MODEL, getModelData);
}
function* getModelCheckpointData({ payload, callback }) {
  try {
    const response = yield call(getModelCheckpointApi, payload);
    if (response.status === true) {
      yield put(getModelCheckpointSuccess(response.data))
      if (callback) callback(response)
    }
  } catch (error) {
    console.log('error', error)
    if (callback) callback(error)
    return;
  }


}

function* getModelData({ payload }) {
  try {
    const response = yield call(getModelApi, payload);
    if (response.status === true) {
      yield put(getModelSuccess(response.data))
    }
  } catch (error) {
    console.log('error', error)
    return;
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetModelCheckpoint),
    fork(watchGetModel),
  ]);
}