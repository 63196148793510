import axios from "axios";
import customAxios from "../api";

import { logStashUrl } from "constants/defaultValues";

export function getToolReportData({
  pageSize = 5,
  page = 1,
  matchFilter = {},
  duration = "7d",
}) {
  const query = [{
    range: {
      "@timestamp": {
        gte: `now-${duration}`,
        lt: "now",
      },
    },
  }];

  for (let key in matchFilter) {
    if (matchFilter[key] && matchFilter[key] !== 'all') {
      if (key === 'userData.email' && matchFilter[key]) {
        query.push({
          wildcard: {
            [key]: `*${matchFilter[key]}*`
          }
        });
      } else {
        query.push({
          match: {
            [key]: matchFilter[key]
          }
        });
      }
    }
  }

  return axios({
    method: "post",
    url: `${logStashUrl}/logstash*/_search?search_type=query_then_fetch&pretty`,
    responseType: "json",
    data: {
      from: (page - 1) * pageSize,
      size: pageSize,
      sort: [
        {
          "@timestamp": "desc",
        },
      ],
      query: {
        bool: {
          must_not: {
            match: {
              url: "users"
            }
          },
          must: query
        },
      }
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export function getToolReportChartData({ limit, timeRange }) {
  return customAxios.get(`/labels/get-tool-report`, {
    params: {
      limit,
      timeRange
    },
  });
}

export function getUserLoginChartData() {
  return axios({
    method: "post",
    url: `${logStashUrl}/logstash*/_search?search_type=query_then_fetch&pretty`,
    responseType: "json",
    data: {
      sort: [
        {
          "@timestamp": "desc",
        },
      ],
      query: {
        bool: {
          must: {
            match: {
              url: "login google github"
            }
          }
        }
      }
    },
  }).then(response => response)
  .catch(error => error);
}

export function getAllLoginData(size) {
  return axios({
    method: "post",
    url: `${logStashUrl}/logstash*/_search?search_type=query_then_fetch&pretty`,
    responseType: "json",
    data: {
      sort: [
        {
          "@timestamp": "desc",
        },
      ],
      query: {
        bool: {
          must: {
            match: {
              url: "login google github"
            }
          }
        }
      },
      size,
    },
  }).then(response => response)
  .catch(error => error);
}
