import {
  GET_LIST_MEMBERS,
  GET_LIST_MEMBERS_SUCCESS,
  GET_LIST_MEMBERS_ERROR,
  DELETE_MEMBER_FROM_PROJECT,
  DELETE_MEMBER_FROM_PROJECT_SUCCESS,
  MAKE_INACTIVE_MEMBER,
  MAKE_INACTIVE_MEMBER_SUCCESS,
  MAKE_INACTIVE_MEMBER_ERROR,
  RESTORE_ACTIVE_MEMBER,
  RESTORE_ACTIVE_MEMBER_SUCCESS,
  RESTORE_ACTIVE_MEMBER_ERROR,
  DELETE_MEMBER,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_ERROR,
  GET_LIST_PROJECT,
  GET_LIST_PROJECT_SUCCESS,
  GET_LIST_PROJECT_ERROR,
  GET_LIST_ROLE,
  GET_LIST_ROLE_SUCCESS,
  GET_LIST_ROLE_ERROR,
  ASIGN_PROJECT_TO_MEMBER,
  ASIGN_PROJECT_TO_MEMBER_SUCCESS,
  ASIGN_PROJECT_TO_MEMBER_ERROR,
  GET_MEMBER_BY_EMAIL,
  GET_MEMBER_BY_EMAIL_SUCCESS,
  GET_MEMBER_BY_EMAIL_ERROR,
  UPDATE_MEMBER,
  ADD_MEMBER,
  GET_LIST_SITE_ADMIN_MEMBERS,
  GET_ALL_MEMBER,
  GET_ALL_MEMBER_SUCCESS,
  GET_ALL_MEMBER_FAILED,
} from "redux/actions";

export const getAllMember = (payload) => ({
  type: GET_ALL_MEMBER,
  payload
});

export const getAllMemberSuccess = (payload) => ({
  type: GET_ALL_MEMBER_SUCCESS,
  payload
});

export const getAllMemberFailed = (payload) => ({
  type: GET_ALL_MEMBER_FAILED,
  payload
});

export const deleteMemberFromProject = (memberId, projectId, callback) => ({
  type: DELETE_MEMBER_FROM_PROJECT,
  payload: { memberId, projectId, callback }
})

export const deleteMemberFromProjectSuccess = (memberId, projectId) => ({
  type: DELETE_MEMBER_FROM_PROJECT_SUCCESS,
  payload: { memberId, projectId }
})

export const getListMembers = (selectedPageSize, currentPage, selectedStatus, projectId) => ({
  type: GET_LIST_MEMBERS,
  payload: { selectedPageSize, currentPage, selectedStatus, projectId },
});

export const getListSiteAdminMembers = (payload) => ({
  type: GET_LIST_SITE_ADMIN_MEMBERS,
  payload,
});

export const getListMembersSuccess = (totalPage, totalItem, members) => ({
  type: GET_LIST_MEMBERS_SUCCESS,
  payload: { totalPage, totalItem, members },
});
export const getListMembersError = (message) => ({
  type: GET_LIST_MEMBERS_ERROR,
  payload: { message },
});

export const makeInactiveMember = (memberIds) => ({
  type: MAKE_INACTIVE_MEMBER,
  payload: { memberIds },
});
export const makeInactiveMemberSuccess = (message) => ({
  type: MAKE_INACTIVE_MEMBER_SUCCESS,
  payload: { message },
});
export const makeInactiveMemberError = (message) => ({
  type: MAKE_INACTIVE_MEMBER_ERROR,
  payload: { message },
});

export const restoreActiveMember = (memberIds) => ({
  type: RESTORE_ACTIVE_MEMBER,
  payload: { memberIds },
});
export const restoreActiveMemberSuccess = (message) => ({
  type: RESTORE_ACTIVE_MEMBER_SUCCESS,
  payload: { message },
});
export const restoreActiveMemberError = (message) => ({
  type: RESTORE_ACTIVE_MEMBER_ERROR,
  payload: { message },
});

export const deleteMember = (payload) => ({
  type: DELETE_MEMBER,
  payload,
});
export const deleteMemberSuccess = (message) => ({
  type: DELETE_MEMBER_SUCCESS,
  payload: { message },
});
export const deleteMemberError = (message) => ({
  type: DELETE_MEMBER_ERROR,
  payload: { message },
});

export const getListProject = () => ({
  type: GET_LIST_PROJECT,
  payload: { },
});
export const getListProjectSuccess = (message, projects) => ({
  type: GET_LIST_PROJECT_SUCCESS,
  payload: { message, projects },
});
export const getListProjectError = (message) => ({
  type: GET_LIST_PROJECT_ERROR,
  payload: { message },
});

export const getListRole = () => ({
  type: GET_LIST_ROLE,
  payload: {},
});
export const getListRoleSuccess = (message, roles) => ({
  type: GET_LIST_ROLE_SUCCESS,
  payload: { message, roles },
});
export const getListRoleError = (message) => ({
  type: GET_LIST_ROLE_ERROR,
  payload: { message },
});

export const assignProjectToMember = (memberId, data) => ({
  type: ASIGN_PROJECT_TO_MEMBER,
  payload: { memberId, data },
});
export const assignProjectToMemberSuccess = (message) => ({
  type: ASIGN_PROJECT_TO_MEMBER_SUCCESS,
  payload: { message },
});
export const assignProjectToMemberError = (message) => ({
  type: ASIGN_PROJECT_TO_MEMBER_ERROR,
  payload: { message },
});

export const getMemberByEmail = (email) => ({
  type: GET_MEMBER_BY_EMAIL,
  payload: { email },
});
export const getMemberByEmailSuccess = (member, message) => ({
  type: GET_MEMBER_BY_EMAIL_SUCCESS,
  payload: { member, message },
});
export const getMemberByEmailError = (message) => ({
  type: GET_MEMBER_BY_EMAIL_ERROR,
  payload: { message },
});
export const updateMember = (payload) => ({
  type: UPDATE_MEMBER,
  payload,
});
export const addMember = (payload) => ({
  type: ADD_MEMBER,
  payload,
});
