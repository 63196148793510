import setWith from "lodash/setWith";
import clone from "lodash/clone";
import {
  SET_ADVANCE_SEARCH_FILTER,
  UPDATE_ADVANCED_FILTERS,
  SET_TYPE_TAG,
} from "../actions";
import { statusOptions } from "components/AdvancedSearch/const";

export const INIT_STATE = {
  imageSize: {
    from: {
      value: "",
      unit: "MB",
    },
    to: {
      value: "",
      unit: "MB",
    },
  },
  datasetIds: [],
  labelIds: [],
  labelingSize: {
    from: 0,
    to: 0,
  },
  resolution: {
    condition: "larger than",
    width: 0,
    height: 0,
  },
  searchByText: "",
  status: statusOptions.map((item) => item.value),
  typeTag: "exists",
  selectedTags: [],
};

export default (state = INIT_STATE, action) => {
  const newState = { ...state };
  switch (action.type) {
    case UPDATE_ADVANCED_FILTERS:
      const { callback } = action;
      // Lodash set will mutate the INIT_STATE, which is unwanted behavior
      // This workaround is from https://github.com/lodash/lodash/issues/1696
      const updatedState = setWith(
        clone(newState),
        action.payload.updatedFieldName,
        action.payload.updatedFieldValue,
        clone,
      );
      callback && callback(updatedState);
      return updatedState;
    // case GET_PROJECT_BY_ID_SUCCESS:
    //   return {
    //     ...state,
    //     datasetIds: action.payload.project.listAllDatasets.map(
    //       (dataset) => dataset.dataset_id,
    //     ),
    //   };
    // case GET_PROJECT_LABELS_SUCCESS:
    //   return {
    //     ...state,
    //     labelIds: action.payload.labels.map(label => label.label_id),
    //   };
    case SET_ADVANCE_SEARCH_FILTER:
      return { ...action.payload };
    case SET_TYPE_TAG:
      return { ...state, typeTag: action.payload };
    default:
      return { ...state };
  }
};
