/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const AUTHENTICATION_GOOGLE = "AUTHENTICATION_GOOGLE";
export const AUTHENTICATION_GOOGLE_SUCCESS = "AUTHENTICATION_GOOGLE_SUCCESS";
export const AUTHENTICATION_GOOGLE_ERROR = "AUTHENTICATION_GOOGLE_ERROR";
export const AUTHENTICATION_GITHUB = "AUTHENTICATION_GITHUB";
export const AUTHENTICATION_GITHUB_SUCCESS = "AUTHENTICATION_GITHUB_SUCCESS";
export const AUTHENTICATION_GITHUB_ERROR = "AUTHENTICATION_GITHUB_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const CONFIRMATION_USER = "CONFIRMATION_USER";
export const CONFIRMATION_USER_SUCCESS = "CONFIRMATION_USER_SUCCESS";
export const CONFIRMATION_USER_ERROR = "CONFIRMATION_USER_ERROR";
export const RESEND_CONFIRMATION = "RESEND_CONFIRMATION";
export const RESEND_CONFIRMATION_SUCCESS = "RESEND_CONFIRMATION_SUCCESS";
export const RESEND_CONFIRMATION_ERROR = "RESEND_CONFIRMATION_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const SETUP_NEW_MEMBER = "SETUP_NEW_MEMBER";
export const SETUP_NEW_MEMBER_SUCCESS = "SETUP_NEW_MEMBER_SUCCESS";
export const SETUP_NEW_MEMBER_ERROR = "SETUP_NEW_MEMBER_ERROR";
export const CHECK_TOKEN = "CHECK_TOKEN";
export const CHECK_TOKEN_SUCCESS = "CHECK_TOKEN_SUCCESS";
export const CHECK_TOKEN_ERROR = "CHECK_TOKEN_ERROR";
export const CHECK_TOKEN_NEW_MEMBER = "CHECK_TOKEN_NEW_MEMBER";
export const CHECK_TOKEN_NEW_MEMBER_SUCCESS = "CHECK_TOKEN_NEW_MEMBER_SUCCESS";
export const CHECK_TOKEN_NEW_MEMBER_ERROR = "CHECK_TOKEN_NEW_MEMBER_ERROR";
export const FETCH_USER_INFO = "FETCH_USER_INFO";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const UPDATE_USER_INFO_SUCCESS = "UPDATE_USER_INFO_SUCCESS";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";

/* MEMBERS ACTION */
export const GET_LIST_MEMBERS = "GET_LIST_MEMBERS";
export const DELETE_MEMBER_FROM_PROJECT = "DELETE_MEMBER_FROM_PROJECT";
export const DELETE_MEMBER_FROM_PROJECT_SUCCESS =
  "DELETE_MEMBER_FROM_PROJECT_SUCCESS";
export const GET_LIST_SITE_ADMIN_MEMBERS = "GET_LIST_SITE_ADMIN_MEMBERS";
export const GET_LIST_MEMBERS_SUCCESS = "GET_LIST_MEMBERS_SUCCESS";
export const GET_LIST_MEMBERS_ERROR = "GET_LIST_MEMBERS_ERROR";
export const MAKE_INACTIVE_MEMBER = "MAKE_INACTIVE_MEMBER";
export const MAKE_INACTIVE_MEMBER_SUCCESS = "MAKE_INACTIVE_MEMBER_SUCCESS";
export const MAKE_INACTIVE_MEMBER_ERROR = "MAKE_INACTIVE_MEMBER_ERROR";
export const RESTORE_ACTIVE_MEMBER = "RESTORE_ACTIVE_MEMBER";
export const RESTORE_ACTIVE_MEMBER_SUCCESS = "RESTORE_ACTIVE_MEMBER_SUCCESS";
export const RESTORE_ACTIVE_MEMBER_ERROR = "RESTORE_ACTIVE_MEMBER_ERROR";
export const DELETE_MEMBER = "DELETE_MEMBER";
export const DELETE_MEMBER_SUCCESS = "DELETE_MEMBER_SUCCESS";
export const DELETE_MEMBER_ERROR = "DELETE_MEMBER_ERROR";
export const GET_LIST_PROJECT = "GET_LIST_PROJECT";
export const GET_LIST_PROJECT_SUCCESS = "GET_LIST_PROJECT_SUCCESS";
export const GET_LIST_PROJECT_ERROR = "GET_LIST_PROJECT_ERROR";
export const GET_LIST_ROLE = "GET_LIST_ROLE";
export const GET_LIST_ROLE_SUCCESS = "GET_LIST_ROLE_SUCCESS";
export const GET_LIST_ROLE_ERROR = "GET_LIST_ROLE_ERROR";
export const ASIGN_PROJECT_TO_MEMBER = "ASIGN_PROJECT_TO_MEMBER";
export const ASIGN_PROJECT_TO_MEMBER_SUCCESS =
  "ASIGN_PROJECT_TO_MEMBER_SUCCESS";
export const ASIGN_PROJECT_TO_MEMBER_ERROR = "ASIGN_PROJECT_TO_MEMBER_ERROR";
export const GET_MEMBER_BY_EMAIL = "GET_MEMBER_BY_EMAIL";
export const GET_MEMBER_BY_EMAIL_SUCCESS = "GET_MEMBER_BY_EMAIL_SUCCESS";
export const GET_MEMBER_BY_EMAIL_ERROR = "GET_MEMBER_BY_EMAIL_ERROR";
export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const ADD_MEMBER = "ADD_MEMBER";
export const GET_ALL_MEMBER = "GET_ALL_MEMBER";
export const GET_ALL_MEMBER_SUCCESS = "GET_ALL_MEMBER_SUCCESS";
export const GET_ALL_MEMBER_FAILED = "GET_ALL_MEMBER_FAILED";

/* PROJECTS ACTION */
export const ACTION_ERROR = "ACTION_ERROR";
export const GET_LIST_PROJECT_DETAIL = "GET_LIST_PROJECT_DETAIL";
export const GET_LIST_PROJECT_DETAIL_SUCCESS =
  "GET_LIST_PROJECT_DETAIL_SUCCESS";
export const GET_LIST_PROJECT_DETAIL_ERROR = "GET_LIST_PROJECT_DETAIL_ERROR";
export const GET_LIST_DATASET = "GET_LIST_DATASET";
export const GET_LIST_DATASET_SUCCESS = "GET_LIST_DATASET_SUCCESS";
export const GET_LIST_DATASET_ERROR = "GET_LIST_DATASET_ERROR";
export const SET_CURRENT_PROJECT = "SET_CURRENT_PROJECT";
export const SET_CURRENT_DATASET = "SET_CURRENT_DATASET";
export const GET_LIST_DATASET_DETAIL = "GET_LIST_DATASET_DETAIL";
export const GET_LIST_DATASET_DETAIL_SUCCESS =
  "GET_LIST_DATASET_DETAIL_SUCCESS";
export const GET_LIST_DATASET_DETAIL_ERROR = "GET_LIST_DATASET_DETAIL_ERROR";
export const CREATE_NEW_DATASET = "CREATE_NEW_DATASET";
export const CREATE_NEW_DATASET_SUCCESS = "CREATE_NEW_DATASET_SUCCESS";
export const CREATE_NEW_DATASET_ERROR = "CREATE_NEW_DATASET_ERROR";
export const GET_SUMMARY_PROJECTS = "GET_SUMMARY_PROJECTS";
export const GET_SUMMARY_PROJECTS_SUCCESS = "GET_SUMMARY_PROJECTS_SUCCESS";
export const GET_SUMMARY_PROJECTS_ERROR = "GET_SUMMARY_PROJECTS_ERROR";
export const GET_EMAIL_LIST = "GET_EMAIL_LIST";
export const GET_ROLE_LIST = "GET_ROLE_LIST";
export const GET_ASSIGN_MEMBER_LIST = "GET_ASSIGN_MEMBER_LIST";
export const GET_EMAIL_LIST_SUCCESS = "GET_EMAIL_LIST_SUCCESS";
export const GET_ROLE_LIST_SUCCESS = "GET_ROLE_LIST_SUCCESS";
export const GET_ASSIGN_MEMBER_LIST_SUCCESS = "GET_ASSIGN_MEMBER_LIST_SUCCESS";
export const GET_EMAIL_LIST_ERROR = "GET_EMAIL_LIST_ERROR";
export const GET_ROLE_LIST_ERROR = "GET_ROLE_LIST_ERROR";
export const GET_ASSIGN_MEMBER_LIST_ERROR = "GET_ASSIGN_MEMBER_LIST_ERROR";
export const CREATE_NEW_PROJECT = "CREATE_NEW_PROJECT";
export const CREATE_NEW_PROJECT_SUCCESS = "CREATE_NEW_PROJECT_SUCCESS";
export const GET_PROJECT_BY_ID = "GET_PROJECT_BY_ID";
export const GET_PROJECT_BY_ID_SUCCESS = "GET_PROJECT_BY_ID_SUCCESS";
export const GET_PROJECT_BY_ID_ERROR = "GET_PROJECT_BY_ID_ERROR";
export const GET_FIRST_DATASET_EXIST_SOURCE = "GET_FIRST_DATASET_EXIST_SOURCE";
export const GET_FIRST_DATASET_EXIST_SOURCE_SUCCESS =
  "GET_FIRST_DATASET_EXIST_SOURCE_SUCCESS";
export const GET_FIRST_DATASET_EXIST_SOURCE_ERROR =
  "GET_FIRST_DATASET_EXIST_SOURCE_ERROR";
export const GET_CURRENT_PROJECT_MEMBERS = "GET_CURRENT_PROJECT_MEMBERS";
export const GET_CURRENT_PROJECT_MEMBERS_SUCCESS =
  "GET_CURRENT_PROJECT_MEMBERS_SUCCESS";
export const GET_CURRENT_PROJECT_MEMBERS_ERROR =
  "GET_CURRENT_PROJECT_MEMBERS_ERROR";
export const ASSIGN_TASK_TO_MEMBER = "ASSIGN_TASK_TO_MEMBER";
export const ASSIGN_TASK_TO_MEMBER_BY_DATASET =
  "ASSIGN_TASK_TO_MEMBER_BY_DATASET";
export const ASSIGN_TASK_TO_MEMBER_SUCCESS = "ASSIGN_TASK_TO_MEMBER_SUCCESS";
export const ASSIGN_TASK_TO_MEMBER_ERROR = "ASSIGN_TASK_TO_MEMBER_ERROR";
export const UNASSIGN_TASK_TO_MEMBER = "UNASSIGN_TASK_TO_MEMBER";
export const UNASSIGN_TASK_TO_MEMBER_BY_DATASET =
  "UNASSIGN_TASK_TO_MEMBER_BY_DATASET";
export const UNASSIGN_TASK_TO_MEMBER_SUCCESS =
  "UNASSIGN_TASK_TO_MEMBER_SUCCESS";
export const UNASSIGN_TASK_TO_MEMBER_ERROR = "UNASSIGN_TASK_TO_MEMBER_ERROR";
export const GET_PROJECT_LABELS = "GET_PROJECT_LABELS";
export const GET_PROJECT_LABELS_SUCCESS = "GET_PROJECT_LABELS_SUCCESS";
export const GET_PROJECT_LABELS_ERROR = "GET_PROJECT_LABELS_ERROR";
export const IS_LOADING = "IS_LOADING";

export const GET_LABEL_LIST = "GET_LABEL_LIST";
export const GET_LABEL_LIST_SUCCESS = "GET_LABEL_LIST_SUCCESS";
export const GET_LABEL_LIST_FAILURE = "GET_LABEL_LIST_FAILURE";

/* classification */
export const GET_PROJECT_CLASSIFICATIONS = "GET_PROJECT_CLASSIFICATIONS";
export const GET_PROJECT_CLASSIFICATIONS_SUCCESS =
  "GET_PROJECT_CLASSIFICATIONS_SUCCESS";
export const GET_PROJECT_CLASSIFICATIONS_ERROR =
  "GET_PROJECT_CLASSIFICATIONS_ERROR";
export const ADD_NEW_CLASSIFICATION = "ADD_NEW_CLASSIFICATION";
export const ADD_NEW_CLASSIFICATION_SUCCESS = "ADD_NEW_CLASSIFICATION_SUCCESS";
export const ADD_NEW_CLASSIFICATION_ERROR = "ADD_NEW_CLASSIFICATION_ERROR";
export const UPDATE_CLASSIFICATION = "UPDATE_CLASSIFICATION";
export const UPDATE_CLASSIFICATION_SUCCESS = "UPDATE_CLASSIFICATION_SUCCESS";
export const UPDATE_CLASSIFICATION_ERROR = "UPDATE_CLASSIFICATION_ERROR";
export const DELETE_CLASSIFICATION = "DELETE_CLASSIFICATION";
export const DELETE_CLASSIFICATION_SUCCESS = "DELETE_CLASSIFICATION_SUCCESS";
/* end - classification */

export const GET_MODULE_BY_ROLE_IN_PROJECT = "GET_MODULE_BY_ROLE_IN_PROJECT";
export const GET_MODULE_BY_ROLE_IN_PROJECT_SUCCESS =
  "GET_MODULE_BY_ROLE_IN_PROJECT_SUCCESS";
export const GET_MODULE_BY_ROLE_IN_PROJECT_ERROR =
  "GET_MODULE_BY_ROLE_IN_PROJECT_ERROR";
export const DELETE_PROJECT_BY_ID = "DELETE_PROJECT_BY_ID";
export const DELETE_PROJECT_BY_ID_SUCCESS = "DELETE_PROJECT_BY_ID_SUCCESS";
export const ADD_CLASS = "projects/ADD_CLASS";
export const ADD_CLASS_SUCCESS = "projects/ADD_CLASS_SUCCESS";
export const ADD_CLASS_FAILURE = "projects/ADD_CLASS_FAILURE";
export const UPDATE_CLASS = "projects/UPDATE_CLASS";
export const UPDATE_CLASS_SUCCESS = "projects/UPDATE_CLASS_SUCCESS";
export const UPDATE_CLASS_FAILURE = "projects/UPDATE_CLASS_FAILURE";
export const DELETE_CLASS = "projects/DELETE_CLASS";
export const DELETE_CLASS_SUCCESS = "projects/DELETE_CLASS_SUCCESS";
export const DELETE_CLASS_FAILURE = "projects/DELETE_CLASS_FAILURE";
export const HANDLE_LABELS_FAILURE = "HANDLE_LABELS_FAILURE";
export const EXPORT_DATASET = "EXPORT_DATASET";
export const EXPORT_DATASET_SUCCESS = "EXPORT_DATASET_SUCCESS";
export const EXPORT_DATASET_ERROR = "EXPORT_DATASET_ERROR";
export const GET_EXPORT_DATASET = "GET_EXPORT_DATASET";
export const GET_EXPORT_DATASET_SUCCESS = "GET_EXPORT_DATASET_SUCCESS";
export const GET_EXPORT_DATASET_ERROR = "GET_EXPORT_DATASET_ERROR";
export const PREPARE_CUSTOMER_DATA = "PREPARE_CUSTOMER_DATA";
export const PREPARE_CUSTOMER_DATA_SUCCESS = "PREPARE_CUSTOMER_DATA_SUCCESS";
export const PREPARE_CUSTOMER_DATA_ERROR = "PREPARE_CUSTOMER_DATA_ERROR";
export const GET_SOURCE_STATUS = "GET_SOURCE_STATUS";
export const GET_SOURCE_STATUS_SUCCESS = "GET_SOURCE_STATUS_SUCCESS";
export const GET_SOURCE_STATUS_ERROR = "GET_SOURCE_STATUS_ERROR";
export const DATASET_ADD_MORE_DATA = "DATASET_ADD_MORE_DATA";
export const DATASET_ADD_MORE_DATA_SUCCESS = "DATASET_ADD_MORE_DATA_SUCCESS";
export const DATASET_ADD_MORE_DATA_ERROR = "DATASET_ADD_MORE_DATA_ERROR";
export const INVITE_MEMBERS_TO_PROJECT = "INVITE_MEMBERS_TO_PROJECT";
export const INVITE_MEMBERS_TO_PROJECT_SUCCESS =
  "INVITE_MEMBERS_TO_PROJECT_SUCCESS";
export const EDIT_MEMBERS_TO_PROJECT = "EDIT_MEMBERS_TO_PROJECT";
export const EDIT_MEMBERS_TO_PROJECT_SUCCESS =
  "EDIT_MEMBERS_TO_PROJECT_SUCCESS";
export const DELETE_MEMBERS_TO_PROJECT = "DELETE_MEMBERS_TO_PROJECT";
export const DELETE_MEMBERS_TO_PROJECT_SUCCESS =
  "DELETE_MEMBERS_TO_PROJECT_SUCCESS";
export const RESET_SOURCE_STATUS = "RESET_SOURCE_STATUS";
export const RESET_SOURCE_STATUS_SUCCESS = "RESET_SOURCE_STATUS_SUCCESS";
export const RESET_SOURCE_STATUS_ERROR = "RESET_SOURCE_STATUS_ERROR";
export const SET_CURRENT_LABEL = "SET_CURRENT_LABEL";
export const ADD_NEW_LABEL_ATTRIBUTE = "ADD_NEW_LABEL_ATTRIBUTE";
export const ADD_NEW_LABEL_ATTRIBUTE_SUCCESS =
  "ADD_NEW_LABEL_ATTRIBUTE_SUCCESS";
export const ADD_NEW_LABEL_ATTRIBUTE_ERROR = "ADD_NEW_LABEL_ATTRIBUTE_ERROR";
export const GET_LABEL_ATTRIBUTE = "GET_LABEL_ATTRIBUTE";
export const GET_LABEL_ATTRIBUTE_SUCCESS = "GET_LABEL_ATTRIBUTE_SUCCESS";
export const GET_LABEL_ATTRIBUTE_ERROR = "GET_LABEL_ATTRIBUTE_ERROR";
export const DELETE_ATTRIBUTE = "DELETE_ATTRIBUTE";
export const DELETE_ATTRIBUTE_SUCCESS = "DELETE_ATTRIBUTE_SUCCESS";
export const DELETE_ATTRIBUTE_ERROR = "DELETE_ATTRIBUTE_ERROR";
export const UPDATE_ATTRIBUTE = "UPDATE_ATTRIBUTE";
export const UPDATE_ATTRIBUTE_SUCCESS = "UPDATE_ATTRIBUTE_SUCCESS";
export const UPDATE_ATTRIBUTE_ERROR = "UPDATE_ATTRIBUTE_ERROR";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const CLONE_DATASETS = "CLONE_DATASETS";
export const CLONE_DATASETS_SUCCESS = "CLONE_DATASETS_SUCCESS";
export const UPDATE_DATASET = "UPDATE_DATASET";
export const UPDATE_DATASET_SUCCESS = "UPDATE_DATASET_SUCCESS";
export const UPDATE_DATASET_ERROR = "UPDATE_DATASET_ERROR";
export const DELETE_DATASET = "DELETE_DATASET";
export const DELETE_DATASET_SUCCESS = "DELETE_DATASET_SUCCESS";
export const DELETE_DATASET_ERROR = "DELETE_DATASET_ERROR";
export const GET_VERSIONS_DATASETS_SOURCE = "GET_VERSIONS_DATASETS_SOURCE";
export const PUBLIC_DATASET = "PUBLIC_DATASET";
export const PUBLIC_DATASET_SUCCESS = "PUBLIC_DATASET_SUCCESS";
export const CREATE_NEW_TAG_SOURCE = "CREATE_NEW_TAG_SOURCE";
export const GET_TAGS_BY_PROJECT_ID_SUCCESS = "GET_TAGS_BY_PROJECT_ID_SUCCESS";
export const GET_TAGS_BY_PROJECT_ID_ERROR = "GET_TAGS_BY_PROJECT_ID_ERROR";

export const GET_PROJECTS_BY_TEXT_SEARCH = "GET_PROJECTS_BY_TEXT_SEARCH";
export const GET_PROJECTS_BY_TEXT_SEARCH_SUCCESS =
  "GET_PROJECTS_BY_TEXT_SEARCH_SUCCESS";
export const DELETE_DATASETS = "DELETE_DATASETS";
export const DELETE_DATASET_DETAILS = "DELETE_DATASET_DETAILS";

export const GET_TOTAL_APPROVE_ANNOTATION_EACH_CLASS_IN_PROJECT =
  "GET_TOTAL_APPROVE_ANNOTATION_EACH_CLASS_IN_PROJECT";
export const GET_TOTAL_APPROVE_ANNOTATION_EACH_CLASS_IN_PROJECT_SUCCESS =
  "GET_TOTAL_APPROVE_ANNOTATION_EACH_CLASS_IN_PROJECT_SUCCESS";
export const GET_TOTAL_APPROVE_ANNOTATION_EACH_CLASS_IN_PROJECT_ERROR =
  "GET_TOTAL_APPROVE_ANNOTATION_EACH_CLASS_IN_PROJECT_ERROR";

export const GET_TOTAL_APPROVE_IMG_CLASS_IN_PROJECT =
  "GET_TOTAL_APPROVE_IMG_CLASS_IN_PROJECT";
export const GET_TOTAL_APPROVE_IMG_CLASS_IN_PROJECT_SUCCESS =
  "GET_TOTAL_APPROVE_IMG_CLASS_IN_PROJECT_SUCCESS";
export const GET_TOTAL_APPROVE_IMG_CLASS_IN_PROJECT_ERROR =
  "GET_TOTAL_APPROVE_IMG_CLASS_IN_PROJECT_ERROR";

export const GET_LABELER_STATUS_IN_PROJECT = "GET_LABELER_STATUS_IN_PROJECT";
export const GET_LABELER_STATUS_IN_PROJECT_SUCCESS =
  "GET_LABELER_STATUS_IN_PROJECT_SUCCESS";
export const GET_LABELER_STATUS_IN_PROJECT_ERROR =
  "GET_LABELER_STATUS_IN_PROJECT_ERROR";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* SURVEY DETAIL APP*/
export const SURVEY_GET_DETAILS = "SURVEY_GET_DETAILS";
export const SURVEY_GET_DETAILS_SUCCESS = "SURVEY_GET_DETAILS_SUCCESS";
export const SURVEY_GET_DETAILS_ERROR = "SURVEY_GET_DETAILS_ERROR";
export const SURVEY_DELETE_QUESTION = "SURVEY_DELETE_QUESTION";
export const SURVEY_SAVE = "SURVEY_SAVE";

/* AGENTS */
export const GET_AGENTS = "GET_AGENTS";
export const GET_AGENTS_SUCCESS = "GET_AGENTS_SUCCESS";
export const GET_AGENTS_ERROR = "GET_AGENTS_ERROR";
export const GET_DATASETS = "GET_DATASET";
export const GET_DATASETS_SUCCESS = "GET_DATASETS_SUCCESS";
export const GET_DATASETS_ERROR = "GET_DATASETS_ERROR";
export const GET_TASKS = "GET_TASKS";
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS";
export const GET_TASKS_ERROR = "GET_TASKS_ERROR";
export const ADD_TASK = "ADD_TASKS";
export const ADD_TASK_SUCCESS = "ADD_TASK_SUCCESS";
export const ADD_TASK_ERROR = "ADD_TASK_ERROR";

/* ORGANIZATION */
export const SETUP_ORGANIZATION = "SETUP_ORGANIZATION";
export const SETUP_ORGANIZATION_SUCCESS = "SETUP_ORGANIZATION_SUCCESS";
export const SETUP_ORGANIZATION_ERROR = "SETUP_ORGANIZATION_ERROR";
export const GET_ORGANIZATION_BY_ID = "GET_ORGANIZATION_BY_ID";
export const GET_ORGANIZATION_BY_ID_SUCCESS = "GET_ORGANIZATION_BY_ID_SUCCESS";
export const GET_ORGANIZATION_BY_ID_ERROR = "GET_ORGANIZATION_BY_ID_ERROR";
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const UPDATE_ORGANIZATION_SUCCESS = "UPDATE_ORGANIZATION_SUCCESS";
export const UPDATE_ORGANIZATION_ERROR = "UPDATE_ORGANIZATION_ERROR";
export const GET_LIST_ORGANIZATION = "GET_LIST_ORGANIZATION";
export const GET_LIST_ORGANIZATION_SUCCESS = "GET_LIST_ORGANIZATION_SUCCESS";
export const GET_LIST_ORGANIZATION_ERROR = "GET_LIST_ORGANIZATION_ERROR";
export const GET_LIST_ORGANIZATION_BY_USER_ID =
  "GET_LIST_ORGANIZATION_BY_USER_ID";
export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION";
export const DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS";
export const GET_STORAGE_SETTING_BY_ORGANIZATION_ID =
  "GET_STORAGE_SETTING_BY_ORGANIZATION_ID";
export const GET_STORAGE_SETTING_BY_ORGANIZATION_ID_SUCCESS =
  "GET_STORAGE_SETTING_BY_ORGANIZATION_ID_SUCCESS";
export const ADD_STORAGE_SETTING = "ADD_STORAGE_SETTING";
export const GET_LIST_ORGANIZATION_FOR_BUSINESS_ADMIN =
  "GET_LIST_ORGANIZATION_FOR_BUSINESS_ADMIN";
export const GET_LIST_ORGANIZATION_SUCCESS_FOR_BUSINESS_ADMIN =
  "GET_LIST_ORGANIZATION_SUCCESS_FOR_BUSINESS_ADMIN";
export const GET_LIST_ORGANIZATION_ERROR_FOR_BUSINESS_ADMIN =
  "GET_LIST_ORGANIZATION_ERROR_FOR_BUSINESS_ADMIN";
export const GET_CHART_ORGANIZATION_FOR_BUSINESS_ADMIN =
  "GET_CHART_ORGANIZATION_FOR_BUSINESS_ADMIN";
export const GET_CHART_ORGANIZATION_FOR_BUSINESS_ADMIN_SUCCESS =
  "GET_CHART_ORGANIZATION_FOR_BUSINESS_ADMIN_SUCCESS";
export const GET_CHART_ORGANIZATION_FOR_BUSINESS_ADMIN_FAILURE =
  "GET_CHART_ORGANIZATION_FOR_BUSINESS_ADMIN_FAILURE";

// API  ORGANIZATION
export const GET_USER_GROUP_USER = "GET_USER_GROUP_USER";
export const GET_USER_GROUP_USER_SUCCESS = "GET_USER_GROUP_USER_SUCCESS";

export const SWITCH_ORGANIZATION_USER = "SWITCH_ORGANIZATION_USER";
export const ADD_ORGANIZATION = "ADD_ORGANIZATION";
export const UPDATE_ORGANIZATION_NEW = "UPDATE_ORGANIZATION_NEW";

/* NOTIFICATION */
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export const FETCH_PUSH_NOTIFICATIONS = "FETCH_PUSH_NOTIFICATIONS";
export const FETCH_PUSH_NOTIFICATIONS_SUCCESS =
  "FETCH_PUSH_NOTIFICATIONS_SUCCESS";
export const FETCH_PUSH_NOTIFICATIONS_FAILURE =
  "FETCH_PUSH_NOTIFICATIONS_FAILURE";
export const UPDATE_CURRENT_NOTIFICATIONS_PAGE =
  "UPDATE_CURRENT_NOTIFICATIONS_PAGE";
export const NO_MORE_NOTIFICATION = "NO_MORE_NOTIFICATION";
export const READ_ONE_NOTIFICATION = "READ_ONE_NOTIFICATION";
export const READ_ONE_NOTIFICATION_SUCCESS = "READ_ONE_NOTIFICATION_SUCCESS";
export const CHANGE_VIEW_NOTIFICATION = "CHANGE_VIEW_NOTIFICATION";
export const MARK_ALL_NOTIFICATION_AS_READ = "MARK_ALL_NOTIFICATION_AS_READ";
export const MARK_ALL_NOTIFICATION_AS_READ_SUCCESS =
  "MARK_ALL_NOTIFICATION_AS_READ_SUCCESS";
export const MARK_ALL_NOTIFICATION_AS_READ_ERROR =
  "MARK_ALL_NOTIFICATION_AS_READ_ERROR";

/* NOTIFICATION MODAL */
export const SHOW_NOTI = "SHOW_NOTI";
export const REMOVE_NOTI = "REMOVE_NOTI";
export const CLEAR_NOTI = "CLEAR_NOTI";

// REPORT DATA
export const GET_REPORT_DATA = "GET_REPORT_DATA";
export const GET_REPORT_DATA_SUCCESS = "GET_REPORT_DATA_SUCCESS";
export const GET_TOOL_CHART_DATA = "GET_TOOL_CHART_DATA";
export const GET_TOOL_CHART_DATA_SUCCESS = "GET_TOOL_CHART_DATA_SUCCESS";
export const GET_USER_LOGIN_CHART_DATA = "GET_USER_LOGIN_CHART_DATA";
export const GET_USER_LOGIN_CHART_DATA_SUCCESS =
  "GET_USER_LOGIN_CHART_DATA_SUCCESS";

// model management
export const GET_MODEL = "GET_MODEL";
export const GET_MODEL_SUCCESS = "GET_MODEL_SUCCESS";
export const GET_MODEL_CHECKPOINT = "GET_MODEL_CHECKPOINT";
export const GET_MODEL_CHECKPOINT_SUCCESS = "GET_MODEL_CHECKPOINT_SUCCESS";

// pricing
export const GET_LIST_PRICING_PACKAGES = "GET_LIST_PRICING_PACKAGES";
export const GET_LIST_PRICING_PACKAGES_SUCCESS =
  "GET_LIST_PRICING_PACKAGES_SUCCESS";
export const GET_LIST_PRICING_PACKAGES_FAILURE =
  "GET_LIST_PRICING_PACKAGES_FAILURE";
export const GET_CURRENT_PRICING_PACKAGE = "GET_CURRENT_PRICING_PACKAGE";
export const GET_CURRENT_PRICING_PACKAGE_SUCCESS =
  "GET_CURRENT_PRICING_PACKAGE_SUCCESS";
export const GET_CURRENT_PRICING_PACKAGE_FAILURE =
  "GET_CURRENT_PRICING_PACKAGE_FAILURE";
export const GET_BILLING_LIST = "GET_BILLING_LIST";
export const GET_BILLING_LIST_SUCCESS = "GET_BILLING_LIST_SUCCESS";
export const GET_BILLING_LIST_FAILURE = "GET_BILLING_LIST_FAILURE";
export const SEND_PRICING_CONTACT_EMAIL_TO_SYSTEM_ADMIN =
  "SEND_PRICING_CONTACT_EMAIL_TO_SYSTEM_ADMIN";

// tour
export const JUMP_TO_STEP = "JUMP_TO_STEP";
export const UPDATE_IS_OPENED_TOUR_GUIDE = "UPDATE_IS_OPENED_TOUR_GUIDE";

// model
export const GET_MODEL_LIST = "GET_MODEL_LIST";
export const GET_MODEL_LIST_SUCCESS = "GET_MODEL_LIST_SUCCESS";
export const GET_MODEL_LIST_FAILURE = "GET_MODEL_LIST_FAILURE";
export const GET_CUSTOM_MODEL_LIST = "GET_CUSTOM_MODEL_LIST";
export const GET_CUSTOM_MODEL_LIST_SUCCESS = "GET_CUSTOM_MODEL_LIST_SUCCESS";
export const GET_CUSTOM_MODEL_LIST_FAILURE = "GET_CUSTOM_MODEL_LIST_FAILURE";
export const GENERATE_MODEL_SUCCESS = "GENERATE_MODEL_SUCCESS";
export const GENERATE_MODEL_FAILURE = "GENERATE_MODEL_FAILURE";
export const GENERATE_MODEL = "GENERATE_MODEL";
export const CLOSE_MODEL_NOTI = "CLOSE_MODEL_NOTI";
export const GENERATE_MODEL_RESPONSE = "GENERATE_MODEL_RESPONSE";
export const SET_CURRENT_MODEL = "SET_CURRENT_MODEL";
export const GET_MODEL_IMAGE_URL = "GET_MODEL_IMAGE_URL";
export const GET_MODEL_IMAGE_URL_SUCCESS = "GET_MODEL_IMAGE_URL_SUCCESS";

export const TRAINING_MODEL_RESPONSE = "GENERATE_MODEL_RESPONSE";
export const TRAINING_MODEL_SUCCESS = "GENERATE_MODEL_SUCCESS";
export const TRAINING_MODEL_FAILURE = "GENERATE_MODEL_FAILURE";

export const GET_DEFAULT_MODEL_LIST = "GET_DEFAULT_MODEL_LIST";
export const GET_DEFAULT_MODEL_LIST_SUCCESS = "GET_DEFAULT_MODEL_LIST_SUCCESS";
export const GET_DEFAULT_MODEL_LIST_FAILURE = "GET_DEFAULT_MODEL_LIST_FAILURE";

export const SET_OPEN_CHANGE_MODEL_STATUS_MODEL =
  "SET_OPEN_CHANGE_MODEL_STATUS_MODEL";
export const SET_OPEN_RETRAIN_MODEL = "SET_OPEN_RETRAIN_MODEL";

export const UPDATE_CUSTOM_MODEL_STATUS = "UPDATE_CUSTOM_MODEL_STATUS";
export const UPDATE_CUSTOM_MODEL_STATUS_SUCCESS =
  "UPDATE_CUSTOM_MODEL_STATUS_SUCCESS";
export const UPDATE_CUSTOM_MODEL_STATUS_FAILURE =
  "UPDATE_CUSTOM_MODEL_STATUS_FAILURE";

export const RETRAIN_MODEL = "RETRAIN_MODEL";
export const RETRAIN_MODEL_SUCCESS = "RETRAIN_MODEL_SUCCESS";
export const RETRAIN_MODEL_FAILURE = "RETRAIN_MODEL_FAILURE";

export const ADD_GENERATION_MODEL = "ADD_GENERATION_MODEL";
export const ADD_GENERATION_MODEL_SUCCESS = "ADD_GENERATION_MODEL_SUCCESS";
export const ADD_GENERATION_MODEL_ERROR = "ADD_GENERATION_MODEL_ERROR";

export const GET_ACTIVE_MODEL_LIST = "GET_ACTIVE_MODEL_LIST";
export const GET_ACTIVE_MODEL_LIST_SUCCESS = "GET_ACTIVE_MODEL_LIST_SUCCESS";
export const GET_ACTIVE_MODEL_LIST_FAILURE = "GET_ACTIVE_MODEL_LIST_FAILURE";

export const DELETE_MODEL_SUCCESS = "DELETE_MODEL_SUCCESS";

// business admin
export const GET_USER_LIST = "GET_USER_LIST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_ERROR = "GET_USER_LIST_ERROR";
export const GET_USER_CHART = "GET_USER_CHART";
export const GET_USER_CHART_SUCCESS = "GET_USER_CHART_SUCCESS";
export const GET_USER_CHART_ERROR = "GET_USER_CHART_ERROR";
export const ADD_USER = "ADD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const GET_USER_REPORT = "GET_USER_REPORT";
export const GET_USER_REPORT_SUCCESS = "GET_USER_REPORT_SUCCESS";
export const GET_USER_REPORT_ERROR = "GET_USER_REPORT_ERROR";

// enterprise
export const GET_ENTERPRISE_USER_LIST = "GET_ENTERPRISE_USER_LIST";
export const GET_ENTERPRISE_USER_LIST_SUCCESS =
  "GET_ENTERPRISE_USER_LIST_SUCCESS";
export const GET_ENTERPRISE_USER_LIST_ERROR = "GET_ENTERPRISE_USER_LIST_ERROR";
export const ADD_ENTERPRISE_LOG_PRICE = "ADD_ENTERPRISE_LOG_PRICE";
export const ADD_ENTERPRISE_LOG_PRICE_SUCCESS =
  "ADD_ENTERPRISE_LOG_PRICE_SUCCESS";
export const ADD_ENTERPRISE_LOG_PRICE_ERROR = "ADD_ENTERPRISE_LOG_PRICE_ERROR";
export const EDIT_ENTERPRISE_LOG_PRICE = "EDIT_ENTERPRISE_LOG_PRICE";
export const EDIT_ENTERPRISE_LOG_PRICE_SUCCESS =
  "EDIT_ENTERPRISE_LOG_PRICE_SUCCESS";
export const EDIT_ENTERPRISE_LOG_PRICE_ERROR =
  "EDIT_ENTERPRISE_LOG_PRICE_ERROR";
export const DELETE_ENTERPRISE_LOG_PRICE = "DELETE_ENTERPRISE_LOG_PRICE";
export const DELETE_ENTERPRISE_LOG_PRICE_SUCCESS =
  "DELETE_ENTERPRISE_LOG_PRICE_SUCCESS";
export const DELETE_ENTERPRISE_LOG_PRICE_ERROR =
  "DELETE_ENTERPRISE_LOG_PRICE_ERROR";

// issue
export const GET_ISSUE_LIST = "GET_ISSUE_LIST";
export const GET_ISSUE_LIST_SUCCESS = "GET_ISSUE_LIST_SUCCESS";
export const GET_ISSUE_LIST_ERROR = "GET_ISSUE_LIST_ERROR";

// automatic annotation
export const AUTOMATIC_ANNOTATION = "AUTOMATIC_ANNOTATION";
export const AUTOMATIC_ANNOTATION_SUCCESS = "AUTOMATIC_ANNOTATION_SUCCESS";
export const AUTOMATIC_ANNOTATION_ERROR = "AUTOMATIC_ANNOTATION_ERROR";

// benchmark
export const GET_ACTION_HISTORY = "GET_ACTION_HISTORY";
export const GET_ACTION_HISTORY_SUCCESS = "GET_ACTION_HISTORY_SUCCESS";
export const GET_ACTION_HISTORY_FAILURE = "GET_ACTION_HISTORY_FAILURE";
export const GET_BENCHMARK = "GET_BENCHMARK";
export const GET_BENCHMARK_SUCCESS = "GET_BENCHMARK_SUCCESS";
export const GET_BENCHMARK_FAILURE = "GET_BENCHMARK_FAILURE";

// dataset
export const SET_SELECTED_DATASETS = "SET_SELECTED_DATASETS";
export const GET_LABELBOX_COUNTER = "GET_LABELBOX_COUNTER";
export const GET_LABELBOX_COUNTER_SUCCESS = "GET_LABELBOX_COUNTER_SUCCESS";
export const GET_LABELBOX_COUNTER_ERROR = "GET_LABELBOX_COUNTER_ERROR";

export const SET_DATASET_LOADING = "SET_DATASET_LOADING";

export const CREATE_CLONE_DATASET_JOB_SUCCESS =
  "CREATE_CLONE_DATASET_JOB_SUCCESS";
export const CLONE_DATASET_JOB_SUCCESS = "CLONE_DATASET_JOB_SUCCESS";
export const CLONE_DATASET_JOB_ERROR = "CLONE_DATASET_JOB_ERROR";

export const CREATE_MERGE_DATASET_JOB_SUCCESS =
  "CREATE_MERGE_DATASET_JOB_SUCCESS";

export const SET_ERROR_ASSIGN_TASK = "SET_ERROR_ASSIGN_TASK";

export const SET_DATASET_DETAIL_VIEW_TYPE = "SET_DATASET_DETAIL_VIEW_TYPE";
export const SET_SELECTED_DATASET_DETAILS = "SET_SELECTED_DATASET_DETAILS";
export const UPDATE_ADVANCED_FILTERS = "UPDATE_ADVANCED_FILTERS";
export const SET_TYPE_TAG = "SET_TYPE_TAG";

export const GET_DATA_BY_ADVANCED_FILTERS = "GET_DATA_BY_ADVANCED_FILTERS";
export const GET_DATA_BY_ADVANCED_FILTERS_SUCCESS =
  "GET_DATA_BY_ADVANCED_FILTERS_SUCCESS";
export const GET_DATA_BY_ADVANCED_FILTERS_ERROR =
  "GET_DATA_BY_ADVANCED_FILTERS_ERROR";

// tags
export const GET_TAGS_BY_SOURCE_ID = "GET_TAGS_BY_SOURCE_ID";
export const GET_TAGS_BY_PROJECT_ID = "GET_TAGS_BY_PROJECT_ID";
export const GET_TAGS_DATA_FOR_TAG_FORM = "GET_TAGS_DATA_FOR_TAG_FORM";
export const GET_TAGS_DATA_FOR_TAG_FORM_SUCCESS =
  "GET_TAGS_DATA_FOR_TAG_FORM_SUCCESS";
export const DELETE_TAG_OF_PROJECT = "DELETE_TAG_OF_PROJECT";
export const DELETE_TAG_OF_SOURCE = "DELETE_TAG_OF_SOURCE";
export const ADD_TAG_TO_SOURCE = "ADD_TAG_TO_SOURCE";
export const SEARCH_PROJECT_TAG_BY_TEXT = "SEARCH_PROJECT_TAG_BY_TEXT";
export const CREATE_PROJECT_TAG = "CREATE_PROJECT_TAG";
export const CREATE_PROJECT_TAG_AND_ADD_TO_SOURCE =
  "CREATE_PROJECT_TAG_AND_ADD_TO_SOURCE";
export const IMAGE_AUGMENTING = "IMAGE_AUGMENTING";

export const SET_ADVANCE_SEARCH_FILTER = "SET_ADVANCE_SEARCH_FILTER";

export const START_CLONE_DATASET = "START_CLONE_DATASET";

export const START_MERGE_DATASET = "START_MERGE_DATASET";

export const START_CLONE_SOURCES = "START_CLONE_SOURCES";

export const START_MERGE_SOURCES = "START_MERGE_SOURCES";

// TODO: Refactor because these code below could lead to cyclic module imports error
export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./surveyDetail/actions";
export * from "./members/actions";
export * from "./projects/actions";
export * from "./agent/actions";
export * from "./organization/actions";
export * from "./notification/actions";
export * from "./notificationModal/actions";
export * from "./dashboard/actions";
export * from "./pricing/actions";
export * from "./tour/actions";
export * from "./socket/actions";
export * from "./model/actions";
export * from "./businessAdmin/actions";
export * from "./enterprise/actions";
export * from "./benchmark/actions";
export * from "./datasets/actions";
export * from "./datasetDetail/actions";
