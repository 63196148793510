import axios from "../api";
import qs from "qs";
import moment from "moment";

export function getListPricingPackages() {
  return axios.get("/members/get-all-pricing-pack");
}

export function getCurrentPricingPack() {
  return axios.get("/members/get-pricing-pack-detail");
}

export function getPaymentSetup() {
  return axios.get("/payments/setup");
}

export function getPaymentSessionId(data) {
  return axios.post("payments/create-checkout-session", {
    ...data,
  });
}

export function getCheckoutSession(sessionId) {
  return axios.get(`payments/checkout-session?sessionId=${sessionId}`);
}

export function handlePricingData(data) {
  return axios.post("members/add-log-pricing/", {
    ...data,
  });
}

export function getBillingList(
  userEmail,
  selectedPageSize = 10,
  currentPage = 1,
  keywords = "",
  searchAdvanced = null,
  versionId = 0,
) {
  const query = {
    userEmail,
    pageSize: selectedPageSize,
    currentPage,
    keywords,
    versionId,
  };

  return axios.get(`payments/billings?${qs.stringify(query)}`, {
    search: searchAdvanced,
  });
}

export function getSubscriptionId(userEmail) {
  return axios.get(`payments/subscription?userEmail=${userEmail}`);
}

export function cancelTransaction({ transactionId, downgradeToFree }) {
  return axios.get(
    `payments/cancel?transactionId=${transactionId}&downgradeToFree=${downgradeToFree}`,
  );
}

export function downgradePlan({ transactionId, priceKey }) {
  return axios.put(`payments/downgrade`, {
    transactionId,
    priceKey,
  });
}

export function sendPricingContactEmailToSystemAdminAPI(payload) {
  return axios.post(`/members/send-mail-to-system-admin`, payload);
}

export function getUsedHistoryStorage({ pageSize = 10, currentPage = 1 }) {
  const currTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return axios.get(
    `/members/get-storage-detail?timezone=${currTimezone}&pageSize=${pageSize}&currentPage=${currentPage}`,
  );
}

export function getUsedHistoryAnnotation({ pageSize = 10, currentPage = 1 }) {
  const currTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return axios.get(
    `/members/get-used-time-detail?timezone=${currTimezone}&pageSize=${pageSize}&currentPage=${currentPage}`,
  );
}

export function getUsedHistoryAnnotationWithDate({ pageSize = 10, currentPage = 1, 
  startDate=moment(new Date()).subtract(30, "days").startOf('day').format('YYYY-MM-DD HH:mm:ss'), 
  endDate=moment(new Date()).endOf('day').format('YYYY-MM-DD HH:mm:ss') }) {
  const currTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return axios.get(
    `/members/get-used-time-detail-with-date?timezone=${currTimezone}&pageSize=${pageSize}&currentPage=${currentPage}&startDate=${startDate}&endDate=${endDate}`,
  );
}

export function getUsedHistoryAssistedToolImage({
  pageSize = 10,
  currentPage = 1,
}) {
  const currTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return axios.get(
    `/members/assisted-tool-time?type=image&timezone=${currTimezone}&pageSize=${pageSize}&currentPage=${currentPage}`,
  );
}

export function getUsedHistoryAssistedToolVideo({
  pageSize = 10,
  currentPage = 1,
}) {
  const currTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return axios.get(
    `/members/assisted-tool-time?type=video&timezone=${currTimezone}&pageSize=${pageSize}&currentPage=${currentPage}`,
  );
}

export function getTierListApi() {
  return axios.get(`/payments/get-enterprise-tiers`);
}

export function getPricingListByUser({ userId }) {
  return axios.get(`/pricing/get-all-pricing-pack-by-user?userId=${userId}`);
}

export function updatePlan(payload) {
  return axios.post(`/pricing/change-plan`, payload);
}
