
import { all, call, fork, takeEvery } from 'redux-saga/effects';
import {
  UPDATE_IS_OPENED_TOUR_GUIDE,
} from '../actions';
import parseJson from "parse-json";
import { getCookie } from "helpers/Utils";
import { updateIsOpenedTourGuideUserAPI } from './api'
import { get } from 'lodash';

function* updateIsOpenedTourGuideSaga({ payload, callback }) {
  try {
    const userInfo = getCookie("userInfo") && parseJson(getCookie("userInfo"));
    const { userId } = userInfo
    const response = yield call(updateIsOpenedTourGuideUserAPI, userId, payload)
    const statusCode = get(response, 'status')
    if (statusCode === 200) {
      if (callback) callback(response)
    }

  } catch (error) {

  }
}

export function* watchUpdateIsOpenedTourGuide() {
  yield takeEvery(UPDATE_IS_OPENED_TOUR_GUIDE, updateIsOpenedTourGuideSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchUpdateIsOpenedTourGuide),
  ]);
}