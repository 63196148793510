import {
    GET_MODEL,
    GET_MODEL_SUCCESS,
    GET_MODEL_CHECKPOINT,
    GET_MODEL_CHECKPOINT_SUCCESS,
} from "redux/actions";

export const getModel = (payload) => ({
    type: GET_MODEL,
    payload,
});

export const getModelSuccess = (payload) => ({
    type: GET_MODEL_SUCCESS,
    payload,
});

export const getModelCheckpoint = (payload, callback) => ({
    type: GET_MODEL_CHECKPOINT,
    payload,
    callback
});

export const getModelCheckpointSuccess = (payload) => ({
    type: GET_MODEL_CHECKPOINT_SUCCESS,
    payload,
});

