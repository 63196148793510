import { createSelector } from "reselect";
const namespace = "projectsApp";
export const selectProjectType = (state) =>
  state[namespace].currentProject?.data_type;

export const selectProjectsBenchmarkType = (state) =>
  state[namespace].currentProject?.benchmarkType || "benchmark";
export const selectProjects = (state) => state[namespace].projects;

export const selectProjectByProjectId = (projectId) =>
  createSelector([selectProjects], (projects) =>
    projects.find((project) => project.project_id === projectId),
  );

 


export const selectLabels = (state) => state[namespace].labels;
export const selectlistAllDatasets = (state) => state[namespace].currentProject?.listAllDatasets || [];
export const selectLabelNames = (state) =>
  state[namespace].labels.map((label) => label.name);
export const selectProjectName = (state) => state[namespace].currentProject?.name;
export const selectModuleRole = (state) => state[namespace].moduleRoleInProject;
export const selectProjectManager = (state) => !!state[namespace].moduleRoleInProject.find(
  (role) => role.role_code === "project_manager",
);
export const selectVIP = (state) => !!state[namespace].moduleRoleInProject.find(
  (role) => ["project_manager", "admin"].includes(role.role_code )
);
export const selectProjectId = (state) => state[namespace].currentProject?.project_id;
export const selectProjectLoading = (state) => state[namespace].loading;

