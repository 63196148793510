import {
  GET_MODEL_LIST_SUCCESS,
  GET_MODEL_LIST,
  GET_MODEL_LIST_FAILURE,
  GET_CUSTOM_MODEL_LIST_SUCCESS,
  GET_CUSTOM_MODEL_LIST,
  GET_CUSTOM_MODEL_LIST_FAILURE,
  CLOSE_MODEL_NOTI,
  GENERATE_MODEL_SUCCESS,
  GENERATE_MODEL_RESPONSE,
  GENERATE_MODEL_FAILURE,
  TRAINING_MODEL_SUCCESS,
  TRAINING_MODEL_RESPONSE,
  TRAINING_MODEL_FAILURE,
  SET_CURRENT_MODEL,
  GET_MODEL_IMAGE_URL,
  GET_MODEL_IMAGE_URL_SUCCESS,
  GET_DEFAULT_MODEL_LIST_FAILURE,
  GET_DEFAULT_MODEL_LIST_SUCCESS,
  GET_DEFAULT_MODEL_LIST,
  SET_OPEN_CHANGE_MODEL_STATUS_MODEL,
  SET_OPEN_RETRAIN_MODEL,
  RETRAIN_MODEL_FAILURE,
  RETRAIN_MODEL_SUCCESS,
  RETRAIN_MODEL,
  GET_ACTIVE_MODEL_LIST_SUCCESS,
  GET_ACTIVE_MODEL_LIST_FAILURE,
  GET_ACTIVE_MODEL_LIST,
  AUTOMATIC_ANNOTATION,
  AUTOMATIC_ANNOTATION_SUCCESS,
  AUTOMATIC_ANNOTATION_ERROR,
  ADD_GENERATION_MODEL,
  ADD_GENERATION_MODEL_SUCCESS,
  ADD_GENERATION_MODEL_ERROR,
  DELETE_MODEL_SUCCESS
} from "redux/actions";

const INIT_STATE = {
  models: [],
  loading: false,
  notiModal: {
    open: false,
    error: false,
    message: "",
  },
  currentModel: {},
  defaultModels: [],
  activeModels: [],
  activeModelsLoading: false,
  changeStatusModalObject: {},
  retrainModelModalObject: {},
  retrainModelLoading: false,
  automaticAnnotationLoading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MODEL_LIST_SUCCESS: {
      const { models } = action.payload;
      return {
        ...state,
        models,
        loading: false,
      };
    }
    case GET_MODEL_LIST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_MODEL_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_CUSTOM_MODEL_LIST_SUCCESS: {
      const { models } = action.payload;
      return {
        ...state,
        models,
        loading: false,
      };
    }
    case GET_CUSTOM_MODEL_LIST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CUSTOM_MODEL_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case CLOSE_MODEL_NOTI:
      return {
        ...state,
        notiModal: {
          open: false,
          error: false,
          message: "",
        },
      };
    case GENERATE_MODEL_SUCCESS:
      return {
        ...state,
        notiModal: {
          open: true,
          error: false,
          message: "Generate model successfully.",
        },
      };
    case GENERATE_MODEL_FAILURE:
      return {
        ...state,
        notiModal: {
          open: true,
          error: true,
          message: "Generate model failed.",
        },
      };
    case GENERATE_MODEL_RESPONSE:
      return {
        ...state,
        notiModal: {
          open: true,
          error: false,
          message: "Generating model...",
        },
      };
    case TRAINING_MODEL_SUCCESS:
      return {
        ...state,
        notiModal: {
          open: true,
          error: false,
          message: "Generate model successfully.",
        },
      };
    case TRAINING_MODEL_FAILURE:
      return {
        ...state,
        notiModal: {
          open: true,
          error: true,
          message: "Generate model failed.",
        },
      };
    case TRAINING_MODEL_RESPONSE:
      return {
        ...state,
        notiModal: {
          open: true,
          error: false,
          message: "Generating model...",
        },
      };
    case SET_CURRENT_MODEL:
      return {
        ...state,
        currentModel: action.payload,
      };
    case GET_MODEL_IMAGE_URL:
      let { models, currentModel: currentM } = state;
      let currentModel = models.filter((x) => x.model_id == action.payload);
      if (currentModel && currentModel.length > 0)
        currentModel[0].loading = true;
      if (currentM?.model_id == action.payload) currentM.loading = true;
      return {
        ...state,
        models,
        currentModel: currentM,
      };
    case GET_MODEL_IMAGE_URL_SUCCESS:
      let { modelId, image_list } = action.payload;
      let { models: lsModels, currentModel: current } = state;
      let cModel = lsModels.filter((x) => x.model_id == modelId);

      if (current?.model_id == modelId) {
        current.image_list = image_list;
        current.loading = false;
      }

      if (cModel && cModel.length > 0) {
        cModel[0].image_list = image_list;
        cModel[0].loading = false;
      }

      return {
        ...state,
        models: lsModels,
        currentModel: current,
      };
    case GET_DEFAULT_MODEL_LIST_SUCCESS: {
      const { models } = action.payload;
      return {
        ...state,
        defaultModels: models,
        loading: false,
      };
    }
    case GET_DEFAULT_MODEL_LIST: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_DEFAULT_MODEL_LIST_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case SET_OPEN_CHANGE_MODEL_STATUS_MODEL: {
      return {
        ...state,
        changeStatusModalObject: action.payload,
      };
    }
    case SET_OPEN_RETRAIN_MODEL: {
      return {
        ...state,
        retrainModelModalObject: action.payload,
      };
    }
    case RETRAIN_MODEL: {
      return {
        ...state,
        retrainModelLoading: true,
      };
    }

    case RETRAIN_MODEL_FAILURE:
    case RETRAIN_MODEL_SUCCESS: {
      return {
        ...state,
        retrainModelLoading: false,
      };
    }
    case GET_ACTIVE_MODEL_LIST_SUCCESS: {
      return {
        ...state,
        activeModels: action.payload,
        activeModelsLoading: false,
      };
    }
    case GET_ACTIVE_MODEL_LIST_FAILURE: {
      return {
        ...state,
        activeModelsLoading: false,
      };
    }
    case GET_ACTIVE_MODEL_LIST: {
      return {
        ...state,
        activeModelsLoading: true,
      };
    }
    case AUTOMATIC_ANNOTATION: {
      return {
        ...state,
        automaticAnnotationLoading: true,
      };
    }
    case AUTOMATIC_ANNOTATION_SUCCESS: {
      return {
        ...state,
        automaticAnnotationLoading: false,
      };
    }
    case AUTOMATIC_ANNOTATION_ERROR: {
      return {
        ...state,
        automaticAnnotationLoading: false,
      };
    }
    case ADD_GENERATION_MODEL: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADD_GENERATION_MODEL_SUCCESS:
    case ADD_GENERATION_MODEL_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_MODEL_SUCCESS: {
      const modelId = action.payload;
      let { models } = state;
      const listNewModel = models.filter(x => x.model_id != modelId);
      return {
        ...state,
        models: listNewModel
      };
    }

    default:
      return state;
  }
};
