import axios from "../api";

export const getDatasetDetailPerUser = (payload) => {
  const { currentPage = 1, pageSize = 10, projectId, isEmployee } = payload;
  let query = `/dataset/get-dataset-detail-by-user?pageSize=${pageSize}&currentPage=${currentPage}&projectId=${projectId}`;
  if (isEmployee) {
    query += `&isEmployee=${isEmployee}`;
  }
  return axios.get(
    query,
  );
}

export const getBenchmarkListApi = (payload) => {
  const { currentPage = 1, pageSize = 10, projectId } = payload;
  return axios.get(
    `/dataset/get-benchmark-list?pageSize=${pageSize}&currentPage=${currentPage}&projectId=${projectId}`,
  );
}

export const getBenchmarkReportApi = ({ projectId }) => {
  return axios.get(
    `/dataset/get-benchmark-report?projectId=${projectId}`
  );
}
