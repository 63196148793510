import { all, call, put, takeEvery } from "redux-saga/effects";
import { get } from "lodash";

import {
  GET_REPORT_DATA,
  GET_TOOL_CHART_DATA,
  GET_USER_LOGIN_CHART_DATA,
  showNoti,
} from "redux/actions";
import {
  getReportDataSuccess,
  getToolChartDataSuccess,
  getUserLoginChartDataSuccess,
} from "./actions";
import {
  getToolReportData,
  getToolReportChartData,
  getUserLoginChartData,
} from "./api";
import { ERROR_CASE } from "../../components/notificationModal/constants";

function* getReportDataSaga({ payload }) {
  const { pageSize = 5, page, matchFilter, duration } = payload;
  const errorMessage = "Can not get report data!";
  const errorMessageId = "notification.cannot-get-report-data";

  const { data, status } = yield call(getToolReportData, {
    pageSize,
    page,
    matchFilter,
    duration,
  });
  if (status === 200) {
    const totalRecord = get(data, "hits.total.value", 0);
    const records = get(data, "hits.hits");
    const reportData = records.map((record) => {
      const source = { ...get(record, "_source", {}) };
      const dateLocaleString = new Date(
        source["@timestamp"],
      ).toLocaleDateString("vi-VN");
      const timeLocaleString = new Date(
        source["@timestamp"],
      ).toLocaleTimeString("vi-VN");
      source["@timestamp"] = `${dateLocaleString} ${timeLocaleString}`;
      return source;
    });

    const totalPage = parseInt(
      totalRecord % pageSize === 0
        ? totalRecord / pageSize
        : totalRecord / pageSize + 1,
    );

    return yield put(
      getReportDataSuccess({
        reportData,
        totalRecord,
        totalPage,
      }),
    );
  }

  yield put(
    showNoti({
      type: ERROR_CASE,
      message: errorMessage,
      messageId: errorMessageId,
    }),
  );
}

function* getReportChartData({ payload }) {
  const errorMessage = "Can not get report data!";
  const errorMessageId = "notification.cannot-get-report-data";
  const { limit = 10, timeRange = '7 days' } = payload;

  try {
    const { data: responseData, status } = yield call(getToolReportChartData, {
      limit,
      timeRange
    });
    if (status === 200) {
      const { data } = responseData;

      return yield put(getToolChartDataSuccess(data));
    }
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  } catch (exc) {
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

function* getUserLoginChartDataSaga({ payload }) {
  const errorMessage = "Can not get user login chart data!";
  const errorMessageId = "notification.cannot-get-user-login-chart-data";

  try {
    const { data, status } = yield call(getUserLoginChartData);
    if (status === 200) {
      const userLogData = get(data, "hits.hits", []);

      const { userLogChart, platformChart } = userLogData.reduce((acc, log) => {

        // handle userLogChart
        const region = get(log, '_source.deviceInfo.position.region');
        if (region) {
          const position = get(log, '_source.deviceInfo.position');
          const existedLogChart = acc.userLogChart.filter(logChart => logChart.region === region);
          if (existedLogChart.length > 0) {
            existedLogChart[0].count++;
          } else {
            const coordinate = get(log, '_source.deviceInfo.position.coordinate');
            acc.userLogChart.push({
              ...position,
              count: 1,
              coordinate: [coordinate.lng, coordinate.lat]
            });
          }
        }

        // handle platformChart
        const platform = get(log, '_source.deviceInfo.platform');
        if (platform) {
          const existedPlatform = acc.platformChart.filter(platformChart => platformChart.platform === platform);
          if (existedPlatform.length > 0) {
            existedPlatform[0].count++;
          } else {
            acc.platformChart.push({
              platform,
              count: 1
            });
          }
        }
        return acc;
      }, {
        userLogChart: [],
        platformChart: []
      });

      yield put(getUserLoginChartDataSuccess({ userLogChart, platformChart }));
    } else {

      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (exc) {

    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

export default function* rootSaga() {
  yield all([takeEvery(GET_REPORT_DATA, getReportDataSaga)]);
  yield all([takeEvery(GET_TOOL_CHART_DATA, getReportChartData)]);
  yield all([takeEvery(GET_USER_LOGIN_CHART_DATA, getUserLoginChartDataSaga)]);
}
