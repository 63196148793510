import {
  LOGOUT_USER,
  GET_LIST_MEMBERS,
  GET_LIST_MEMBERS_SUCCESS,
  GET_LIST_MEMBERS_ERROR,
  MAKE_INACTIVE_MEMBER,
  MAKE_INACTIVE_MEMBER_SUCCESS,
  MAKE_INACTIVE_MEMBER_ERROR,
  DELETE_MEMBER,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_ERROR,
  GET_LIST_PROJECT,
  GET_LIST_PROJECT_SUCCESS,
  GET_LIST_PROJECT_ERROR,
  GET_LIST_ROLE,
  GET_LIST_ROLE_SUCCESS,
  GET_LIST_ROLE_ERROR,
  ASIGN_PROJECT_TO_MEMBER,
  ASIGN_PROJECT_TO_MEMBER_SUCCESS,
  ASIGN_PROJECT_TO_MEMBER_ERROR,
  RESTORE_ACTIVE_MEMBER,
  RESTORE_ACTIVE_MEMBER_SUCCESS,
  RESTORE_ACTIVE_MEMBER_ERROR,
  GET_MEMBER_BY_EMAIL,
  GET_MEMBER_BY_EMAIL_SUCCESS,
  GET_MEMBER_BY_EMAIL_ERROR,
  GET_LIST_SITE_ADMIN_MEMBERS,
  GET_ALL_MEMBER,
  GET_ALL_MEMBER_SUCCESS,
  GET_ALL_MEMBER_FAILED,
} from "redux/actions";


const INIT_STATE = {
  members: [],
  totalPage: 0,
  totalItem: 0,
  loading: false,
  message: "",
  error: "",
  projects: [],
  roles: [],
  memberByEmail: null,
  membersForAdminPage: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      return INIT_STATE;
    case GET_LIST_MEMBERS:
      return { ...state, loading: true, message: "", error: "" };
    case GET_LIST_SITE_ADMIN_MEMBERS:
      return { ...state, loading: true, message: "", error: "" };
    case GET_LIST_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        members: action.payload.members,
        totalPage: parseInt(action.payload.totalPage),
        totalItem: parseInt(action.payload.totalItem),
        error: "",
      };
    case GET_LIST_MEMBERS_ERROR:
      return {
        ...state,
        loading: false,
        members: [],
        error: action.payload.message,
      };
    case MAKE_INACTIVE_MEMBER:
      return { ...state, loading: true, message: "", error: "" };
    case MAKE_INACTIVE_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    case MAKE_INACTIVE_MEMBER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case RESTORE_ACTIVE_MEMBER:
      return { ...state, loading: true, message: "", error: "" };
    case RESTORE_ACTIVE_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    case RESTORE_ACTIVE_MEMBER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case DELETE_MEMBER:
      return { ...state, loading: true, message: "", error: "" };
    case DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    case DELETE_MEMBER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case GET_LIST_PROJECT:
      return { ...state, loading: true, message: "", error: "" };
    case GET_LIST_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: action.payload.projects,
        message: action.payload.message,
      };
    case GET_LIST_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
        projects: [],
        error: action.payload.message,
      };
    case GET_LIST_ROLE:
      return { ...state, loading: true, message: "", error: "" };
    case GET_LIST_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.payload.roles,
        message: action.payload.message,
      };
    case GET_LIST_ROLE_ERROR:
      return {
        ...state,
        loading: false,
        roles: [],
        error: action.payload.message,
      };
    case ASIGN_PROJECT_TO_MEMBER:
      return { ...state, loading: true, message: "", error: "" };
    case ASIGN_PROJECT_TO_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    case ASIGN_PROJECT_TO_MEMBER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case GET_MEMBER_BY_EMAIL:
      return { ...state, loading: true, message: "", error: "", memberByEmail: null };
    case GET_MEMBER_BY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        memberByEmail: action.payload.member,
      };
    case GET_MEMBER_BY_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case GET_ALL_MEMBER:
      return {
        ...state,
        loading: true,
        message: '',
        error: ''
      };
    case GET_ALL_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        membersForAdminPage: action.payload,
      };
    case GET_ALL_MEMBER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
  
    default:
      return { ...state };
  }
}