import {
  GET_LIST_DATASET_DETAIL,
  GET_LIST_DATASET_DETAIL_SUCCESS,
  GET_LIST_DATASET_DETAIL_ERROR,
  SET_DATASET_DETAIL_VIEW_TYPE,
  SET_SELECTED_DATASET_DETAILS,
} from "redux/actions";

export const getListDatasetDetail = (payload) => ({
  type: GET_LIST_DATASET_DETAIL,
  payload,
});
export const getListDatasetDetailSuccess = ({
  pagination,
  datasetDetails,
  datasetName,
  query,
  isRefresh
}) => ({
  type: GET_LIST_DATASET_DETAIL_SUCCESS,
  payload: { pagination, datasetDetails, datasetName, query , isRefresh},
});
export const getListDatasetDetailError = (message) => ({
  type: GET_LIST_DATASET_DETAIL_ERROR,
  payload: { message },
});
export const setDatasetDetailViewType = (payload) => ({
  type: SET_DATASET_DETAIL_VIEW_TYPE,
  payload,
});

export const setSelectedDatasetDetails = (payload) => ({
  type: SET_SELECTED_DATASET_DETAILS,
  payload,
});
