import {
  UPDATE_ADVANCED_FILTERS,
  GET_DATA_BY_ADVANCED_FILTERS,
  GET_DATA_BY_ADVANCED_FILTERS_SUCCESS,
  GET_DATA_BY_ADVANCED_FILTERS_ERROR,
  SET_ADVANCE_SEARCH_FILTER,
  SET_TYPE_TAG,
} from "../actions";

export const updateAdvancedFilters = (payload, callback) => ({
  type: UPDATE_ADVANCED_FILTERS,
  payload,
  callback,
});

export const getDataByAdvancedSearch = (payload, callback) => ({
  type: GET_DATA_BY_ADVANCED_FILTERS,
  payload,
  callback,
});

export const getDataByAdvancedSearchSuccess = (payload) => ({
  type: GET_DATA_BY_ADVANCED_FILTERS_SUCCESS,
  payload,
});

export const getDataByAdvancedSearchError = (payload) => ({
  type: GET_DATA_BY_ADVANCED_FILTERS_ERROR,
  payload,
});

export const setAdvanceSearchFilter = (payload) => ({
  type: SET_ADVANCE_SEARCH_FILTER,
  payload,
});

export const setTypeTag = (payload) => ({
  type: SET_TYPE_TAG,
  payload,
});
