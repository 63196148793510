/* eslint-disable no-use-before-define */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import get from "lodash/get";

import { setCookie } from "helpers/Utils";
import {
  SETUP_ORGANIZATION,
  GET_ORGANIZATION_BY_ID,
  UPDATE_ORGANIZATION,
  GET_USER_GROUP_USER,
  SWITCH_ORGANIZATION_USER,
  ADD_ORGANIZATION,
  GET_LIST_ORGANIZATION,
  GET_LIST_ORGANIZATION_BY_USER_ID,
  DELETE_ORGANIZATION,
  UPDATE_ORGANIZATION_NEW,
  GET_STORAGE_SETTING_BY_ORGANIZATION_ID,
  ADD_STORAGE_SETTING,
  GET_LIST_ORGANIZATION_FOR_BUSINESS_ADMIN,
  GET_CHART_ORGANIZATION_FOR_BUSINESS_ADMIN,
} from "redux/actions";

import {
  setupOrganizationSuccess,
  setupOrganizationError,
  getOrgByIdSuccess,
  getOrgByIdError,
  updateOrgSuccess,
  updateOrgError,
  getListOrganizationSuccess,
  getListOrganizationError,
  getStorageSettingSuccess,
  getUserGroupUserSuccess,
  getListOrganizationForBusinessAdminSuccess,
  getListOrganizationForBusinessAdminError,
  getChartOrganizationFailure,
  getChartOrganizationSuccess,
} from "./actions";

import {
  createOrganization,
  getOrgById,
  updateOrganization,
  getGroupUsers,
  switchOrganization,
  addOrganizationAPI,
  getListOrganization,
  getListOrganizationByUserId,
  deleteOrganizationbyId,
  updateOrganizationAPI,
  getStorageSettingAPI,
  addStorageSettingAPI,
  getListOrganizationForBusinessAdminApi,
  getChartOrganizationForBusinessAdminApi,
} from "./api";

import {
  SUCCESS_CASE,
  ERROR_CASE,
} from "components/notificationModal/constants";
import { showNoti } from "redux/actions";
import { numberWithCommas } from "helpers/Utils";

// Register
export function* watchSetupOrganization() {
  yield takeEvery(SETUP_ORGANIZATION, setupOrganizationSaga);
}

function* setupOrganizationSaga({ payload }) {
  const { org_name, org_description, postal_code, street, district, city, country, phone_number } = payload.organization;
  const { history } = payload;
  try {
    const response = yield call(createOrganization, org_name, org_description, postal_code, street, district, city, country, phone_number);
    const status = get(response, "status", "");
    if (status === 200) {
      // eslint-disable-next-line no-useless-rename
      const { is_verified: is_verified, full_name: full_name, email: email, user_id: user_id, social_type: social_type, is_admin: is_admin, role_code: role_code, image_preview_path, org_id: org_id, is_open_tour_guide: isOpenedTourGuide } = get(
        response,
        "data.data",
        {
          is_verified: false,
          full_name: "",
          email: "",
          user_id: "",
          social_type: null,
          is_admin: false,
          role_code: null,
          image_preview_path: "",
          org_id: null,
          isOpenedTourGuide: false
        },
      );
      setCookie(
        "userInfo",
        JSON.stringify({ full_name, email, user_id, social_type, is_admin, role_code, image_preview_path, org_id, isOpenedTourGuide }),
        7,
      );
      yield put(
        setupOrganizationSuccess(
          { full_name, email, user_id, social_type, is_admin, role_code, image_preview_path, org_id },
          is_verified,
        ),
      );
      history.push("/app/projects");
    } else {
      const msg = get(response, "data[0].msg", "") || get(response, "data.msg", "");
      if (msg) {
        yield put(setupOrganizationError(msg, null));
      } else {
        yield put(setupOrganizationError("Setup Organization Error!", "notification.setup-org-error"));
      }
      
    }
  } catch (error) {
    yield put(setupOrganizationError("Setup Organization Error!", "notification.setup-org-error"));
  }
}

export function* watchGetOrgById() {
  yield takeEvery(
    GET_ORGANIZATION_BY_ID,
    getOrgByIdSaga,
  );
}

function* getOrgByIdSaga({ payload }) {
  const { orgId } = payload;
  try {
    const response = yield call(getOrgById, orgId);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status) {
        const { data: orgInfo } = get(response, "data", {
          orgInfo: null,
        });

        yield put(
          getOrgByIdSuccess(orgInfo, message),
        );
      } else {
        yield put(getOrgByIdError(message));
      }
    } else {
      yield put(
        getOrgByIdError(
          "Get Org info Error!",
        ),
      );
    }
  } catch (error) {
    yield put(
      getOrgByIdError(
        "Get Org info Error!",
      ),
    );
  }
}

export function* watchUpdateOrg() {
  yield takeEvery(UPDATE_ORGANIZATION, updateOrgSaga);
}

function* updateOrgSaga({ payload }) {
  const { orgInfo } = payload;
  try {
    const response = yield call(updateOrganization, orgInfo);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message, data } = get(response, "data");
      if (status) {
        yield put(updateOrgSuccess(data, message));
        yield put(showNoti({
          type: SUCCESS_CASE,
          message: message,
          messageId: null,
        }));
      } else {
        yield put(updateOrgError(message));
        yield put(showNoti({
          type: ERROR_CASE,
          message: message,
          messageId: null,
        }));
      }
    } else {
      yield put(updateOrgError("Update Org Error!"));
      yield put(showNoti({
        type: ERROR_CASE,
        message: "Update Org Error!",
        messageId: "notification.update-org-error",
      }));
    }
  } catch (error) {
    yield put(updateOrgError(error));
    yield put(showNoti({
      type: ERROR_CASE,
      message: "Update Org Error!",
      messageId: "notification.update-org-error",
    }));
  }
}

export function* watchGetUserGroupUser() {
  yield takeEvery(GET_USER_GROUP_USER, getUserGroupUserSaga);
}

function* getUserGroupUserSaga({ callback }) {
  try {
    const response = yield call(getGroupUsers);
    const statusCode = response.status
    if (statusCode === 200) {
      yield put(getUserGroupUserSuccess(response.data?.data))
    }

    if (callback) callback(response)
  } catch (error) {
    if (callback) callback(error)
  }
}

export function* watchSwitchOrganization() {
  yield takeEvery(SWITCH_ORGANIZATION_USER, switchOrganizationSaga);
}
function* switchOrganizationSaga({ payload: { ActiveCode } }) {
  try {
    const response = yield call(switchOrganization, ActiveCode);
    if (response.status === 200) {
      const {

        full_name: fullName,
        email,
        user_id: userId,
        social_type: socialType,
        is_admin: isAdmin,
        role_id: roleId,
        role_code: roleCode,
        image_preview_path,
        org_id: organizationId,
        moduleByRole,
        storage_config,
        is_site_admin: isSiteAdmin,
        is_open_tour_guide: isOpenedTourGuide
      } = get(response, "data",
        {

          fullName: "",
          email: "",
          userId: "",
          socialType: null,
          isAdmin: false,
          roleId: null,
          roleCode: "",
          image_preview_path: "",
          organizationId: null,
          moduleByRole: null,
          storage_config,
          isSiteAdmin: false,
          isOpenedTourGuide: false
        },
      );
      setCookie("Bearer", ActiveCode, 7);
      setCookie(
        "userInfo",
        JSON.stringify({
          fullName,
          email,
          userId,
          socialType,
          isAdmin,
          roleId,
          roleCode,
          image_preview_path,
          organizationId,
          moduleByRole,
          storage_config,
          isSiteAdmin,
          isOpenedTourGuide

        }),
        7,
      );
      window.location.href = '/app/projects';
    }

  } catch (error) {
    console.log('error', error)
  }
}
export function* watchGetListOrganization() {
  yield takeEvery(GET_LIST_ORGANIZATION, getListOrganizationSaga);
}

export function* watchGetListOrganizationForBusinessAdmin() {
  yield takeEvery(GET_LIST_ORGANIZATION_FOR_BUSINESS_ADMIN, getListOrganizationForBusinessAdminSaga);
}

export function* watchGetChartOrganizationForBusinessAdmin() {
  yield takeEvery(GET_CHART_ORGANIZATION_FOR_BUSINESS_ADMIN, getChartOrganizationForBusinessAdminSaga);
}

export function* watchAddOrganization() {
  yield takeEvery(
    ADD_ORGANIZATION,
    addOrganizationSaga,
  );
}

function* addOrganizationSaga(action) {
  const { payload, callback } = action.payload;
  const response = yield call(addOrganizationAPI, payload)
  if (response.status === 200) {
    yield put(showNoti({
      type: SUCCESS_CASE,
      message: "Add organization successfully!",
      messageId: "notification.add-organization-success",
    }));
    callback && callback(response.data);
  } else {
    yield put(showNoti({
      type: ERROR_CASE,
      message: "Add organization error!",
      messageId: "notification.add-organization-error",
    }));
  }
}

function* getListOrganizationSaga({ payload }) {
  const { selectedPageSize, currentPage } = payload;
  try {
    const response = yield call(
      getListOrganization,
      selectedPageSize,
      currentPage,
    );

    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status === true) {
        const { totalPage, totalItem, organizations } = get(response, "data.data", {
          totalItem: 0,
          totalPage: 0,
          organizations: [],
        });
        yield put(getListOrganizationSuccess({ totalPage, totalItem, organizations, message }));
      } else {
        yield put(getListOrganizationError({ message, messageId: null }));
      }
    } else {
      yield put(getListOrganizationError({ message: "Get List Organization Error!", messageId: "notification.get-list-org-error" }));
    }
  } catch (error) {
    console.log(error);
    yield put(getListOrganizationError({ message: "Get List Organization Error!", messageId: "notification.get-list-org-error" }));
  }
}

function* getListOrganizationForBusinessAdminSaga({ payload }) {
  try {
    const response = yield call(
      getListOrganizationForBusinessAdminApi,
      payload
    );

    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status === true) {
        const { totalPage, totalItem, organizations } = get(response, "data.data", {
          totalItem: 0,
          totalPage: 0,
          organizations: [],
        });
        const organizationWithStatistic = organizations.map(organization => {
          organization.statistic = {
            total_members: organization.total_members,
            total_projects: organization.total_projects,
            used_storage: parseInt(organization.used_storage)
          };
          organization.data_storage = 10000000;

          return organization;
        });

        yield put(getListOrganizationForBusinessAdminSuccess({ totalPage, totalItem, organizations: organizationWithStatistic, message }));
      } else {
        yield put(getListOrganizationForBusinessAdminError({ message }));
      }
    } else {
      yield put(getListOrganizationForBusinessAdminError({ "message": "Get List Organization Error!" }));
    }
  } catch (error) {
    yield put(getListOrganizationForBusinessAdminError({ "message": "Get List Organization Error!" }));
  }
}

function prepareOrgChart({
  labels,
  newOrgChartData,
  activeChartData,
  inActiveChartData,
  newOrg,
  totalActiveOrg,
  totalInActiveOrg,
  totalOrg,
  totalChartData,
}) {
  const orgOverviewLineChart = {
    labels,
    datasets: [
      {
        label: 'New Organization',
        data: newOrgChartData,
        fill: false,
        backgroundColor: '#5D7CC4',
        borderColor: '#5D7CC4',
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Total Organization Active',
        data: activeChartData,
        fill: false,
        backgroundColor: '#DE8344',
        borderColor: '#DE8344',
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Total Organization InActive',
        data: inActiveChartData,
        fill: false,
        backgroundColor: '#A5A5A5',
        borderColor: '#A5A5A5',
        yAxisID: 'y-axis-1',
      },
    ],
  };

  const totalLineChartData = {
    labels,
    datasets: [
      {
        pointRadius: 0,
        pointBorderWidth: 2,
        pointHoverRadius: 0,
        backgroundColor: '#E9F2F7',
        borderColor: '#6BAFD9',
        fill: true,
        borderWidth: 2,
        data: totalChartData,
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
    ],
    total: totalOrg,
    title: 'Total Organizations'
  };

  const newOrgLineChartData = {
    labels,
    datasets: [
      {
        pointRadius: 0,
        pointBorderWidth: 2,
        pointHoverRadius: 0,
        backgroundColor: '#E9F2F7',
        borderColor: '#6BAFD9',
        fill: true,
        borderWidth: 2,
        data: newOrgChartData,
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
    ],
    total: newOrg,
    title: 'New Organizations'
  };

  const activeLineChartData = {
    labels,
    datasets: [
      {
        pointRadius: 0,
        pointBorderWidth: 2,
        pointHoverRadius: 0,
        backgroundColor: '#E9F2F7',
        borderColor: '#6BAFD9',
        fill: true,
        borderWidth: 2,
        data: activeChartData,
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
    ],
    total: totalActiveOrg,
    title: 'Active Organizations'
  };

  const inActiveLineChartData = {
    labels,
    datasets: [
      {
        pointRadius: 0,
        pointBorderWidth: 2,
        pointHoverRadius: 0,
        backgroundColor: '#E9F2F7',
        borderColor: '#6BAFD9',
        fill: true,
        borderWidth: 2,
        data: inActiveChartData,
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
    ],
    total: totalInActiveOrg,
    title: 'InActive Organizations'
  };

  const orgPieChartData = {
    labels: ['Active Organization', 'InActiveOrganization'],
    datasets: [
      {
        label: '# of Votes',
        data: [totalActiveOrg, totalInActiveOrg],
        backgroundColor: [
          '#DE8344',
          '#6BAFD9',
        ],
        borderColor: [
          '#DE8344',
          '#6BAFD9',
        ],
        borderWidth: 1,
      },
    ],
  };

  return {
    orgOverviewLineChart,
    newOrgLineChartData,
    activeLineChartData,
    inActiveLineChartData,
    orgPieChartData,
    totalLineChartData
  }
}

function preparePricingChart({
  labels,
  newPlanChartData,
  newProPlanChartData,
  newEnterprisePlanChartData,
  newPricingPackage,
  newFreePlanChartData,
  totalNewFreePlan,
  totalNewStartPlan,
  totalNewProPlan,
  totalNewEnterprisePlan,
  newStarterPlanChartData,

  totalStartPlan,
  totalProPlan,
  totalEnterprisePlan,
  totalPackage,
  totalFreePlan,
  totalStarterPlanChartData,
  totalProPlanChartData,
  totalEnterprisePlanChartData,
  totalFreePlanChartData,
  totalPlanChartData,
}) {
  const pricingOverviewLineChart = {
    labels,
    datasets: [
      {
        label: 'Total Enterprise Plan',
        labelId: '',
        data: totalEnterprisePlanChartData,
        fill: false,
        backgroundColor: '#4472C4',
        borderColor: '#4472C4',
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Total Starter Plan',
        labelId: '',
        data: totalStarterPlanChartData,
        fill: false,
        backgroundColor: '#ED7D31',
        borderColor: '#ED7D31',
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Total Pro Plan',
        labelId: '',
        data: totalProPlanChartData,
        fill: false,
        backgroundColor: '#A5A5A5',
        borderColor: '#A5A5A5',
        yAxisID: 'y-axis-1',
      },
      {
        label: 'New Enterprise Plan',
        labelId: '',
        data: newEnterprisePlanChartData,
        fill: false,
        backgroundColor: '#FFC000',
        borderColor: '#FFC000',
        yAxisID: 'y-axis-1',
      },
      {
        label: 'New Pro Plan',
        labelId: '',
        data: newProPlanChartData,
        fill: false,
        backgroundColor: '#5B9BD5',
        borderColor: '#5B9BD5',
        yAxisID: 'y-axis-1',
      },
      {
        label: 'New Starter Plan',
        labelId: '',
        data: newStarterPlanChartData,
        fill: false,
        backgroundColor: '#70AD47',
        borderColor: '#70AD47',
        yAxisID: 'y-axis-1',
      },
    ],
  };

  const totalPlanLineChart = {
    labels,
    datasets: [
      {
        pointRadius: 0,
        pointBorderWidth: 2,
        pointHoverRadius: 0,
        backgroundColor: '#E9F2F7',
        borderColor: '#6BAFD9',
        fill: true,
        borderWidth: 2,
        data: totalPlanChartData,
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
    ],
    total: totalPackage,
    title: 'Total Plan',
    titleId: '',
  };

  const newPlanLineChart = {
    labels,
    datasets: [
      {
        pointRadius: 0,
        pointBorderWidth: 2,
        pointHoverRadius: 0,
        backgroundColor: '#E9F2F7',
        borderColor: '#6BAFD9',
        fill: true,
        borderWidth: 2,
        data: newPlanChartData,
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
    ],
    total: newPricingPackage,
    title: 'Total New Plan',
    titleId: '',
  };

  const freePlanLineChart = {
    labels,
    datasets: [
      {
        pointRadius: 0,
        pointBorderWidth: 2,
        pointHoverRadius: 0,
        backgroundColor: '#E9F2F7',
        borderColor: '#6BAFD9',
        fill: true,
        borderWidth: 2,
        data: totalFreePlanChartData,
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
    ],
    total: totalFreePlan,
    title: 'Total Free Plan',
    titleId: '',
  };

  const starterPlanLineChart = {
    labels,
    datasets: [
      {
        pointRadius: 0,
        pointBorderWidth: 2,
        pointHoverRadius: 0,
        backgroundColor: '#E9F2F7',
        borderColor: '#6BAFD9',
        fill: true,
        borderWidth: 2,
        data: totalStarterPlanChartData,
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
    ],
    total: totalStartPlan,
    title: 'Total Starter Plan',
    titleId: '',
  };

  const proPlanLineChart = {
    labels,
    datasets: [
      {
        pointRadius: 0,
        pointBorderWidth: 2,
        pointHoverRadius: 0,
        backgroundColor: '#E9F2F7',
        borderColor: '#6BAFD9',
        fill: true,
        borderWidth: 2,
        data: totalProPlanChartData,
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
    ],
    total: totalProPlan,
    title: 'Total Pro Plan',
    titleId: '',
  };

  const enterprisePlanLineChart = {
    labels,
    datasets: [
      {
        pointRadius: 0,
        pointBorderWidth: 2,
        pointHoverRadius: 0,
        backgroundColor: '#E9F2F7',
        borderColor: '#6BAFD9',
        fill: true,
        borderWidth: 2,
        data: totalEnterprisePlanChartData,
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
    ],
    total: totalEnterprisePlan,
    title: 'Total Enterprise Plan',
    titleId: '',
  };

  const pricingPieChartData = {
    labels: ['Free Plan', 'Starter Plan', 'Pro Plan', 'Enterprise Plan'],
    datasets: [
      {
        label: '# of Votes',
        data: [totalNewFreePlan, totalNewStartPlan, totalNewProPlan, totalNewEnterprisePlan],
        backgroundColor: [
          '#F5C242',
          '#A5A5A5',
          '#DE8344',
          '#5D7CC4'
        ],
        borderColor: [
          '#F5C242',
          '#A5A5A5',
          '#DE8344',
          '#5D7CC4'
        ],
        borderWidth: 1,
      },
    ],
  };

  return {
    pricingOverviewLineChart,
    newPlanLineChart,
    freePlanLineChart,
    starterPlanLineChart,
    proPlanLineChart,
    enterprisePlanLineChart,
    pricingPieChartData,
    totalPlanLineChart,
  };
}

function prepareFinancialChart({
  labels,

  newFinancial,
  newEnterpriseFinancial,
  newProFinancial,
  newStarterFinancial,
  newEnterpriseFinancialChartData,
  newProFinancialChartData,
  newStarterFinancialChartData,
  newFinancialChartData,

  totalFinancial,
  starterFinancial,
  proFinancial,
  enterpriseFinancial,
  totalStarterFinancialChartData,
  totalProFinancialChartData,
  totalEnterpriseFinancialChartData,
  totalFinancialChartData,
}) {
  const financialOverviewLineChart = {
    labels,
    datasets: [
      {
        label: 'Total Enterprise Plan',
        data: totalEnterpriseFinancialChartData,
        fill: false,
        backgroundColor: '#4472C4',
        borderColor: '#4472C4',
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Total Starter Plan',
        data: totalStarterFinancialChartData,
        fill: false,
        backgroundColor: '#ED7D31',
        borderColor: '#ED7D31',
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Total Pro Plan',
        data: totalProFinancialChartData,
        fill: false,
        backgroundColor: '#A5A5A5',
        borderColor: '#A5A5A5',
        yAxisID: 'y-axis-1',
      },
      {
        label: 'New Enterprise Plan',
        data: newEnterpriseFinancialChartData,
        fill: false,
        backgroundColor: '#FFC000',
        borderColor: '#FFC000',
        yAxisID: 'y-axis-1',
      },
      {
        label: 'New Pro Plan',
        data: newProFinancialChartData,
        fill: false,
        backgroundColor: '#5B9BD5',
        borderColor: '#5B9BD5',
        yAxisID: 'y-axis-1',
      },
      {
        label: 'New Starter Plan',
        data: newStarterFinancialChartData,
        fill: false,
        backgroundColor: '#70AD47',
        borderColor: '#70AD47',
        yAxisID: 'y-axis-1',
      },
    ],
  };

  const totalFinancialLineChart = {
    labels,
    datasets: [
      {
        pointRadius: 0,
        pointBorderWidth: 2,
        pointHoverRadius: 0,
        backgroundColor: '#E9F2F7',
        borderColor: '#6BAFD9',
        fill: true,
        borderWidth: 2,
        data: totalFinancialChartData,
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
    ],
    total: numberWithCommas(totalFinancial.toFixed(2)),
    title: 'Total Paid Account'
  };

  const newFinancialLineChart = {
    labels,
    datasets: [
      {
        pointRadius: 0,
        pointBorderWidth: 2,
        pointHoverRadius: 0,
        backgroundColor: '#E9F2F7',
        borderColor: '#6BAFD9',
        fill: true,
        borderWidth: 2,
        data: newFinancialChartData,
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
    ],
    total: numberWithCommas(newFinancial.toFixed(2)),
    title: 'Total New Paid Account'
  };

  const starterFinancialLineChart = {
    labels,
    datasets: [
      {
        pointRadius: 0,
        pointBorderWidth: 2,
        pointHoverRadius: 0,
        backgroundColor: '#E9F2F7',
        borderColor: '#6BAFD9',
        fill: true,
        borderWidth: 2,
        data: totalStarterFinancialChartData,
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
    ],
    total: numberWithCommas(starterFinancial.toFixed(2)),
    title: 'Starter Account'
  };

  const proFinancialLineChart = {
    labels,
    datasets: [
      {
        pointRadius: 0,
        pointBorderWidth: 2,
        pointHoverRadius: 0,
        backgroundColor: '#E9F2F7',
        borderColor: '#6BAFD9',
        fill: true,
        borderWidth: 2,
        data: totalProFinancialChartData,
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
    ],
    total: numberWithCommas(proFinancial.toFixed(2)),
    title: 'Pro Account'
  };

  const enterpriseFinancialLineChart = {
    labels,
    datasets: [
      {
        pointRadius: 0,
        pointBorderWidth: 2,
        pointHoverRadius: 0,
        backgroundColor: '#E9F2F7',
        borderColor: '#6BAFD9',
        fill: true,
        borderWidth: 2,
        data: totalEnterpriseFinancialChartData,
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
    ],
    total: numberWithCommas(enterpriseFinancial.toFixed(2)),
    title: 'Enterprise Account'
  };

  const financialPieChartData = {
    labels: ['Starter Plan', 'Pro Plan', 'Enterprise Plan'],
    datasets: [
      {
        label: '# of Votes',
        data: [newStarterFinancial, newProFinancial, newEnterpriseFinancial],
        backgroundColor: [
          '#A5A5A5',
          '#DE8344',
          '#5D7CC4'
        ],
        borderColor: [
          '#A5A5A5',
          '#DE8344',
          '#5D7CC4'
        ],
        borderWidth: 1,
      },
    ],
  };

  return {
    financialOverviewLineChart,
    totalFinancialLineChart,
    starterFinancialLineChart,
    proFinancialLineChart,
    enterpriseFinancialLineChart,
    financialPieChartData,
    newFinancialLineChart,
  };
}

function* getChartOrganizationForBusinessAdminSaga({ payload }) {
  try {
    const { type } = payload;
    const response = yield call(
      getChartOrganizationForBusinessAdminApi,
      payload
    );

    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status === true) {
        const data = get(response, "data.data", []);
        const newOrgData = get(data, "newOrgChartData", []);
        const statusData = get(data, "statusOrgChartData", []);
        const pricingData = get(data, "pricingChartData", []);
        const enterpriseData = get(data, "enterpriseChartData", []);
        const statusOldData = get(data, "statusSourceData", []);
        const oldPricingData = get(data, "planSourceData", []);
        const oldEnterpriseData = get(data, ["enterpriseSourceData", 0], {});
        const oldActiveData = statusOldData.find(data => data.is_verified);
        const oldInActiveData = statusOldData.find(data => !data.is_verified);
        const oldStartPlanData = oldPricingData.find(data => data.pricing_pack_id === "2");
        const oldProPlanData = oldPricingData.find(data => data.pricing_pack_id === "3");
        const oldFreePlanData = oldPricingData.find(data => data.pricing_pack_id === "1");

        let newOrg = 0;
        let newPricingPackage = 0;
        let totalNewFreePlan = 0;
        let totalNewStartPlan = 0;
        let totalNewProPlan = 0;
        let totalNewEnterprisePlan = 0;

        let totalStartPlan = parseInt(oldStartPlanData?.total_plan) || 0;
        let totalProPlan = parseInt(oldProPlanData?.total_plan) || 0;
        let totalEnterprisePlan = parseInt(oldEnterpriseData?.total_plan) || 0;
        let totalFreePlan = parseInt(oldFreePlanData?.total_plan) || 0;
        let totalPackage = totalStartPlan + totalProPlan + totalEnterprisePlan + totalFreePlan;
        
        let labels = [];
        const totalChartData = [];
        const newOrgChartData = [];
        const activeChartData = [];
        const inActiveChartData = [];

        const newFreePlanChartData = [];
        const newStarterPlanChartData = [];
        const newProPlanChartData = [];
        const newEnterprisePlanChartData = [];
        const newPlanChartData = [];

        const totalStarterPlanChartData = [];
        const totalProPlanChartData = [];
        const totalEnterprisePlanChartData = [];
        const totalFreePlanChartData = [];
        const totalPlanChartData = [];

        let totalActiveOrg = parseInt(oldActiveData?.count) || 0;
        let totalInActiveOrg = parseInt(oldInActiveData?.count) || 0;
        let totalOrg = totalActiveOrg + totalInActiveOrg;

        const newStarterFinancialChartData = [];
        const newProFinancialChartData = [];
        const newEnterpriseFinancialChartData = [];
        const newFinancialChartData = [];

        let newStarterFinancial = 0;
        let newProFinancial = 0;
        let newEnterpriseFinancial = 0;
        let newFinancial = 0;

        const totalFinancialChartData = [];
        const totalStarterFinancialChartData = [];
        const totalProFinancialChartData = [];
        const totalEnterpriseFinancialChartData = [];
        let starterFinancial = parseInt(oldStartPlanData?.total_price) / 100 || 0;
        let proFinancial = parseInt(oldProPlanData?.total_price) / 100 || 0;
        let enterpriseFinancial = parseInt(oldEnterpriseData?.total_price) / 100 || 0;
        let totalFinancial = starterFinancial + proFinancial + enterpriseFinancial;

        for (const item of newOrgData) {
          const date = item.the_date;
          const newOrgInItem = parseInt(item?.total_org) || 0;
          const currentActiveOrg = statusData.find(statusItem => statusItem.the_date === date && statusItem.is_verified);
          const currentInActiveOrg = statusData.find(statusItem => statusItem.the_date === date && !statusItem.is_verified);
          const currentFreePlan = pricingData.find(planItem => planItem.the_date === date && planItem.pricing_pack_id === "1");
          const currentStarterPlan = pricingData.find(planItem => planItem.the_date === date && planItem.pricing_pack_id === "2");
          const currentProPlan = pricingData.find(planItem => planItem.the_date === date && planItem.pricing_pack_id === "3");
          const currentEnterprisePlan = enterpriseData.find(planItem => planItem.the_date === date);

          const currentActiveTotalOrg = parseInt(currentActiveOrg?.total_org) || 0;
          const currentInActiveTotalOrg = parseInt(currentInActiveOrg?.total_org) || 0;
          
          const currentFreePlanTotalPackage = parseInt(currentFreePlan?.total_package) || 0;
          const currentStarterPlanTotalPackage = parseInt(currentStarterPlan?.total_package) || 0;
          const currentProPlanTotalPackage = parseInt(currentProPlan?.total_package) || 0;
          const currentEnterpriseTotalPackage = parseInt(currentEnterprisePlan?.total_package) || 0;
          const totalCurrentPricingPackage = currentFreePlanTotalPackage + currentStarterPlanTotalPackage + currentProPlanTotalPackage + currentEnterpriseTotalPackage;

          const currentStarterFinancial = parseInt(currentStarterPlan?.total_price) / 100 || 0;
          const currentProFinancial = parseInt(currentProPlan?.total_price) / 100 || 0;
          const currentEnterpriseFinancial = parseInt(currentEnterprisePlan?.total_price) / 100 || 0;
          const totalCurrentPricingFinancial = currentStarterFinancial + currentProFinancial + currentEnterpriseFinancial;

          totalActiveOrg += currentActiveTotalOrg;
          totalInActiveOrg += currentInActiveTotalOrg;
          newOrg += newOrgInItem;
          totalOrg += newOrgInItem + currentInActiveTotalOrg + currentActiveTotalOrg;

          newPricingPackage += totalCurrentPricingPackage;
          totalNewFreePlan += currentFreePlanTotalPackage;
          totalNewStartPlan += currentStarterPlanTotalPackage;
          totalNewProPlan += currentProPlanTotalPackage;
          totalNewEnterprisePlan += currentEnterpriseTotalPackage;

          totalStartPlan += currentStarterPlanTotalPackage;
          totalProPlan += currentProPlanTotalPackage;
          totalEnterprisePlan += currentEnterpriseTotalPackage;
          totalPackage += totalCurrentPricingPackage;
          totalFreePlan += currentFreePlanTotalPackage;

          newStarterFinancial += currentStarterFinancial;
          newProFinancial += currentProFinancial;
          newEnterpriseFinancial += currentEnterpriseFinancial;
          newFinancial += totalCurrentPricingFinancial;

          starterFinancial += currentStarterFinancial;
          proFinancial += currentProFinancial;
          enterpriseFinancial += currentEnterpriseFinancial;
          totalFinancial += totalCurrentPricingFinancial;

          newFreePlanChartData.push(totalNewFreePlan);
          newStarterPlanChartData.push(totalNewStartPlan);
          newProPlanChartData.push(totalNewProPlan);
          newEnterprisePlanChartData.push(totalNewEnterprisePlan);
          newPlanChartData.push(newPricingPackage);

          totalStarterPlanChartData.push(totalStartPlan);
          totalProPlanChartData.push(totalProPlan);
          totalEnterprisePlanChartData.push(totalEnterprisePlan);
          totalFreePlanChartData.push(totalFreePlan);
          totalPlanChartData.push(totalPackage);
          
          newOrgChartData.push(newOrgInItem);
          activeChartData.push(totalActiveOrg);
          inActiveChartData.push(totalInActiveOrg);
          totalChartData.push(totalOrg);

          newStarterFinancialChartData.push(newStarterFinancial);
          newProFinancialChartData.push(newProFinancial);
          newEnterpriseFinancialChartData.push(newEnterpriseFinancial);
          newFinancialChartData.push(newFinancial);

          totalStarterFinancialChartData.push(starterFinancial);
          totalProFinancialChartData.push(proFinancial);
          totalEnterpriseFinancialChartData.push(enterpriseFinancial);
          totalFinancialChartData.push(totalFinancial);
        }

        switch (type) {
          case 'date': {
            labels = newOrgData.map(item => new Date(item.the_date).toLocaleDateString());
            break;
          }
          case 'month': {
            labels = newOrgData.map(item => new Date(item.the_date).toLocaleString('default', { month: 'short' }));
            break;
          }
          case 'year': {
            labels = newOrgData.map(item => new Date(item.the_date).getFullYear());
            break;
          }
          default:
            break;
        }

        const {
          orgOverviewLineChart,
          newOrgLineChartData,
          activeLineChartData,
          inActiveLineChartData,
          orgPieChartData,
          totalLineChartData,
        } = prepareOrgChart({
          labels,
          newOrgChartData,
          activeChartData,
          inActiveChartData,
          newOrg,
          totalActiveOrg,
          totalInActiveOrg,
          totalOrg,
          totalChartData,
        });
        
        const {
          pricingOverviewLineChart,
          newPlanLineChart,
          freePlanLineChart,
          starterPlanLineChart,
          proPlanLineChart,
          enterprisePlanLineChart,
          pricingPieChartData,
          totalPlanLineChart,
        } = preparePricingChart({
          labels,
          newPlanChartData,
          newProPlanChartData,
          newEnterprisePlanChartData,
          newPricingPackage,
          newFreePlanChartData,
          totalNewFreePlan,
          totalNewStartPlan,
          totalNewProPlan,
          totalNewEnterprisePlan,
          newStarterPlanChartData,
        
          totalStartPlan,
          totalProPlan,
          totalEnterprisePlan,
          totalPackage,
          totalFreePlan,
          totalStarterPlanChartData,
          totalProPlanChartData,
          totalEnterprisePlanChartData,
          totalFreePlanChartData,
          totalPlanChartData,
        });

        const {
          financialOverviewLineChart,
          totalFinancialLineChart,
          starterFinancialLineChart,
          proFinancialLineChart,
          enterpriseFinancialLineChart,
          financialPieChartData,
          newFinancialLineChart,
        } = prepareFinancialChart({
          labels,

          newFinancial,
          newEnterpriseFinancial,
          newProFinancial,
          newStarterFinancial,
          newEnterpriseFinancialChartData,
          newProFinancialChartData,
          newStarterFinancialChartData,
          newFinancialChartData,

          totalFinancial,
          starterFinancial,
          proFinancial,
          enterpriseFinancial,
          totalStarterFinancialChartData,
          totalProFinancialChartData,
          totalEnterpriseFinancialChartData,
          totalFinancialChartData,
        });

        yield put(getChartOrganizationSuccess({ data: {
          orgOverviewLineChart,
          newOrgLineChartData,
          activeLineChartData,
          inActiveLineChartData,
          orgPieChartData,
          totalLineChartData,

          pricingOverviewLineChart,
          newPlanLineChart,
          freePlanLineChart,
          starterPlanLineChart,
          proPlanLineChart,
          enterprisePlanLineChart,
          pricingPieChartData,
          totalPlanLineChart,

          financialOverviewLineChart,
          totalFinancialLineChart,
          starterFinancialLineChart,
          proFinancialLineChart,
          enterpriseFinancialLineChart,
          financialPieChartData,
          newFinancialLineChart,
        }, message }));
      } else {
        yield put(getChartOrganizationFailure({ message }));
      }
    } else {
      yield put(getChartOrganizationFailure({ "message": "Get List Organization Error!" }));
    }
  } catch (error) {
    yield put(getChartOrganizationFailure({ "message": "Get List Organization Error!" }));
  }
}

function* getListOrganizationByUserIdSaga({ payload }) {
  const { selectedPageSize, currentPage, userId } = payload;
  try {
    const response = yield call(
      getListOrganizationByUserId,
      selectedPageSize,
      currentPage,
      userId
    );

    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status === true) {
        const { totalPage, totalItem, organizations } = get(response, "data.data", {
          totalItem: 0,
          totalPage: 0,
          organizations: [],
        });
        yield put(getListOrganizationSuccess({ totalPage, totalItem, organizations, message }));
      } else {
        yield put(getListOrganizationError({ message }));
      }
    } else {
      yield put(getListOrganizationError({ "message": "Get List Organization Error!" }));
    }
  } catch (error) {
    console.log(error);
    yield put(getListOrganizationError({ "message": "Get List Organization Error!" }));
  }
}

export function* watchUpdateOrganization() {
  yield takeEvery(
    UPDATE_ORGANIZATION_NEW,
    updateOrganizationSaga,
  );
}

function* updateOrganizationSaga({ id, payload, callback }) {
  const response = yield call(updateOrganizationAPI, { org_id: id, ...payload })
  if (response.status === 200) {
    if (callback) callback(response.data)
    yield put(showNoti({
      type: SUCCESS_CASE,
      message: "Update organization successfully!"
    }));
  } else {
    yield put(showNoti({
      type: ERROR_CASE,
      message: "Update organization error!"
    }));
  }

}


export function* watchDeleteOrganization() {
  yield takeEvery(
    DELETE_ORGANIZATION,
    deleteOrganizationSaga,
  );
}

function* deleteOrganizationSaga({ id, callback }) {
  const response = yield call(deleteOrganizationbyId, { org_id: id })
  if (response.status === 200) {
    if (callback) callback()
    yield put(showNoti({
      type: SUCCESS_CASE,
      message: "Delete organization successfully!"
    }));
  } else {
    yield put(showNoti({
      type: ERROR_CASE,
      message: "Delete organization error!"
    }));
  }
}

export function* getStorageSettingSaga({ payload }) {
  const { orgId } = payload;
  const response = yield call(getStorageSettingAPI, { orgId });
  if (response.status === 200) {
    const data = get(response, ["data", "data"]);
    yield put(getStorageSettingSuccess({ orgId, data }));
  } else {
    yield put(showNoti({
      type: ERROR_CASE,
      message: "Get Storage Setting data error!"
    }));
  }
}

export function* addStorageSettingSaga({ payload }) {
  const { data, callback } = payload;

  const response = yield call(addStorageSettingAPI, {
    ...data
  });
  if (response.status === 200) {
    yield put(showNoti({
      type: SUCCESS_CASE,
      message: "Add Storage Setting data success!"
    }));
    callback && callback();
  } else {
    yield put(showNoti({
      type: ERROR_CASE,
      message: "Add Storage Setting data error!"
    }));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchSetupOrganization),
    fork(watchGetOrgById),
    fork(watchUpdateOrg),
    fork(watchGetUserGroupUser),
    fork(watchSwitchOrganization),
    fork(watchAddOrganization),
    fork(watchGetListOrganization),
    fork(watchUpdateOrganization),
    fork(watchDeleteOrganization),
    takeEvery(GET_LIST_ORGANIZATION_BY_USER_ID, getListOrganizationByUserIdSaga),
    takeEvery(GET_STORAGE_SETTING_BY_ORGANIZATION_ID, getStorageSettingSaga),
    takeEvery(ADD_STORAGE_SETTING, addStorageSettingSaga),
    fork(watchGetListOrganizationForBusinessAdmin),
    fork(watchGetChartOrganizationForBusinessAdmin),
  ]);
}
