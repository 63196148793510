import {
    GET_ACTION_HISTORY,
    GET_ACTION_HISTORY_SUCCESS,
    GET_ACTION_HISTORY_FAILURE,
    GET_BENCHMARK,
    GET_BENCHMARK_SUCCESS,
    GET_BENCHMARK_FAILURE,
} from "redux/actions";

export const getActionHistory = (payload) => ({
    type: GET_ACTION_HISTORY,
    payload
});

export const getActionHistorySuccess = (payload) => ({
    type: GET_ACTION_HISTORY_SUCCESS,
    payload
})

export const getActionHistoryFailure = (payload) => ({
    type: GET_ACTION_HISTORY_FAILURE,
    payload
});

export const getBenchmark = (payload) => ({
    type: GET_BENCHMARK,
    payload
});

export const getBenchmarkSuccess = (payload) => ({
    type: GET_BENCHMARK_SUCCESS,
    payload
});

export const getBenchmarkFailure = (payload) => ({
    type: GET_BENCHMARK_FAILURE,
    payload
});
