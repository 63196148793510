import {
  GET_LIST_PRICING_PACKAGES,
  GET_LIST_PRICING_PACKAGES_SUCCESS,
  GET_LIST_PRICING_PACKAGES_FAILURE,
  GET_CURRENT_PRICING_PACKAGE,
  GET_CURRENT_PRICING_PACKAGE_SUCCESS,
  GET_CURRENT_PRICING_PACKAGE_FAILURE,
  GET_BILLING_LIST,
  GET_BILLING_LIST_SUCCESS,
  GET_BILLING_LIST_FAILURE,
} from "redux/actions";

const INIT_STATE = {
  pricingPackages: {
    data: [],
    loading: false,
  },
  currentPackage: {},
  currentLoading: false,
  billingList: {
    data: [],
    loading: false,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_PRICING_PACKAGES: {
      return {
        ...state,
        pricingPackages: {
          ...state.pricingPackages,
          loading: true,
        },
      };
    }
    case GET_LIST_PRICING_PACKAGES_FAILURE: {
      return {
        ...state,
        pricingPackages: {
          ...state.pricingPackages,
          loading: false,
        },
      };
    }
    case GET_LIST_PRICING_PACKAGES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        pricingPackages: {
          data,
          loading: false,
        },
      };
    }

    case GET_CURRENT_PRICING_PACKAGE: {
      return {
        ...state,
        currentLoading: true,
        currentPackage: {
          ...state.currentPackage,
        },
      };
    }

    case GET_CURRENT_PRICING_PACKAGE_FAILURE: {
      return {
        ...state,
        currentLoading: false,
        currentPackage: {
          ...state.currentPackage,
        },
      };
    }

    case GET_CURRENT_PRICING_PACKAGE_SUCCESS: {
      return {
        ...state,
        currentLoading: false,
        currentPackage: action.payload,
      };
    }

    case GET_BILLING_LIST: {
      return {
        ...state,
        billingList: {
          ...state.billingList,
          loading: true,
        },
      };
    }
    case GET_BILLING_LIST_FAILURE: {
      return {
        ...state,
        billingList: {
          ...state.billingList,
          loading: false,
        },
      };
    }
    case GET_BILLING_LIST_SUCCESS: {
      const { data } = action.payload;
      const { billingList, totalItem, totalPage } = data;
      return {
        ...state,
        billingList: {
          data: billingList,
          totalItem,
          totalPage,
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};
