
import axios, { coreToolAxios } from "../api";
import { getCookie } from "helpers/Utils";
import { socket } from "redux/socket/saga";

export function getListProjectsDetail(selectedPageSize = 10, currentPage = 1) {
  return axios.get(
    `/project/get-projects-details?pageSize=${selectedPageSize}&currentPage=${currentPage}`,
  );
}

export function createNewDataset(
  projectId,
  datasetName,
  description,
  thumbnailPath,
  dataSource,
  classifyData,
) {
  return axios.post(`/dataset/add-new-dataset`, {
    projectId: projectId,
    datasetName: datasetName,
    description: description,
    thumbnailPath: thumbnailPath,
    dataSource: dataSource,
    classifyData: classifyData,
  });
}

export function getSummaryProjects(projectId) {
  return axios.get(`/project/get-summary-projects?project_id=${projectId}`);
}

export function getEmailList() {
  return axios.get(`/project/get-assign-user-to-project`);
}

export function getRoleList() {
  return axios.get(`/project/get-role-by-project`);
}

export function getAssignMemberList(projectId) {
  return axios.get(
    `/project/get-user-assigned-by-project?ProjectId=${projectId}`,
  );
}

export function createNewProject({
  projectName,
  dataType,
  projectDesc,
  imagePath,
  members,
  settingQuality,
  coverage,
  startTime,
  endTime
}) {
  let payload = {
    ProjectName: projectName,
    DataType: dataType,
    ProjectDescription: projectDesc,
    ImagePath: imagePath,
    Members: members,
    target: 0,
    startTime,
    endTime,
  };

  if (settingQuality) {
    payload.settingQuality = settingQuality;
    payload.coverage = coverage;
  }
  return axios.post(`/project/add-new-project`, payload);
}

export function getProjectById(projectId) {
  return axios.get(
    `/project/get-projects-details-by-id?ProjectId=${projectId}`,
  );
}

export function getFirstDatasetExistSource(projectId) {
  return axios.get(
    `/dataset/get-dataset-last-active-by-project?projectId=${projectId}`,
  );
}

export function getCurrentProjectMembers(projectId, roleCode) {
  return axios.get(
    `/dataset/get-user-in-project?projectId=${projectId}&roleCode=${roleCode}`,
  );
}

export function assignTaskToMemberByDatasetApi(projectId, assignMembers) {
  return axios.post(`/dataset/assign-task-to-member-by-dataset`, {
    projectId: projectId,
    assignMembers: assignMembers,
  });
}

export function assignTaskToMemberApi(projectId, assignMembers) {
  return axios.post(`/dataset/assign-task-to-member`, {
    projectId: projectId,
    assignMembers: assignMembers,
  });
}

export function unassignTaskToMemberApi(projectId, assignMembers) {
  return axios.post(`/dataset/unassign-task-to-member`, {
    projectId: projectId,
    assignMembers: assignMembers,
  });
}

export function unassignTaskToMemberByDatasetApi(projectId, assignMembers) {
  return axios.post(`/dataset/unassign-task-to-member-by-dataset`, {
    projectId: projectId,
    assignMembers: assignMembers,
  });
}

export function getModuleByRoleInProject(projectId) {
  return axios.get(
    `/project/get-module-by-role-in-project?projectId=${projectId}`,
  );
}

export function getProjectLabels(projectId) {
  return axios.get(
    `/classes/get-label-type-by-project-id?projectId=${projectId}`,
  );
}

// Get list classification by projectid
export function getProjectClassifications(projectId) {
  return axios.get(
    `/classify/get-classify-by-projectid?projectId=${projectId}`,
  );
}

// Get list classification by projectid
export function getClassifications(projectId) {
  return axios.get(`/classify/get-classify?projectId=${projectId}`);
}

// Add new classification
export function addNewClassification(projectId, name, dataType, items) {
  return axios.post(`/classify/add-new-classify`, {
    projectId: projectId,
    classifyName: name,
    dataType: dataType,
    classifyItems: items,
  });
}

// Update new classification
export function updateClassification(
  projectId,
  classifyId,
  name,
  dataType,
  items,
) {
  return axios.post(`/classify/update-classify`, {
    projectId: projectId,
    classifyId: classifyId,
    classifyName: name,
    dataType: dataType,
    classifyItems: items,
  });
}

// Delete classification
export function deleteClassification(classifyId) {
  return axios.delete(`/classify/delete-classify/${classifyId}`);
}

export function deleteProjectbyId({ projectId, projectType }) {
  return axios.put(`/project/make-delete-project`, {
    projectId,
    projectType,
  });
}

export async function importClassification(data) {
  try {
    return await axios.post(`/classify/import-classify`, {
      ...data,
    });
  } catch (error) {
    return error?.response;
  }
}

export function addProjectLabels(
  projectId,
  parentId,
  color,
  name,
  desc,
  path,
  type,
) {
  return axios.post(`/classes/add-new-label-type`, {
    projectId: projectId,
    parentId: parentId,
    colorPattern: color,
    className: name,
    description: desc,
    referencePath: path,
    classesTypeCode: type,
  });
}

export function updateProjectLabels({
  projectId,
  id,
  description,
  referencePath,
  color,
  type,
  target,
  isShowLabeling = true,
}) {
  return axios.post(`/classes/update-label-type`, {
    projectId: projectId,
    id,
    description,
    referencePath,
    color,
    classesTypeCode: type,
    target,
    isShowLabeling,
  });
}

export function deleteProjectLabels({ projectId, id }) {
  return axios.post(`/classes/delete-label-type`, {
    projectId,
    id,
  });
}

export function exportDataset(data) {
  return axios.post(`/dataset/add-export-history`, {
    ...data,
  });
}

export function getExportDataset(
  projectId,
  selectedPageSize,
  currentPage,
  status,
) {
  return axios.get(
    `/dataset/get-export-history?projectId=${projectId}&pageSize=${selectedPageSize}&currentPage=${currentPage}&status=${status}`,
  );
}

export function prepareCustomerData(projectId) {
  return axios.post(`/project/start-prepare-review-data`, {
    projectId: projectId,
  });
}

export function getSourceStatus() {
  return axios.get(`/organization/get-const-data?module_code=source`);
}

export function datasetAddMoreData(
  projectId,
  datasetId,
  dataSource,
  classifyData,
) {
  return axios.post(`/dataset/add-more-for-dataset`, {
    projectId: projectId,
    datasetId: datasetId,
    dataSource: dataSource,
    classifyData: classifyData,
  });
}

export function inviteMembersToProject(projectId, assignMembers) {
  return axios.post(`/project/invite-member-to-project`, {
    ProjectId: parseInt(projectId),
    Members: assignMembers,
  });
}

export function editMembersToProject(projectId, memberId, roleCode) {
  return axios.post(`/project/change-role-member-in-project`, {
    ProjectId: parseInt(projectId),
    UserId: memberId,
    RoleCode: roleCode,
  });
}

export function deleteMembersToProject(projectId, memberId, roleCode) {
  return axios.post(`/project/remove-member-in-project`, {
    ProjectId: parseInt(projectId),
    UserId: memberId,
  });
}

export function resetSourceStatus(
  projectId,
  datasetId,
  oldStatus,
  newStatus,
  sourceIds,
) {
  return axios.post(`/dataset/reset-status-source`, {
    projectId: parseInt(projectId),
    datasetId: parseInt(datasetId),
    oldStatus: oldStatus,
    newStatus: newStatus,
    sourceIds: sourceIds,
  });
}

export function getTotalApproveAnnotationOfEachClassInProject(projectId) {
  return axios.get(
    `/labels/get-total-approve-annotation-each-class-in-project?projectId=${projectId}`,
  );
}

export function getTotalApproveImgClassInProject(projectId, datasetIds = null, status = null) {
  return axios.get(
    `/labels/get-total-approve-image-for-each-class?projectId=${projectId}${datasetIds ? `&datasetIds=${datasetIds}` : ''}${status ? `&status=${status}` : ''}`,
  );
}

export function getLabelerStatusInProject(projectId, labelerId) {
  return axios.get(
    `/labels/get-labeler-status?projectId=${projectId}&labelerId=${labelerId}`,
  );
}

export function getReviewerStatusInProject(projectId, labelerId) {
  return axios.get(
    `/labels/get-reviewer-status?projectId=${projectId}&labelerId=${labelerId}`,
  );
}

export function addNewLabelAttribute(
  projectId,
  labelId,
  name,
  dataType,
  defaultValue,
) {
  return axios.post(`/classes/add-new-attribute`, {
    projectId: projectId,
    labelTypeId: parseInt(labelId),
    attributeName: name,
    dataType: dataType,
    attributeValue: defaultValue,
  });
}

export function getLabelAttribute(labelId) {
  return axios.get(
    `/classes/get-attribute-by-label-type-id?labelTypeId=${labelId}`,
  );
}

export function deleteAttribute(projectId, attributeId) {
  return axios.post(`/classes/delete-attribute-by-id`, {
    projectId: parseInt(projectId),
    attributeId: parseInt(attributeId),
  });
}

export function updateAttribute(
  projectId,
  attributeId,
  attributeName,
  dataType,
  attributeValue,
) {
  return axios.post(`/classes/update-attribute-by-id`, {
    projectId: parseInt(projectId),
    attributeId: parseInt(attributeId),
    dataType: dataType,
    attributeName: attributeName,
    attributeValue: attributeValue,
  });
}

export function updateProject(
  projectId,
  projectName,
  projectDesc,
  dataType,
  target,
  imagePath,
  startTime, 
  endTime
) {
  return axios.post(`/project/add-new-project`, {
    ProjectId: parseInt(projectId),
    ProjectName: projectName,
    ProjectDescription: projectDesc,
    DataType: dataType,
    ImagePath: imagePath,
    target: parseInt(target),
    startTime: startTime, 
    endTime: endTime
  });
}

export function cloneDatasetsApi(data) {
  return axios.post(`/dataset/clone-dataset`, data);
}


export const getVersionsDataSetSource = (projectId, datasetId) => {
  return axios.get(
    `/dataset/get-versions-dataset?datasetId=${datasetId}&projectId=${projectId}`,
  );
};
export function publicDatasetsApi(data) {
  return axios.post(`/dataset/public-dataset`, data);
}

export function getListTagByProjectId(projectId) {
  return axios.get(`/tag/get-list-tag?projectId=${projectId}`);
}

export function createTagSource(data) {
  return axios.post(`/tag`, data);
}
export function getProjectsByTextSearchApi(
  projectId,
  search,
  selectedPageSize = 10,
  currentPage = 1,
) {
  return axios.get(
    `/project/get-projects-by-text-search?projectId=${projectId}&pageSize=${selectedPageSize}&currentPage=${currentPage}&search=${search}`,
  );
}




export function getLanguageListApi() {
  return axios.get(`/languages/get-language-list`);
}

export function startGenerateModel(socket, payload) {
  socket.emit("start_generate_model", payload);
}

export function getClassificationModelCounter(data) {
  return axios.post(`/dataset/get-classification-counter`, {
    ...data,
  });
}

export async function getLabelBoxesCounter(projectId, datasetIds, modelId = null) {
  try {
    return await axios.get(`/labels/get-labels-and-box-count?projectId=${projectId}&datasetIds=${datasetIds}${modelId ? ("&modelId=" + modelId) : ""}`);
  } catch(error) {
    return error?.response ;
  }
}

export async function importObjectAnnotation(data) {
  try {
    return await axios.post(`/dataset/import-object-annotation`, {
      ...data,
    });
  } catch (error) {
    return error?.response;
  }
}

export async function importTags(data) {
  try {
    return await axios.post(`/dataset/import-tags`, {
      ...data,
    });
  } catch (error) {
    return error?.response;
  }
}

export async function importClassificationForDataset(data) {
  try {
    return await axios.post(`/classify/import-classify-dataset`, {
      ...data,
    });
  } catch (error) {
    return error?.response;
  }
}

export async function genarateModelByApi(data) {
  try {
    coreToolAxios.defaults.headers.Authorization = "JWT " + getCookie("Bearer");
    const socketId = socket.id;

    return await coreToolAxios.post(`/automlcallback/genarate-model`, {
      ...data,
      socketId: socketId,
    });
  } catch (error) {
    return error?.response;
  }
}

export async function trainingModelByApi(data) {
  try {
    coreToolAxios.defaults.headers.Authorization = "JWT " + getCookie("Bearer");
    const socketId = socket.id;

    return await coreToolAxios.post(`/model-generation`, {
      ...data,
      socketId: socketId
    });
  } catch(error) {
    return error?.response ;
  }
}

export function createClassAPI(payload) {
  return axios.post("/classes/add-new-class-setting", {
    ...payload,
  });
}

export function updateClassAPI(payload) {
  return axios.post("/classes/update-class-setting", {
    ...payload,
  });
}

export function addAttributeAPI(payload) {
  return axios.post("/classes/add-new-attribute", {
    ...payload,
  });
}

export function updateAttributeAPI(payload) {
  return axios.post("/classes/update-attribute-by-id", {
    ...payload,
  });
}

export function deleteLabelAPI(payload) {
  return axios.post("/classes/delete-label-type", {
    ...payload,
  });
}

export function getCountAnnotateByClassId(payload) {
  return axios.get(
    `/classes/get-count-annotate-by-class-id?projectId=${payload.projectId}&labelId=${payload.labelId}`,
  );
}

export function getLabeListApi(payload) {
  const { projectId, currentPage, pageSize } = payload;
  return axios.get(
    `/classes/get-label-type-for-list-page?projectId=${projectId}&pageSize=${pageSize}&currentPage=${currentPage}`,
  );
}

export async function automaticAnnotationApi(data) {
  try {
    coreToolAxios.defaults.headers.Authorization = "JWT " + getCookie("Bearer");
    const socketId = socket.id;

    return await coreToolAxios.post(`/automaticAnnotation`, {
      ...data,
      socketId: socketId,
    });
  } catch (error) {
    return error?.response;
  }
}

export async function getProjectTargetFilter(data) {
  try {
    return await axios.get(`/labels/get-project-target-by-member?projectId=${data.projectId}&datasetId=${data.datasetId}&memberId=${data.memberId}&fromDate=${data.fromDate}&toDate=${data.toDate}${data.role ? ("&role=" + data.role) : ''}${data.status ? ("&status=" + data.status) : ''}`);
  } catch(error) {
    return error?.response ;
  }
}

export async function getLabelCountFilter(data) {
  try {
    return await axios.get(`/labels/get-label-count-by-role?projectId=${data.projectId}&datasetId=${data.datasetId}&memberId=${data.memberId}&fromDate=${data.fromDate}&toDate=${data.toDate}${data.role ? ("&role=" + data.role) : ''}${data.status ? ("&status=" + data.status) : ''}`);
  } catch(error) {
    return error?.response ;
  }
}

export async function getProjectStatusFilter(data) {
  try {
    return await axios.get(`/project/get-project-status-statistic?projectId=${data.projectId}&datasetId=${data.datasetId}&memberId=${data.memberId}&fromDate=${data.fromDate}&toDate=${data.toDate}${data.role ? ("&role=" + data.role) : ''}${data.status ? ("&status=" + data.status) : ''}`);
  } catch(error) {
    return error?.response ;
  }
}

export async function getClassCountFilter(data) {
  try {
    return await axios.get(`/labels/get-class-count-for-statistic?projectId=${data.projectId}&datasetId=${data.datasetId}&memberId=${data.memberId}&fromDate=${data.fromDate}&toDate=${data.toDate}${data.role ? ("&role=" + data.role) : ''}${data.status ? ("&status=" + data.status) : ''}`);
  } catch(error) {
    return error?.response ;
  }
}

export async function getLabelingTimeFilter(data) {
  try {
    return await axios.get(`/labels/get-labeling-time-by-role?projectId=${data.projectId}&datasetId=${data.datasetId}&memberId=${data.memberId}&fromDate=${data.fromDate}&toDate=${data.toDate}${data.role ? ("&role=" + data.role) : ''}`);
  } catch(error) {
    return error?.response ;
  }
}

export async function getLabelingTimeDetailFilter(data) {
  try {
    return await axios.get(`/labels/get-labeling-time-detail?projectId=${data.projectId}&datasetId=${data.datasetId}&memberId=${data.memberId}&fromDate=${data.fromDate}&toDate=${data.toDate}${data.role ? ("&role=" + data.role) : ''}&sort=${data.sort ?? 'asc'}`);
  } catch(error) {
    return error?.response ;
  }
}
