/* eslint-disable */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import _, { get } from "lodash";

import {
    GET_ENTERPRISE_USER_LIST,
    ADD_ENTERPRISE_LOG_PRICE,
    EDIT_ENTERPRISE_LOG_PRICE,
    DELETE_ENTERPRISE_LOG_PRICE,
    showNoti
} from "redux/actions";

import {
    getEnterpriseUserListSuccess,
    getEnterpriseUserListError,
    addEnterPriseLogPriceSuccess,
    addEnterPriseLogPriceError,
    editEnterPriseLogPriceSuccess,
    editEnterPriseLogPriceError,
    deleteEnterPriseLogPriceSuccess,
    deleteEnterPriseLogPriceError
} from './actions'

import {
    SUCCESS_CASE,
    ERROR_CASE,
  } from "components/notificationModal/constants";

import {
    getEnterpriseUserListApi,
    postAddLogPricing,
    postDeleteLogPricing,
    postUpdateLogPricing
} from './api'
import { number } from "yup";

const prepareEnterprice = (enterpriseUsers)=>{
    let grouped = _.map(_.toPairs(_.groupBy(enterpriseUsers,"user_id")), currentItem => {
        return _.zipObject(["user_id", "childs"], currentItem);
    });
    grouped = _.orderBy(grouped, (currentItem)=>+currentItem.user_id, ['desc']);
    grouped.forEach(item=>{
        item['total_price'] = item.childs.reduce((total, num)=>total+(+num.current_price), 0);
    })
    console.log(grouped)
    return grouped
}

export function* watchGetEnterpriseUserList() {
    yield takeEvery(GET_ENTERPRISE_USER_LIST, getEnterpriseUserListData);
}

function* getEnterpriseUserListData({ payload }) {
    const { selectedPageSize, currentPage, status, organizationName, email, startTime, endTime } = payload;
    try {
        const response = yield call(getEnterpriseUserListApi, selectedPageSize, currentPage, status, organizationName, email, startTime, endTime);
        const statusCode = get(response, "status", "");
        if (statusCode === 200) {
            let { enterpriseUsers, totalItem, totalPage } = get(response,"data.data")
            const result = prepareEnterprice(enterpriseUsers);
            yield put(getEnterpriseUserListSuccess({enterpriseUserList: result, totalItem, totalPage}));
        } else {
            yield put(getEnterpriseUserListError("Get Users Error!"));
        }
    } catch (error) {
        yield put(getEnterpriseUserListError("Get Users Error!"));
    }
}

function* addEnterpriseLogPrice(action) {
    const { payload, callback } = action.payload;
    const response = yield call(postAddLogPricing, { ...payload });
    const status = get(response, ['data', 'status']);
    const message = get(response, ['data', 'message'], '');
    if (response.status === 200 && status) {
      if (callback) callback(response.data);
      yield put(showNoti({
        type: SUCCESS_CASE,
        message: `add price successfully`,
        messageId: "notification.add-price-success",
      }));
    } else {
      yield put(showNoti({
        type: ERROR_CASE,
        message: `Add price error! ${message}`,
        messageId: "notification.add-price-error",
        intlValues: {
          message
        }
      }));
    }
  }

function* editEnterpriseLogPrice(action) {
    const { payload, callback } = action.payload;
    const response = yield call(postUpdateLogPricing, { ...payload });
    const status = get(response, ['data', 'status']);
    const message = get(response, ['data', 'message'], '');
    if (response.status === 200 && status) {
      if (callback) callback(response.data);
      yield put(showNoti({
        type: SUCCESS_CASE,
        message: `update price successfully`,
        messageId: "notification.update-price-success",
      }));
    } else {
      yield put(showNoti({
        type: ERROR_CASE,
        message: `Edit price error! ${message}`,
        messageId: "notification.update-price-error",
        intlValues: {
          message
        }
      }));
    }
  }

function* deleteEnterpriseLogPrice(action) {
    const { payload, callback } = action.payload;
    const response = yield call(postDeleteLogPricing, { ...payload });
    const status = get(response, ['data', 'status']);
    const message = get(response, ['data', 'message'], '');
    if (response.status === 200 && status) {
      if (callback) callback(response.data);
      yield put(showNoti({
        type: SUCCESS_CASE,
        message: `delete price successfully`,
        messageId: "notification.delete-price-success",
      }));
    } else {
      yield put(showNoti({
        type: ERROR_CASE,
        message: `delete price error! ${message}`,
        messageId: "notification.delete-price-error",
        intlValues: {
          message
        }
      }));
    }
  }

export default function* rootSaga() {
    yield all([
        fork(watchGetEnterpriseUserList),
        takeEvery(ADD_ENTERPRISE_LOG_PRICE, addEnterpriseLogPrice),
        takeEvery(EDIT_ENTERPRISE_LOG_PRICE, editEnterpriseLogPrice),
        takeEvery(DELETE_ENTERPRISE_LOG_PRICE, deleteEnterpriseLogPrice),
    ]);
}
