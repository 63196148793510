import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  AUTHENTICATION_GOOGLE,
  AUTHENTICATION_GOOGLE_SUCCESS,
  AUTHENTICATION_GOOGLE_ERROR,
  AUTHENTICATION_GITHUB,
  AUTHENTICATION_GITHUB_SUCCESS,
  AUTHENTICATION_GITHUB_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHECK_TOKEN,
  CHECK_TOKEN_SUCCESS,
  CHECK_TOKEN_ERROR,
  CHECK_TOKEN_NEW_MEMBER,
  CHECK_TOKEN_NEW_MEMBER_SUCCESS,
  CHECK_TOKEN_NEW_MEMBER_ERROR,
  SETUP_NEW_MEMBER,
  SETUP_NEW_MEMBER_SUCCESS,
  SETUP_NEW_MEMBER_ERROR,
  CONFIRMATION_USER_ERROR,
  CONFIRMATION_USER,
  CONFIRMATION_USER_SUCCESS,
  RESEND_CONFIRMATION,
  RESEND_CONFIRMATION_SUCCESS,
  RESEND_CONFIRMATION_ERROR,
  FETCH_USER_INFO,
  UPDATE_USER_INFO_SUCCESS,
} from "redux/actions";

const INIT_STATE = {
  user: null,
  isVerified: false,
  isValidToken: null,
  loading: false,
  error: "",
  message: "",
  path: "",
  userInfo : {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_USER_INFO_SUCCESS: {
      const { userInfo } = action.payload;
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...userInfo
        }
      }
    }
    case FETCH_USER_INFO: {
      const { userInfo } = action.payload;
      return {
        ...state,
        userInfo
      }
    }
    case LOGIN_USER:
      return { ...state, loading: true, error: "", path: "/user/login" };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        isVerified: action.payload.isVerified,
        error: "",
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: null,
        isVerified: false,
        error: action.payload.message,
      };
    case AUTHENTICATION_GOOGLE:
      return { ...state, loading: true, error: "", path: "/user/login" };
    case AUTHENTICATION_GOOGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        isVerified: action.payload.isVerified,
        error: "",
      };
    case AUTHENTICATION_GOOGLE_ERROR:
      return {
        ...state,
        loading: false,
        user: null,
        isVerified: false,
        error: action.payload.message,
      };
    case AUTHENTICATION_GITHUB:
      return { ...state, loading: true, error: "", path: "/user/login" };
    case AUTHENTICATION_GITHUB_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        isVerified: action.payload.isVerified,
        error: "",
      };
    case AUTHENTICATION_GITHUB_ERROR:
      return {
        ...state,
        loading: false,
        user: null,
        isVerified: false,
        error: action.payload.message,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        loading: true,
        error: "",
        path: "/user/forgot-password",
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        error: "",
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        message: "",
        error: action.payload.message,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        loading: true,
        error: "",
        path: "/user/reset-password",
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        error: "",
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    case CHECK_TOKEN:
      return { ...state, loading: true, error: "" };
    case CHECK_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        isValidToken: true,
        error: "",
      };
    case CHECK_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        isValidToken: false,
        error: action.payload.message,
      };
    case CHECK_TOKEN_NEW_MEMBER:
      return { ...state, loading: true, error: "" };
    case CHECK_TOKEN_NEW_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        isValidToken: true,
        error: "",
      };
    case CHECK_TOKEN_NEW_MEMBER_ERROR:
      return {
        ...state,
        loading: false,
        isValidToken: false,
        error: action.payload.message,
      };
    case REGISTER_USER:
      return { ...state, loading: true, error: "", path: "/user/register" };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: "Register Success!",
      };
    case REGISTER_USER_ERROR:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload.message,
        message: "",
      };
    case SETUP_NEW_MEMBER:
      return {
        ...state,
        loading: true,
        isVerified: false,
        error: "",
        message: "",
        path: "/user/setup-new-member",
      };
    case SETUP_NEW_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        isVerified: true,
        error: "",
        message: "New member is setup successfully!",
        path: "/user/setup-new-member",
      };
    case SETUP_NEW_MEMBER_ERROR:
      return {
        ...state,
        loading: false,
        isVerified: "",
        error: action.payload.message,
        message: "",
        path: "/user/setup-new-member",
      };
    case CONFIRMATION_USER:
      return {
        ...state,
        loading: true,
        isVerified: false,
        error: "",
        message: "",
        path: "/user/confirmation",
      };
    case CONFIRMATION_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isVerified: true,
        error: "",
        message: "Confirmation Success!",
      };
    case CONFIRMATION_USER_ERROR:
      return {
        ...state,
        loading: false,
        isVerified: "",
        error: action.payload.message,
        message: "",
      };
    case RESEND_CONFIRMATION:
      return {
        ...state,
        loading: true,
        isVerified: "",
        error: "",
        message: "",
        path: "/user/validate-email",
      };
    case RESEND_CONFIRMATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        message: action.payload.message,
      };
    case RESEND_CONFIRMATION_ERROR:
      return {
        ...state,
        loading: false,
        isVerified: "",
        user: null,
        error: action.payload.message,
        message: "",
      };
    case LOGOUT_USER:
      return { ...state, user: null, error: "" };
    default:
      return { ...state };
  }
};
