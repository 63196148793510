import {
    JUMP_TO_STEP,
    UPDATE_IS_OPENED_TOUR_GUIDE
} from "redux/actions";

export const jumpToStep = (payload) => ({
    type: JUMP_TO_STEP,
    payload
})

export const updateIsOpenedTourGuide = ({ payload, callback }) => ({
    type: UPDATE_IS_OPENED_TOUR_GUIDE,
    payload,
    callback
})
