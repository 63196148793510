export const sizeUnitOptions = [
  {value: "KB", label: "KB", key: 0},
  {value: "MB", label: "MB", key: 1},
  {value: "GB", label: "GB", key: 2},
];

export const conditionOptions = [
  {value: "larger than", label: "Larger than", labelId: "advanced-search.larger-than", key: 0},
  {value: "equal to", label: "Equal to", labelId: "advanced-search.equal-to", key: 1},
  {value: "smaller than", label: "Smaller than", labelId: "advanced-search.smaller-than", key: 2},
];

export const statusOptions = [
  {value: "assigned", labelId: "status.assigned", key: 0},
  {value: "inprogress", labelId: "status.inprogress", key: 1},
  {value: "submitted", labelId: "status.submitted", key: 2},
  {value: "approved", labelId: "status.approved", key: 3},
  {value: "skipped", labelId: "status.skipped", key: 4},
  {value: "new", labelId: "Unassigned", key: 5},
  {value: "reopen", labelId: "status.reopen", key: 6},
  {value: "doublecheck", labelId: "status.doublecheck", key: 7},
];