export function handler(data, emitter, event) {
  data.event = event;
  return emitter(data);
}

export function startGenerateModel(socket, payload) {
    socket.emit("start_generate_model", payload);
}

export function generateModelResponse(socket, emitter) {
  socket.off("generate_model_response", (data) =>
    handler(data, emitter, "generate_model_response"),
  );
}

export function generateModelOff(socket, emitter) {
  socket.off("generate_model_end", (data) =>
    handler(data, emitter, "generate_model_end"),
  );
}

export function trainingModelResponse(socket, emitter) {
  socket.off("training_model_response", (data) =>
    handler(data, emitter, "training_model_response"),
  );
}

export function trainingModelProcessing(socket, emitter) {
  socket.off("training_model_processing", (data) =>
    handler(data, emitter, "training_model_processing"),
  );
}

export function trainingModelOff(socket, emitter) {
  socket.off("training_model_end", (data) =>
    handler(data, emitter, "training_model_end"),
  );
}

export function automaticAnnotationOff(socket, emitter) {
  socket.off("automatic_annotation_end", (data) =>
    handler(data, emitter, "automatic_annotation_end"),
  );
}
