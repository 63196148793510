/*
 * SocketReducer
 */

import produce from "immer";
import {
  START_CHANNEL,
  STOP_CHANNEL,
  AUTHENTICATED_SOCKET,
  UNAUTHORIZED_SOCKET,
} from "./constants";

export const initialState = {
  lastFrameFetchingAnnotations: new Map(),
  connected: null,
  serverStatus: "",
};

/* eslint-disable default-case, no-param-reassign */
const socketReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case START_CHANNEL:
        draft.connected = true;
        break;

      case STOP_CHANNEL:
        draft.connected = false;
        break;

      case AUTHENTICATED_SOCKET:
        draft.serverStatus = "success";
        break;

      case UNAUTHORIZED_SOCKET:
        draft.serverStatus = "error";
        break;
    }
  });

export default socketReducer;
