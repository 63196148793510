import {
    GET_ENTERPRISE_USER_LIST,
    GET_ENTERPRISE_USER_LIST_SUCCESS,
    GET_ENTERPRISE_USER_LIST_ERROR,
    ADD_ENTERPRISE_LOG_PRICE,
    ADD_ENTERPRISE_LOG_PRICE_SUCCESS,
    ADD_ENTERPRISE_LOG_PRICE_ERROR,
    EDIT_ENTERPRISE_LOG_PRICE,
    EDIT_ENTERPRISE_LOG_PRICE_SUCCESS,
    EDIT_ENTERPRISE_LOG_PRICE_ERROR,
    DELETE_ENTERPRISE_LOG_PRICE,
    DELETE_ENTERPRISE_LOG_PRICE_SUCCESS,
    DELETE_ENTERPRISE_LOG_PRICE_ERROR,
} from "redux/actions";

export const getEnterpriseUserList = (filter) => ({
    type: GET_ENTERPRISE_USER_LIST,
    payload: { ...filter },
});

export const getEnterpriseUserListSuccess = (userList) => ({
    type: GET_ENTERPRISE_USER_LIST_SUCCESS,
    payload: { ...userList },
});

export const getEnterpriseUserListError = (message) => ({
    type: GET_ENTERPRISE_USER_LIST_ERROR,
    payload: { message },
});


export const addEnterPriseLogPrice = (data) => ({
    type: ADD_ENTERPRISE_LOG_PRICE,
    payload: { ...data },
});

export const addEnterPriseLogPriceSuccess = (message) => ({
    type: ADD_ENTERPRISE_LOG_PRICE_SUCCESS,
    payload: { ...message },
});

export const addEnterPriseLogPriceError = (message) => ({
    type: ADD_ENTERPRISE_LOG_PRICE_ERROR,
    payload: { message },
});

export const editEnterPriseLogPrice = (data) => ({
    type: EDIT_ENTERPRISE_LOG_PRICE,
    payload: { ...data },
});

export const editEnterPriseLogPriceSuccess = (message) => ({
    type: EDIT_ENTERPRISE_LOG_PRICE_SUCCESS,
    payload: { ...message },
});

export const editEnterPriseLogPriceError = (message) => ({
    type: EDIT_ENTERPRISE_LOG_PRICE_ERROR,
    payload: { message },
});

export const deleteEnterPriseLogPrice = (data) => ({
    type: DELETE_ENTERPRISE_LOG_PRICE,
    payload: { ...data },
});

export const deleteEnterPriseLogPriceSuccess = (message) => ({
    type: DELETE_ENTERPRISE_LOG_PRICE_SUCCESS,
    payload: { ...message },
});

export const deleteEnterPriseLogPriceError = (message) => ({
    type: DELETE_ENTERPRISE_LOG_PRICE_ERROR,
    payload: { message },
});
