/* eslint-disable */
import {
    GET_USER_LIST,
    GET_USER_LIST_SUCCESS,
    GET_USER_LIST_ERROR,
    GET_USER_CHART,
    GET_USER_CHART_SUCCESS,
    GET_USER_CHART_ERROR,
    GET_USER_REPORT,
    GET_USER_REPORT_SUCCESS,
    GET_USER_REPORT_ERROR,
} from "redux/actions";

const INIT_STATE = {
    userList: [],
    totalItem:0, 
    totalPage:0,
    loadingUsers: false,
    userChart: {},
    error: "",

    userReport: [],
    totalItem: 0,
    totalPage: 0,
    loadingReport: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_USER_LIST:
        return { ...state, loadingUsers: true, error: "" };
      case GET_USER_LIST_SUCCESS:
        return {
          ...state,
          userList: action.payload.userList,
          totalItem: action.payload.totalItem,
          totalPage: action.payload.totalPage,
          loadingUsers: false,
          error: "",
        };
      case GET_USER_LIST_ERROR:
        return {
          ...state,
          userList: [],
          totalItem: 0,
          totalPage: 0,
          loadingUsers: false,
          error: action.payload.message,
        };
      case GET_USER_CHART:
        return { ...state, loadingUsers: true, error: "" };
      case GET_USER_CHART_SUCCESS:
        return {
          ...state,
          userChart: action.payload.userChart,
          loadingUsers: false,
          error: "",
        };
      case GET_USER_CHART_ERROR:
        return {
          ...state,
          userChart: {},
          loadingUsers: false,
          error: action.payload.message,
        };
      case GET_USER_REPORT_SUCCESS: {
        return {
          ...state,
          userReport: action.payload.userReport,
          totalPage: action.payload.totalPage,
          totalItem: action.payload.totalItem,
          loadingReport: false,
        };
      };
      case GET_USER_REPORT_ERROR:
        return {
          ...state,
          loadingReport: false,
        };
      case GET_USER_REPORT:
        return {
          ...state,
          loadingReport: true,
        };
      default:
        return { ...state };
    }
}
