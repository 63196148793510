/* eslint-disable */
import React from 'react'
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import _, { get } from "lodash";
import IntlMessages from "helpers/IntlMessages";


import {
  GET_ISSUE_LIST, showNoti,
} from "redux/actions";

import {
  getIssueListSuccess,
  getIssueListError,
} from './actions'
import { INIT_STATE } from 'redux/issue/reducer'

import {
  getIssueListAPI
} from './api'
import { ERROR_CASE } from 'components/notificationModal/constants';


function* getIssueListSaga({ payload }) {
  try {
    const response = yield call(getIssueListAPI, payload);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      let data = get(response, "data.data")
      yield put(getIssueListSuccess({
        data: data.issues,
        pagination: {
          ...INIT_STATE.pagination,
          currentPage: payload.currentPage,
          totalItem: data.totalItem,
          totalPage: data.totalPage
        }
      }))

    } else {
      yield put(showNoti({
        type: ERROR_CASE,
        message: <IntlMessages id="issue-page.get-issue-list-error" />
      }))
      yield put(getIssueListError(<IntlMessages id="issue-page.get-issue-list-error" />));
    }
  } catch (error) {
    yield put(showNoti({
      type: ERROR_CASE,
      message: error.toString()
    }))
    yield put(getIssueListError(<IntlMessages id="issue-page.get-issue-list-error" />));
  }
}



export default function* rootSaga() {
  yield all([
    takeEvery(GET_ISSUE_LIST, getIssueListSaga)
  ]);
}
