import axios from "../api";

export function getAgentsApi() {
  return axios.get(`/agent`);
}

export function getDatasetsApi() {
  return axios.get(`/dataset`);
}

export function getTasksApi() {
  return axios.get(`/agent/task`);
}

export function addTaskApi(typeId, agentId, datasetId, config) {
  return axios.post(`/agent/task`, {
    agentTaskType: typeId,
    agentId: agentId,
    datasetId: datasetId,
    config: config
  });
}