import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
// import { messaging } from "../../helpers/Firebase";
import get from "lodash/get";
import { setCookie, deleteAllCookies } from "helpers/Utils";
import {
  LOGIN_USER,
  REGISTER_USER,
  CONFIRMATION_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SETUP_NEW_MEMBER,
  CHECK_TOKEN,
  CHECK_TOKEN_NEW_MEMBER,
  RESEND_CONFIRMATION,
  AUTHENTICATION_GOOGLE,
  AUTHENTICATION_GITHUB,
  UPDATE_USER_INFO,
  UPDATE_USER_PASSWORD,
} from "redux/actions";
import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  confirmationUserSuccess,
  confirmationUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  setupNewMemberSuccess,
  setupNewMemberError,
  resendConfirmationSuccess,
  resendConfirmationError,
  authenticationGoogleSuccess,
  authenticationGoogleError,
  authenticationGithubSuccess,
  authenticationGithubError,
  checkTokenSuccess,
  checkTokenError,
  checkTokenNewMemberSuccess,
  checkTokenNewMemberError,
} from "./actions";

import {
  createUser,
  confirmUser,
  resendEmail,
  login,
  authGoogle,
  authGithub,
  forgotPassword,
  resetPassword,
  setupNewMember,
  checkTokenApi,
  checkTokenNewMember,
  updatePassword,
  updateProfile,
} from "./api";
import { showNoti } from "redux/notificationModal/actions";
import {
  SUCCESS_CASE,
  ERROR_CASE,
} from "components/notificationModal/constants";

import TagManager from "react-gtm-module";
import { gtmId, isProduction } from "constants/defaultValues";
// Login with email
export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    // // call get device token
    // const deviceToken = yield call(goFetchFirebaseToken);
    // if (!deviceToken) {
    //   console.log({ type: "error", msg: "Cannot fetch FIREBASE deviceToken." })
    // }
    // setCookie('deviceToken', deviceToken);

    const response = yield call(login, email, password);
    const status = get(response, "status", "");
    const type = get(response, "data.type", "");
    if (status === 200) {
      const token = get(response, ["headers", "x-auth-token"], "");
      const {
        is_verified: isVerified,
        full_name: fullName,
        email,
        user_id: userId,
        social_type: socialType,
        is_admin: isAdmin,
        role_id: roleId,
        role_code: roleCode,
        image_preview_path,
        org_id: organizationId,
        moduleByRole,
        storage_service_name,
        storage_config,
        is_site_admin: isSiteAdmin,
        phone_number: phoneNumber,
        country,
        is_open_tour_guide: isOpenedTourGuide,
      } = get(response, "data", {
        isVerified: false,
        fullName: "",
        email: "",
        userId: "",
        socialType: null,
        isAdmin: false,
        roleId: null,
        roleCode: "",
        image_preview_path: "",
        organizationId: null,
        moduleByRole: null,
        storage_service_name: null,
        storage_config: {},
        isSiteAdmin: false,
        phoneNumber: "",
        country: "",
        isOpenedTourGuide: false,
      });
      if (token && isVerified) {
        setCookie("Bearer", token, 7);
        setCookie(
          "userInfo",
          JSON.stringify({
            fullName,
            email,
            userId,
            socialType,
            isAdmin,
            roleId,
            roleCode,
            image_preview_path,
            organizationId,
            storage_service_name,
            moduleByRole,
            storage_config,
            isSiteAdmin,
            phoneNumber,
            country,
            isOpenedTourGuide,
          }),
          7,
        );

        yield put(
          loginUserSuccess(
            {
              fullName,
              email,
              userId,
              socialType,
              isAdmin,
              roleId,
              image_preview_path,
              organizationId,
            },
            isVerified,
          ),
        );
        history.push("/");
        // window.location.replace(
        //   "http://salflabeling.seta-international.vn:94/",
        // );
      } else {
        yield put(loginUserError("Wrong credentials! Try again."));
        return;
      }
    } else if (status === 401 && type === "not verified") {
      yield put(loginUserError());
      // User has not verified email
      history.push(
        `/user/validate-email?email=${encodeURIComponent(
          email,
        )}&isVerified=false`,
      );
      return;
    } else {
      yield put(loginUserError("Invalid username or password!"));
    }
  } catch (error) {
    yield put(loginUserError("Wrong credentials!"));
  }
}

// Login with Google
export function* watchLoginWithGoogle() {
  yield takeEvery(AUTHENTICATION_GOOGLE, loginWithGoogle);
}

function* loginWithGoogle({ payload }) {
  // eslint-disable-next-line no-unused-vars
  const { accessToken, history } = payload;
  try {
    // // call get device token
    // const deviceToken = yield call(goFetchFirebaseToken);
    // if (!deviceToken) {
    //   console.log({ type: "error", msg: "Cannot fetch FIREBASE deviceToken." })
    // }
    // setCookie('deviceToken', deviceToken);

    const response = yield call(authGoogle, accessToken);
    const status = get(response, "status", "");
    if (status === 200) {
      const token = get(response, ["headers", "x-auth-token"], "");
      const {
        is_verified: isVerified,
        full_name: fullName,
        email,
        user_id: userId,
        social_type: socialType,
        is_admin: isAdmin,
        role_id: roleId,
        role_code: roleCode,
        image_preview_path,
        org_id: organizationId,
        moduleByRole,
        storage_service_name,
        is_site_admin: isSiteAdmin,
        storage_config,
        phone_number: phoneNumber,
        country,
        is_open_tour_guide: isOpenedTourGuide,
      } = get(response, "data", {
        isVerified: false,
        fullName: "",
        email: "",
        userId: "",
        socialType: null,
        isAdmin: false,
        roleId: null,
        roleCode: "",
        image_preview_path: "",
        organizationId: null,
        moduleByRole: null,
        storage_service_name: null,
        isSiteAdmin: false,
        storage_config: {},
        phoneNumber: "",
        country: "",
        isOpenedTourGuide: false,
      });

      if (token) {
        setCookie("Bearer", token, 7);
        setCookie(
          "userInfo",
          JSON.stringify({
            fullName,
            email,
            userId,
            socialType,
            isAdmin,
            roleId,
            roleCode,
            image_preview_path,
            organizationId,
            storage_service_name,
            moduleByRole,
            isSiteAdmin,
            storage_config,
            phoneNumber,
            country,
            isOpenedTourGuide,
          }),
          7,
        );
        yield put(
          authenticationGoogleSuccess(
            {
              fullName,
              email,
              userId,
              socialType,
              isAdmin,
              roleId,
              image_preview_path,
              organizationId,
            },
            isVerified,
          ),
        );

        history.push("/");
      } else {
        yield put(authenticationGoogleError("Wrong credentials!"));
      }
    } else {
      const message = get(response, "data.message", "");
      yield put(authenticationGoogleError(message));
    }
  } catch (error) {
    yield put(authenticationGoogleError("Wrong credentials!"));
  }
}

// Login with Github
export function* watchLoginWithGithub() {
  yield takeEvery(AUTHENTICATION_GITHUB, loginWithGithub);
}

function* loginWithGithub({ payload }) {
  const { code, history } = payload;
  try {
    // // call get device token
    // const deviceToken = yield call(goFetchFirebaseToken);
    // if (!deviceToken) {
    //   console.log({ type: "error", msg: "Cannot fetch FIREBASE deviceToken." })
    // }
    // setCookie('deviceToken', deviceToken);

    const response = yield call(authGithub, code);
    const status = get(response, "status", "");
    if (status === 200) {
      const token = get(response, ["headers", "x-auth-token"], "");
      const {
        is_verified: isVerified,
        full_name: fullName,
        email,
        user_id: userId,
        social_type: socialType,
        is_admin: isAdmin,
        role_id: roleId,
        role_code: roleCode,
        image_preview_path,
        org_id: organizationId,
        moduleByRole,
        storage_service_name,
        // eslint-disable-next-line no-unused-vars
        is_site_admin: isSiteAdmin,
        country,
      } = get(response, "data", {
        isVerified: false,
        fullName: "",
        email: "",
        userId: "",
        socialType: null,
        isAdmin: false,
        roleId: null,
        roleCode: "",
        image_preview_path: "",
        organizationId: null,
        moduleByRole: null,
        storage_service_name: null,
        isSiteAdmin: false,
        country: "",
      });
      if (token) {
        setCookie("Bearer", token, 7);
        setCookie(
          "userInfo",
          JSON.stringify({
            fullName,
            email,
            userId,
            socialType,
            isAdmin,
            roleId,
            roleCode,
            image_preview_path,
            organizationId,
            storage_service_name,
            moduleByRole,
            country,
          }),
          7,
        );
        yield put(
          authenticationGithubSuccess(
            {
              fullName,
              email,
              userId,
              socialType,
              isAdmin,
              roleId,
              image_preview_path,
              organizationId,
            },
            isVerified,
          ),
        );
        history.push("/");
        // window.location.replace(
        //   "http://salflabeling.seta-international.vn:94/",
        // );
      } else {
        yield put(authenticationGithubError("Wrong credentials!"));
      }
    } else {
      yield put(authenticationGithubError("Wrong credentials!"));
    }
  } catch (error) {
    yield put(authenticationGithubError("Wrong credentials!"));
  }
}

// Register
export function* watchRegisterUser() {
  yield takeLatest(REGISTER_USER, registerWithEmail);
}

function* registerWithEmail({ payload }) {
  const { fullName, email, password, companyName, yourTitle, countryName } =
    payload.user;
  const { history } = payload;
  try {
    const response = yield call(createUser, {
      fullName,
      email,
      password,
      companyName,
      yourTitle,
      countryName,
    });
    const status = get(response, "status", "");
    if (status === 200) {
      yield put(registerUserSuccess(email));
     isProduction &&  gtmId && TagManager.dataLayer({ dataLayer: { event: "tryitfree_form" } });

      history.push(
        `/user/validate-email?email=${encodeURIComponent(email)}&newUser=true`,
      );
    } else {
      const msg =
        get(response, "data[0].msg", "") ||
        get(response, "data.msg", "") ||
        "Register Error!";
      yield put(registerUserError(msg));
    }
  } catch (error) {
    yield put(registerUserError("Register Error!"));
  }
}

// Confirmation
export function* watchConfimationUser() {
  yield takeEvery(CONFIRMATION_USER, confirmationEmail);
}

function* confirmationEmail({ payload }) {
  const { email, token, history } = payload;
  try {
    const response = yield call(confirmUser, email, token);
    const status = get(response, "status", "");
    if (status === 200) {
      yield put(confirmationUserSuccess());
    } else {
      const type = get(response, "data.type", "");
      switch (type) {
        case "already-verified":
          yield put(confirmationUserError("Your email has already verified"));
          break;
        case "token expire":
          yield put(confirmationUserError());
          history.push(
            `/user/validate-email?email=${encodeURIComponent(
              email,
            )}&invalidToken=true`,
          );
          return;
        default:
          yield put(confirmationUserError("Confirmation Error!"));
      }
    }
    history.push("/user/login");
  } catch (error) {
    yield put(confirmationUserError("Confirmation Error!"));
    history.push("/user/login");
  }
}

// Resend
export function* watchResendConfimation() {
  yield takeEvery(RESEND_CONFIRMATION, resendConfirmationUser);
}

function* resendConfirmationUser({ payload }) {
  const { email } = payload;
  try {
    const response = yield call(resendEmail, email);
    const status = get(response, "status", "");
    if (status === 200) {
      yield put(
        resendConfirmationSuccess("confirmation.alert-success"),
      );
    } else {
      const msg =
        get(response, "data[0].msg", "") ||
        get(response, "data.msg", "") ||
        "Resend Confirmation Error!";
      yield put(resendConfirmationError(msg));
    }
  } catch (error) {
    yield put(resendConfirmationError("Resend Confirmation Error!"));
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

function logout({ payload }) {
  const { history } = payload;
  try {
    localStorage.setItem("log", "logout l475");
    deleteAllCookies();
    history.push("/user/login");
  } catch (error) {
    console.log("logout -> error", error);
  }
}

// Forgot password
export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPasswordUser);
}

function* forgotPasswordUser({ payload }) {
  const { email, history } = payload;
  try {
    const response = yield call(forgotPassword, email);
    const type = get(response, "data.type", "");
    const status = get(response, "status", "");
    if (status === 200) {
      yield put(
        forgotPasswordSuccess("We've sent you an email. Please check now!"),
      );
    } else if (type === "authentication error") {
      const msg = get(response, "data.msg", "Forgot Password Error!");
      yield put(forgotPasswordError(msg));
    } else if (type === "not verified") {
      history.push(
        `/user/validate-email?email=${encodeURIComponent(
          email,
        )}&isVerified=false&fromForgotPass=true`,
      );
      return;
      // const msg = get(response, "data.msg", "The email is not activated!");
      // yield put(forgotPasswordError(msg));
    } else {
      yield put(forgotPasswordError("Forgot Password Error!"));
    }
  } catch (error) {
    yield put(forgotPasswordError("Forgot Password Error!"));
  }
}

// Check token expire
export function* watchCheckTokenExpire() {
  yield takeEvery(CHECK_TOKEN, checkTokenExpire);
}

function* checkTokenExpire({ payload }) {
  const { resetToken, history } = payload;
  try {
    const response = yield call(checkTokenApi, resetToken);
    const status = get(response, "status", "");
    if (status === 200) {
      yield put(checkTokenSuccess());
    } else {
      yield put(
        checkTokenError("Your token is invalid. Please resend your email."),
      );
      // If token is invalid, navigate user to forgot-password page to resend email
      history.push("/user/forgot-password");
    }
  } catch {
    yield put(
      checkTokenError("Your token is invalid. Please resend your email."),
    );
    history.push("/user/forgot-password");
  }
}

// Check token expire
export function* watchCheckTokenNewMemberExpire() {
  yield takeEvery(CHECK_TOKEN_NEW_MEMBER, checkTokenNewMemberExpire);
}

function* checkTokenNewMemberExpire({ payload }) {
  const { token, history } = payload;
  try {
    const response = yield call(checkTokenNewMember, token);
    const status = get(response, "status", "");
    if (status === 200) {
      yield put(checkTokenNewMemberSuccess());
    } else {
      yield put(
        checkTokenNewMemberError(
          "Your token is invalid. Please confirm & reinvite with your manager!",
        ),
      );
      history.push("/user/login");
    }
  } catch {
    yield put(
      checkTokenNewMemberError(
        "Your token is invalid. Please resend your email.",
      ),
    );
    history.push("/user/login");
  }
}

// Reset password
export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPasswordUser);
}

function* resetPasswordUser({ payload }) {
  const { email, resetToken, newPassword, history } = payload;
  try {
    const response = yield call(resetPassword, email, newPassword, resetToken);
    const status = get(response, "status", "");
    if (status === 200) {
      yield put(resetPasswordSuccess("Reset Password Success!"));
      history.push("/user/login");
    } else {
      yield put(resetPasswordError("Reset Password Error! Try again."));
    }
  } catch (error) {
    yield put(resetPasswordError("Reset Password Error! Try again."));
  }
}

// setup password for new member
export function* watchSetupNewMember() {
  yield takeEvery(SETUP_NEW_MEMBER, setupNewMemberSaga);
}

function* setupNewMemberSaga({ payload }) {
  const { token, password, history } = payload;
  try {
    const response = yield call(setupNewMember, password, token);
    const status = get(response, "status", "");
    if (status === 200) {
      yield put(setupNewMemberSuccess("New member is setup successfully!"));
      history.push("/user/login");
    } else {
      yield put(setupNewMemberError("New member is setup Error! Try again."));
    }
  } catch (error) {
    yield put(setupNewMemberError("New member is setup Error! Try again."));
  }
}

function* updateUserInfo(action) {
  const { userInfo, callback } = action.payload;

  const payload = {
    fullname: userInfo.fullName,
    phone_number: userInfo.phoneNumber,
    image_preview_path: userInfo.image_preview_path,
    country: userInfo.country,
  };

  try {
    const response = yield call(updateProfile, { ...payload });
    const status = get(response, ["data", "status"]);
    const message = get(response, ["data", "message"], "");

    if (response.status === 200 && status) {
      if (callback) callback(response.data);
    } else {
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: `Update profile error! ${message}`,
          messageId: "notification.update-profile-error",
          intlValues: {
            message
          },
        }),
      );
    }
  } catch (exc) {
    const message = get(exc, ["data", "error", 0, "msg"], "");

    yield put(
      showNoti({
        type: ERROR_CASE,
        message: `Update profile error! ${message}`,
        messageId: "notification.update-profile-error",
        intlValues: {
          message
        },
      }),
    );
  }
}

function* updateUserPassword(action) {
  const { oldPassword, newPassword } = action.payload;

  try {
    const response = yield call(updatePassword, { oldPassword, newPassword });
    const status = get(response, ["data", "status"]);
    const message = get(response, ["data", "message"], "");

    if (response.status === 200 && status) {
      yield put(
        showNoti({
          type: SUCCESS_CASE,
          message: `Update password successfully!`,
          messageId: "notification.update-password-success",
        }),
      );
    } else {
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: `Update password error! ${message}`,
          messageId: "notification.update-password-error",
          intlValues: {
            message
          }
        }),
      );
    }
  } catch (exc) {
    const message = get(exc, ["data", "msg"], "");

    yield put(
      showNoti({
        type: ERROR_CASE,
        message: `Update password error! ${message}`,
        messageId: "notification.update-password-error",
        intlValues: {
          message
        }
      }),
    );
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLoginWithGoogle),
    fork(watchLoginWithGithub),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchConfimationUser),
    fork(watchResendConfimation),
    fork(watchForgotPassword),
    fork(watchCheckTokenExpire),
    fork(watchCheckTokenNewMemberExpire),
    fork(watchResetPassword),
    fork(watchSetupNewMember),
    takeEvery(UPDATE_USER_INFO, updateUserInfo),
    takeEvery(UPDATE_USER_PASSWORD, updateUserPassword),
  ]);
}
