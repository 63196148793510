import axios from "../api";

export function getIssueListAPI({
  pageSize=10 ,
  currentPage = 0,
  projectId,
  orderField,
  orderBy,
  status
}) {
  let query = `/issue?projectId=${projectId}`
  if (orderField) query = `${query}&orderField=${orderField}&orderBy=${orderBy || 'DESC'}`
  if (status) query = `${query}&status=${status}`
  query = `${query}&limit=${pageSize}&offset=${currentPage}`
  return axios.get(query);
}


