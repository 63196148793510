import {
  GET_ACTION_HISTORY,
  GET_ACTION_HISTORY_SUCCESS,
  GET_ACTION_HISTORY_FAILURE,
  GET_BENCHMARK,
  GET_BENCHMARK_SUCCESS,
  GET_BENCHMARK_FAILURE,
} from "redux/actions";

const INIT_STATE = {
  actionHistory: {
    data: [],
    totalItem: 0,
    totalPage: 0,
    loading: false,
  },
  benchmark: {
    data: [],
    totalItem: 0,
    totalPage: 0,
    loading: false,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ACTION_HISTORY: {
      return {
        ...state,
        actionHistory: {
          ...state.actionHistory,
          loading: true,
        },
      };
    }
    case GET_ACTION_HISTORY_FAILURE: {
      return {
        ...state,
        actionHistory: {
          ...state.actionHistory,
          loading: false,
        },
      };
    }
    case GET_ACTION_HISTORY_SUCCESS: {
      const { data, totalItem, totalPage } = action.payload;
      return {
        ...state,
        actionHistory: {
          data,
          totalItem,
          totalPage,
          loading: false,
        },
      };
    }

    case GET_BENCHMARK: {
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          loading: true,
        },
      };
    }
    case GET_BENCHMARK_FAILURE: {
      return {
        ...state,
        benchmark: {
          ...state.benchmark,
          loading: false,
        },
      };
    }
    case GET_BENCHMARK_SUCCESS: {
      const { data, totalItem, totalPage } = action.payload;
      return {
        ...state,
        benchmark: {
          data,
          totalItem,
          totalPage,
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};
