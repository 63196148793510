/* eslint-disable no-useless-escape */
import React from "react";
import { get } from "lodash";
import Resizer from "react-image-file-resizer";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import {
  defaultDirection,
  videoLabelingUrl,
  imageLabelingUrl,
} from "constants/defaultValues";
import parseJson from "parse-json";
import moment from "moment";
import { useEffect, useRef } from "react";
import config from "../../serviceConfig.json";
// import config from "./serviceConfig.json";
import {
  SUCCESS_CASE,
  ERROR_CASE,
} from "components/notificationModal/constants";
import { SHOW_NOTI, UPDATE_USER_INFO_SUCCESS } from "redux/actions";
import IntlMessages from "./IntlMessages";

const currentDomain =
  window.location.hostname.indexOf(config.defaultDomain) > 0
    ? `.${config.defaultDomain}`
    : window.location.hostname;

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key],
      B = b[key];
    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return dd + "." + mm + "." + yyyy;
};

export const getCurrentTime = () => {
  const now = new Date();
  return now.getHours() + ":" + now.getMinutes();
};

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem("direction")) {
    const localValue = localStorage.getItem("direction");
    if (localValue === "rtl" || localValue === "ltr") {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === "rtl",
  };
};

export const setDirection = (localValue) => {
  let direction = "ltr";
  if (localValue === "rtl" || localValue === "ltr") {
    direction = localValue;
  }
  localStorage.setItem("direction", direction);
};

export function getParameterByName(name, url) {
  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2]);
}

export function setCookie(
  cname,
  cvalue = "",
  exdays,
  domain = currentDomain,
  path = "/",
) {
  if (cname == null) return;
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  document.cookie = `${cname}=${cvalue}; expires=${d.toUTCString()}; domain=${domain} ;path=${path}`;
}

export function getCookie(cname) {
  const name = cname + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function deleteAllCookies() {
  document.cookie.split(";").forEach((c) => {
    const name = c.split("=")[0];
    if (name !== "localIP") {
      setCookie(name);
    }
  });
}

export function clearLocalStorage() {
  localStorage.clear();
}

export function isAdminAuthUser() {
  const user = parseJson(getCookie("userInfo"));
  if (user && user.isAdmin === true) {
    return true;
  }
  return false;
}

export function getLabelingBaseUrl() {
  return {
    videoLabelingUrl: videoLabelingUrl,
    imageLabelingUrl: imageLabelingUrl,
  };
}

export function isValidEmail(email) {
  if (
    /*
     * A valid email must required as below:
     * Contains uppercase (A-Z) or lowercase (a-z) English letters
     * Contains Digits (0-9)
     * No special characters: ! # $ % & ' * / = ? ^ _ ` { | } ~
     * Character . (period, dot or fullstop): not the first or last character, not come one after the other.
     */
    !/^\w+([\\.+-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,4})+$/i.test(email.trim())
  ) {
    return false;
  }
  return true;
}

export function formatNumber(num) {
  return num.toLocaleString("en-US");
}

export function checkModuleAccessByRoleCode(moduleRole, roleCode) {
  if (moduleRole.length === 0) return false;
  const moduleByCode = moduleRole.filter((module) => {
    return module.module_code === roleCode;
  });
  if (moduleByCode.length > 0) {
    return moduleByCode[0].access_module;
  }
  return false;
}

export function getStatus(status, value) {
  const statusItem = status.filter((item) => {
    return item.key === value;
  });
  return statusItem.length > 0 ? statusItem[0] : {};
}

export function formatTime(date) {
  return moment(date).format("dddd MMMM DD YYYY HH:mm:ss");
}

export function formatExportName(date) {
  return "Export_Dataset_" + moment(date).format("YYYY_MM_DD_HH_mm_ss");
}

export function durationToTime(duration) {
  const date = new Date(0);
  date.setMilliseconds(duration);
  return date.toISOString().substr(11, 8);
}

export function userInfoCookie() {
  const strUserInfo = getCookie("userInfo");
  if (strUserInfo && strUserInfo.length > 0) {
    const userInfo = JSON.parse(strUserInfo);
    if (userInfo.storage_config && userInfo.storage_config.length > 0)
      userInfo.storage_config = parseJson(userInfo.storage_config);
    return userInfo;
  }
  return null;
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function processSourceStatus(statusData, intl, haveAllOption = false) {
  const sourceStatus = haveAllOption
    ? [
        {
          key: "",
          label: intl.formatMessage({ id: "common.all" }),
          color: "",
          value: "",
        },
      ]
    : [];
  if (statusData.length > 0) {
    const status = statusData.map((item) => {
      return {
        key: item.data_code,
        label: intl.formatMessage({ id: item.data_text }),
        value: item.data_code,
        color: item.background_color,
      };
    });
    return [...sourceStatus, ...status];
  }
  return sourceStatus;
}

export function getExportType() {
  return [
    { key: "csv", label: "CSV", value: "csv" },
    { key: "json_coco", label: "COCO (JSON)", value: "json_coco" },
    {
      key: "json_vtn_standard",
      label: "VTN-Standard (Bounding Box)",
      value: "json_vtn_standard",
    },
    { key: "pascal_voc", label: "Pascal VOC", value: "pascal_voc" },
    {
      key: "transcription_json_vtn_standard",
      label: "VTN-Standard (Transcription)",
      value: "transcription_json_vtn_standard",
    },
    {
      key: "translation_json_vtn_standard",
      label: "VTN-Standard (Translation)",
      value: "translation_json_vtn_standard",
    },
    {
      key: "speaker_json_vtn_standard",
      label: "VTN-Standard (Speaker Separation)",
      value: "speaker_json_vtn_standard",
    },
    {
      key: "xml_cvat_image",
      label: "CVAT for images 1.1",
      value: "xml_cvat_image",
    },
    {
      key: "classify_json_image",
      label: "Classification (JSON)",
      value: "classify_json_image",
    },
    { key: "yolo_darknet", label: "YOLO Darknet (txt)", value: "yolo_darknet" },
  ];
}

export const handleNonReduxSideEffect = async (
  sideEffectFunc,
  params,
  notiFunc,
  successMessage,
) => {
  const response = await sideEffectFunc(params);
  const errorMessage =
    "Oops! It seems like there's something wrong to our server!";
  const statusCode = get(response, "status", "");
  if (statusCode === 200) {
    const { data } = get(response, "data");
    successMessage &&
      notiFunc({
        type: SUCCESS_CASE,
        message: successMessage,
      });
    return {
      success: true,
      data,
    };
  } else {
    notiFunc({
      type: ERROR_CASE,
      message: errorMessage,
    });
    return {
      success: false,
      data: null,
    };
  }
};

export const resizeFile = async (file) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      50,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob",
    );
  });
};
export const checkMobile = () => {
  return (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent,
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4),
    )
  );
};
export const checkResolution = () => {
  const width = window.innerWidth;
  return width < 1366;
};

export const handleUpdateUserSuccess = (userInfo, dispatch) => {
  setCookie("userInfo", JSON.stringify(userInfo), 7);

  const showNoti = (payload) => {
    dispatch({
      type: SHOW_NOTI,
      payload,
    });
  };

  const updateUserInforSuccess = (payload) => {
    dispatch({
      type: UPDATE_USER_INFO_SUCCESS,
      payload,
    });
  };

  updateUserInforSuccess({ userInfo });
  showNoti({
    type: SUCCESS_CASE,
    message: <IntlMessages id="member.profile-updated" />,
  });
};

export const initialLanguageList = async (data) => {
  const languageList = get(data, ["data", "data"], []).map((language) => ({
    value: language.language_code,
    label: language.language_name,
  }));
  const selectedLanguage = languageList.find(
    (language) => language.value === "en",
  );

  return {
    languageList,
    selectedLanguage,
  };
};

export const formatBytes = (bytes = 0, decimals = 2) => {
  if (typeof bytes === "string") {
    bytes = parseInt(bytes);
  }
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const secondToTimes = (sec, intl) => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor(sec / 60);
  const seconds = Math.floor(sec % 60);

  if (hours) {
    return `${hours} ${intl
      .formatMessage({ id: "plan-limit.hour" })
      .toLowerCase()}`;
  }

  if (minutes) {
    return `${minutes} ${intl
      .formatMessage({ id: "plan-limit.minutes" })
      .toLowerCase()}minutes`;
  }

  if (seconds) {
    return `${seconds} ${intl
      .formatMessage({ id: "plan-limit.second" })
      .toLowerCase()}seconds`;
  }
};

export const secondToAllTimes = (sec) => {
  const hours = Math.floor(sec / 3600);
  sec = sec % 3600;
  const minutes = Math.floor(sec / 60);
  const seconds = Math.floor(sec % 60);

  let times = "";
  if (hours) {
    times += `${hours} hours`;
  }

  if (minutes) {
    times += `
      ${minutes} minutes
    `;
  }

  if (seconds) {
    times += `
      ${seconds} seconds
    `;
  }
  return times;
};

export const readURL = (file, callback) => {
  const reader = new FileReader();
  reader.onload = function (e) {
    const img = new Image();
    img.onload = function (e) {
      callback({
        width: img.width,
        height: img.height,
      });
    };
    img.src = reader.result;
  };
  reader.readAsDataURL(file);
};

export function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const ellipsisTextByNumber = (text, n) => {
  if (!text?.trim()) return "";
  const trimText = text.trim();

  return trimText.length <= n ? trimText : `${trimText.slice(0, n)}...`;
};

export function payloadToQuery(payload) {
  let query = "";
  for (const key in payload) {
    if (!query) {
      query += `?${key}=${payload[key]}`;
    } else {
      query += `&${key}=${payload[key]}`;
    }
  }
  return query;
}

export const changeAlphaOfColor = (color, alpha = 1) => {
  if (!color?.trim()) return "";

  if (color.includes("#")) {
    color = color.replace("#", "");
    const r = parseInt(
      color.length === 3 ? color.slice(0, 1).repeat(2) : color.slice(0, 2),
      16,
    );
    const g = parseInt(
      color.length === 3 ? color.slice(1, 2).repeat(2) : color.slice(2, 4),
      16,
    );
    const b = parseInt(
      color.length === 3 ? color.slice(2, 3).repeat(2) : color.slice(4, 6),
      16,
    );
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  }
  if (color.includes("rgba(")) {
    return color?.replace(/[^,]+(?=\))/, alpha.toString());
  }
  if (color.includes("rgb(")) {
    return color
      ?.replace(/rgb/i, "rgba")
      ?.replace(/\)/i, `,${alpha.toString()}`);
  }
  return "";
};

export function convertViToEn(str, toUpperCase = false) {
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư

  return toUpperCase ? str.toUpperCase() : str;
}
