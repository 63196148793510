import { getCookie } from "helpers/Utils";
import axios, { coreToolAxios } from "../api";
import { socket } from "redux/socket/saga";

export const getModelList = (projectId) => {
  return axios.get(`/engine-models/get-model?projectId=${projectId}`);
};

export const getCustomModelList = (projectId) => {
  return axios.get(`/engine-models/get-custom-model?projectId=${projectId}`);
};

export const getActiveModelByProjectId = (projectId) => {
  return axios.get(`/engine-models/get-active-model?projectId=${projectId}`);
};

export const getModelImageUrl = (modelId) => {
  return axios.get(`/engine-models/get-model-image-url?modelId=${modelId}`);
};

export const getDefaultModelList = () => {
  return axios.get(`/engine-models/get-default-model`);
};

export const updateCustomModelStatusAPI = (payload) => {
  return axios.put(`/engine-models/update-custom-model-status`, payload);
};

export const retrainModelAPI = (payload) => {
  coreToolAxios.defaults.headers.Authorization = "JWT " + getCookie("Bearer");
  const socketId = socket.id;

  return coreToolAxios.post(`/model-generation/retrain`, {
    ...payload,
    socketId: socketId,
  });
};

export const getActiveModelListAPI = ({ projectId }) => {
  return axios.get(`/engine-models/get-active-model?projectId=${projectId}`);
};

export async function deleteModel(modelId) {
  try {
    return await axios.delete(`/engine-models/delete-model?modelId=${modelId}`);
  } catch(error) {
    return error?.response ;
  }
}

export async function getEpochMetrics(modelId) {
  try {
    return await axios.get(`/engine-models/get-epoch-metrics?modelId=${modelId}`);
  } catch(error) {
    return error?.response ;
  }
}
