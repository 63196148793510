import * as React from "react";

function AppstoreOutlined(props) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.0717 3.2168H3.85745C3.50388 3.2168 3.21459 3.50608 3.21459 3.85965V16.0739C3.21459 16.4275 3.50388 16.7168 3.85745 16.7168H16.0717C16.4253 16.7168 16.7146 16.4275 16.7146 16.0739V3.85965C16.7146 3.50608 16.4253 3.2168 16.0717 3.2168ZM13.9824 13.9847H5.94674V5.94894H13.9824V13.9847ZM32.1432 3.2168H19.9289C19.5753 3.2168 19.286 3.50608 19.286 3.85965V16.0739C19.286 16.4275 19.5753 16.7168 19.9289 16.7168H32.1432C32.4967 16.7168 32.786 16.4275 32.786 16.0739V3.85965C32.786 3.50608 32.4967 3.2168 32.1432 3.2168ZM30.0539 13.9847H22.0182V5.94894H30.0539V13.9847ZM16.0717 19.2882H3.85745C3.50388 19.2882 3.21459 19.5775 3.21459 19.9311V32.1454C3.21459 32.4989 3.50388 32.7882 3.85745 32.7882H16.0717C16.4253 32.7882 16.7146 32.4989 16.7146 32.1454V19.9311C16.7146 19.5775 16.4253 19.2882 16.0717 19.2882ZM13.9824 30.0561H5.94674V22.0204H13.9824V30.0561ZM32.1432 19.2882H19.9289C19.5753 19.2882 19.286 19.5775 19.286 19.9311V32.1454C19.286 32.4989 19.5753 32.7882 19.9289 32.7882H32.1432C32.4967 32.7882 32.786 32.4989 32.786 32.1454V19.9311C32.786 19.5775 32.4967 19.2882 32.1432 19.2882ZM30.0539 30.0561H22.0182V22.0204H30.0539V30.0561Z"
        fill="currentColor"
        fill-opacity="0.85"
      />
    </svg>
  );
}

export default AppstoreOutlined;
