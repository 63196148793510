import {
  GET_REPORT_DATA,
  GET_REPORT_DATA_SUCCESS,
  GET_TOOL_CHART_DATA,
  GET_TOOL_CHART_DATA_SUCCESS,
  GET_USER_LOGIN_CHART_DATA,
  GET_USER_LOGIN_CHART_DATA_SUCCESS
} from "redux/actions";

export const getReportData = ({ pageSize, page, matchFilter, duration }) => ({
  type: GET_REPORT_DATA,
  payload: { pageSize, page, matchFilter, duration },
});

export const getReportDataSuccess = ({
  reportData,
  totalRecord,
  totalPage,
}) => ({
  type: GET_REPORT_DATA_SUCCESS,
  payload: { reportData, totalRecord, totalPage },
});

export const getToolChartData = ({ limit, timeRange }) => ({
  type: GET_TOOL_CHART_DATA,
  payload: { limit, timeRange },
});

export const getToolChartDataSuccess = ({
  toolClassesImage,
  toolClassesVideo,
  toolOrgImage,
  toolOrgVideo,
}) => ({
  type: GET_TOOL_CHART_DATA_SUCCESS,
  payload: { toolClassesImage, toolClassesVideo, toolOrgImage, toolOrgVideo },
});

export const getUserLoginChartData = () => ({
  type: GET_USER_LOGIN_CHART_DATA,
  payload: {}
});

export const getUserLoginChartDataSuccess = ({ userLogChart, platformChart }) => ({
  type: GET_USER_LOGIN_CHART_DATA_SUCCESS,
  payload: { userLogChart, platformChart }
})
