import {
  SHOW_NOTIFICATION,
  REMOVE_NOTIFICATION,
  FETCH_PUSH_NOTIFICATIONS,
  FETCH_PUSH_NOTIFICATIONS_SUCCESS,
  FETCH_PUSH_NOTIFICATIONS_FAILURE,
  NO_MORE_NOTIFICATION,
  UPDATE_CURRENT_NOTIFICATIONS_PAGE,
  CHANGE_VIEW_NOTIFICATION,
  READ_ONE_NOTIFICATION,
  READ_ONE_NOTIFICATION_SUCCESS,
  MARK_ALL_NOTIFICATION_AS_READ,
  MARK_ALL_NOTIFICATION_AS_READ_SUCCESS,
  MARK_ALL_NOTIFICATION_AS_READ_ERROR,
} from "redux/actions";
// Actions
export const showNotification = (payload) => ({
  type: SHOW_NOTIFICATION,
  payload,
});

export const removeNotification = (payload) => ({
  type: REMOVE_NOTIFICATION,
  payload,
});

export const fetchPushNotifications = (payload) => ({
  type: FETCH_PUSH_NOTIFICATIONS,
  payload,
});

export const fetchPushNotificationsSuccess = (payload) => ({
  type: FETCH_PUSH_NOTIFICATIONS_SUCCESS,
  payload,
});

export const fetchPushNotificationsFailure = (payload) => ({
  type: FETCH_PUSH_NOTIFICATIONS_FAILURE,
  payload,
});

export const updateCurrentNotiPage = (payload) => ({
  type: UPDATE_CURRENT_NOTIFICATIONS_PAGE,
  payload,
});

export const noMoreNotifications = (payload) => ({
  type: NO_MORE_NOTIFICATION,
  payload,
});

export const readOneNotification = (payload) => ({
  type: READ_ONE_NOTIFICATION,
  payload,
});

export const readOneNotificationSuccess = (payload) => ({
  type: READ_ONE_NOTIFICATION_SUCCESS,
  payload,
});

export const changeViewNotification = (payload) => ({
  type: CHANGE_VIEW_NOTIFICATION,
  payload,
});

export const markAllNotificationAsRead = (payload) => ({
  type: MARK_ALL_NOTIFICATION_AS_READ,
  payload,
});

export const markAllNotificationAsReadSuccess = (payload) => ({
  type: MARK_ALL_NOTIFICATION_AS_READ_SUCCESS,
  payload,
});

export const markAllNotificationAsReadError = (payload) => ({
  type: MARK_ALL_NOTIFICATION_AS_READ_ERROR,
  payload,
});
