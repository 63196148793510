import qs from 'qs'
import axios from "../api";

export function getModelCheckpointApi(payload) {
  return axios.get(`/models/get-all-models-checkpoints?${qs.stringify(payload)}`).then(res => res.data);
}

export function getModelApi(payload) {
  return axios.get(`/models/get-models-by-projectId?${qs.stringify(payload)}`).then(res => res.data);
}


