import { datasetPageSizes } from "constants/defaultValues";
import axios, {coreToolAxios} from "../api";
import {getCookie} from "../../helpers/Utils";
import {socket} from "redux/socket/saga";

export const getListDatasetAPI = ({
  projectId,
  pageSize = datasetPageSizes[0],
  currentPage = 1,
}) =>
  axios.post(
    `/dataset/get-datasets-by-project?projectId=${projectId}&pageSize=${pageSize}&currentPage=${currentPage}`,
  );
export const updateDataSetByIdAPI = (id, objDataSet) => {
  return axios.put(`/dataset/${id}`, objDataSet);
};

export const deleteDatasetByIdAPI = ({ id, versionId, projectType }) => {
  return axios.delete(
    `/dataset/${id}?versionId=${versionId}&projectType=${projectType}`,
  );
};

export function deleteDatasetsAPI({ datasets, projectType }) {
  return axios.post("/dataset/delete-datasets", {
    datasets,
    projectType,
  });
}

export const augmentingImageAPI = (payload) => {
  return axios.post("/dataset/augmentation-image", payload);
};
  
export async function cloneDatasetApi(data) {
  try {
    coreToolAxios.defaults.headers.Authorization = "JWT " + getCookie("Bearer");
    const socketId = socket.id;
    return await coreToolAxios.post(`/dataset/clone-dataset`, {
      ...data,
      socketId: socketId,
    });
  } catch (error) {
    return error?.response;
  }
}

export async function mergeDatasetApi(data) {
  try {
    coreToolAxios.defaults.headers.Authorization = "JWT " + getCookie("Bearer");
    const socketId = socket.id;
    return await coreToolAxios.post(`/dataset/merge-dataset`, {
      ...data,
      socketId: socketId,
    });
  } catch (error) {
    return error?.response;
  }
}

export async function cloneSourcesApi(data) {
  try {
    coreToolAxios.defaults.headers.Authorization = "JWT " + getCookie("Bearer");
    const socketId = socket.id;
    return await coreToolAxios.post(`/dataset/clone-sources`, {
      ...data,
      socketId: socketId,
    });
  } catch (error) {
    return error?.response;
  }
}

export async function mergeSourcesApi(data) {
  try {
    coreToolAxios.defaults.headers.Authorization = "JWT " + getCookie("Bearer");
    const socketId = socket.id;
    return await coreToolAxios.post(`/dataset/merge-sources`, {
      ...data,
      socketId: socketId,
    });
  } catch (error) {
    return error?.response;
  }
}