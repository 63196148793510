
import { datasetPageSizes } from "constants/defaultValues";
import {
  GET_LIST_DATASET,
  GET_LIST_DATASET_ERROR,
  GET_LIST_DATASET_SUCCESS,
  SET_CURRENT_DATASET,
  SET_SELECTED_DATASETS,
  EXPORT_DATASET_SUCCESS,
  AUTOMATIC_ANNOTATION_SUCCESS,
  GET_LABELBOX_COUNTER,
  GET_LABELBOX_COUNTER_SUCCESS,
  GET_LABELBOX_COUNTER_ERROR,
  SET_DATASET_LOADING,
  DELETE_DATASETS,
  DELETE_DATASET,
  CREATE_CLONE_DATASET_JOB_SUCCESS,
} from "redux/actions";

const INIT_STATE = {
  datasets: [],
  pagination: {
    pageSize: datasetPageSizes[0],
    totalPage: 0,
    totalItem: 0,
    currentPage: 1,
  },
  isLoading: false,
  currentDataset: {},
  selectedDatasets: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LIST_DATASET:
      return {
        ...state,
        isLoading: true,
        currentDataset: {},
        // selectedDatasets: []
      };
    case DELETE_DATASETS:
    case DELETE_DATASET:
      return {
        ...state,
        isLoading: true,
      };
    case GET_LIST_DATASET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        datasets: action.payload.datasets,
        pagination: action.payload.pagination,
      };
    case GET_LIST_DATASET_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case SET_CURRENT_DATASET:
      return {
        ...state,
        currentDataset: action.payload,
      };

    case SET_SELECTED_DATASETS:
      return {
        ...state,
        selectedDatasets: action.payload,
      };

    case EXPORT_DATASET_SUCCESS:
      return {
        ...state,
        selectedDatasets: [],
      };

    case AUTOMATIC_ANNOTATION_SUCCESS: {
      state.datasets = state.datasets.map((x) => {
        let cloneData = { ...x };
        if (
          cloneData.dataset_id === action.payload.dataset_id ||
          (action.payload.dataset_ids &&
            action.payload.dataset_ids
              .split(",")
              .includes(cloneData.dataset_id))
        ) {
          cloneData.process_data = action.payload;
        }
        return cloneData;
      });

      return {
        ...state,
      };
    }

    case GET_LABELBOX_COUNTER:
      return {
        ...state,
        isLoading: true,
      };

    case GET_LABELBOX_COUNTER_SUCCESS:
    case GET_LABELBOX_COUNTER_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case SET_DATASET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    default:
      return { ...state };
  }
};
