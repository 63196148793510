import produce from "immer";
import {
  LOGOUT_USER,
  SHOW_NOTIFICATION,
  REMOVE_NOTIFICATION,
  FETCH_PUSH_NOTIFICATIONS,
  FETCH_PUSH_NOTIFICATIONS_SUCCESS,
  NO_MORE_NOTIFICATION,
  UPDATE_CURRENT_NOTIFICATIONS_PAGE,
  CHANGE_VIEW_NOTIFICATION,
  MARK_ALL_NOTIFICATION_AS_READ,
  MARK_ALL_NOTIFICATION_AS_READ_SUCCESS,
  MARK_ALL_NOTIFICATION_AS_READ_ERROR,
} from "redux/actions";

export const initialState = {
  notifications: [],
  pushNotifications: [],
  totalNewNotifications: 0,
  currPage: 1,
  pageSize: 10,
  fetchMore: false,
  markAllAsRead: "",
};

// Reducer
const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOGOUT_USER:
        return initialState;
      case SHOW_NOTIFICATION:
        draft.notifications = [
          { ...action.payload, id: Date.now() },
          ...state.notifications,
        ];
        break;

      case REMOVE_NOTIFICATION:
        draft.notifications = state.notifications.filter(
          (item) => item.id !== action.payload
        );
        break;

      case FETCH_PUSH_NOTIFICATIONS_SUCCESS:
        draft.pushNotifications = [
          ...state.pushNotifications,
          ...action.payload.notifications,
        ];
        draft.totalNewNotifications = action.payload.totalNewNotifications;
        break;

      case FETCH_PUSH_NOTIFICATIONS:
        draft.fetchMore = true;
        break;

      case NO_MORE_NOTIFICATION:
        draft.fetchMore = false;
        break;

      case UPDATE_CURRENT_NOTIFICATIONS_PAGE:
        draft.currPage = action.payload;
        break;

      case CHANGE_VIEW_NOTIFICATION: {
        const newNotifications = state.pushNotifications.map((noti) => {
          if (parseInt(noti.notification_id) === parseInt(action.payload)) {
            return {
              ...noti,
              is_read: true,
            };
          } else {
            return noti;
          }
        });
        draft.pushNotifications = newNotifications;
        draft.totalNewNotifications -= 1;
        break;
      }
      case MARK_ALL_NOTIFICATION_AS_READ:
        draft.markAllAsRead = "";
        break;
      case MARK_ALL_NOTIFICATION_AS_READ_SUCCESS:
        draft.markAllAsRead = action.payload.message;
        const newNotifications = state.pushNotifications.map((noti) => {
          return {
            ...noti,
            is_read: true,
          };
        });
        draft.pushNotifications = newNotifications;
        draft.totalNewNotifications = 0;
        draft.markAllAsRead = action.payload.message;
        break;
      case MARK_ALL_NOTIFICATION_AS_READ_ERROR:
        draft.markAllAsRead = action.payload.message;
        break;
      default:
    }
  });

export default reducer;