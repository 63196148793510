import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { get } from "lodash";

import {
  GET_MODEL_LIST,
  GET_CUSTOM_MODEL_LIST,
  GET_MODEL_IMAGE_URL,
  showNoti,
  getModelListSuccess,
  getCustomModelListSuccess,
  getModelImageUrlSuccess,
  GET_DEFAULT_MODEL_LIST,
  getDefaultModelListSuccess,
  UPDATE_CUSTOM_MODEL_STATUS,
  RETRAIN_MODEL,
  GET_ACTIVE_MODEL_LIST,
  AUTOMATIC_ANNOTATION,
  GET_LABELBOX_COUNTER,
  ADD_GENERATION_MODEL,
} from "redux/actions";
import {
  getModelList,
  getCustomModelList,
  getModelImageUrl,
  getDefaultModelList,
  updateCustomModelStatusAPI,
  retrainModelAPI,
  getActiveModelListAPI,
} from "./api";
import {
  ERROR_CASE,
  SUCCESS_CASE,
} from "components/notificationModal/constants";
import {
  addGenerationModelError,
  addGenerationModelSuccess,
  automaticAnnotationSuccess,
  getActiveModelListFailure,
  getActiveModelListSuccess,
  getLabelboxCounterError,
  getLabelboxCounterSuccess,
  retrainModelFailure,
  retrainModelSuccess,
} from "./actions";
import { automaticAnnotationApi, getLabelBoxesCounter, trainingModelByApi } from "redux/projects/api";

function* getModelListSaga({ payload, callback }) {
  const { projectId } = payload;
  const response = yield call(getModelList, projectId);
  const statusCode = get(response, "status", "");
  const { message } = get(response, ["data", "message"], "");

  if (statusCode === 200) {
    const models = get(response, "data.data", []);
    yield put(getModelListSuccess({ models }));
  } else {
    yield put(
      showNoti({
        type: ERROR_CASE,
        message,
      }),
    );
  }
}

function* getCustomModelListSaga({ payload, callback }) {
  const { projectId } = payload;
  const response = yield call(getCustomModelList, projectId);
  const statusCode = get(response, "status", "");
  const { message } = get(response, ["data", "message"], "");

  if (statusCode === 200) {
    const models = get(response, "data.data", []);
    yield put(getCustomModelListSuccess({ models }));
  } else {
    yield put(
      showNoti({
        type: ERROR_CASE,
        message,
      }),
    );
  }
}

function* getModelImageUrlSaga({ payload }) {
  const response = yield call(getModelImageUrl, payload);
  const statusCode = get(response, "status", "");
  const { message } = get(response, ["data", "message"], "");

  if (statusCode === 200) {
    const imageList = get(response, "data.data", []);
    yield put(getModelImageUrlSuccess(imageList));
  } else {
    yield put(
      showNoti({
        type: ERROR_CASE,
        message,
      }),
    );
  }
}

function* getDefaultModelListSaga({ payload, callback }) {
  const response = yield call(getDefaultModelList);
  const statusCode = get(response, "status", "");
  const { message } = get(response, ["data", "message"], "");

  if (statusCode === 200) {
    const models = get(response, "data.data", []);
    yield put(getDefaultModelListSuccess({ models }));
  } else {
    yield put(
      showNoti({
        type: ERROR_CASE,
        message,
      }),
    );
  }
}

function* updateCustomModelStatusSaga({ payload, callback }) {
  const response = yield call(updateCustomModelStatusAPI, payload);
  const statusCode = get(response, "status", "");
  const { message } = get(response, ["data", "message"], "");

  if (statusCode === 200) {
    callback && callback();
  } else {
    yield put(
      showNoti({
        type: ERROR_CASE,
        message,
      }),
    );
  }
}

function* retrainModelSaga({ payload, callback }) {
  try {
    const response = yield call(retrainModelAPI, payload);
    const statusCode = get(response, "status", "");
    const { message } = get(response, ["data", "message"], "");
    if (statusCode === 200) {
      yield put(retrainModelSuccess());
      yield put(
        showNoti({
          type: SUCCESS_CASE,
          message: "Retrain model successfully",
          messageId: "notification.retrain-model-success",
        }),
      );
      callback && callback();
    } else {
      yield put(retrainModelFailure());
      yield put(
        showNoti({
          type: ERROR_CASE,
          message,
        }),
      );
    }
  } catch (error) {
    yield put(retrainModelFailure());
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: error.toString(),
      }),
    );
  }
}

function* getActiveModelListSaga({ payload, callback }) {
  try {
    const response = yield call(getActiveModelListAPI, payload);
    const statusCode = get(response, "status", "");
    const { message } = get(response, ["data", "message"], "");

    if (statusCode === 200) {
      const models = get(response, "data.data", []);
      yield put(getActiveModelListSuccess(models));
    } else {
      yield put(getActiveModelListFailure());
      yield put(
        showNoti({
          type: ERROR_CASE,
          message,
        }),
      );
    }
  } catch (error) {
    yield put(getActiveModelListFailure());
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: error.toString(),
      }),
    );
  }
}

function* automaticAnnotationSaga({ payload, callback }) {
  try {
    const response = yield call(automaticAnnotationApi, payload);
    const statusCode = get(response, "status", "");

    if (statusCode === 200) {
      const retData = get(response,"data.data",{})

      yield put(automaticAnnotationSuccess(retData));
      callback && callback()
      
    } else {
      yield put(getActiveModelListFailure());
      yield put(
        showNoti({
          type: ERROR_CASE,
          message:"Automatic annotation unsuccessful!",
        }),
      );
    }
  } catch (error) {
    yield put(getActiveModelListFailure());
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: error.toString(),
      }),
    );
  }
}

function* getLabelBoxCounterSaga({ payload, callback }) {
  try {
    const {projectId, selectedDatasets} = payload
    const response = yield call(getLabelBoxesCounter, projectId, selectedDatasets.join(','));
    const statusCode = get(response, "status", "");

    if (statusCode === 200) {
      yield put(getLabelboxCounterSuccess())
      callback && callback(response)
      
    } else {
      yield put(getLabelboxCounterError())

      yield put(
        showNoti({
          type: ERROR_CASE,
          message:"Get information for generating model unsuccessful!",
        }),
      );
      callback && callback()
    }
  } catch (error) {
    yield put(getLabelboxCounterError())

    yield put(
      showNoti({
        type: ERROR_CASE,
        message: error.toString(),
      }),
    );
    callback && callback()
  }
}

export function* watchGetModelList() {
  yield takeEvery(GET_MODEL_LIST, getModelListSaga);
}

export function* watchGetCustomModelList() {
  yield takeEvery(GET_CUSTOM_MODEL_LIST, getCustomModelListSaga);
}

export function* watchGetModelImageList() {
  yield takeEvery(GET_MODEL_IMAGE_URL, getModelImageUrlSaga);
}

export function* watchGetDefaultModelList() {
  yield takeEvery(GET_DEFAULT_MODEL_LIST, getDefaultModelListSaga);
}

function* addGenerationModelSaga({ payload, callback }) {
  try {
    const response = yield call(trainingModelByApi, payload);
    const statusCode = get(response, "status", "");

    if (statusCode === 200) {
      const retData = get(response,"data.data",{})

      yield put(addGenerationModelSuccess(retData));
      callback && callback()
      
    } else {
      yield put(addGenerationModelError());
      yield put(
        showNoti({
          type: ERROR_CASE,
          message:"Generate model unsuccessful!",
        }),
      );
    }
  } catch (error) {
    yield put(addGenerationModelError());
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: error.toString(),
      }),
    );
  }
}



export default function* rootSaga() {
  yield all([
    fork(watchGetModelList),
    fork(watchGetCustomModelList),
    fork(watchGetModelImageList),
    fork(watchGetDefaultModelList),
    yield takeEvery(UPDATE_CUSTOM_MODEL_STATUS, updateCustomModelStatusSaga),
    yield takeEvery(RETRAIN_MODEL, retrainModelSaga),
    yield takeEvery(GET_ACTIVE_MODEL_LIST, getActiveModelListSaga),
    yield takeEvery(AUTOMATIC_ANNOTATION, automaticAnnotationSaga),
    yield takeEvery(GET_LABELBOX_COUNTER, getLabelBoxCounterSaga),
    yield takeEvery(ADD_GENERATION_MODEL, addGenerationModelSaga),
  ]);
}
