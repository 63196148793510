import axios from "../api";
import { payloadToQuery } from "helpers/Utils";

export function getUserListApi(
  selectedPageSize = 5,
  currentPage = 0,
  status,
  uniqueId,
  email,
  startTime, 
  endTime
) {
  return axios.get(
    `/users/get-user-list-business-admin?pageSize=${selectedPageSize}&currentPage=${currentPage}&status=${status}&uniqueId=${uniqueId}&email=${email}&startTime=${startTime}&endTime=${endTime}`,
  );
}

export function getUserChartApi(
  type,
  startTime, 
  endTime
) {
  return axios.get(
    `/users/get-user-chart-business-admin?type=${type}&startTime=${startTime}&endTime=${endTime}`,
  );
}

export function addNewUser(data) {
  return axios.post("/users/add-new-user-for-business-admin", data);
}

export function updateUser(data) {
  return axios.post("/members/update-member-for-busines-admin", data);
}

export function getUserReportApi(payload) {
  const query = payloadToQuery(payload);
  return axios.get(`/members/get-user-data-for-business-admin${query}`);
}
