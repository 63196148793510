import {
    GET_LIST_PRICING_PACKAGES_FAILURE,
    GET_LIST_PRICING_PACKAGES_SUCCESS,
    GET_CURRENT_PRICING_PACKAGE,
    GET_CURRENT_PRICING_PACKAGE_SUCCESS,
    GET_CURRENT_PRICING_PACKAGE_FAILURE,
    GET_BILLING_LIST,
    GET_BILLING_LIST_SUCCESS,
    GET_BILLING_LIST_FAILURE,
    SEND_PRICING_CONTACT_EMAIL_TO_SYSTEM_ADMIN
} from "redux/actions";

export const getListPricingPackagesSuccess = (payload) => ({
    type: GET_LIST_PRICING_PACKAGES_SUCCESS,
    payload
});

export const getListPricingPackagesFailure = (payload) => ({
    type: GET_LIST_PRICING_PACKAGES_FAILURE,
    payload
})

export const getCurrentPricingPackage = (payload) => ({
    type: GET_CURRENT_PRICING_PACKAGE,
    payload
});

export const getCurrentPricingPackageSuccess = (payload) => ({
    type: GET_CURRENT_PRICING_PACKAGE_SUCCESS,
    payload
});

export const getCurrentPricingPackageFailure = (payload) => ({
    type: GET_CURRENT_PRICING_PACKAGE_FAILURE,
    payload
});

export const getBillingList = (payload) => ({
    type: GET_BILLING_LIST,
    payload
});

export const getBillingListSuccess = (payload) => ({
    type: GET_BILLING_LIST_SUCCESS,
    payload
});

export const getBillingListFailure = (payload) => ({
    type: GET_BILLING_LIST_FAILURE,
    payload
});

export const sendPricingContactEmailToSystemAdmin = ({ payload, callback }) => ({
    type: SEND_PRICING_CONTACT_EMAIL_TO_SYSTEM_ADMIN,
    payload,
    callback
});

