import axios from "../api";

export function getEnterpriseUserListApi(
  selectedPageSize = 10,
  currentPage = 0,
  status,
  organizationName,
  email,
  startTime, 
  endTime
) {
  return axios.get(
    `/members/get-enterprise-user-list-business-admin?pageSize=${selectedPageSize}&currentPage=${currentPage}&status=${status}&orgName=${organizationName}&email=${email}&startTime=${startTime}&endTime=${endTime}`,
  );
}

export function postAddLogPricing(data) {
  
  // const data = {
  //   price,
  //   userId,
  //   startTime,
  //   endTime,
  //   paymentTime
  //   information
  // };
  return axios.post("members/add-log-pricing-for-busines-admin/", {
    ...data,
  });
}

export function postDeleteLogPricing(data) {
  //
  // const data = {
  //   logPricingId
  // };
  return axios.post("members/delete-log-pricing-for-busines-admin/", {
    ...data,
  });
}

export function postUpdateLogPricing(data) {
  
  // const data = {
  //   logPricingId,
  //   price,
  //   startTime,
  //   endTime,
  //   paymentTime
  //   information
  // };
  return axios.post("members/update-log-pricing-for-busines-admin/", {
    ...data,
  });
}

