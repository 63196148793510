/* eslint-disable */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import get from "lodash/get";

import {
    GET_AGENTS,
    GET_DATASETS,
    GET_TASKS,
    ADD_TASK,
} from "redux/actions";

import {
    getAgentsSuccess,
    getAgentsError,
    getDatasetsSuccess,
    getDatasetsError,
    getTasksSuccess,
    getTasksError,
    addTaskSuccess,
    addTaskError,
} from './actions'

import {
    getAgentsApi,
    getDatasetsApi,
    getTasksApi,
    addTaskApi,
} from './api'

export function* watchGetAgents() {
    yield takeEvery(GET_AGENTS, getAgentData);
}
function* getAgentData({ payload }) {
    const { agents } = payload;
    try {
        const response = yield call(getAgentsApi);
        const statusCode = get(response, "status", "");

        if (statusCode === 200) {
            let result = [];
            response.data.map((agent, index) => {
                result.push({
                    value: agent.agentId,
                    label: agent.name,
                    key: index,
                });
            });

            yield put(getAgentsSuccess(result));
        } else {
            yield put(getAgentsError("Get Agents Error!"));
            return;
        }
    } catch (error) {
        yield put(getAgentsError("Get Agents Error!"));
        return;
    }
}

export function* watchGetDatasets() {
    yield takeEvery(GET_DATASETS, getDatasetData);
}
function* getDatasetData({ payload }) {
    const { datasets } = payload;
    try {
        const response = yield call(getDatasetsApi);
        const statusCode = get(response, "status", "");

        if (statusCode === 200) {
            let result = [];
            response.data.map((dataset, index) => {
                result.push({
                    value: dataset.datasetId,
                    label: dataset.name,
                    key: index,
                });
            });

            yield put(getDatasetsSuccess(result));
        } else {
            yield put(getDatasetsError("Get Datasets Error!"));
            return;
        }
    } catch (error) {
        yield put(getDatasetsError("Get Datasets Error!"));
        return;
    }
}

export function* watchGetTasks() {
    yield takeEvery(GET_TASKS, getTaskData);
}
function* getTaskData({ payload }) {
    const { tasks } = payload;
    try {
        const response = yield call(getTasksApi);
        const statusCode = get(response, "status", "");

        if (statusCode === 200) {
            yield put(getTasksSuccess(response.data));
        } else {
            yield put(getTasksError("Get Tasks Error!"));
            return;
        }
    } catch (error) {
        yield put(getTasksError("Get Tasks Error!"));
        return;
    }
}

export function* watchAddTask() {
    yield takeEvery(ADD_TASK, addTaskData);
}
function* addTaskData({ payload }) {
    const { typeId, agentId, datasetId, config } = payload;
    try {
        const response = yield call(addTaskApi, typeId, agentId, datasetId, config);
        const status = get(response, "status", "");
        if (status === 200) {
            const { statusCode, message } = get(
                response,
                "data"
            );
            if (statusCode === 200) {
                yield put(addTaskSuccess(message));
            } else {
                yield put(addTaskError(message));
            }
        } else {
            yield put(addTaskError("Error when add new Task"));
        }
    } catch (error) {
        yield put(addTaskError("Error when add new Task"));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchGetAgents),
        fork(watchGetDatasets),
        fork(watchGetTasks),
    ]);
}