import { call } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import {
  authenticationOn,
  authenticationOff,
} from "redux/socket/eventHandlers/auth";
import {
  generateModelOff,
  generateModelResponse,
  automaticAnnotationOff,
  trainingModelResponse,
  trainingModelProcessing,
  trainingModelOff,
} from "redux/socket/eventHandlers/generateModel";
import {
  cloneDatasetResponse,
  cloneDatasetSuccess,
  cloneDatasetFailed,
} from "redux/socket/eventHandlers/cloneDataset";
import io from "socket.io-client";

import { socketServerURL, token, socketOption } from "constants/defaultValues";

let socket;

export let commonEmitter; // eslint-disable-line import/no-mutable-exports
// This is how channel is created
export const createSocketChannel = (socket) =>
  eventChannel((emitter) => {
    commonEmitter = emitter;
    authenticationOn(socket, emitter, token);

    return () => {
      // return to close socket when functions are unsubscribed
      authenticationOff(socket, emitter);
      generateModelOff(socket, emitter);
      generateModelResponse(socket, emitter);
      trainingModelOff(socket, emitter);
      trainingModelResponse(socket, emitter);
      trainingModelProcessing(socket, emitter);
      automaticAnnotationOff(socket, emitter);
      cloneDatasetResponse(socket, emitter);
      cloneDatasetSuccess(socket, emitter);
      cloneDatasetFailed(socket, emitter);
    };
  });

// Connection monitoring sagas
export function* listenDisconnectSaga() {
  while (true) {
    yield call(disconnect);
  }
}

export function* listenConnectSaga() {
  while (true) {
    yield call(reconnect);
  }
}

// Wrapping functions for socket events (connect, disconnect, reconnect)
export function connect() {
  if (!socket) socket = io(socketServerURL, socketOption);
  return socket;
}

function disconnect() {
  if (!socket) socket = io(socketServerURL, socketOption);
  return new Promise((resolve) => {
    socket.on("disconnect", () => {
      resolve(socket);
    });
  });
}

function reconnect() {
  if (!socket) socket = io(socketServerURL, socketOption);
  return new Promise((resolve) => {
    socket.on("reconnect", () => {
      resolve(socket);
    });
  });
}
