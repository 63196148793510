import { all, fork } from 'redux-saga/effects';

// fix to dynamic import rootSaga function from the filename saga.js
const moduleReducer = require.context('.', true, /saga.js$/)
let sagas = [];
let activeSaga = [];
moduleReducer.keys().forEach(file => {
  const module = moduleReducer(file);
  if (module.default && !file.includes('socket')) {
    sagas = [...sagas, module.default]
  } else {
    activeSaga = [module.default];
  }
});

export default function* rootSaga(getState) {
  yield all(
    [...sagas, ...activeSaga].map(saga => fork(saga)),
  );
}
