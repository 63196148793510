import {
  LOGOUT_USER,
  ACTION_ERROR,
  GET_LIST_PROJECT_DETAIL,
  GET_LIST_PROJECT_DETAIL_SUCCESS,
  GET_LIST_PROJECT_DETAIL_ERROR,
  GET_LIST_DATASET_SUCCESS,
  SET_CURRENT_PROJECT,
  CREATE_NEW_DATASET,
  CREATE_NEW_DATASET_SUCCESS,
  CREATE_NEW_DATASET_ERROR,
  GET_SUMMARY_PROJECTS,
  GET_SUMMARY_PROJECTS_SUCCESS,
  GET_SUMMARY_PROJECTS_ERROR,
  GET_EMAIL_LIST,
  GET_EMAIL_LIST_SUCCESS,
  GET_EMAIL_LIST_ERROR,
  GET_ROLE_LIST,
  GET_ROLE_LIST_SUCCESS,
  GET_ROLE_LIST_ERROR,
  GET_ASSIGN_MEMBER_LIST,
  GET_ASSIGN_MEMBER_LIST_SUCCESS,
  GET_ASSIGN_MEMBER_LIST_ERROR,
  CREATE_NEW_PROJECT_SUCCESS,
  CREATE_NEW_PROJECT,
  GET_PROJECT_BY_ID,
  GET_PROJECT_BY_ID_SUCCESS,
  GET_PROJECT_BY_ID_ERROR,
  GET_FIRST_DATASET_EXIST_SOURCE,
  GET_FIRST_DATASET_EXIST_SOURCE_SUCCESS,
  GET_FIRST_DATASET_EXIST_SOURCE_ERROR,
  GET_CURRENT_PROJECT_MEMBERS,
  GET_CURRENT_PROJECT_MEMBERS_SUCCESS,
  GET_CURRENT_PROJECT_MEMBERS_ERROR,
  ASSIGN_TASK_TO_MEMBER,
  ASSIGN_TASK_TO_MEMBER_SUCCESS,
  ASSIGN_TASK_TO_MEMBER_ERROR,
  UNASSIGN_TASK_TO_MEMBER,
  UNASSIGN_TASK_TO_MEMBER_SUCCESS,
  UNASSIGN_TASK_TO_MEMBER_ERROR,
  GET_PROJECT_LABELS,
  GET_PROJECT_LABELS_SUCCESS,
  GET_PROJECT_LABELS_ERROR,
  GET_PROJECT_CLASSIFICATIONS,
  GET_PROJECT_CLASSIFICATIONS_SUCCESS,
  GET_PROJECT_CLASSIFICATIONS_ERROR,
  ADD_NEW_CLASSIFICATION,
  ADD_NEW_CLASSIFICATION_SUCCESS,
  UPDATE_CLASSIFICATION,
  UPDATE_CLASSIFICATION_SUCCESS,
  DELETE_CLASSIFICATION,
  DELETE_CLASSIFICATION_SUCCESS,
  DELETE_PROJECT_BY_ID,
  DELETE_PROJECT_BY_ID_SUCCESS,
  GET_MODULE_BY_ROLE_IN_PROJECT,
  GET_MODULE_BY_ROLE_IN_PROJECT_SUCCESS,
  GET_MODULE_BY_ROLE_IN_PROJECT_ERROR,
  HANDLE_LABELS_FAILURE,
  EXPORT_DATASET,
  EXPORT_DATASET_SUCCESS,
  EXPORT_DATASET_ERROR,
  GET_EXPORT_DATASET,
  GET_EXPORT_DATASET_SUCCESS,
  GET_EXPORT_DATASET_ERROR,
  PREPARE_CUSTOMER_DATA,
  PREPARE_CUSTOMER_DATA_SUCCESS,
  PREPARE_CUSTOMER_DATA_ERROR,
  GET_SOURCE_STATUS,
  GET_SOURCE_STATUS_SUCCESS,
  GET_SOURCE_STATUS_ERROR,
  DATASET_ADD_MORE_DATA,
  DATASET_ADD_MORE_DATA_SUCCESS,
  DATASET_ADD_MORE_DATA_ERROR,
  INVITE_MEMBERS_TO_PROJECT,
  INVITE_MEMBERS_TO_PROJECT_SUCCESS,
  RESET_SOURCE_STATUS,
  RESET_SOURCE_STATUS_SUCCESS,
  RESET_SOURCE_STATUS_ERROR,
  GET_TOTAL_APPROVE_ANNOTATION_EACH_CLASS_IN_PROJECT,
  GET_TOTAL_APPROVE_ANNOTATION_EACH_CLASS_IN_PROJECT_SUCCESS,
  GET_TOTAL_APPROVE_ANNOTATION_EACH_CLASS_IN_PROJECT_ERROR,
  GET_TOTAL_APPROVE_IMG_CLASS_IN_PROJECT,
  GET_TOTAL_APPROVE_IMG_CLASS_IN_PROJECT_SUCCESS,
  GET_TOTAL_APPROVE_IMG_CLASS_IN_PROJECT_ERROR,
  GET_LABELER_STATUS_IN_PROJECT,
  GET_LABELER_STATUS_IN_PROJECT_SUCCESS,
  GET_LABELER_STATUS_IN_PROJECT_ERROR,
  SET_CURRENT_LABEL,
  ADD_NEW_LABEL_ATTRIBUTE,
  ADD_NEW_LABEL_ATTRIBUTE_SUCCESS,
  ADD_NEW_LABEL_ATTRIBUTE_ERROR,
  GET_LABEL_ATTRIBUTE,
  GET_LABEL_ATTRIBUTE_SUCCESS,
  GET_LABEL_ATTRIBUTE_ERROR,
  DELETE_ATTRIBUTE,
  DELETE_ATTRIBUTE_SUCCESS,
  DELETE_ATTRIBUTE_ERROR,
  UPDATE_ATTRIBUTE,
  UPDATE_ATTRIBUTE_SUCCESS,
  UPDATE_ATTRIBUTE_ERROR,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  CLONE_DATASETS,
  CLONE_DATASETS_SUCCESS,
  ADD_CLASS,
  UPDATE_CLASS,
  DELETE_CLASS,
  ADD_CLASS_SUCCESS,
  ADD_CLASS_FAILURE,
  UPDATE_CLASS_SUCCESS,
  UPDATE_CLASS_FAILURE,
  DELETE_CLASS_SUCCESS,
  DELETE_CLASS_FAILURE,
  GET_LABEL_LIST,
  GET_LABEL_LIST_SUCCESS,
  GET_LABEL_LIST_FAILURE,
  IS_LOADING,
  SET_ERROR_ASSIGN_TASK,
  ASSIGN_TASK_TO_MEMBER_BY_DATASET,
  UNASSIGN_TASK_TO_MEMBER_BY_DATASET
} from "redux/actions";
import {  find } from "lodash";

const namespace = "projectsApp";

const INIT_STATE = {
  projects: [],
  totalPage: 0,
  totalItem: 0,
  currentProject: null,
  currentProjectMembers: [],
  datasetId: "",
  datasetObj: null,
  refreshDataset: false,
  startLabelingDatasetId: null,
  exportDataset: {
    exportDatasetList: [],
    totalPage: 0,
    totalItem: 0,
  },
  exportDatasetId: null,
  sourceStatus: [],
  datasetDetail: {
    totalPage: 0,
    totalItem: 0,
    datasetName: "",
    datasetList: [],
  },
  addMoreSourceError: [],
  summary: {
    totalItem: 0,
    summary: null,
  },
  roles: [],
  mails: [],
  newProjectId: null,
  assignedMembers: [],
  errorAssignTask: [],
  labelPageData: {
    labels: [],
    totalItem: 0,
    totalPage: 0
  },
  labels: [],
  currentLabelTree: null,
  newLabelId: null,
  moduleRoleInProject: [],
  loading: false,
  loadingProjects: false,
  message: "",
  error: "",
  errorId: "",
  totalApproveAnnotation: [],
  totalApproveImg: [],
  labelerStatus: [],
};

export default (state = INIT_STATE, action) => {
  let currentLabel = state.currentLabelTree;
  switch (action.type) {
    case LOGOUT_USER:
      return INIT_STATE;
    case ACTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        errorId: action.payload.messageId,
      };
    case GET_LIST_PROJECT_DETAIL:
      return { ...state, loadingProjects: true, message: "", error: "", errorId: "", };
    case GET_LIST_PROJECT_DETAIL_SUCCESS:
      return {
        ...state,
        loadingProjects: false,
        projects: action.payload.projects,
        totalPage: parseInt(action.payload.totalPage),
        totalItem: parseInt(action.payload.totalItem),
        error: "",
        errorId: "",
      };
    case GET_LIST_PROJECT_DETAIL_ERROR:
      return {
        ...state,
        loadingProjects: false,
        projects: [],
        error: action.payload.message,
        errorId: action.payload.messageId,
      };
    case SET_CURRENT_PROJECT:
      return {
        ...state,
        currentProject: action.payload.project,
        currentLabelTree: null,
      };
    case GET_LIST_DATASET_SUCCESS:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          listDatasets: action.payload.datasets?.map(item => {
            return {
              dataset_id: item.dataset_id,
              dataset_name: item.name
            }
          }) ?? [],
        },
      };
    case CREATE_NEW_DATASET:
      return { ...state, loading: true, message: "", error: "", errorId: "", datasetId: "" };
    case CREATE_NEW_DATASET_SUCCESS:
      return {
        ...state,
        loading: false,
        datasetId: action.payload.datasetId,
        message: action.payload.message,
      };
    case CREATE_NEW_DATASET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        errorId: action.payload.messageId,
      };
    case GET_PROJECT_BY_ID:
      return { ...state, loading: true, message: "", error: "", errorId: "", };
    case GET_PROJECT_BY_ID_SUCCESS:
        setTimeout(() => {
          return {
            ...state,
            loading: false
          }
        },2000);
      return {
        ...state,
        currentProject: action.payload.project,
        message: action.payload.message,
      };
    case GET_PROJECT_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        currentProject: null,
        error: action.payload.message,
        errorId: action.payload.messageId,
      };
    case GET_SUMMARY_PROJECTS:
      return { ...state, loading: true, message: "", error: "", errorId: "", };
    case GET_SUMMARY_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        summary: {
          totalItem: parseInt(action.payload.totalItem),
          summary: action.payload.summary,
        },
      };
    case GET_SUMMARY_PROJECTS_ERROR:
      return {
        ...state,
        loading: false,
        summary: {
          totalItem: 0,
          summary: null,
        },
        error: action.payload.message,
        errorId: action.payload.messageId,
      };
    case GET_EMAIL_LIST:
      return { ...state, loading: true, message: "", error: "", errorId: "", };
    case GET_EMAIL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        mails: action.payload.mails,
      };
    case GET_EMAIL_LIST_ERROR:
      return {
        ...state,
        loading: false,
        mails: [],
        error: action.payload.message,
        errorId: action.payload.messageId,
      };
    case GET_ROLE_LIST:
      return { ...state, loading: true, message: "", error: "", errorId: "", };
    case GET_ROLE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.payload.roles,
      };
    case GET_ROLE_LIST_ERROR:
      return {
        ...state,
        loading: false,
        roles: [],
        error: action.payload.message,
        errorId: action.payload.messageId,
      };
    case GET_ASSIGN_MEMBER_LIST:
      return {
        ...state,
        loading: true,
        assignedMembers: [],
        message: "",
        error: "",
        errorId: "",
      };
    case GET_ASSIGN_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        assignedMembers: action.payload.members,
      };
    case GET_ASSIGN_MEMBER_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };
    case CREATE_NEW_PROJECT:
      return {
        ...state,
        loading: true,
        newProjectId: null,
      };
    case CREATE_NEW_PROJECT_SUCCESS:
      console.log("new project success", action.payload.data);
      return {
        ...state,
        loading: false,
        newProjectId: action.payload.data.project_id,
        message: action.payload.message,
      };
    case GET_FIRST_DATASET_EXIST_SOURCE:
      return {
        ...state,
        loading: true,
        message: "",
        error: "",
        errorId: "",
        startLabelingDatasetId: null,
      };
    case GET_FIRST_DATASET_EXIST_SOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        startLabelingDatasetId: action.payload.datasetId,
        message: action.payload.message,
      };
    case GET_FIRST_DATASET_EXIST_SOURCE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        errorId: action.payload.messageId,
      };
    case GET_CURRENT_PROJECT_MEMBERS:
      return {
        ...state,
        loading: true,
        message: "",
        error: "",
        errorId: "",
        currentProjectMembers: [],
      };
    case GET_CURRENT_PROJECT_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentProjectMembers: action.payload.users,
        message: action.payload.message,
      };
    case GET_CURRENT_PROJECT_MEMBERS_ERROR:
      return {
        ...state,
        loading: false,
        currentProjectMembers: [],
        error: action.payload.message,
        errorId: action.payload.messageId,
      };
      case UNASSIGN_TASK_TO_MEMBER:
        return {
          ...state,
          loading: true,
          message: "",
          error: "",
          errorId: "",
          errorAssignTask: [],
        };
        case UNASSIGN_TASK_TO_MEMBER_BY_DATASET:
          return {
            ...state,
            loading: true,
            message: "",
            error: "",
            errorId: "",
            errorAssignTask: [],
          };
      case UNASSIGN_TASK_TO_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    case UNASSIGN_TASK_TO_MEMBER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        errorId: action.payload.messageId,
        errorAssignTask: action.payload.errorAssignTask,
      };
    
    case ASSIGN_TASK_TO_MEMBER:
      return {
        ...state,
        loading: true,
        message: "",
        error: "",
        errorId: "",
        errorAssignTask: [],
      };
      case ASSIGN_TASK_TO_MEMBER_BY_DATASET:
        return {
          ...state,
          loading: true,
          message: "",
          error: "",
          errorId: "",
          errorAssignTask: [],
        };
    
    case ASSIGN_TASK_TO_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    case ASSIGN_TASK_TO_MEMBER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        errorId: action.payload.messageId,
        errorAssignTask: action.payload.errorAssignTask,
      };
    case GET_PROJECT_LABELS:
      return { ...state, loading: true, message: "", error: "", errorId: "", };
    case GET_PROJECT_LABELS_SUCCESS:
      return {
        ...state,
        loading: false,
        labels: action.payload.labels,
        message: action.payload.message,
      };
    case GET_PROJECT_LABELS_ERROR:
      return {
        ...state,
        loading: false,
        labels: [],
        error: action.payload.message,
        errorId: action.payload.messageId,
      };

    /* classification */
    case GET_PROJECT_CLASSIFICATIONS:
      return { ...state, loading: true, message: "", error: "", errorId: "",};
    case GET_PROJECT_CLASSIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        classifications: action.payload.classifications,
        error: action.payload.message,
        errorId: action.payload.messageId,
      };
    case GET_PROJECT_CLASSIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        classifications: [],
        error: action.payload.message,
        errorId: action.payload.messageId,
      }

    case ADD_NEW_CLASSIFICATION:
      return { ...state, loading: true, message: "", error: "", errorId: "",};
    case ADD_NEW_CLASSIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        classifications: [...state.classifications,action.payload.data],
      };

    case UPDATE_CLASSIFICATION:
      return { ...state, loading: true, message: "", error: "", errorId: "",};
    case UPDATE_CLASSIFICATION_SUCCESS:
      const updatedData = action.payload.data;
      return {
        ...state,
        loading: false,
        classifications: state.classifications.map((item) => {
          if (item.classify_id === updatedData.classify_id) {
            return {
              ...item,
              ...updatedData
            }
          }
          return item;
        }),
      };

    case DELETE_CLASSIFICATION:
      return { ...state, loading: true, message: "", error: "", errorId: "",};
    case DELETE_CLASSIFICATION_SUCCESS:
      const classificationId = action.payload.classificationId;
      return {
        ...state,
        loading: false,
        classifications: state.classifications.filter((item) => item.classify_id !== classificationId)
      }

    /* end - classification */

    case GET_MODULE_BY_ROLE_IN_PROJECT:
      return {
        ...state,
        loading: true,
        message: "",
        error: "",
        errorId: "",
        moduleRoleInProject: [],
      };
    case GET_MODULE_BY_ROLE_IN_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        moduleRoleInProject: action.payload.moduleRole,
        message: action.payload.message,
      };
    case GET_MODULE_BY_ROLE_IN_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        errorId: action.payload.messageId,
      };
    case ADD_CLASS:
    case UPDATE_CLASS:
    case DELETE_CLASS:
      return {
        ...state,
        loading: false,
      }
  
        case ADD_CLASS_SUCCESS: {
          const {
            attributes: label_attribute,
            classes_type_code,
          } = action.payload;
          const newClass = {
            ...action.payload,
            tools: [classes_type_code],
            label_attribute,
          };
          const labelCheck = find(state.labelPageData.labels, ["label_id", action.payload?.label_id]);
          let labels = [...state.labelPageData.labels];
          if (!labelCheck) {
            labels.unshift(newClass);
          }
            return {
            ...state,
            loading: false,
            labelPageData: {
              ...state.labelPageData,
              labels,
            },
          }
        }
  
        case ADD_CLASS_FAILURE:
        case UPDATE_CLASS_FAILURE:
        case DELETE_CLASS_FAILURE:
          return {
            ...state,
            loading: false,
          };
        case UPDATE_CLASS_SUCCESS: {
          const { label_id } = action.payload;
          const labels = [...state.labelPageData.labels];
          const findIndex = state.labelPageData.labels.findIndex((label) => label.label_id === label_id);
          labels[findIndex] = action.payload;
          return {
            ...state,
            labelPageData: {
              ...state.labelPageData,
              labels,
            }
          }
        }
        case DELETE_CLASS_SUCCESS: {
          const { id } = action.payload;
          const labels = [...state.labelPageData.labels];
          const findIndex = state.labelPageData.labels.findIndex((label) => label.label_id === id);
          if (findIndex > -1) {
            labels.splice(findIndex, 1);
          }
          return {
            ...state,
            labelPageData: {
              ...state.labelPageData,
              labels,
            }
          }
        }

    case HANDLE_LABELS_FAILURE: {
      const { message } = action.payload;

      return {
        ...state,
        loading: false,
        message,
      };
    }

    case DELETE_PROJECT_BY_ID:
      return { ...state, loading: true, message: "", error: "", errorId: "", };
    case DELETE_PROJECT_BY_ID_SUCCESS: {
      const { projectId } = action.payload;
      return {
        ...state,
        projects: [
          ...state.projects.filter(
            (project) => project.project_id !== projectId,
          ),
        ],
        loading: false,
      };
    }
    case PREPARE_CUSTOMER_DATA:
      return { ...state, loading: true, message: "", error: "", errorId: "", };
    case PREPARE_CUSTOMER_DATA_SUCCESS: {
      const { data, message } = action.payload;

      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          customerReviewStatus: data,
        },
        message,
        loading: false,
      };
    }
    case PREPARE_CUSTOMER_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        errorId: action.payload.messageId,
      };
    case EXPORT_DATASET:
      return {
        ...state,
        loading: true,
        message: "",
        error: "",
        errorId: "",
        exportDatasetId: null,
      };
    case EXPORT_DATASET_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        messageId: action.payload.messageId,
        exportDatasetId: action.payload.exportDatasetId,
      };
    case EXPORT_DATASET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        errorId: action.payload.messageId,
      };
    case GET_EXPORT_DATASET:
      return { ...state, loading: true, message: "", error: "", errorId: "", };
    case GET_EXPORT_DATASET_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        exportDataset: {
          exportDatasetList: action.payload.exportDatasetList,
          totalPage: action.payload.totalPage,
          totalItem: action.payload.totalItem,
        },
      };
    case GET_EXPORT_DATASET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        errorId: action.payload.messageId,
        exportDataset: {
          exportDatasetList: [],
          totalPage: 0,
          totalItem: 0,
        },
      };
    case GET_SOURCE_STATUS:
      return { ...state, loading: true, message: "", error: "", errorId: "", };
    case GET_SOURCE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        sourceStatus: action.payload.sourceStatus,
      };
    case GET_SOURCE_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        errorId: action.payload.messageId,
        sourceStatus: [],
      };
    case DATASET_ADD_MORE_DATA:
      return {
        ...state,
        loading: true,
        message: "",
        error: "",
        errorId: "",
        addMoreSourceError: [],
      };
    case DATASET_ADD_MORE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        addMoreSourceError: action.payload.sourcesError,
      };
    case DATASET_ADD_MORE_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        errorId: action.payload.messageId,
        addMoreSourceError: action.payload.sourcesError,
      };
    case INVITE_MEMBERS_TO_PROJECT:
      return { ...state, loading: true, message: "", error: "", errorId: "", };
    case INVITE_MEMBERS_TO_PROJECT_SUCCESS: {
      const { projectId, data } = action.payload;

      return {
        ...state,
        projects: state.projects.map((project) => {
          if (project.project_id === projectId) {
            project.managers = data;
          }
          return project;
        }),
        loading: false,
        message: action.payload.message,
      };
    }
    case RESET_SOURCE_STATUS:
      return { ...state, loading: true, message: "", error: "", errorId: "", };
    case RESET_SOURCE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    case RESET_SOURCE_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        errorId: action.payload.messageId,
      };

    case GET_TOTAL_APPROVE_ANNOTATION_EACH_CLASS_IN_PROJECT:
      return { ...state, loading: true, message: "", error: "", errorId: "", };
    case GET_TOTAL_APPROVE_ANNOTATION_EACH_CLASS_IN_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        totalApproveAnnotation: action.payload.project.summary,
        message: action.payload.message,
      };
    case GET_TOTAL_APPROVE_ANNOTATION_EACH_CLASS_IN_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
        totalApproveAnnotation: null,
        error: action.payload.message,
        errorId: action.payload.messageId,
      };

    case GET_TOTAL_APPROVE_IMG_CLASS_IN_PROJECT:
      return { ...state, loading: true, message: "", error: "", errorId: "", loadingPopup: true};
    case GET_TOTAL_APPROVE_IMG_CLASS_IN_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        totalApproveImg: action.payload.project.summary,
        message: action.payload.message,
        loadingPopup: false
      };
    case GET_TOTAL_APPROVE_IMG_CLASS_IN_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
        totalApproveImg: null,
        message: action.payload.message,
        loadingPopup: false
      };

    case GET_LABELER_STATUS_IN_PROJECT:
      return { ...state, loading: true, message: "", error: "", errorId: "", };
    case GET_LABELER_STATUS_IN_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        labelerStatus: action.payload.labelerStatus.summary,
        message: action.payload.message,
      };
    case GET_LABELER_STATUS_IN_PROJECT_ERROR:
      return {
        ...state,
        loading: false,
        labelerStatus: null,
        message: action.payload.message,
      };

    case SET_CURRENT_LABEL:
      return {
        ...state,
        currentLabelTree: action.payload.label,
      };
    case ADD_NEW_LABEL_ATTRIBUTE:
      return { ...state, loading: true, message: "", error: "", errorId: "", };
    case ADD_NEW_LABEL_ATTRIBUTE_SUCCESS:
      const newAttr = action.payload.data;
      if (newAttr !== null) {
        if (currentLabel.id === newAttr.label_type_id) {
          if (currentLabel.attribute !== undefined) {
            currentLabel.attribute = [...currentLabel.attribute, ...[newAttr]];
          } else {
            currentLabel.attribute = [newAttr];
          }
        }
      }
      const listLabel = state.labelPageData.labels.map((label) => {
        if (label.label_id === newAttr.label_type_id && !label.has_attribute) {
          label.has_attribute = true;
        }
        return label;
      });
      return {
        ...state,
        loading: false,
        labelPageData: {
          ...state.labelPageData,
          labels: listLabel,
        },
        currentLabelTree: currentLabel,
        message: action.payload.message,
      };
    case ADD_NEW_LABEL_ATTRIBUTE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        errorId: action.payload.messageId,
      };
    case GET_LABEL_ATTRIBUTE:
      return { ...state, loading: true, message: "", error: "", errorId: "", };
    case GET_LABEL_ATTRIBUTE_SUCCESS:
      const attribute = action.payload.data;
      if (attribute.length > 0) {
        if (currentLabel.id === attribute[0].label_type_id) {
          currentLabel.attribute = attribute;
        }
      }
      return {
        ...state,
        currentLabelTree: currentLabel,
        loading: false,
        message: action.payload.message,
      };
    case GET_LABEL_ATTRIBUTE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        errorId: action.payload.messageId,
      };
    case DELETE_ATTRIBUTE:
      return { ...state, loading: true, message: "", error: "", errorId: "", };
    case DELETE_ATTRIBUTE_SUCCESS:
      currentLabel.attribute = currentLabel.attribute.filter(
        (item) => item.label_attribute_id !== action.payload.attributeId,
      );
      const listLabelUpdate = state.labelPageData.labels.map((label) => {
        if (label.label_id === action.payload.labelId && label.has_attribute) {
          label.has_attribute = false;
        }
        return label;
      });
      return {
        ...state,
        labelPageData: {
          ...state.labelPageData,
          labels: listLabelUpdate,
        },
        currentLabelTree: currentLabel,
        loading: false,
        message: action.payload.message,
      };
    case DELETE_ATTRIBUTE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        errorId: action.payload.messageId,
      };
    case UPDATE_ATTRIBUTE:
      return { ...state, loading: true, message: "", error: "", errorId: "", };
    case UPDATE_ATTRIBUTE_SUCCESS:
      currentLabel.attribute = currentLabel.attribute.map((item) => {
        if (
          item.label_attribute_id ===
          action.payload.attributeData.label_attribute_id
        ) {
          return action.payload.attributeData;
        }
        return item;
      });
      return {
        ...state,
        currentLabelTree: currentLabel,
        loading: false,
        message: action.payload.message,
      };
    case UPDATE_ATTRIBUTE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        errorId: action.payload.messageId,
      };
    case UPDATE_PROJECT:
      return { ...state, loading: true, message: "", error: "", errorId: "", };
    case UPDATE_PROJECT_SUCCESS:
      const updateData = action.payload.data;
      state.projects = state.projects.map((item) => {
        if (item.project_id === updateData.project_id) {
          item.name = updateData.name;
          item.description = updateData.description;
          item.image_path = updateData.image_path;
          item.target = updateData.target;
        }
        return item;
      });
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };
    case CLONE_DATASETS:
      return {
        ...state,
        refreshDataset: false,
        loading: true,
        message: "",
        error: "",
        errorId: "",
      };
    case CLONE_DATASETS_SUCCESS:
      const isCurrentProject = state.currentProject.project_id === action.payload.data.project_id;
      if (isCurrentProject) return {
        ...state,
        refreshDataset: true,
        loading: false,
        message: action.payload.message,
      }
      return {
        ...state,
        loading: false
      };
      case GET_LABEL_LIST:
        return { ...state, loading: true, message: "", error: "", errorId: "", };
      case GET_LABEL_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          labelPageData: {
            labels: action.payload.labels,
            totalPage: action.payload.totalPage,
            totalItem: action.payload.totalItem,
          },
          message: action.payload.message,
        };
      case GET_LABEL_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          labelPageData: {
            labels: [],
            totalPage: 0,
            totalItem: 0,
          },
          error: action.payload.message,
          errorId: action.payload.messageId,
        };
    case IS_LOADING:
      return {
        ...state,
        loading: action.payload
      }
      case SET_ERROR_ASSIGN_TASK:
        return {
          ...state,
          errorAssignTask: action.payload
        }
      
    
    default:
      return { ...state };
  }
};

export const getProjects = (state) => state[namespace].projects;
export const getMessage = (state) => state[namespace].message;
export const getError = (state) => state[namespace].error;
export const getErrorId = (state) => state[namespace].errorId;
export const getLoading = (state) => state[namespace].loading;
export const getDatasetId = (state) => state[namespace].datasetId;
export const getCurrentLabel = (state) => state[namespace].currentLabelTree;
export const getNewLabelId = (state) => state[namespace].newLabelId;
