import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import get from "lodash/get";

import { ERROR_CASE } from "components/notificationModal/constants";
import { showNoti } from "redux/actions";

import {
  GET_LIST_PRICING_PACKAGES,
  GET_CURRENT_PRICING_PACKAGE,
  GET_BILLING_LIST,
  SEND_PRICING_CONTACT_EMAIL_TO_SYSTEM_ADMIN,
} from "redux/actions";

import {
  getListPricingPackagesSuccess,
  getListPricingPackagesFailure,
  getCurrentPricingPackageSuccess,
  getCurrentPricingPackageFailure,
  getBillingListSuccess,
  getBillingListFailure,
} from "./actions";

import {
  getListPricingPackages,
  getCurrentPricingPack,
  getBillingList,
  sendPricingContactEmailToSystemAdminAPI,
} from "./api";
import { selectUsetOrganizationList } from "redux/organization/selectors";
import { selectCurrentPackage } from "redux/pricing/selectors";
import { userInfoCookie } from "helpers/Utils";

function* getListPricingPackagesSaga() {
  const response = yield call(getListPricingPackages);
  const errorMessage = "Get List Pricing Packages Error!";
  const errorMessageId = "notification.get-list-pricing-packages-error";
  const statusCode = get(response, "status", "");
  if (statusCode === 200) {
    const { status, data } = get(response, "data");
    if (status) {
      yield put(getListPricingPackagesSuccess({ data }));
    } else {
      yield put(getListPricingPackagesFailure());
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } else {
    yield put(getListPricingPackagesFailure());
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

function* getCurrentPricingPackagesSaga({ payload: { callback } }) {
  const response = yield call(getCurrentPricingPack);
  const errorMessage = "Get Current Pricing Packages Error!";
  const errorMessageId = "notification.get-current-pricing-packages-error";
  const statusCode = get(response, "status", "");
  if (statusCode === 200) {
    const { status, data } = get(response, "data");
    if (status) {
      yield put(getCurrentPricingPackageSuccess(data));
    } else {
      yield put(getCurrentPricingPackageFailure());
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
    if (callback) callback();
  } else {
    yield put(getCurrentPricingPackageFailure());
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

function* getBillingListSaga({ payload }) {
  const userEmail = get(payload, "userEmail", "");
  const currentPage = get(payload, "currentPage", "");
  const pageSize = get(payload, "pageSize", 10);
  const { callback } = payload;
  const response = yield call(getBillingList, userEmail, pageSize, currentPage);
  const errorMessage = "Get Billing List Error!";
  const errorMessageId = "notification.get-billing-list-error";
  const statusCode = get(response, "status", "");
  if (statusCode === 200) {
    const { status, data } = get(response, "data");
    if (status) {
      yield put(getBillingListSuccess({ data }));
      callback && callback(data);
    } else {
      yield put(getBillingListFailure());
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } else {
    yield put(getBillingListFailure());
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

function* sendPricingContactEmailToSystemAdminSaga({ callback, payload }) {
  const orgList = yield select(selectUsetOrganizationList);
  const userInfo = userInfoCookie();
  const currenPackage = yield select(selectCurrentPackage);
  const bodyData = {
    email: payload.email,
    name: payload.name,
    phoneNumber: payload.phoneNumber,
    userDemand: payload.userDemand,
    message: payload.message,
    upgrade_plan: payload.upgrade_plan,
    org_name: orgList?.find((org) => org.OrgID === userInfo?.organizationId)
      ?.OrgName,
    current_plan: currenPackage.pricing_pack_name,
  };
  const response = yield call(
    sendPricingContactEmailToSystemAdminAPI,
    bodyData,
  );
  const errorMessage = "Send Contact Email Error!";
  const errorMessageId = "notification.send-contact-email-error";
  const statusCode = get(response, "status", "");
  const { status } = get(response, "data", null);
  if (statusCode === 200 && status) {
    callback && callback();
  } else {
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(GET_LIST_PRICING_PACKAGES, getListPricingPackagesSaga),
    takeEvery(GET_CURRENT_PRICING_PACKAGE, getCurrentPricingPackagesSaga),
    takeEvery(GET_BILLING_LIST, getBillingListSaga),
    takeLatest(
      SEND_PRICING_CONTACT_EMAIL_TO_SYSTEM_ADMIN,
      sendPricingContactEmailToSystemAdminSaga,
    ),
  ]);
}
