/* eslint-disable */
import {
    GET_ENTERPRISE_USER_LIST,
    GET_ENTERPRISE_USER_LIST_SUCCESS,
    GET_ENTERPRISE_USER_LIST_ERROR,
    ADD_ENTERPRISE_LOG_PRICE,
    ADD_ENTERPRISE_LOG_PRICE_SUCCESS,
    ADD_ENTERPRISE_LOG_PRICE_ERROR,
    EDIT_ENTERPRISE_LOG_PRICE,
    EDIT_ENTERPRISE_LOG_PRICE_SUCCESS,
    EDIT_ENTERPRISE_LOG_PRICE_ERROR,
    DELETE_ENTERPRISE_LOG_PRICE,
    DELETE_ENTERPRISE_LOG_PRICE_SUCCESS,
    DELETE_ENTERPRISE_LOG_PRICE_ERROR,
} from "redux/actions";

const INIT_STATE = {
    enterpriseUserList: [],
    totalItem:0, 
    totalPage:0,
    loadingUsers: false,
    error: ""
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ENTERPRISE_USER_LIST:
            return {...state, loadingUsers: true, error: ""};
        case GET_ENTERPRISE_USER_LIST_SUCCESS:
            return {
                ...state,
                enterpriseUserList: action.payload.enterpriseUserList,
                totalItem: action.payload.totalItem, 
                totalPage: action.payload.totalPage,
                loadingUsers: false,
                error: "",
            };
        case GET_ENTERPRISE_USER_LIST_ERROR:
            return {
                ...state,
                enterpriseUserList: [], 
                totalItem:0, 
                totalPage:0,
                loadingUsers: false,
                error: action.payload.message,
            };
        // case ADD_ENTERPRISE_LOG_PRICE:
        //     return {...state, loadingUsers: true, error: ""};
        //     break;
        // case ADD_ENTERPRISE_LOG_PRICE_SUCCESS:
        //     return {
        //         ...state,
        //         loadingUsers: false,
        //         error: "",
        //     };
        //     break;
        // case ADD_ENTERPRISE_LOG_PRICE_ERROR:
        //     return {
        //         ...state,
        //         loadingUsers: false,
        //         error: action.payload.message,
        //     };
        //     break;
        // case EDIT_ENTERPRISE_LOG_PRICE:
        //     return {...state, loadingUsers: true, error: ""};
        //     break;
        // case EDIT_ENTERPRISE_LOG_PRICE_SUCCESS:
        //     return {
        //         ...state,
        //         loadingUsers: false,
        //         error: "",
        //     };
        //     break;
        // case EDIT_ENTERPRISE_LOG_PRICE_ERROR:
        //     return {
        //         ...state,
        //         loadingUsers: false,
        //         error: action.payload.message,
        //     };
        //     break;
        // case DELETE_ENTERPRISE_LOG_PRICE:
        //     return {...state, loadingUsers: true, error: ""};
        //     break;
        // case DELETE_ENTERPRISE_LOG_PRICE_SUCCESS:
        //     return {
        //         ...state,
        //         loadingUsers: false,
        //         error: "",
        //     };
        //     break;
        // case DELETE_ENTERPRISE_LOG_PRICE_ERROR:
        //     return {
        //         ...state,
        //         loadingUsers: false,
        //         error: action.payload.message,
        //     };
        //     break;
        default:
            return {...state};
    }
}
