import React from 'react'
import IntlMessages from "helpers/IntlMessages";
import { selectLabels, selectlistAllDatasets } from "redux/projects/selectors";
import { createSelector } from "reselect";
import { statusOptions } from 'components/AdvancedSearch/const';

export const selectAdvancedSearch = (state) => state.advancedSearch;

export const selectFilterValues = createSelector(
  selectLabels,
  selectlistAllDatasets,
  selectAdvancedSearch,
  (labels, datasets, filters) => {
      const statusLabelId = statusOptions.find(item=> item.value === filters.status)?.labelId
    return {
      ...filters,
      datasetIds: (filters.datasetIds || []).map((datasetId) => ({
        value: datasetId,
        label: (datasets || []).find(
          (item) => Number(item.dataset_id) === Number(datasetId),
        )?.dataset_name,
      })),
      labelIds: (filters.labelIds || []).map((labelId) => ({
        value: labelId,
        label: (labels || []).find(
          (item) => Number(item.label_id) === Number(labelId),
        )?.name,
      })),
      status: (filters.status || []).map((statusItem) => ({
        value: statusItem,
        label: <IntlMessages id={statusLabelId} />,
      })),
      
    };
  },
);
