import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  deleteTagOfSourceAPI,
  getTagsByProjectId,
  getTagsBySourceId,
  deleteTagOfProjectAPI,
  addTagToSourceAPI,
  searchProjectTagByTextAPI,
  createProjectTagAPI,
} from "./api";
import {
  ADD_TAG_TO_SOURCE,
  CREATE_PROJECT_TAG,
  CREATE_PROJECT_TAG_AND_ADD_TO_SOURCE,
  DELETE_TAG_OF_PROJECT,
  DELETE_TAG_OF_SOURCE,
  GET_TAGS_BY_PROJECT_ID,
  GET_TAGS_DATA_FOR_TAG_FORM,
  SEARCH_PROJECT_TAG_BY_TEXT,
  showNoti,
} from "redux/actions";
import {
  ERROR_CASE,
  SUCCESS_CASE,
} from "components/notificationModal/constants";
import get from "lodash/get";
import {
  getTagDataForTagFormSuccess,
  getTagsByProjectIdError,
  getTagsByProjectIdSuccess
} from "./actions";

function* getTagsDataForTagFormSaga({ payload, callback }) {
  const { sourceIds, projectId } = payload;
  const [srcTagsRes, projectTagsRes] = yield all([
    call(getTagsBySourceId, { sourceIds }),
    call(getTagsByProjectId, { projectId }),
  ]);

  const checkSuccessSourceTag =
    srcTagsRes?.status === 200 && srcTagsRes?.data?.status;
  const checkSuccessProjectTag =
    projectTagsRes?.status === 200 && projectTagsRes?.data?.status;

  if (checkSuccessSourceTag && checkSuccessProjectTag) {
    yield put(
      getTagDataForTagFormSuccess({
        srcTags: srcTagsRes.data?.data?.tags,
        projectTags: projectTagsRes.data?.data?.tags,
      }),
    );
  } else {
    yield put(
      showNoti({
        type: ERROR_CASE,
        messageId: "tags.error-to-fetch-tag",
      }),
    );
  }

  callback &&
    callback({ srcTags: srcTagsRes.data, projectTags: projectTagsRes.data });
}

function* deleteTagOfSourceSaga({ payload, callback }) {
  const { projectId, sourceIds, tags } = payload;
  const response = yield call(deleteTagOfSourceAPI, {
    projectId,
    sourceIds,
    tags,
  });
  const checkResponse = response?.status === 200 && response?.data?.status;

  yield put(
    showNoti({
      type: checkResponse ? SUCCESS_CASE : ERROR_CASE,
      messageId: checkResponse
        ? "tags.delete-tag-of-source-success"
        : "tags.delete-tag-of-source-error",
    }),
  );
  callback && callback(response?.data?.data);
}

function* deleteTagOfProjectSaga({ payload, callback }) {
  const { projectId, tags } = payload;
  const response = yield call(deleteTagOfProjectAPI, {
    projectId,
    tags,
  });
  const checkResponse = response?.status === 200 && response?.data?.status;

  yield put(
    showNoti({
      type: checkResponse ? SUCCESS_CASE : ERROR_CASE,
      messageId: checkResponse
        ? "tags.delete-tag-of-project-success"
        : "tags.delete-tag-of-project-error",
    }),
  );
  callback && callback(response?.data?.data);
}

function* addTagToSourceSaga({ payload, callback }) {
  const { projectId, tags, sourceIds } = payload;
  const response = yield call(addTagToSourceAPI, {
    projectId,
    sourceIds,
    tags,
  });
  const checkResponse = response?.status === 200 && response?.data?.status;

  yield put(
    showNoti({
      type: checkResponse ? SUCCESS_CASE : ERROR_CASE,
      messageId: checkResponse
        ? "tags.add-tag-to-source-successfully"
        : "tags.add-tag-to-source-error",
    }),
  );
  callback && callback(response?.data?.data);
  return response?.data?.data;
}

function* searchProjectTagByTextSaga({ payload, callback }) {
  const { projectId, textSearch } = payload;
  const response = yield call(searchProjectTagByTextAPI, {
    projectId,
    textSearch,
  });
  const checkResponse = response?.status === 200 && response?.data?.status;

  if (!checkResponse) {
    yield put(
      showNoti({
        type: ERROR_CASE,
        messageId: "tags.search-project-tag-by-text-error",
      }),
    );
  }
  callback && callback(response?.data?.data);
}

function* createProjectTagSaga({ payload, callback }) {
  const { projectId, newtag } = payload;
  const response = yield call(createProjectTagAPI, {
    projectId,
    newtag,
  });
  const checkResponse = response?.status === 200 && response?.data?.status;

  yield put(
    showNoti({
      type: checkResponse ? SUCCESS_CASE : ERROR_CASE,
      messageId: checkResponse
        ? "tags.add-tag-to-project-successfully"
        : "tags.add-tag-to-project-error",
    }),
  );

  callback && callback(response?.data?.data);
  return response?.data?.data;
}

function* createProjectTagAndAddToSourceSaga({ payload, callback }) {
  const res = yield call(createProjectTagSaga, { payload });
  let addToSourceRes = null;

  if (res) {

    addToSourceRes = yield call(addTagToSourceSaga, {
      payload: {
        ...payload,
        tags: res.tags?.map((item) => item.tag_id),
      },
    });
    
  }

  callback && callback(addToSourceRes);
}

function* watchGetTagsByProjectIdSaga ({ payload }) {
  const { projectId } = payload;
  try {
    const response = yield call(getTagsByProjectId, { projectId });
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { tags } = get(response, "data.data", []);
      yield put(getTagsByProjectIdSuccess({tags}));
    }
    
  } catch (error) {
    yield put(getTagsByProjectIdError)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(GET_TAGS_DATA_FOR_TAG_FORM, getTagsDataForTagFormSaga),
    takeEvery(DELETE_TAG_OF_SOURCE, deleteTagOfSourceSaga),
    takeEvery(DELETE_TAG_OF_PROJECT, deleteTagOfProjectSaga),
    takeEvery(ADD_TAG_TO_SOURCE, addTagToSourceSaga),
    takeEvery(SEARCH_PROJECT_TAG_BY_TEXT, searchProjectTagByTextSaga),
    takeEvery(CREATE_PROJECT_TAG, createProjectTagSaga),
    takeEvery(
      CREATE_PROJECT_TAG_AND_ADD_TO_SOURCE,
      createProjectTagAndAddToSourceSaga,
    ),
    takeEvery(GET_TAGS_BY_PROJECT_ID, watchGetTagsByProjectIdSaga),
  ]);
}
