  import {
  all,
  call,
  put,
  takeEvery,
} from "redux-saga/effects";
import get from "lodash/get";

import { ERROR_CASE } from "components/notificationModal/constants";
import { showNoti } from "redux/actions";

import {
  GET_ACTION_HISTORY,
  GET_BENCHMARK,
} from "redux/actions";

import {
  getActionHistorySuccess,
  getActionHistoryFailure,
  getBenchmarkSuccess,
  getBenchmarkFailure,
} from "./actions";

import {
  getDatasetDetailPerUser,
  getBenchmarkListApi,
} from "./api";

function* getDatasetDetailPerUserSaga({ payload }) {
  const response = yield call(getDatasetDetailPerUser, payload);
  const errorMessage = "Get Dataset Detail Per User Error!";
  const errorMessageId = "notification.get-dataset-detail-per-user-error";
  const statusCode = get(response, "status", "");
  if (statusCode === 200) {
    const { status, data, totalItem, totalPage } = get(response, "data");
    if (status) {
      yield put(getActionHistorySuccess({ data, totalItem, totalPage }));
    } else {
      yield put(getActionHistoryFailure());
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } else {
    yield put(getActionHistoryFailure());
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

function* getBenchmarkListSaga({ payload }) {
  const response = yield call(getBenchmarkListApi, payload);
  const errorMessage = "Get Dataset Detail Per User Error!";
  const errorMessageId = "notification.get-dataset-detail-per-user-error";
  const statusCode = get(response, "status", "");
  console.log('response', response);
  if (statusCode === 200) {
    const { status, data, totalItem, totalPage } = get(response, "data");
    if (status) {
      yield put(getBenchmarkSuccess({ data, totalItem, totalPage }));
    } else {
      yield put(getBenchmarkFailure());
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } else {
    yield put(getBenchmarkFailure());
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(GET_ACTION_HISTORY, getDatasetDetailPerUserSaga),
    takeEvery(GET_BENCHMARK, getBenchmarkListSaga),
  ]);
}
