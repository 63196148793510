import axios from "../api";

export function getListMembers(
  selectedPageSize = 5,
  currentPage = 1,
  selectedStatus = "active",
  projectId,
) {
  return axios.get(
    `/members/get-members-in-organization?&pageSize=${selectedPageSize}&currentPage=${currentPage}&status=${selectedStatus}&projectId=${projectId}`,
  );
}

export function makeInactiveMember(memberIds) {
  return axios.put(`/members/make-inactive-member`, {
    members: memberIds,
  });
}

export function restoreActiveMember(memberIds) {
  return axios.put(`/members/make-active-member`, {
    members: memberIds,
  });
}

export function deleteMember(memberIds) {
  return axios.put(`/members/make-delete-member`, {
    members: memberIds,
  });
}

export function getListProject() {
  return axios.get(`/project/get-projects-in-organization`);
}

export function getListRole() {
  return axios.get(`/project/get-role-by-project`);
}

export function assignProjectToMember(memberId, data) {
  return axios.post(`/members/assign-members-to-project`, {
    memberId: memberId,
    data: data,
  });
}

export function deleteMemberFromProject(memberId, projectId) {
  return axios.post("/project/remove-member-in-project", {
    UserId: memberId,
    ProjectId: projectId,
  });
}

export function getMemberByEmail(email) {
  return axios.get(`/members/get-members-by-email?email=${email}`);
}

export function addNewMember(data) {
  return axios.post("/members/add-new-member", data);
}

export function getAllSiteAdmin() {
  return axios.get("/members/get-all-site-admin");
}

export function getAllUsers() {
  return axios.get("/users/get-all-users");
}

export function updateMember(data) {
  return axios.post("/members/update-member", data);
}

export function getNextPlanFromStripe(userEmail) {
  return axios.get(`/members/next-plan?email=${userEmail}`);
}
