
import {
  GET_TAGS_BY_PROJECT_ID_SUCCESS,
  GET_TAGS_DATA_FOR_TAG_FORM,
  GET_TAGS_DATA_FOR_TAG_FORM_SUCCESS,
} from "redux/actions";

const INIT_STATE = {
  sourceTags: [],
  projectTags: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TAGS_DATA_FOR_TAG_FORM:
      return {
        ...state,
        sourceTags:[],
        projectTags: []
      }
     case GET_TAGS_DATA_FOR_TAG_FORM_SUCCESS:
       return {
         ...state,
         sourceTags: action.payload?.srcTags,
         projectTags: action.payload?.projectTags,
       };
     case GET_TAGS_BY_PROJECT_ID_SUCCESS:
       return {
         ...state,
         projectTags: action.payload?.tags,
       };
    default:
      return { ...state };
  }
};
