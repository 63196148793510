import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  AUTHENTICATION_GOOGLE,
  AUTHENTICATION_GOOGLE_SUCCESS,
  AUTHENTICATION_GOOGLE_ERROR,
  AUTHENTICATION_GITHUB,
  AUTHENTICATION_GITHUB_SUCCESS,
  AUTHENTICATION_GITHUB_ERROR,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  CONFIRMATION_USER,
  CONFIRMATION_USER_SUCCESS,
  CONFIRMATION_USER_ERROR,
  RESEND_CONFIRMATION,
  RESEND_CONFIRMATION_SUCCESS,
  RESEND_CONFIRMATION_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SETUP_NEW_MEMBER,
  SETUP_NEW_MEMBER_SUCCESS,
  SETUP_NEW_MEMBER_ERROR,
  CHECK_TOKEN,
  CHECK_TOKEN_SUCCESS,
  CHECK_TOKEN_ERROR,
  CHECK_TOKEN_NEW_MEMBER,
  CHECK_TOKEN_NEW_MEMBER_SUCCESS,
  CHECK_TOKEN_NEW_MEMBER_ERROR,
  FETCH_USER_INFO,
  UPDATE_USER_INFO,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_PASSWORD,
} from "redux/actions";

export const updateUserPassword = (payload) => ({
  type: UPDATE_USER_PASSWORD,
  payload
});

export const updateUserInforSuccess = (payload) => ({
  type: UPDATE_USER_INFO_SUCCESS,
  payload
});

export const updateUserInfo = (payload) => ({
  type: UPDATE_USER_INFO,
  payload
});

export const fetchUserInfo = (payload) => ({
  type: FETCH_USER_INFO,
  payload
})
export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
});
export const loginUserSuccess = (user, isVerified) => ({
  type: LOGIN_USER_SUCCESS,
  payload: { user, isVerified },
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const authenticationGoogle = (accessToken, history) => ({
  type: AUTHENTICATION_GOOGLE,
  payload: { accessToken, history },
});
export const authenticationGoogleSuccess = (user, isVerified) => ({
  type: AUTHENTICATION_GOOGLE_SUCCESS,
  payload: { user, isVerified },
});
export const authenticationGoogleError = (message) => ({
  type: AUTHENTICATION_GOOGLE_ERROR,
  payload: { message },
});

export const authenticationGithub = (code, history) => ({
  type: AUTHENTICATION_GITHUB,
  payload: { code, history },
});
export const   authenticationGithubSuccess = (user, isVerified) => ({
  type: AUTHENTICATION_GITHUB_SUCCESS,
  payload: { user, isVerified },
});
export const authenticationGithubError = (message) => ({
  type: AUTHENTICATION_GITHUB_ERROR,
  payload: { message },
});

export const confirmationUser = (email, token, history) => ({
  type: CONFIRMATION_USER,
  payload: { email, token, history },
});
export const confirmationUserSuccess = () => ({
  type: CONFIRMATION_USER_SUCCESS,
});
export const confirmationUserError = (message) => ({
  type: CONFIRMATION_USER_ERROR,
  payload: { message },
});

export const resendConfirmation = (email) => ({
  type: RESEND_CONFIRMATION,
  payload: { email },
});
export const resendConfirmationSuccess = (message) => ({
  type: RESEND_CONFIRMATION_SUCCESS,
  payload: { message },
});
export const resendConfirmationError = (message) => ({
  type: RESEND_CONFIRMATION_ERROR,
  payload: { message },
});

export const forgotPassword = (email, history) => ({
  type: FORGOT_PASSWORD,
  payload: { email, history },
});
export const forgotPasswordSuccess = (message) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: { message },
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message },
});

export const resetPassword = (email, resetToken, newPassword, history) => ({
  type: RESET_PASSWORD,
  payload: { email, resetToken, newPassword, history },
});
export const resetPasswordSuccess = (message) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: { message },
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message },
});

export const setupNewMember = (token, password, history) => ({
  type: SETUP_NEW_MEMBER,
  payload: { token, password, history },
});
export const setupNewMemberSuccess = (message) => ({
  type: SETUP_NEW_MEMBER_SUCCESS,
  payload: { message },
});
export const setupNewMemberError = (message) => ({
  type: SETUP_NEW_MEMBER_ERROR,
  payload: { message },
});

export const checkToken = (resetToken, history) => ({
  type: CHECK_TOKEN,
  payload: { resetToken, history },
});
export const checkTokenSuccess = (isValidToken) => ({
  type: CHECK_TOKEN_SUCCESS,
  payload: { isValidToken },
});
export const checkTokenError = (message) => ({
  type: CHECK_TOKEN_ERROR,
  payload: { message },
});

export const checkTokenNewMember = (token, history) => ({
  type: CHECK_TOKEN_NEW_MEMBER,
  payload: { token, history },
});
export const checkTokenNewMemberSuccess = (isValidToken) => ({
  type: CHECK_TOKEN_NEW_MEMBER_SUCCESS,
  payload: { isValidToken },
});
export const checkTokenNewMemberError = (message) => ({
  type: CHECK_TOKEN_NEW_MEMBER_ERROR,
  payload: { message },
});

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history },
});
export const registerUserSuccess = (email) => ({
  type: REGISTER_USER_SUCCESS,
  payload: { email },
});
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message },
});

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
});
