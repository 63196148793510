import { datasetPageSizes } from "constants/defaultValues";
import {
  GET_LIST_DATASET,
  GET_LIST_DATASET_SUCCESS,
  GET_LIST_DATASET_ERROR,
  SET_CURRENT_DATASET,
  UPDATE_DATASET,
  UPDATE_DATASET_SUCCESS,
  DELETE_DATASET,
  DELETE_DATASET_SUCCESS,
  SET_SELECTED_DATASETS,
  DELETE_DATASETS,
  SET_DATASET_LOADING,
  IMAGE_AUGMENTING,
  CREATE_CLONE_DATASET_JOB_SUCCESS,
  CLONE_DATASET_JOB_SUCCESS,
  CLONE_DATASET_JOB_ERROR,
  START_CLONE_DATASET,
  START_MERGE_DATASET,
  CREATE_MERGE_DATASET_JOB_SUCCESS,
  START_CLONE_SOURCES,
  START_MERGE_SOURCES,
} from "redux/actions";

export const getListDataset = ({
  projectId,
  pageSize = datasetPageSizes[0],
  currentPage = 1,
}) => ({
  type: GET_LIST_DATASET,
  payload: {
    projectId,
    pageSize,
    currentPage,
  },
});
export const getListDatasetSuccess = ({ pagination, datasets }) => ({
  type: GET_LIST_DATASET_SUCCESS,
  payload: { pagination, datasets },
});
export const getListDatasetError = (message) => ({
  type: GET_LIST_DATASET_ERROR,
  payload: { message },
});

export const setCurrentDataset = (payload) => ({
  type: SET_CURRENT_DATASET,
  payload,
});

export const updateDatasetById = ({ payload, callback }) => ({
  type: UPDATE_DATASET,
  payload,
  callback,
});
export const updateDatasetByIdSuccess = (data, message) => ({
  type: UPDATE_DATASET_SUCCESS,
  payload: { data, message },
});

export const deleteDatasetById = ({ payload, callback }) => ({
  type: DELETE_DATASET,
  payload,
  callback,
});
export const deleteDatasetByIdSuccess = (data, message) => ({
  type: DELETE_DATASET_SUCCESS,
  payload: { data, message },
});

export const setSelectedDataset = (payload) => ({
  type: SET_SELECTED_DATASETS,
  payload,
});

export const deleteDatasets = ({payload, callback}) => ({
  type: DELETE_DATASETS,
  payload,
  callback
});

export const setDatasetLoading = (payload) => ({
  type: SET_DATASET_LOADING,
  payload,
});
export const imageAugmenting = ({payload, callback}) => ({
  type: IMAGE_AUGMENTING,
  payload,
  callback
});

export const startCloneDataset = (payload) => ({
  type: START_CLONE_DATASET,
  payload
})

export const createCloneDatasetJobSuccess = (payload) => ({
  type: CREATE_CLONE_DATASET_JOB_SUCCESS,
  payload,
})

export const cloneDatasetJobSuccess = () => ({
  type: CLONE_DATASET_JOB_SUCCESS,
})

export const cloneDatasetJobError = () => ({
  type: CLONE_DATASET_JOB_ERROR,
})

export const startMergeDataset = (payload) => ({
  type: START_MERGE_DATASET,
  payload
})

export const createMergeDatasetJobSuccess = (payload) => ({
  type: CREATE_MERGE_DATASET_JOB_SUCCESS,
  payload
})

export const startCloneSources = (payload) => ({
  type: START_CLONE_SOURCES,
  payload,
})

export const startMergeSources = (payload) => ({
  type: START_MERGE_SOURCES,
  payload
})