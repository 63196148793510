import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select,
  takeLatest,
} from "redux-saga/effects";
import { get } from "lodash";

import {
  selectProjectByProjectId,
} from "redux/projects/selectors";
import {
  SUCCESS_CASE,
  ERROR_CASE,
} from "components/notificationModal/constants";
import {
  showNoti,
  getCurrentPricingPackage,
  GENERATE_MODEL,
  ADD_NEW_CLASSIFICATION,
  UPDATE_CLASSIFICATION,
  DELETE_CLASSIFICATION,
} from "redux/actions";

import {
  GET_LIST_PROJECT_DETAIL,
  CREATE_NEW_DATASET,
  GET_SUMMARY_PROJECTS,
  GET_EMAIL_LIST,
  GET_ROLE_LIST,
  GET_ASSIGN_MEMBER_LIST,
  CREATE_NEW_PROJECT,
  GET_PROJECT_BY_ID,
  GET_FIRST_DATASET_EXIST_SOURCE,
  GET_CURRENT_PROJECT_MEMBERS,
  ASSIGN_TASK_TO_MEMBER,
  UNASSIGN_TASK_TO_MEMBER,
  DELETE_PROJECT_BY_ID,
  GET_PROJECT_LABELS,
  GET_PROJECT_CLASSIFICATIONS,
  ADD_CLASS,
  UPDATE_CLASS,
  DELETE_CLASS,
  EXPORT_DATASET,
  GET_EXPORT_DATASET,
  GET_MODULE_BY_ROLE_IN_PROJECT,
  PREPARE_CUSTOMER_DATA,
  GET_SOURCE_STATUS,
  DATASET_ADD_MORE_DATA,
  INVITE_MEMBERS_TO_PROJECT,
  RESET_SOURCE_STATUS,
  GET_TOTAL_APPROVE_ANNOTATION_EACH_CLASS_IN_PROJECT,
  GET_TOTAL_APPROVE_IMG_CLASS_IN_PROJECT,
  GET_LABELER_STATUS_IN_PROJECT,
  ADD_NEW_LABEL_ATTRIBUTE,
  GET_LABEL_ATTRIBUTE,
  DELETE_ATTRIBUTE,
  UPDATE_ATTRIBUTE,
  UPDATE_PROJECT,
  CLONE_DATASETS,
  GET_VERSIONS_DATASETS_SOURCE,
  PUBLIC_DATASET,
  GET_TAGS_BY_PROJECT_ID,
  CREATE_NEW_TAG_SOURCE,
  EDIT_MEMBERS_TO_PROJECT,
  DELETE_MEMBERS_TO_PROJECT,
  GET_LABEL_LIST,
  ASSIGN_TASK_TO_MEMBER_BY_DATASET,
  UNASSIGN_TASK_TO_MEMBER_BY_DATASET
} from "redux/actions";

import {
  actionError,
  getListProjectsDetailSuccess,
  getListProjectsDetailError,
  createNewDatasetSuccess,
  getSummaryProjectsSuccess,
  getSummaryProjectsError,
  getEmailListSuccess,
  getRoleListSuccess,
  getAssignMemberListSuccess,
  getEmailListError,
  getRoleListError,
  getAssignMemberListError,
  getProjectByIdSuccess,
  getProjectByIdError,
  getFirstDatasetExistSourceSuccess,
  getFirstDatasetExistSourceError,
  getCurrentProjectMembersSuccess,
  getCurrentProjectMembersError,
  assignTaskToMemberSuccess,
  assignTaskToMemberError,
  unassignTaskToMemberSuccess,
  unassignTaskToMemberError,
  getProjectLabelsSuccess,
  deleteProjectByIdSuccess,
  getModuleByRoleInProjectSuccess,
  getModuleByRoleInProjectError,
  exportDatasetSuccess,
  getExportDatasetSuccess,
  getExportDatasetError,
  prepareCustomerDataSuccess,
  prepareCustomerDataError,
  getSourceStatusSuccess,
  getSourceStatusError,
  datasetAddMoreDataSuccess,
  inviteMembersToProjectSuccess,
  resetSourceStatusSuccess,
  resetSourceStatusError,
  getTotalApproveAnnotationEachClassInProjectSuccess,
  getTotalApproveAnnotationEachClassInProjectError,
  getTotalApproveImgClassInProjectSuccess,
  getTotalApproveImgClassInProjectError,
  getLabelerStatusInProjectSuccess,
  getLabelerStatusInProjectError,
  addNewLabelAttributeSuccess,
  getLabelAttributeSuccess,
  getLabelAttributeError,
  deleteAttributeSuccess,
  updateAttributeSuccess,
  updateAttributeError,
  createNewProjectSuccess,
  setCurrentDataset,
  updateProjectSuccess,
  editMembersToProjectSuccess,
  deleteMembersToProjectSuccess,
  getProjectClassificationsSuccess,
  addNewClassificationSuccess,
  updateClassificationSuccess,
  deleteClassificationSuccess,
  cloneDatasetsSuccess,
  addClassSuccess,
  addClassFailure,
  deleteClassSuccess,
  deleteClassFailure,
  updateClassSuccess,
  updateClassFailure,
  getLabelListSuccess,
} from "./actions";

import {
  getListProjectsDetail,
  createNewDataset,
  getSummaryProjects,
  getEmailList,
  getRoleList,
  getAssignMemberList,
  createNewProject,
  getProjectById,
  getFirstDatasetExistSource,
  getCurrentProjectMembers,
  assignTaskToMemberApi,
  unassignTaskToMemberApi,
  getProjectLabels,
  getProjectClassifications,
  deleteProjectbyId,
  getModuleByRoleInProject,
  exportDataset,
  getExportDataset,
  prepareCustomerData,
  getSourceStatus,
  datasetAddMoreData,
  inviteMembersToProject,
  resetSourceStatus,
  getTotalApproveAnnotationOfEachClassInProject,
  getTotalApproveImgClassInProject,
  getLabelerStatusInProject,
  getReviewerStatusInProject,
  addNewLabelAttribute,
  getLabelAttribute,
  deleteAttribute,
  updateAttribute,
  updateProject,
  cloneDatasetsApi,
  // updateDataSetById,
  // deleteDatasetById,
  getVersionsDataSetSource,
  publicDatasetsApi,
  getListTagByProjectId,
  createTagSource,
  editMembersToProject,
  deleteMembersToProject,
  startGenerateModel,
  addNewClassification,
  updateClassification,
  deleteClassification,
  createClassAPI,
  updateClassAPI,
  deleteLabelAPI,
  getLabeListApi,
  assignTaskToMemberByDatasetApi,
  unassignTaskToMemberByDatasetApi
} from "./api";
import { getListDataset } from "redux/datasets/actions"

import { getListProjectsDetail as getListProjectsDetailAction } from "./actions";

import { socket } from "redux/socket/saga";

export function* watchGetListProjectsDetail() {
  yield takeEvery(GET_LIST_PROJECT_DETAIL, getListProjectsDetailFunction);
}

function* getListProjectsDetailFunction({ payload }) {
  const { selectedPageSize, currentPage } = payload;
  const errorMessage = "Get List Projects Detail Error!";
  const errorMessageId = "notification.get-list-projects-detail-error";
  try {
    const response = yield call(
      getListProjectsDetail,
      selectedPageSize,
      currentPage,
    );

    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status === true) {
        const { totalPage, totalItem, projects } = get(response, "data.data", {
          totalItem: 0,
          totalPage: 0,
          members: [],
        });
        yield put(getListProjectsDetailSuccess(totalPage, totalItem, projects));
      } else {
        yield put(getListProjectsDetailError(message));
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: message,
            messageId: null,
          }),
        );
      }
    } else {
      yield put(getListProjectsDetailError(errorMessage));
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(getListProjectsDetailError(errorMessage));
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId
      }),
    );
  }
}


export function* watchCreateNewDataset() {
  yield takeEvery(CREATE_NEW_DATASET, createNewDatasetSaga);
}

function* createNewDatasetSaga({ payload }) {
  const {
    projectId,
    datasetName,
    description,
    thumbnailPath,
    dataSource,
    classifyData,
  } = payload;
  const errorMessage = "Create new dataset error!";
  const errorMessageId = "notification.create-new-dataset-error";
  
  try {
    const response = yield call(
      createNewDataset,
      projectId,
      datasetName,
      description,
      thumbnailPath,
      dataSource,
      classifyData,
    );
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, datasetId, message } = get(response, "data");
      if (status === true) {
        yield put(getCurrentPricingPackage({}));
        yield put(createNewDatasetSuccess(status, datasetId, message));
        yield put(
          showNoti({
            type: SUCCESS_CASE,
            message: `The new dataset ${datasetName} is created successfully with ${dataSource.length} file(s)`,
            messageId: 'notification.new-dataset-created-successfully',
            intlValues: {
              datasetName,
              fileNumber: dataSource.length,
            }
          }),
        );
      } else {
        yield put(actionError(message));
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: message,
            messageId: null,
          }),
        );
      }
    } else {
      yield put(actionError(errorMessage));
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(actionError(errorMessage));
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

export function* watchGetSummaryProjects() {
  yield takeEvery(GET_SUMMARY_PROJECTS, getSummaryProjectsSaga);
}

function* getSummaryProjectsSaga({ payload }) {
  const { projectId } = payload;
  const errorMessage = "Get Summary Projects Error!";
  const errorMessageId = "notification.get-summary-projects-error";
  try {
    const response = yield call(getSummaryProjects, projectId);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status) {
        const { summary, totalItem } = get(response, "data.data", {
          totalItem: 0,
          summary: null,
        });
        yield put(getSummaryProjectsSuccess(totalItem, summary));
      } else {
        yield put(getSummaryProjectsError(message, null));
      }
    } else {
      yield put(getSummaryProjectsError(errorMessage, errorMessageId));
    }
  } catch (error) {
    yield put(getSummaryProjectsError(errorMessage, errorMessageId));
  }
}

export function* watchGetEmailList() {
  yield takeEvery(GET_EMAIL_LIST, getEmailListSaga);
}

function* getEmailListSaga({ payload }) {
  const errorMessage = "Get Project Email Error!";
  const errorMessageId = "notification.get-project-email-error";
  try {
    const response = yield call(getEmailList);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status) {
        const { data: mails } = get(response, "data", {
          mails: null,
        });
        yield put(getEmailListSuccess(mails, message));
      } else {
        yield put(getEmailListError(message, null));
      }
    } else {
      yield put(getEmailListError(errorMessage, errorMessageId));
    }
  } catch (error) {
    yield put(getEmailListError(errorMessage, errorMessageId));
  }
}

export function* watchGetRoleList() {
  yield takeEvery(GET_ROLE_LIST, getRoleListSaga);
}

function* getRoleListSaga({ payload }) {
  const errorMessage = "Get Project Role Error!";
  const errorMessageId = "notification.get-project-role-error";
  try {
    const response = yield call(getRoleList);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status) {
        const { data: roles } = get(response, "data", {
          roles: null,
        });
        yield put(getRoleListSuccess(roles, message));
      } else {
        yield put(getRoleListError(message, null));
      }
    } else {
      yield put(getRoleListError(errorMessage, errorMessageId));
    }
  } catch (error) {
    yield put(getRoleListError(errorMessage, errorMessageId));
  }
}

export function* watchGetAssignMemberList() {
  yield takeEvery(GET_ASSIGN_MEMBER_LIST, getAssignMemberListSaga);
}

function* getAssignMemberListSaga({ payload }) {
  const { projectId } = payload;
  const errorMessage = "Get Assign Members Error!";
  const errorMessageId = "notification.get-assign-members-error";
  try {
    const response = yield call(getAssignMemberList, projectId);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status) {
        const { data: members } = get(response, "data", {
          members: [],
        });
        yield put(getAssignMemberListSuccess(members, message));
      } else {
        yield put(getAssignMemberListError(message, null));
      }
    } else {
      yield put(getAssignMemberListError(errorMessage, errorMessageId));
    }
  } catch (error) {
    yield put(getAssignMemberListError(errorMessage, errorMessageId));
  }
}

export function* watchCreateNewProject() {
  yield takeEvery(CREATE_NEW_PROJECT, createNewProjectSaga);
}

function* createNewProjectSaga({ payload }) {
  const {
    projectName,
    dataType,
    projectDesc,
    imagePath,
    members,
    settingQuality,
    coverage,
    startTime,
    endTime,
    callback,
  } = payload;
  const errorMessage = "Failed to create new project! Please try again.";
  const errorMessageId = "notification.fail-to-create-new-project";
  try {
    const response = yield call(createNewProject, {
      projectName,
      dataType,
      projectDesc,
      imagePath,
      members,
      settingQuality,
      coverage,
      startTime,
      endTime,
    });
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message, data } = get(response, "data");
      if (status === true) {
        yield put(createNewProjectSuccess(data, message));
        yield put(
          showNoti({
            type: SUCCESS_CASE,
            message: message,
            messageId: null,
          }),
        );
      } else {
        yield put(actionError(message));
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: message,
            messageId: null,
          }),
        );
      }
    } else {
      yield put(actionError(errorMessage));
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(actionError(errorMessage));
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }

  if (callback) callback();
}

export function* watchGetProjectById() {
  yield takeEvery(GET_PROJECT_BY_ID, getProjectByIdSaga);
}

function* getProjectByIdSaga({ payload }) {
  const { projectId } = payload;
  const errorMessage = "Get Project Detail Error!";
  const errorMessageId = "notification.get-project-detail-error";
  try {
    const response = yield call(getProjectById, projectId);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status) {
        const { data: project } = get(response, "data", {
          project: null,
        });
        yield put(getProjectByIdSuccess({...project, listAllDatasets: project.listDatasets}, message));
      } else {
        yield put(getProjectByIdError(message, null));
      }
    } else {
      yield put(getProjectByIdError(errorMessage, errorMessageId));
    }
  } catch (error) {
    yield put(getProjectByIdError(errorMessage, errorMessageId));
  }
}

export function* watchGetFirstDatasetExistSource() {
  yield takeEvery(
    GET_FIRST_DATASET_EXIST_SOURCE,
    watchGetFirstDatasetExistSourceSaga,
  );
}

function* watchGetFirstDatasetExistSourceSaga({ payload }) {
  const { projectId } = payload;
  const errorMessage = "Get First Dataset Exist Source Error!";
  const errorMessageId = "notification.get-first-dataset-exist-source-error";
  try {
    const response = yield call(getFirstDatasetExistSource, projectId);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status) {
        const { dataset } = get(response, "data.data", {
          dataset: null,
        });
        yield put(
          getFirstDatasetExistSourceSuccess(dataset.dataset_id, message),
        );
      } else {
        yield put(getFirstDatasetExistSourceError(message, null));
      }
    } else {
      yield put(
        getFirstDatasetExistSourceError(errorMessage, errorMessageId),
      );
    }
  } catch (error) {
    yield put(
      getFirstDatasetExistSourceError(errorMessage, errorMessageId),
    );
  }
}

export function* watchGetCurrentProjectMembers() {
  yield takeEvery(GET_CURRENT_PROJECT_MEMBERS, getCurrentProjectMembersSaga);
}

function* getCurrentProjectMembersSaga({ payload }) {
  const { projectId, roleCode } = payload;
  const errorMessage = "Get Current Project Members Error!";
  const errorMessageId = "notification.get-current-project-members-error";
  try {
    const response = yield call(getCurrentProjectMembers, projectId, roleCode);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status) {
        const { users } = get(response, "data.data", {
          users: [],
        });
        yield put(getCurrentProjectMembersSuccess(users, message));
      } else {
        yield put(getCurrentProjectMembersError(message, null));
      }
    } else {
      yield put(
        getCurrentProjectMembersError(errorMessage, errorMessageId),
      );
    }
  } catch (error) {
    yield put(
      getCurrentProjectMembersError(errorMessage, errorMessageId),
    );
  }
}

export function* watchAssignTaskToMember() {
  yield takeEvery(ASSIGN_TASK_TO_MEMBER, assignTaskToMemberSaga);
}

function* assignTaskToMemberSaga({ payload }) {
  const { projectId, assignMembers } = payload;
  const errorMessage = "Assign task to member Error!";
  const errorMessageId = "notification.get-current-project-members-error";
  try {
    const response = yield call(
      assignTaskToMemberApi,
      projectId,
      assignMembers,
    );
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message, result } = get(response, "data");
      if (status) {
        const errorAssignTask = result.filter((item) => {
          return !item.status;
        });
        if (errorAssignTask.length === 0) {
          yield put(assignTaskToMemberSuccess(message));
        } else {
          yield put(assignTaskToMemberError(message, null, errorAssignTask));
        }
      } else {
        yield put(assignTaskToMemberError(message, null));
      }
    } else {
      yield put(assignTaskToMemberError(errorMessage, errorMessageId));
    }
  } catch (error) {
    yield put(assignTaskToMemberError(errorMessage, errorMessageId));
  }
}

export function* watchAssignTaskToMemberByDataset() {
  yield takeEvery(ASSIGN_TASK_TO_MEMBER_BY_DATASET, assignTaskToMemberByDatasetSaga);
}

function* assignTaskToMemberByDatasetSaga({ payload }) {
  const { projectId, assignMembers } = payload;
  const errorMessage = "Assign task to member Error!";
  const errorMessageId = "notification.get-current-project-members-error";
  try {
    const response = yield call(
      assignTaskToMemberByDatasetApi,
      projectId,
      assignMembers,
    );
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message, result } = get(response, "data");
      if (status) {
        const errorAssignTask = result.status
        if (errorAssignTask === true) {
          yield put(assignTaskToMemberSuccess(message));
          yield put(getListDataset({projectId: projectId}))
        } else {
          yield put(assignTaskToMemberError(message, null, errorAssignTask));
        }
      } else {
        yield put(assignTaskToMemberError(message, null));
      }
    } else {
      yield put(assignTaskToMemberError(errorMessage, errorMessageId));
    }
  } catch (error) {
    yield put(assignTaskToMemberError(errorMessage, errorMessageId));
  }
}

export function* watchUnassignTaskToMember() {
  yield takeEvery(UNASSIGN_TASK_TO_MEMBER, unassignTaskToMemberSaga);
}
function* unassignTaskToMemberSaga({ payload }) {
  const { projectId, assignMembers } = payload;
  const errorMessage = "Unassign task to member Error!";
  const errorMessageId = "notification.unassign-task-to-member-error";
  try {
    const response = yield call(
      unassignTaskToMemberApi,
      projectId,
      assignMembers,
    );
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message, result } = get(response, "data");
      if (status) {
        const errorAssignTask = result.filter((item) => {
          return !item.status;
        });
        if (errorAssignTask.length === 0) {
          yield put(unassignTaskToMemberSuccess(message));
        } else {
          yield put(unassignTaskToMemberError(message, null, errorAssignTask));
        }
      } else {
        yield put(unassignTaskToMemberError(message, null));
      }
    } else {
      yield put(unassignTaskToMemberError(errorMessage, errorMessageId));
    }
  } catch (error) {
    yield put(unassignTaskToMemberError(errorMessage, errorMessageId));
  }
}

export function* watchUnassignTaskToMemberByDataset() {
  yield takeEvery(UNASSIGN_TASK_TO_MEMBER_BY_DATASET, unassignTaskToMemberByDatasetSaga);
}
function* unassignTaskToMemberByDatasetSaga({ payload }) {
  const { projectId, assignMembers } = payload;
  const errorMessage = "Unassign task to member Error!";
  const errorMessageId = "notification.unassign-task-to-member-error";
  try {
    const response = yield call(
      unassignTaskToMemberByDatasetApi,
      projectId,
      assignMembers,
    );
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message, result } = get(response, "data");
      if (status) {
        const errorAssignTask = result.status
        if (errorAssignTask === true) {
          yield put(unassignTaskToMemberSuccess(message));
          yield put(getListDataset({projectId: projectId}))
        } else {
          yield put(unassignTaskToMemberError(message, null, errorAssignTask));
        }
      } else {
        yield put(unassignTaskToMemberError(message, null));
      }
    } else {
      yield put(unassignTaskToMemberError(errorMessage, errorMessageId));
    }
  } catch (error) {
    yield put(unassignTaskToMemberError(errorMessage, errorMessageId));
  }
}

export function* watchGetLabelList() {
  yield takeEvery(GET_LABEL_LIST, getLabelListSaga);
}
function* getLabelListSaga({ payload }) {
  const { projectId, currentPage, pageSize } = payload;
  const errorMessage = "Get Project Labels Error!";
  const errorMessageId = "notification.get-project-labels-error";
  try {
    const response = yield call(getLabeListApi, {
      projectId,
      currentPage,
      pageSize,
    });
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status) {
        const {
          data: labels,
          totalItem,
          totalPage,
        } = get(response, "data", {
          labels: [],
          totalItem: 0,
          totalPage: 0,
        });
        yield put(
          getLabelListSuccess({ labels, message, totalItem, totalPage }),
        );
      } else {
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: message,
            messageId: null,
          }),
        );
      }
    } else {
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

export function* watchGetProjectLabels() {
  yield takeEvery(GET_PROJECT_LABELS, getProjectLabelsSaga);
}
function* getProjectLabelsSaga({ payload }) {
  const { projectId } = payload;
  const errorMessage = "Get Project Labels Error!";
  const errorMessageId = "notification.get-project-labels-error";
  try {
    const response = yield call(getProjectLabels, projectId);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status) {
        const { data: labels } = get(response, "data", {
          labels: [],
          totalItem: 0,
          totalPage: 0,
        });
        yield put(getProjectLabelsSuccess({ labels, message }));
      } else {
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: message,
            messageId: null,
          }),
        );
      }
    } else {
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

/* classification */
export function* watchGetProjectClassifications() {
  yield takeEvery(GET_PROJECT_CLASSIFICATIONS, getProjectClassificationsSaga);
}
function* getProjectClassificationsSaga({ payload }) {
  const { projectId } = payload;
  const errorMessage = "Get Project Classifications Error!";
  const errorMessageId = "notification.get-project-classifications-error";
  try {
    const response = yield call(getProjectClassifications, projectId);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status) {
        const { data: classifications } = get(response, "data", {
          classifications: [],
        });
        yield put(getProjectClassificationsSuccess(classifications, message));
      } else {
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: message,
            messageId: null,
          }),
        );
      }
    } else {
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

export function* watchAddNewClassifiCation() {
  yield takeEvery(ADD_NEW_CLASSIFICATION, addNewClassificationSaga);
}
function* addNewClassificationSaga({ payload }) {
  const { projectId, name, dataType, items } = payload;
  const errorMessage = "Add New Classification Error!";
  const errorMessageId = "notification.add-new-classification-error";
  try {
    const response = yield call(
      addNewClassification,
      projectId,
      name,
      dataType,
      items,
    );
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, data, message } = get(response, "data", { data: null });
      if (status === true) {
        yield put(addNewClassificationSuccess(data, message));
        yield put(
          showNoti({
            type: SUCCESS_CASE,
            message: message,
            messageId: null,
          }),
        );
      } else {
        yield put(actionError(message));
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: message,
            messageId: null,
          }),
        );
      }
    } else {
      yield put(actionError(errorMessage));
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(actionError(errorMessage));
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

export function* watchUpdateClassifiCation() {
  yield takeEvery(UPDATE_CLASSIFICATION, updateClassificationSaga);
}
function* updateClassificationSaga({ payload }) {
  const { projectId, name, dataType, items, classificationId } = payload;
  const errorMessage = "Update Classification Error!";
  const errorMessageId = "notification.update-classification-error";
  try {
    const response = yield call(
      updateClassification,
      projectId,
      classificationId,
      name,
      dataType,
      items,
    );
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, data, message } = get(response, "data", { data: null });
      if (status === true) {
        yield put(updateClassificationSuccess(data, message));
        yield put(
          showNoti({
            type: SUCCESS_CASE,
            message: message,
            messageId: null,
          }),
        );
      } else {
        yield put(actionError(message));
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: message,
            messageId: null,
          }),
        );
      }
    } else {
      yield put(actionError(errorMessage));
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(actionError(errorMessage));
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

export function* watchDeleteClassifiCation() {
  yield takeEvery(DELETE_CLASSIFICATION, deleteClassificationSaga);
}
function* deleteClassificationSaga({ payload }) {
  const { classificationId } = payload;
  const errorMessage = "Delete Classification Error!";
  const errorMessageId = "notification.delete-classification-error";
  try {
    const response = yield call(deleteClassification, classificationId);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, data, message } = get(response, "data", { data: null });
      if (status === true) {
        yield put(deleteClassificationSuccess(data, message));
        yield put(
          showNoti({
            type: SUCCESS_CASE,
            message: message,
            messageId: null,
          }),
        );
      } else {
        yield put(actionError(message));
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: message,
            messageId: null,
          }),
        );
      }
    } else {
      yield put(actionError(errorMessage));
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(actionError(errorMessage));
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}
/* end - classification*/

export function* watchGetModuleByRoleInProject() {
  yield takeEvery(
    GET_MODULE_BY_ROLE_IN_PROJECT,
    watchGetModuleByRoleInProjectSaga,
  );
}

function* watchGetModuleByRoleInProjectSaga({ payload }) {
  const { projectId } = payload;
  const errorMessage = "Get Module By Role In Project Error!";
  const errorMessageId = "notification.get-module-by-role-error";
  try {
    const response = yield call(getModuleByRoleInProject, projectId);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status) {
        const { data } = get(response, "data", {
          dataset: null,
        });
        yield put(getModuleByRoleInProjectSuccess(data, message));
      } else {
        yield put(getModuleByRoleInProjectError(message, null));
      }
    } else {
      yield put(
        getModuleByRoleInProjectError(errorMessage, errorMessageId),
      );
    }
  } catch (error) {
    yield put(
      getModuleByRoleInProjectError(errorMessage, errorMessageId),
    );
  }
}

export function* watchDeleteProjectById() {
  yield takeEvery(DELETE_PROJECT_BY_ID, watchDeleteProjectByIdSaga);
}

function* watchDeleteProjectByIdSaga({ payload }) {
  const { projectId, selectedPageSize, currentPage } = payload;
  const errorMessage = "Delete Project Error!";
  const errorMessageId = "notification.delete-project-error";
  const successMessage = "This project is deleted successfully";
  const successMessageId = "notification.delete-project-success";
  const currentProject = yield select(selectProjectByProjectId(projectId));
  const projectType = get(currentProject, "data_type", "");

  try {
    const response = yield call(deleteProjectbyId, { projectId, projectType });
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");

      if (status) {
        yield put(deleteProjectByIdSuccess(projectId));
        yield put(getCurrentPricingPackage({}));
        yield put(getListProjectsDetailAction(selectedPageSize, currentPage));
        yield put(
          showNoti({
            type: SUCCESS_CASE,
            message: successMessage,
            messageId: successMessageId,
          }),
        );
      } else {
        yield put(actionError(message));
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: message,
            messageId: null,
          }),
        );
      }
    } else {
      yield put(actionError(errorMessage));
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(actionError(errorMessage));
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

export function* watchExportDataset() {
  yield takeEvery(EXPORT_DATASET, exportDatasetSaga);
}

function* exportDatasetSaga({ payload, callback }) {
  const { status: statusExported } = payload;
  const errorMessage = "Export Dataset List Error!";
  const errorMessageId = "notification.export-dataset-list-error";
  const processingMessage = "Your data export job is being processed. We will notify you when it's done.";
  const processingMessagedId = "notification.your-export-job-is-being-processed";
  try {
    const response = yield call(exportDataset, payload);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status === true) {
        const { exportHistoryId: exportDatasetId } = get(response, "data", {
          exportDatasetId: null,
        });
        yield put(
          exportDatasetSuccess(
            exportDatasetId,
            processingMessage,
          ),
        );
        yield put(
          showNoti({
            type: SUCCESS_CASE,
            message: processingMessage,
            messageId: processingMessagedId,
          }),
        );
        callback && callback();
      } else {
        yield put(actionError(message));
        yield put(
          showNoti({
            type: ERROR_CASE,
            message,
            messageId: null,
          }),
        );
        callback && callback(true);
      }
    } else {
      yield put(actionError(errorMessage));
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
      callback && callback(true);
    }
  } catch (error) {
    yield put(actionError(errorMessage));
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
    callback && callback(true);
  }
}

export function* watchGetExportDataset() {
  yield takeEvery(GET_EXPORT_DATASET, getExportDatasetSaga);
}

function* getExportDatasetSaga({ payload }) {
  const { projectId, selectedPageSize, currentPage, status } = payload;
  const errorMessage = "Get Export Dataset List Error!";
  const errorMessageId = "notification.get-export-dataset-list-error";
  try {
    const response = yield call(
      getExportDataset,
      projectId,
      selectedPageSize,
      currentPage,
      status,
    );
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status === true) {
        const {
          totalPage,
          totalItem,
          exportHistory: exportDatasetList,
        } = get(response, "data.data", {
          totalItem: 0,
          totalPage: 0,
          exportDatasetList: [],
        });
        yield put(
          getExportDatasetSuccess(
            exportDatasetList,
            totalPage,
            totalItem,
            "Get Export Dataset List Success",
          ),
        );
      } else {
        yield put(getExportDatasetError(message));
      }
    } else {
      yield put(getExportDatasetError(errorMessage));
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(getExportDatasetError(errorMessage));
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

export function* watchPrepareCustomerData() {
  yield takeEvery(PREPARE_CUSTOMER_DATA, watchPrepareCustomerDataSaga);
}

function* watchPrepareCustomerDataSaga({ payload }) {
  const { projectId } = payload;
  const errorMessage = "Prepare Customer Data Error!";
  const errorMessageId = "notification.prepare-customer-data-error";
  try {
    const response = yield call(prepareCustomerData, projectId);

    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message, data } = get(response, "data");
      if (status) {
        yield put(prepareCustomerDataSuccess(data, message));
      } else {
        yield put(prepareCustomerDataError(message, null));
      }
    } else {
      yield put(prepareCustomerDataError(errorMessage, errorMessageId));
    }
  } catch (error) {
    yield put(prepareCustomerDataError(errorMessage, errorMessageId));
  }
}

export function* watchGetSourceStatus() {
  yield takeEvery(GET_SOURCE_STATUS, watchGetSourceStatusSaga);
}

function* watchGetSourceStatusSaga({ payload }) {
  const errorMessage = "Get Source Status Error!";
  const errorMessageId = "notification.get-source-status-error";
  try {
    const response = yield call(getSourceStatus);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status) {
        const { data } = get(response, "data");
        yield put(getSourceStatusSuccess(data, message));
      } else {
        yield put(getSourceStatusError(message, null));
      }
    } else {
      yield put(getSourceStatusError(errorMessage, errorMessageId));
    }
  } catch (error) {
    yield put(getSourceStatusError(errorMessage, errorMessageId));
  }
}

export function* watchDatasetAddMoreData() {
  yield takeEvery(DATASET_ADD_MORE_DATA, datasetAddMoreDataSaga);
}

function* datasetAddMoreDataSaga({ payload }) {
  const { projectId, datasetId, dataSource, classifyData } = payload;
  const errorMessage = "Add More Data Error!";
  const errorMessageId = "notification.add-more-data-error";
  try {
    const response = yield call(
      datasetAddMoreData,
      projectId,
      datasetId,
      dataSource,
      classifyData,
    );
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message, sourcesError } = get(response, "data");
      if (status === true) {
        yield put(getCurrentPricingPackage({}));
        yield put(
          datasetAddMoreDataSuccess("Add More Data Success", sourcesError),
        );
        yield put(
          showNoti({
            type: SUCCESS_CASE,
            message: message,
            messageId: null,
          }),
        );
      } else {
        yield put(actionError(message));
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: message,
            messageId: null,
          }),
        );
      }
    } else {
      yield put(actionError(errorMessage));
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(actionError(errorMessage));
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

export function* watchInviteMembersToProject() {
  yield takeEvery(INVITE_MEMBERS_TO_PROJECT, watchInviteMembersToProjectSaga);
}

function* watchInviteMembersToProjectSaga({ payload }) {
  const { projectId, assignMembers, cb } = payload;
  const errorMessage = "Invite Member To Project Error!";
  const errorMessageId = "notification.invite-member-to-project-error";
  try {
    const response = yield call(
      inviteMembersToProject,
      projectId,
      assignMembers,
    );
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message, data } = get(response, "data");
      if (status) {
        yield put(inviteMembersToProjectSuccess(projectId, data));
        if (cb) cb();
        yield put(
          showNoti({
            type: SUCCESS_CASE,
            message: message,
            messageId: null,
          }),
        );
      } else {
        yield put(actionError(message));
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: message,
            messageId: null,
          }),
        );
      }
    } else {
      yield put(actionError(errorMessage));
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(actionError(errorMessage));
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

export function* watchResetSourceStatus() {
  yield takeEvery(RESET_SOURCE_STATUS, watchResetSourceStatusSaga);
}
export function* watchEditMembersToProject() {
  yield takeEvery(EDIT_MEMBERS_TO_PROJECT, doEditMembersToProjectSaga);
}
function* doEditMembersToProjectSaga({ payload }) {
  const { projectId, memberId, roleCode, cb } = payload;
  const errorMessage = "Edit Member To Project Error!";
  const errorMessageId = "notification.edit-member-to-project-error";
  const messageId = "notification.edit-member-to-project";
  try {
    const response = yield call(
      editMembersToProject,
      projectId,
      memberId,
      roleCode,
    );
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message, data } = get(response, "data");
      if (status) {
        yield put(editMembersToProjectSuccess(projectId, data));
        if (cb) cb();
        yield put(
          showNoti({
            type: SUCCESS_CASE,
            message: message,
            messageId: messageId,
          }),
        );
      } else {
        yield put(actionError(message));
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: message,
            messageId: null,
          }),
        );
      }
    } else {
      yield put(actionError(errorMessage));
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(actionError(errorMessage));
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}
export function* watchDeleteMembersToProject() {
  yield takeEvery(DELETE_MEMBERS_TO_PROJECT, doDeleteMembersToProjectSaga);
}
function* doDeleteMembersToProjectSaga({ payload }) {
  const { projectId, memberId, cb } = payload;
  const errorMessage = "Delete Member To Project Error!";
  const errorMessageId = "notification.delete-member-in-project-error";
  const messageId = "notification.delete-member-in-project";
  try {
    const response = yield call(deleteMembersToProject, projectId, memberId);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message, data } = get(response, "data");
      if (status) {
        yield put(deleteMembersToProjectSuccess(projectId, data));
        if (cb) cb();
        yield put(
          showNoti({
            type: SUCCESS_CASE,
            message: message,
            messageId: messageId,
          }),
        );
      } else {
        yield put(actionError(message));
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: message,
            messageId: null,
          }),
        );
      }
    } else {
      yield put(actionError(errorMessage));
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(actionError(errorMessage));
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}
function* watchResetSourceStatusSaga({ payload }) {
  const { projectId, datasetId, oldStatus, newStatus, sourceIds } = payload;
  const errorMessage = "Reset Source Status Error!";
  const errorMessageId = "notification.reset-source-status-error";
  try {
    const response = yield call(
      resetSourceStatus,
      projectId,
      datasetId,
      oldStatus,
      newStatus,
      sourceIds,
    );
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status) {
        yield put(resetSourceStatusSuccess(message));
      } else {
        yield put(resetSourceStatusError(message, null));
      }
    } else {
      yield put(resetSourceStatusError(errorMessage, errorMessageId));
    }
  } catch (error) {
    yield put(resetSourceStatusError(errorMessage, errorMessageId));
  }
}

export function* watchGetTotalApproveAnnotationOfEachClassInProject() {
  yield takeEvery(
    GET_TOTAL_APPROVE_ANNOTATION_EACH_CLASS_IN_PROJECT,
    getTotalApproveAnnotationOfEachClassInProjectSaga,
  );
}

function* getTotalApproveAnnotationOfEachClassInProjectSaga({ payload }) {
  const { projectId } = payload;
  const errorMessage = "Get total annotation for each Class of Project Error!";
  const errorMessageId = "notification.get-total-annotation-for-each-class-error";
  try {
    const response = yield call(
      getTotalApproveAnnotationOfEachClassInProject,
      projectId,
    );
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status) {
        const { data: totalApproveAnnotation } = get(response, "data", {
          totalApproveAnnotation: null,
        });
        yield put(
          getTotalApproveAnnotationEachClassInProjectSuccess(
            totalApproveAnnotation,
            message,
          ),
        );
      } else {
        yield put(getTotalApproveAnnotationEachClassInProjectError(message, null));
      }
    } else {
      yield put(
        getTotalApproveAnnotationEachClassInProjectError(errorMessage, errorMessageId),
      );
    }
  } catch (error) {
    yield put(
      getTotalApproveAnnotationEachClassInProjectError(errorMessage, errorMessageId),
    );
  }
}

export function* watchGetTotalApproveImgClassInProject() {
  yield takeEvery(
    GET_TOTAL_APPROVE_IMG_CLASS_IN_PROJECT,
    getTotalApproveImgClassInProjectSaga,
  );
}

function* getTotalApproveImgClassInProjectSaga({ payload }) {
  const { projectId, datasetIds, status } = payload;
  try {
    const response = yield call(getTotalApproveImgClassInProject, projectId, datasetIds, status);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status) {
        const { data: totalApproveImg } = get(response, "data", {
          totalApproveImg: null,
        });
        yield put(
          getTotalApproveImgClassInProjectSuccess(totalApproveImg, message),
        );
      } else {
        yield put(getTotalApproveImgClassInProjectError(message));
      }
    } else {
      yield put(
        getTotalApproveImgClassInProjectError(
          "Get total images for each Class of Project Error!",
        ),
      );
    }
  } catch (error) {
    yield put(
      getTotalApproveImgClassInProjectError(
        "Get total images for each Class of Project Error!",
      ),
    );
  }
}

export function* watchGetLabelerStatusInProject() {
  yield takeEvery(GET_LABELER_STATUS_IN_PROJECT, getLabelerStatusInProjectSaga);
}

function* getLabelerStatusInProjectSaga({ payload }) {
  const { projectId, labelerId, isLabeler } = payload;
  try {
    const response = isLabeler
      ? yield call(getLabelerStatusInProject, projectId, labelerId)
      : yield call(getReviewerStatusInProject, projectId, labelerId);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status) {
        const { data: labelerStatus } = get(response, "data", {
          labelerStatus: null,
        });
        yield put(getLabelerStatusInProjectSuccess(labelerStatus, message));
      } else {
        yield put(getLabelerStatusInProjectError(message));
      }
    } else {
      yield put(
        getLabelerStatusInProjectError("Get Labeler status of Project Error!"),
      );
    }
  } catch (error) {
    yield put(
      getLabelerStatusInProjectError("Get Labeler status of Project Error!"),
    );
  }
}

export function* watchAddNewLabelAttribute() {
  yield takeEvery(ADD_NEW_LABEL_ATTRIBUTE, addNewLabelAttributeSaga);
}

function* addNewLabelAttributeSaga({ payload }) {
  const { projectId, labelId, name, dataType, defaultValue } = payload;
  const errorMessage = "Add New Label Attribute Error!";
  const errorMessageId = "notification.add-new-label-attribute-error";
  try {
    const response = yield call(
      addNewLabelAttribute,
      projectId,
      labelId,
      name,
      dataType,
      defaultValue,
    );
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, data, message } = get(response, "data", { data: null });
      if (status === true) {
        yield put(addNewLabelAttributeSuccess(data, message));
        yield put(
          showNoti({
            type: SUCCESS_CASE,
            message: message,
            messageId: null,
          }),
        );
      } else {
        yield put(actionError(message));
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: message,
            messageId: null,
          }),
        );
      }
    } else {
      yield put(actionError(errorMessage));
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(actionError(errorMessage));
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

export function* watchGetLabelAttribute() {
  yield takeEvery(GET_LABEL_ATTRIBUTE, getLabelAttributeSaga);
}
function* getLabelAttributeSaga({ payload }) {
  const { labelId } = payload;
  try {
    const response = yield call(getLabelAttribute, labelId);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status) {
        const { data: attribute } = get(response, "data", {
          attribute: [],
        });
        yield put(getLabelAttributeSuccess(attribute, message));
      } else {
        yield put(getLabelAttributeError(message, null));
      }
    } else {
      yield put(getLabelAttributeError("Get Label Attribute Error!", "notification.get-label-attribute-error"));
    }
  } catch (error) {
    yield put(getLabelAttributeError(error, null));
  }
}

export function* watchDeleteAttribute() {
  yield takeEvery(DELETE_ATTRIBUTE, deleteAttributeSaga);
}
function* deleteAttributeSaga({ payload }) {
  const { projectId, labelId, attributeId } = payload;
  const errorMessage = "Delete Attribute Error!";
  const errorMessageId = "delete-attribute-error";
  try {
    const response = yield call(deleteAttribute, projectId, attributeId);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status) {
        yield put(deleteAttributeSuccess(labelId, attributeId, message));
        yield put(
          showNoti({
            type: SUCCESS_CASE,
            message: message,
            messageId: null,
          }),
        );
      } else {
        yield put(actionError(message));
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: message,
            messageId: null,
          }),
        );
      }
    } else {
      yield put(actionError(errorMessage));
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(actionError(errorMessage));
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

export function* watchUpdateAttribute() {
  yield takeEvery(UPDATE_ATTRIBUTE, updateAttributeSaga);
}
function* updateAttributeSaga({ payload }) {
  const { projectId, attributeId, name, dataType, defaultValue } = payload;
  const errorMessage = "Update Attribute Error!";
  const errorMessageId = "notification.update-attribute-error";
  try {
    const response = yield call(
      updateAttribute,
      projectId,
      attributeId,
      name,
      dataType,
      defaultValue,
    );
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message, data } = get(response, "data");
      if (status) {
        yield put(updateAttributeSuccess(data, message));
        yield put(
          showNoti({
            type: SUCCESS_CASE,
            message: message,
            messageId: null,
          }),
        );
      } else {
        yield put(updateAttributeError(message));
        yield put(actionError(message));
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: message,
            messageId: null,
          }),
        );
      }
    } else {
      yield put(actionError(errorMessage));
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(actionError(errorMessage));
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

export function* watchUpdateProject() {
  yield takeEvery(UPDATE_PROJECT, updateProjectSaga);
}
function* updateProjectSaga({ payload }) {
  const {
    projectId,
    projectName,
    description,
    dataType,
    target,
    projectThumbnail,
    startTime,
    endTime,
  } = payload;
  const errorMessage = "Update Project Error!";
  const errorMessageId = "notification.update-project-error";
  try {
    const response = yield call(
      updateProject,
      projectId,
      projectName,
      description,
      dataType,
      target,
      projectThumbnail,
      startTime,
      endTime,
    );
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, data } = get(response, "data");
      if (status) {
        yield put(updateProjectSuccess(data, "Update project successfully!"));
        yield put(
          showNoti({
            type: SUCCESS_CASE,
            message: "Update project successfully!",
            messageId: "notification.update-project-successfully",
          }),
        );
      } else {
        yield put(actionError(errorMessage, errorMessageId));
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: errorMessage,
            messageId: errorMessageId,
          }),
        );
      }
    } else {
      yield put(actionError(errorMessage, errorMessageId));
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(actionError(errorMessage, errorMessageId));
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

function* cloneDatasetsSaga({ payload }) {
  const successMessage = "Clone dataset successfully";
  const successMessageId = "notification.clone-dataset-success";
  const errorMessage = "Failed to clone dataset! Please try again.";
  const errorMessageId = "notification.clone-dataset-error";

  try {
    const response = yield call(cloneDatasetsApi, payload);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, data, message } = get(response, "data", { data: null });
      if (status === true) {
        console.log(data);
        yield put(cloneDatasetsSuccess(payload, successMessage));
        yield put(getCurrentPricingPackage({}));
        yield put(
          showNoti({
            type: SUCCESS_CASE,
            message: successMessage,
            messageId: successMessageId,
          }),
        );
      } else {
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: message,
            messageId: null,
          }),
        );
      }
    } else {
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}
function* publicDatasetsSaga({ payload }) {
  const successMessage = "Public dataset successfully";
  const successMessageId = "notification.public-dataset-success";
  const errorMessage = "Failed to public dataset! Please try again.";
  const errorMessageId = "notification.public-dataset-error";

  try {
    const response = yield call(publicDatasetsApi, payload);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, data, message } = get(response, "data", { data: null });
      if (status === true) {
        console.log(data);
        yield put(
          showNoti({
            type: SUCCESS_CASE,
            message: successMessage,
            messageId: successMessageId,
          }),
        );
      } else {
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: message,
            messageId: null,
          }),
        );
      }
    } else {
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

export function* watchGetVersionsDataSetSource() {
  yield takeEvery(GET_VERSIONS_DATASETS_SOURCE, getVersionsDatasetSourceSaga);
}
function* getVersionsDatasetSourceSaga({ payload, callback }) {
  const errorMessage = "Get versions dataset source Error!";
  const errorMessageId = "notification.get-version-dataset-source-error";
  const { projectId, datasetId } = payload;
  try {
    const response = yield call(getVersionsDataSetSource, projectId, datasetId);
    const statusCode = get(response, "status", "");
    if (statusCode === 200 && callback) {
      const { data } = get(response, "data");
      callback(data);
    } else {
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

// export function* watchGetListTagByProjectId() {
//   yield takeEvery(GET_TAGS_BY_PROJECT_ID, getListTagByProjectIdSaga);
// }
function* getListTagByProjectIdSaga({ payload, callback }) {
  console.log(payload);
  const errorMessage = "Get tags Error!";
  const errorMessageId = "notification.get-tags-error";
  try {
    const response = yield call(getListTagByProjectId, payload);
    const statusCode = get(response, "status", "");
    if (statusCode === 200 && callback) {
      const { data } = get(response, "data");
      callback(data);
    } else {
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}
export function* watchCreateNewTagSource() {
  yield takeEvery(CREATE_NEW_TAG_SOURCE, createNewTagSourceSaga);
}
function* createNewTagSourceSaga({ payload, callback }) {
  const errorMessage = "Create new tags Error!";
  const errorMessageId = "notification.create-new-tags-error";
  try {
    const response = yield call(createTagSource, payload);
    const statusCode = get(response, "status", "");
    const message = get(response, "message", "");
    if (statusCode === 200 && callback) {
      const { data } = get(response, "data");
      yield put(
        showNoti({
          type: SUCCESS_CASE,
          message: message,
          messageId: null,
        }),
      );
      console.log(response);
      callback(data);
    } else {
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}


function* generateModelSaga({ payload }) {
  yield call(startGenerateModel, socket, payload);
}

function* addClassSaga({ payload }) {
  const { classData, attributeData, callback, finishHandle } = payload;
  try {
    const classResponse = yield createClassAPI({
      ...classData,
      attributes: attributeData,
    });
    const isSuccess = get(classResponse, ["data", "status"], false);
    const msg = get(classResponse, ["data", "message"], "");
    if (isSuccess) {
      const newClass = get(classResponse, ["data", "data"], {});
      yield put(addClassSuccess(newClass));
      yield put(showNoti({ type: SUCCESS_CASE, message: msg, messageId: null }));
      callback && callback();
    } else {
      yield put(addClassFailure());
      yield put(showNoti({ type: ERROR_CASE, message: msg, messageId: null }));
    }
    finishHandle && finishHandle();
  } catch (exc) {
    yield put(addClassFailure());
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: "Cannot create new class setting!",
        messageId: "notification.cannot-create-new-class-setting"
      }),
    );
    finishHandle && finishHandle();
  }
}

function* updateClassSaga({ payload }) {
  const {
    classData,
    newAttributes,
    editedAttributes,
    deletedAttributes,
    callback,
    finishHandle,
  } = payload;
  try {
    const classResponse = yield updateClassAPI({
      ...classData,
      attributes: newAttributes,
      updateAttributes: editedAttributes,
      deletedAttributes,
    });

    const isSuccess = get(classResponse, ["data", "status"], false);
    const msg = get(classResponse, ["data", "message"], "");
    if (isSuccess) {
      const updatedClass = get(classResponse, ["data", "data"], {});
      yield put(updateClassSuccess(updatedClass));
      yield put(showNoti({ type: SUCCESS_CASE, message: msg, messageId: null }));
      callback && callback();
    } else {
      yield put(updateClassFailure());
      yield put(showNoti({ type: ERROR_CASE, message: msg, messageId: null }));
    }
    finishHandle && finishHandle();
  } catch (exc) {
    yield put(updateClassFailure());
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: "Cannot create new class setting!",
        messageId: "notification.cannot-create-new-class-setting",
      }),
    );
    finishHandle && finishHandle();
  }
}

function* deleteClassSaga({ payload }) {
  try {
    const { id, projectId, callback } = payload;
    const classResponse = yield deleteLabelAPI({
      id,
      projectId,
    });

    const isSuccess = get(classResponse, ["data", "status"], false);
    const msg = get(classResponse, ["data", "message"], "");
    if (isSuccess) {
      yield put(deleteClassSuccess({ id }));
      yield put(
        showNoti({ type: SUCCESS_CASE, message: "Delete class successfully", messageId: "notification.delete-class-success" }),
      );
      callback && callback();
    } else {
      yield put(deleteClassFailure());
      yield put(showNoti({ type: ERROR_CASE, message: msg }));
    }
  } catch (exc) {
    yield put(deleteClassFailure());
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: "Cannot create new class setting!",
        messageId: "notification.cannot-create-new-class-setting",
      }),
    );
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetListProjectsDetail),
    // fork(watchGetListDatasetDetail),
    fork(watchCreateNewDataset),
    fork(watchGetSummaryProjects),
    fork(watchGetEmailList),
    fork(watchGetRoleList),
    fork(watchGetAssignMemberList),
    fork(watchCreateNewProject),
    fork(watchGetProjectById),
    fork(watchGetFirstDatasetExistSource),
    fork(watchGetCurrentProjectMembers),
    fork(watchAssignTaskToMember),
    fork(watchUnassignTaskToMember),
    fork(watchGetProjectLabels),
    fork(watchGetProjectClassifications),
    fork(watchDeleteProjectById),
    fork(watchGetModuleByRoleInProject),
    takeLatest(ADD_CLASS, addClassSaga),
    fork(watchExportDataset),
    fork(watchGetExportDataset),
    fork(watchPrepareCustomerData),
    fork(watchGetSourceStatus),
    fork(watchDatasetAddMoreData),
    fork(watchInviteMembersToProject),
    fork(watchResetSourceStatus),
    fork(watchGetTotalApproveAnnotationOfEachClassInProject),
    fork(watchGetTotalApproveImgClassInProject),
    fork(watchGetLabelerStatusInProject),
    takeLatest(UPDATE_CLASS, updateClassSaga),
    takeLatest(DELETE_CLASS, deleteClassSaga),
    takeEvery(CLONE_DATASETS, cloneDatasetsSaga),
    takeEvery(PUBLIC_DATASET, publicDatasetsSaga),
    fork(watchAddNewLabelAttribute),
    fork(watchGetLabelAttribute),
    fork(watchDeleteAttribute),
    fork(watchUpdateAttribute),
    fork(watchUpdateProject),
    // fork(watchUpdateDataSetById),
    // fork(watchDeleteDataSetById),
    fork(watchGetVersionsDataSetSource),
    // fork(watchGetListTagByProjectId),
    fork(watchCreateNewTagSource),
    fork(watchEditMembersToProject),
    fork(watchDeleteMembersToProject),
    // takeEvery(DELETE_DATASETS, deleteDatasetsSaga),
    takeEvery(GENERATE_MODEL, generateModelSaga),
    fork(watchAddNewClassifiCation),
    fork(watchUpdateClassifiCation),
    fork(watchDeleteClassifiCation),
    fork(watchGetLabelList),
    fork(watchAssignTaskToMemberByDataset),
    fork(watchUnassignTaskToMemberByDataset),
  ]);
}
