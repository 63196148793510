/* eslint-disable */
import {
  GET_ISSUE_LIST_SUCCESS,
  GET_ISSUE_LIST,
  GET_ISSUE_LIST_ERROR
} from "redux/actions";

export const INIT_STATE = {
  issueList: [],
  pagination: {
    pageSize: 10,
    currentPage: 0,
    totalItem: 0,
    totalPage: 1
  },
  loading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ISSUE_LIST:
      return {
        ...state,
        loading: true
      };
    case GET_ISSUE_LIST_SUCCESS:
      return {
        ...state,
        issueList: action.payload.data,
        pagination: action.payload.pagination,
        loading: false
      };
    case GET_ISSUE_LIST_ERROR:
      return {
        ...state,
        issueList: [],
        pagination: INIT_STATE.pagination,
        loading: false
      };
    default:
      return state;
  }
}
