import { datasetDetailPageSizes } from "constants/defaultValues";
import axios from "../api";

export function getListDatasetDetailApi({
  datasetId,
  pageSize  = datasetDetailPageSizes[0],
  currentPage = 1,
  keywords = "",
  searchAdvanced = null,
  versionId = 0,
  isBenchmark,
  projectId = "",
}) {
  let query = `/dataset/get-dataset-details?dataset_id=${datasetId}&pageSize=${pageSize}&currentPage=${currentPage}&keywords=${keywords}&versionId=${versionId}&projectId=${projectId}`;
  if (typeof isBenchmark === 'boolean') {
    query += `&isBenchmark=${isBenchmark}`;
  }
  return axios.post(query, { search: searchAdvanced });
}

export function deleteDatasetDetailsApi({ labelSources, projectType }) {
  return axios.post("/dataset/delete-dataset-detail", {
    labelSources,
    projectType,
  });
}

export function getAllListDatasetDetailApi({
  datasetIds,
}) {
  let query = `/dataset/get-all-dataset-details?dataset_id=${datasetIds}`;
  return axios.post(query);
}
