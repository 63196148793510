import axios from "../api";

export const getTagsBySourceId = ({ sourceIds }) =>
  axios
    .post(`/tag/getTagRecommendBySourceId`, {
      sourceIds,
    })
    .catch((error) => ({ error, status: 404 }));

export const getTagsByProjectId = ({ projectId }) =>
  axios
    .post(`/tag/getTagRecommendByProjectId`, {
      projectId,
    })
    .catch((error) => ({ error, status: 404 }));

export const deleteTagOfSourceAPI = ({ projectId, ...payload }) =>
  axios
    .post(`/tag/removeTagFromLabelSource`, {
      ...payload,
      projectId: Number(projectId),
    })
    .catch((error) => ({ error, status: 404 }));

export const deleteTagOfProjectAPI = ({ projectId, ...payload }) =>
  axios
    .post(`/tag/removeTagFromProject`, {
      ...payload,
      projectId: Number(projectId),
    })
    .catch((error) => ({ error, status: 404 }));

export const addTagToSourceAPI = ({ projectId,tags, ...payload }) =>
  axios
    .post(`/tag/addTagToLabelSource`, {
      ...payload,
      projectId: Number(projectId),
      tags: tags?.map(item=> Number(item))
    })
    .catch((error) => ({ error, status: 404 }));

export const searchProjectTagByTextAPI = ({ projectId, textSearch }) =>
  axios
    .post(`/tag/searchProjectTagByName`, {
      textSearch,
      projectId: Number(projectId),
    })
    .catch((error) => ({ error, status: 404 }));

export const createProjectTagAPI = ({ projectId, newtag }) =>
  axios
    .post(`/tag/addNewTag`, {
      newtag ,
      projectId: Number(projectId),
    })
    .catch((error) => ({ error, status: 404 }));
