/* eslint-disable no-console */
// import React from "react";
import {
  all,
  fork,
  take,
  put,
  race,
  call,
  cancelled,
  delay,
  takeEvery,
} from "redux-saga/effects";
import { get } from "lodash";

import {
  START_CHANNEL,
  STOP_CHANNEL,
  START_GENERATE_MODEL,
  
} from "./constants";
import { commonEmitter, connect, createSocketChannel } from "./socketChannel";
import {
  stopChannel,
  authenticatedSocket,
  unauthorizedSocket,
} from "./actions";
import { startGenerateModel, handler } from "./eventHandlers/generateModel";
import {
  getModelList,
  getCustomModelList,
  generateModelSuccess,
  generateModelResponse,
  generateModelFailure,
  automaticAnnotationSuccess,
  trainingModelResponse,
  trainingModelFailure,
  trainingModelSuccess,
  createCloneDatasetJobSuccess,
  cloneDatasetJobSuccess,
  cloneDatasetJobError,
  START_CLONE_DATASET,
} from "redux/actions";
import {startCloneDataset} from "./eventHandlers/cloneDataset";

export let socket; // eslint-disable-line import/no-mutable-exports

// Saga listens for start and stop actions
function* startStopChannel() {
  try {
    while (true) {
      yield take(START_CHANNEL);
      yield race({
        task: call(listenServerSaga),
        cancel: take(STOP_CHANNEL),
      });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("error", error);
  }
}

// Saga to switch on channel
function* listenServerSaga() {
  try {
    const { connected, timeout } = yield race({
      connected: call(connect),
      timeout: delay(2000),
    });

    if (timeout) {
      yield put(stopChannel());
    }
    if (connected) socket = connected;
    const socketChannel = yield call(createSocketChannel, connected);

    socket.on("disconnect", () => {
      console.error("disconnect");
      handler({ event: "disconnect" }, commonEmitter, "disconnect");
    });

    socket.on("connect_error", () => {
      console.error("connect_error");
      handler({ event: "connect_error" }, commonEmitter, "connect_error");
    });

    socket.on("connect_timeout", () => {
      console.error("connect_timeout");
      handler({ event: "connect_timeout" }, commonEmitter, "connect_timeout");
    });

    socket.on("connect", () => {
      console.log("connect", socket);
    });

    socket.on("reconnect", () => {
      console.log("reconnect");
    });

    socket.on("generate_model_response", (data) => {
      handler(data, commonEmitter, "generate_model_response");
    });
    socket.on("generate_model_end", (data) => {
      handler(data, commonEmitter, "generate_model_end");
    });
    socket.on("automatic_annotation_end", (data) => {
      handler(data, commonEmitter, "automatic_annotation_end");
    });
    socket.on("training_model_response", (data) => {
      handler(data, commonEmitter, "training_model_response");
    });
    socket.on("training_model_processing", (data) => {
      handler(data, commonEmitter, "training_model_processing");
    });
    socket.on("training_model_end", (data) => {
      handler(data, commonEmitter, "training_model_end");
    });
    socket.on("clone_dataset_response", (data) => {
      handler(data, commonEmitter, "clone_dataset_response");
    })
    socket.on("clone_dataset_success", (data) => {
      handler(data, commonEmitter, "clone_dataset_success");
    })
    socket.on("clone_dataset_failure", (data) => {
      handler(data, commonEmitter, "clone_dataset_failure");
    })
    while (true) {
      // take the response from socketChannel and deal with them in mapEventToAction function
      const payload = yield take(socketChannel);
      yield call(mapEventToAction, payload);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("listenServerSaga error", error);
  } finally {
    if (yield cancelled()) {
      // eslint-disable-next-line no-console
      console.log("cancel socket");
      socket.disconnect(true);
      yield put(stopChannel());
    }
  }
}

function* mapEventToAction(payload) {
  const { event, data } = payload;

  /* eslint-disable default-case, no-param-reassign, no-console */
  switch (event) {
    case "disconnect": {
      // case "connect_error":
      // case "connect_timeout": {
      console.log("reconnect");
      socket.connect();
      // yield put(
      //   showNoti({
      //     type: "error",
      //     message: DISCONNECT_message,
      //   }),
      // );
      break;
    }
    case "authenticated":
      yield put(authenticatedSocket());
      break;

    case "unauthorized":
      yield put(unauthorizedSocket());
      break;
    case "generate_model_response": {
      const projectId = get(payload, ['data', 'project_id'], '');
      const statusCode = get(payload, 'status', 200);
      if (statusCode == 200) {
        yield put(generateModelResponse());
      } else {
        yield put(generateModelFailure());
      }
      
      yield put(getModelList({ projectId }));
      break;
    }
    case "generate_model_end": {
      const projectId = get(payload, ['data', 'project_id'], '');
      const statusCode = get(payload, 'status', 200);
      if (statusCode == 200) {
        yield put(generateModelSuccess());
      } else {
        yield put(generateModelFailure());
      }
      
      yield put(getModelList({ projectId }));
      break;
    }
    case "training_model_response": {
      const projectId = get(payload, ['data', 'project_id'], '');
      const statusCode = get(payload, 'status', 200);
      if (statusCode == 200) {
        yield put(trainingModelResponse());
      } else {
        yield put(trainingModelFailure());
      }
      
      yield put(getCustomModelList({ projectId }));
      break;
    }
    case "training_model_processing": {
      const projectId = get(payload, ['data', 'project_id'], '');
      yield put(getCustomModelList({ projectId }));
      break;
    }
    case "training_model_end": {
      const projectId = get(payload, ['data', 'project_id'], '');
      const statusCode = get(payload, 'status', 200);
      if (statusCode == 200) {
        yield put(trainingModelSuccess());
      } else {
        yield put(trainingModelFailure());
      }
      
      yield put(getCustomModelList({ projectId }));
      break;
    }
    case "automatic_annotation_end": {
      yield put(automaticAnnotationSuccess({...payload.data}));
      break;
    }
    case "clone_dataset_response": {
      yield put(createCloneDatasetJobSuccess({...payload.data}));
      break;
    }
    case "clone_dataset_success": {
      yield put(cloneDatasetJobSuccess());
      break;
    }
    case "clone_dataset_failure": {
      yield put(cloneDatasetJobError());
      break;
    }
    default:
      break;
  }
}

function* startGenerateModelSaga({ payload }) {
  yield call(startGenerateModel, socket, payload);
}

// function* startCloneDatasetSaga({ payload }) {
//   yield call(startCloneDataset, socket, payload)
// }

export default function* socketSaga() {
  yield all([
    fork(startStopChannel),
    takeEvery(START_GENERATE_MODEL, startGenerateModelSaga),
    // takeEvery(START_CLONE_DATASET, startCloneDatasetSaga),
  ]);
}
