/* eslint-disable array-callback-return */
import { combineReducers } from "redux";
import settings from "./settings/reducer";
import menu from "./menu/reducer";
import authUser from "./auth/reducer";
import surveyDetailApp from "./surveyDetail/reducer";
import membersApp from "./members/reducer";
import projectsApp from "./projects/reducer";
import agentsApp from "./agent/reducer";
import notification from "./notification/reducer";
import notificationModal from "./notificationModal/reducer";
import dashboard from "./dashboard/reducer";
import modelManagement from "./modelManagement/reducer";
import orgData from "./organization/reducer";
import pricing from "./pricing/reducer";
import tourGuide from "./tour/reducer";
import socket from "./socket/reducer";
import automl from "./model/reducer";
import businessAdmin from "./businessAdmin/reducer";
import enterprise from "./enterprise/reducer";
import issue from "./issue/reducer";
import benchmark from "./benchmark/reducer";
import datasets from "./datasets/reducer";
import datasetDetail from "./datasetDetail/reducer";
import advancedSearch from "./advancedSearch/reducer";
import tags from "./tags/reducer";

const reducerModules = {
  menu,
  settings,
  authUser,
  surveyDetailApp,
  membersApp,
  projectsApp,
  agentsApp,
  notification,
  notificationModal,
  dashboard,
  modelManagement,
  orgData,
  pricing,
  tourGuide,
  socket,
  automl,
  businessAdmin,
  enterprise,
  issue,
  benchmark,
  datasets,
  datasetDetail,
  tags,
  advancedSearch,
};

const reducers = combineReducers(reducerModules);

export default reducers;

export const namespaces = Object.keys(reducerModules);
