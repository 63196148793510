import { all, call, put, select, takeEvery } from "redux-saga/effects";
import get from "lodash/get";
import {
  augmentingImageAPI,
  cloneDatasetApi,
  cloneSourcesApi,
  deleteDatasetByIdAPI,
  deleteDatasetsAPI,
  getListDatasetAPI,
  mergeDatasetApi,
  mergeSourcesApi,
  updateDataSetByIdAPI,
} from "./api";
import {
  DELETE_DATASET,
  DELETE_DATASETS,
  getCurrentPricingPackage,
  GET_LIST_DATASET,
  IMAGE_AUGMENTING,
  setSelectedDataset,
  showNoti,
  UPDATE_DATASET,
  createCloneDatasetJobSuccess,
  CLONE_DATASET_JOB_SUCCESS,
  CLONE_DATASET_JOB_ERROR,
  getListDataset,
  START_CLONE_DATASET, START_MERGE_DATASET, createMergeDatasetJobSuccess, START_CLONE_SOURCES, START_MERGE_SOURCES,
} from "redux/actions";
import { getListDatasetError, getListDatasetSuccess } from "./actions";
import {
  ERROR_CASE,
  SUCCESS_CASE,
} from "components/notificationModal/constants";
import {
  selectlistAllDatasets,
  selectProjectByProjectId,
  selectProjectType,
} from "redux/projects/selectors";
import { datasetPageSizes } from "constants/defaultValues";

function* getListDatasetOfProjectSaga({ payload }) {
  const errorMessage = "Get list dataset error!";
  const errorMessageId = "notification.get-list-dataset-error";
  try {
    const response = yield call(getListDatasetAPI, payload);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status === true) {
        const { totalPage, totalItem, datasets } = get(response, "data.data", {
          totalItem: 0,
          totalPage: 0,
          project_name: "",
          datasets: [],
        });
        yield put(
          getListDatasetSuccess({
            pagination: {
              totalPage,
              totalItem,
              currentPage: payload.currentPage,
              pageSize: payload.pageSize,
            },
            datasets,
          }),
        );
      } else {
        yield put(getListDatasetError(message));
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: message,
            messageId: null,
          }),
        );
      }
    } else {
      yield put(getListDatasetError(errorMessage));
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(getListDatasetError(errorMessage));
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: error.toString(),
        messageId: errorMessageId,
      }),
    );
  }
}

function* updateDataSetByIdSaga({ payload, callback }) {
  const { projectId, datasetId, datasetName, description, thumbnailPath } =
    payload;
  const errorMessage = "Update dataset error!";
  const errorMessageId = "notification.update-dataset-error";
  try {
    const response = yield call(updateDataSetByIdAPI, datasetId, {
      dataset_name: datasetName,
      dataset_description: description,
      thumbnail_path: thumbnailPath,
      project_id: projectId,
    });
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      yield put(
        showNoti({
          type: SUCCESS_CASE,
          message: "Update dataset successfully!",
          messageId: "notification.update-dataset-success",
        }),
      );
      if (callback) callback();
    } else {
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: error.toString(),
        messageId: errorMessageId,
      }),
    );
  }
}

function* deleteDatasetByIdSaga({ payload, callback }) {
  const errorMessage = "Delete dataset Error!";
  const errorMessageId = "notification.delete-dataset-error";

  const currentProject = yield select(
    selectProjectByProjectId(payload.projectId),
  );
  const projectType = get(currentProject, "data_type", "");

  try {
    const response = yield call(deleteDatasetByIdAPI, {
      id: payload.id,
      projectType,
    });
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      yield put(getCurrentPricingPackage({}));
      yield put(
        showNoti({
          type: SUCCESS_CASE,
          message: "Delete dataset successfully!",
          messageId: "notification.delete-dataset-success",
        }),
      );
      callback && callback();
    } else {
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: error.toString(),
        messageId: errorMessageId,
      }),
    );
  }
}

function* deleteDatasetsSaga({ payload, callback }) {
  const errorMessage = "Delete dataset Error!";
  const errorMessageId = "notification.delete-dataset-error";
  const projectType = yield select(selectProjectType);
  try {
    const response = yield call(deleteDatasetsAPI, {
      datasets: payload,
      projectType,
    });
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      yield put(
        showNoti({
          type: SUCCESS_CASE,
          message: "Delete dataset successfully!",
          messageId: "notification.delete-dataset-success",
        }),
      );
      yield put(setSelectedDataset([]));
      callback && callback();
    } else {
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: errorMessage,
          messageId: errorMessageId,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: errorMessage,
        messageId: errorMessageId,
      }),
    );
  }
}

function* imageAugmentingSaga({ payload, callback }) {
  const errorMessageId = "image_augmentation.image-augmenting-error";

  try {
    const response = yield call(augmentingImageAPI, payload);
    const statusCode = get(response, "status", "");
    const data = get(response, "data", "");
    if (statusCode === 200 && data.status) {
      yield put(
        showNoti({
          type: SUCCESS_CASE,
          messageId: "image_augmentation.image-augmenting-success",
        }),
      );
    } else {
      yield put(
        showNoti({
          type: ERROR_CASE,
          messageId: data?.message || errorMessageId,
        }),
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      showNoti({
        type: ERROR_CASE,
        messageId: errorMessageId,
      }),
    );
  }

  callback && callback(true);
}

function* startCloneDatasetSaga({ payload, callback }) {
  try {
    const listAllDatasets = yield select(selectlistAllDatasets);
    const { datasetIds } = payload;
    let isDatasetsClonable = true;
    const emptyDatasets = [];
    for (let datasetIdItem of datasetIds) {
      const currentDatasetItem = listAllDatasets.find(dataset => dataset.dataset_id === datasetIdItem);
      if (!currentDatasetItem || currentDatasetItem.total_source == 0) {
        isDatasetsClonable = false;
        emptyDatasets.push(currentDatasetItem.dataset_name);
      }
    }
    
    if (isDatasetsClonable) {
      const response = yield call(cloneDatasetApi, payload);
      const statusCode = get(response, "status", "");
      const data = get(response, "data", {});
      if (Number(statusCode) === 200 && data.success) {
        yield put(createCloneDatasetJobSuccess(payload));
        yield put(getListDataset({
          projectId: payload.projectId,
          pageSize: datasetPageSizes[0]
        }))
        yield put(
          showNoti({
            type: SUCCESS_CASE,
            message: "Successfully cloned datasets",
            messageId: "clone-dataset.successfully-cloned-datasets",
          }),
        )
        callback && callback();
      } else {
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: "Fail to clone dataset",
            messageId: "clone-dataset.fail-to-clone-dataset",
          })
        )
      }
    } else {
      yield put(
        showNoti({
          type: ERROR_CASE,
          messageId: "clone-dataset.cannot-clone-empty-dataset",
          intlValues: {
            datasetNames: emptyDatasets.join(', '),
          }
        })
      )
    }
  } catch (error) {
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: "Fail to clone dataset",
        messageId: "clone-dataset.fail-to-clone-dataset",
      })
    )
  }
}


function* startMergeDatasetSaga({ payload, callback }) {
  try {
    const response = yield call(mergeDatasetApi, payload);
    const statusCode = get(response, "status", "");
    const data = get(response, "data", {});
    if (Number(statusCode) === 200 && data.success) {
      yield put(createMergeDatasetJobSuccess(payload));
      yield put(getListDataset({
        projectId: payload.projectId,
        pageSize: datasetPageSizes[0]
      }))
      yield put(
        showNoti({
          type: SUCCESS_CASE,
          message: "Successfully merged datasets",
          messageId: "merge-dataset.successfully-merged-datasets",
        }),
      )
      callback && callback();
    } else {
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: "Fail to merge dataset",
          messageId: "merge-dataset.fail-to-merge-datasets",
        })
      )
    }
  } catch (error) {
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: "Fail to merge dataset",
        messageId: "merge-dataset.fail-to-merge-datasets",
      })
    )
  }
}

function* startCloneSourcesSaga({ payload, callback }) {
  try {
      const response = yield call(cloneSourcesApi, payload);
      const statusCode = get(response, "status", "");
      const data = get(response, "data", {});
      if (Number(statusCode) === 200 && data.success) {
        yield put(createCloneDatasetJobSuccess(payload));
        yield put(getListDataset({
          projectId: payload.projectId,
          pageSize: datasetPageSizes[0]
        }))
        yield put(
          showNoti({
            type: SUCCESS_CASE,
            message: "Successfully cloned sources",
            messageId: "clone-dataset.successfully-cloned-sources",
          }),
        )
        callback && callback();
      } else {
        yield put(
          showNoti({
            type: ERROR_CASE,
            message: "Fail to clone sources",
            messageId: "clone-dataset.fail-to-clone-sources",
          })
        )
      }
  } catch (error) {
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: "Fail to clone sources",
        messageId: "clone-dataset.fail-to-clone-sources",
      })
    )
  }
}

function* startMergeSourcesSaga({ payload, callback }) {
  try {
    const response = yield call(mergeSourcesApi, payload);
    const statusCode = get(response, "status", "");
    const data = get(response, "data", {});
    if (Number(statusCode) === 200 && data.success) {
      yield put(createCloneDatasetJobSuccess(payload));
      yield put(getListDataset({
        projectId: payload.projectId,
        pageSize: datasetPageSizes[0]
      }))
      yield put(
        showNoti({
          type: SUCCESS_CASE,
          message: "Successfully merged sources",
          messageId: "merge-dataset.successfully-merged-sources",
        }),
      )
      callback && callback();
    } else {
      yield put(
        showNoti({
          type: ERROR_CASE,
          message: "Fail to clone sources",
          messageId: "merge-dataset.fail-to-merge-sources",
        })
      )
    }
  } catch (error) {
    yield put(
      showNoti({
        type: ERROR_CASE,
        message: "Fail to clone sources",
        messageId: "merge-dataset.fail-to-merge-sources",
      })
    )
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(GET_LIST_DATASET, getListDatasetOfProjectSaga),
    takeEvery(UPDATE_DATASET, updateDataSetByIdSaga),
    takeEvery(DELETE_DATASET, deleteDatasetByIdSaga),
    takeEvery(DELETE_DATASETS, deleteDatasetsSaga),
    takeEvery(IMAGE_AUGMENTING, imageAugmentingSaga),
    takeEvery(START_CLONE_DATASET, startCloneDatasetSaga),
    takeEvery(START_MERGE_DATASET, startMergeDatasetSaga),
    takeEvery(START_CLONE_SOURCES, startCloneSourcesSaga),
    takeEvery(START_MERGE_SOURCES, startMergeSourcesSaga)
  ]);
}
