import {
  cancel,
  all,
  fork,
  put,
  select,
  takeEvery,
  call,
} from "redux-saga/effects";
import get from "lodash/get";
import {
  FETCH_PUSH_NOTIFICATIONS,
  READ_ONE_NOTIFICATION,
  MARK_ALL_NOTIFICATION_AS_READ,
} from "redux/actions";
import {
  showNotification,
  updateCurrentNotiPage,
  fetchPushNotificationsSuccess,
  noMoreNotifications,
  changeViewNotification,
  markAllNotificationAsReadSuccess,
  markAllNotificationAsReadError,
} from "./actions";
import { fetchNotification, updateOneNotification, markAllNotificationAsRead } from "./api";
import { selectNotiPageSize, selectCurrentNotiPage, selectPushNotifications } from "./selectors";
import { userInfoCookie } from "helpers/Utils";

function* watchFetchPushNotis() {
  yield takeEvery(FETCH_PUSH_NOTIFICATIONS, function* ( ) {
    const pageSize = yield select(selectNotiPageSize);
    const user = userInfoCookie();
    const userId = user.userId;
    const currentPage = yield select(selectCurrentNotiPage);
    const allPushNoti = yield select(selectPushNotifications);
    let sendingPayload = {
      userId,
      pageSize,
      currentPage
    };
    if ((currentPage-1) * pageSize === allPushNoti.length) {
      const response = yield call(fetchNotification, sendingPayload);
      const notifications = get(response, "data.data", []);
      const totalNewNotifications = get(response, "data.totalUnread", 0);
      const result = { notifications, totalNewNotifications };

      if (!response || !response.status) {
        yield put(
          showNotification({
            type: "error",
            msg: "Can not fetch notifications",
          })
        );
        yield cancel();
      }

      if (notifications.length === 0 || totalNewNotifications === 0) {
        yield put(noMoreNotifications());
      }

      if (notifications && notifications.length) {
        yield put(fetchPushNotificationsSuccess(result));
        yield put(updateCurrentNotiPage(currentPage + 1));
        if (notifications.length < pageSize) {
          yield put(noMoreNotifications());
        }
        return;
      }
      yield put(noMoreNotifications());
    } else {
      yield put(noMoreNotifications());
    }
  });
}

function* watchReadOneNotification() {
  yield takeEvery(READ_ONE_NOTIFICATION, function* ({ payload }) {
    const { notificationId } = payload;
    const newPayload = { ...payload };
    delete newPayload.notificationId;
    const response = yield call(
      updateOneNotification,
      notificationId,
      newPayload
    );
    const status = get(response, "data", false);

    if (status) {
      yield put(changeViewNotification(notificationId));
    } else {
      yield put(
        showNotification({
          type: "error",
          msg: "Fail to update notifications",
        })
      );
      yield cancel();
    }
  });
}

function* watchMarkAllNotificationAsRead() {
  yield takeEvery(MARK_ALL_NOTIFICATION_AS_READ, function* ({ payload }) {
    const response = yield call(markAllNotificationAsRead);
    const { status, message } = get(response, "data", false);
    if (status) {
      yield put(markAllNotificationAsReadSuccess(message));
    } else {
      yield put(markAllNotificationAsReadError(message));
    }
  });
}

export default function* saga() {
  yield all([
    fork(watchFetchPushNotis),
    fork(watchReadOneNotification),
    fork(watchMarkAllNotificationAsRead),
  ]);
}
