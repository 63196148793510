import { createElement } from "react";
import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

const InjectMassage = ({ className, component, ...props }) => (
  <FormattedMessage {...props}>
    {(chunk) =>
      component ? (
        createElement(component, {
          children: chunk,
          className,
          ...props,
        })
      ) : (
        <span className={className}>{chunk}</span>
      )
    }
  </FormattedMessage>
);

export default injectIntl(InjectMassage, {
  withRef: false,
});
