/*
 * Socket Constants
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const START_CHANNEL = "socket/START_CHANNEL";
export const STOP_CHANNEL = "socket/STOP_CHANNEL";

export const AUTHENTICATED_SOCKET = "socket/AUTHENTICATED_SOCKET";
export const UNAUTHORIZED_SOCKET = "socket/UNAUTHORIZED_SOCKET";

export const START_GENERATE_MODEL = "socket/START_GENERATE_MODEL";
export const START_GENERATE_MODEL_SUCCESS = "socket/START_GENERATE_MODEL_SUCCESS";
