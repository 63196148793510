import {
  SETUP_ORGANIZATION,
  SETUP_ORGANIZATION_SUCCESS,
  SETUP_ORGANIZATION_ERROR,
  GET_ORGANIZATION_BY_ID,
  GET_ORGANIZATION_BY_ID_SUCCESS,
  GET_ORGANIZATION_BY_ID_ERROR,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_ERROR,
  GET_USER_GROUP_USER,
  GET_USER_GROUP_USER_SUCCESS,
  SWITCH_ORGANIZATION_USER,
  GET_LIST_ORGANIZATION,
  GET_LIST_ORGANIZATION_SUCCESS,
  GET_LIST_ORGANIZATION_ERROR,
  GET_LIST_ORGANIZATION_BY_USER_ID,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_SUCCESS,
  ADD_ORGANIZATION,
  UPDATE_ORGANIZATION_NEW,
  GET_STORAGE_SETTING_BY_ORGANIZATION_ID,
  GET_STORAGE_SETTING_BY_ORGANIZATION_ID_SUCCESS,
  ADD_STORAGE_SETTING,
  GET_LIST_ORGANIZATION_FOR_BUSINESS_ADMIN,
  GET_LIST_ORGANIZATION_SUCCESS_FOR_BUSINESS_ADMIN,
  GET_LIST_ORGANIZATION_ERROR_FOR_BUSINESS_ADMIN,
  GET_CHART_ORGANIZATION_FOR_BUSINESS_ADMIN,
  GET_CHART_ORGANIZATION_FOR_BUSINESS_ADMIN_SUCCESS,
  GET_CHART_ORGANIZATION_FOR_BUSINESS_ADMIN_FAILURE,
} from "redux/actions";

export const getChartOrganization = (payload) => ({
  type: GET_CHART_ORGANIZATION_FOR_BUSINESS_ADMIN,
  payload,
});

export const getChartOrganizationSuccess = (payload) => ({
  type: GET_CHART_ORGANIZATION_FOR_BUSINESS_ADMIN_SUCCESS,
  payload,
});

export const getChartOrganizationFailure = (payload) => ({
  type: GET_CHART_ORGANIZATION_FOR_BUSINESS_ADMIN_FAILURE,
  payload,
});

export const getUserGroupUser = (callback) => ({
  type: GET_USER_GROUP_USER,
  callback
});
export const getUserGroupUserSuccess = (payload) => ({
  type: GET_USER_GROUP_USER_SUCCESS,
  payload
});

export const switchOrganizationUser = (ActiveCode) => ({
  type: SWITCH_ORGANIZATION_USER,
  payload: { ActiveCode }
});

export const setupOrganization = (organization, history) => ({
  type: SETUP_ORGANIZATION,
  payload: { organization, history },
});
export const setupOrganizationSuccess = (history) => ({
  type: SETUP_ORGANIZATION_SUCCESS,
  payload: { history },
});
export const setupOrganizationError = (message, messageId) => ({
  type: SETUP_ORGANIZATION_ERROR,
  payload: { message, messageId },
});

export const getOrgById = (orgId) => ({
  type: GET_ORGANIZATION_BY_ID,
  payload: { orgId },
});
export const getOrgByIdSuccess = (data, message) => ({
  type: GET_ORGANIZATION_BY_ID_SUCCESS,
  payload: { data, message },
});
export const getOrgByIdError = (message) => ({
  type: GET_ORGANIZATION_BY_ID_ERROR,
  payload: { message },
});

export const updateOrg = (orgInfo) => ({
  type: UPDATE_ORGANIZATION,
  payload: { orgInfo },
});
export const updateOrgSuccess = (message) => ({
  type: UPDATE_ORGANIZATION_SUCCESS,
  payload: { message },
});
export const updateOrgError = (message) => ({
  type: UPDATE_ORGANIZATION_ERROR,
  payload: { message },
});

export const getListOrganization = (payload) => ({
  type: GET_LIST_ORGANIZATION,
  payload
});
export const getListOrganizationSuccess = (payload) => ({
  type: GET_LIST_ORGANIZATION_SUCCESS,
  payload
});
export const getListOrganizationError = (payload) => ({
  type: GET_LIST_ORGANIZATION_ERROR,
  payload
});

export const getListOrganizationByUserId = (payload) => ({
  type: GET_LIST_ORGANIZATION_BY_USER_ID,
  payload
});

export const deleteOrganization = ({ id, callback }) => ({
  type: DELETE_ORGANIZATION,
  id,
  callback
});
export const deleteSuccess = (payload) => ({
  type: DELETE_ORGANIZATION_SUCCESS,
  payload
});

export const addOrganization = (payload) => ({
  type: ADD_ORGANIZATION,
  payload,
});

export const updateOrganization = ({ id, payload, callback }) => ({
  type: UPDATE_ORGANIZATION_NEW,
  id,
  payload,
  callback
});

export const getStorageSetting = (payload) => ({
  type: GET_STORAGE_SETTING_BY_ORGANIZATION_ID,
  payload
});

export const getStorageSettingSuccess = (payload) => ({
  type: GET_STORAGE_SETTING_BY_ORGANIZATION_ID_SUCCESS,
  payload
});

export const addStorageSetting = (payload) => ({
  type: ADD_STORAGE_SETTING,
  payload
});

export const getListOrganizationForBusinessAdmin = (payload) => ({
  type: GET_LIST_ORGANIZATION_FOR_BUSINESS_ADMIN,
  payload
});
export const getListOrganizationForBusinessAdminSuccess = (payload) => ({
  type: GET_LIST_ORGANIZATION_SUCCESS_FOR_BUSINESS_ADMIN,
  payload
});
export const getListOrganizationForBusinessAdminError = (payload) => ({
  type: GET_LIST_ORGANIZATION_ERROR_FOR_BUSINESS_ADMIN,
  payload
});

