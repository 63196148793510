import axios from "../api";
import { deleteAllCookies, getCookie } from "helpers/Utils";

axios.interceptors.response.use((response) => {
  return response;
}, function (error) {
  // Do something with response error
  if (error.response.status === 401) {
    console.log('unauthorized, logging out ...');
    try {
      localStorage.setItem('log', JSON.stringify(error.response.config));
      deleteAllCookies();
      if(window.location.pathname !== '/user/login' && window.location.pathname !== '/user/forgot-password'){
        window.location.href = '/user/login';
      }
    } catch (err) {
      console.log("logout -> error", err);
    }
  }
  return Promise.reject(error);
});

export function fetchNotification(payload) {
  const { userId, pageSize, currentPage } = payload;
  axios.defaults.headers.Authorization = 'Bearer ' + getCookie("Bearer");
  return axios.get(`/notification/get-list-notification-by-user-id?userId=${userId}&pageSize=${pageSize}&currentPage=${currentPage}`);
}

export function updateOneNotification(id, payload) {
  return axios.put(`/notification/${id}`);
}

export function markAllNotificationAsRead() {
  return axios.post('/notification/read-all-notification');
}
