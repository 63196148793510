import {
  ADD_TAG_TO_SOURCE,
  CREATE_PROJECT_TAG,
  CREATE_PROJECT_TAG_AND_ADD_TO_SOURCE,
  DELETE_TAG_OF_PROJECT,
  DELETE_TAG_OF_SOURCE,
  GET_TAGS_BY_PROJECT_ID,
  GET_TAGS_BY_PROJECT_ID_ERROR,
  GET_TAGS_BY_PROJECT_ID_SUCCESS,
  GET_TAGS_BY_SOURCE_ID,
  GET_TAGS_DATA_FOR_TAG_FORM,
  GET_TAGS_DATA_FOR_TAG_FORM_SUCCESS,
  SEARCH_PROJECT_TAG_BY_TEXT,
} from "redux/actions";

export const getTagBySourceId = ({ payload, callback }) => ({
  type: GET_TAGS_BY_SOURCE_ID,
  payload,
  callback,
});

export const getTagDataForTagForm = ({ payload, callback }) => ({
  type: GET_TAGS_DATA_FOR_TAG_FORM,
  payload,
  callback,
});

export const getTagDataForTagFormSuccess = (payload) => ({
  type: GET_TAGS_DATA_FOR_TAG_FORM_SUCCESS,
  payload,
});

export const deleteTagOfSource = ({ payload, callback }) => ({
  type: DELETE_TAG_OF_SOURCE,
  payload,
  callback,
});

export const deleteTagOfProject = ({ payload, callback }) => ({
  type: DELETE_TAG_OF_PROJECT,
  payload,
  callback,
});

export const addTagToSource = ({ payload, callback }) => ({
  type: ADD_TAG_TO_SOURCE,
  payload,
  callback,
});

export const searchProjectTagByText = ({ payload, callback }) => ({
  type: SEARCH_PROJECT_TAG_BY_TEXT,
  payload,
  callback,
});

export const createProjectTag = ({ payload, callback }) => ({
  type: CREATE_PROJECT_TAG,
  payload,
  callback,
});

export const createProjectTagAndAddTagToSource = ({ payload, callback }) => ({
  type: CREATE_PROJECT_TAG_AND_ADD_TO_SOURCE,
  payload,
  callback,
});

export const getTagsByProjectId = (payload) => ({
  type: GET_TAGS_BY_PROJECT_ID,
  payload
})

export const getTagsByProjectIdSuccess = (payload) => ({
  type: GET_TAGS_BY_PROJECT_ID_SUCCESS,
  payload
})

export const getTagsByProjectIdError = (payload) => ({
  type: GET_TAGS_BY_PROJECT_ID_ERROR,
  payload
})

