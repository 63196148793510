import {
    GET_USER_LIST,
    GET_USER_LIST_SUCCESS,
    GET_USER_LIST_ERROR,
    GET_USER_CHART,
    GET_USER_CHART_SUCCESS,
    GET_USER_CHART_ERROR,
    ADD_USER,
    UPDATE_USER,
    GET_USER_REPORT,
    GET_USER_REPORT_SUCCESS,
    GET_USER_REPORT_ERROR,
} from "redux/actions";

export const getUserReportFailure = (payload) => ({
    type: GET_USER_REPORT_ERROR,
    payload,
});

export const getUserReportSuccess = (payload) => ({
    type: GET_USER_REPORT_SUCCESS,
    payload,
});

export const getUserReport = (payload) => ({
    type: GET_USER_REPORT,
    payload,
});

export const getUserList = (filter) => ({
    type: GET_USER_LIST,
    payload: { ...filter },
});

export const getUserListSuccess = (userList) => ({
    type: GET_USER_LIST_SUCCESS,
    payload: { ...userList },
});

export const getUserListError = (message) => ({
    type: GET_USER_LIST_ERROR,
    payload: { message },
});

export const getUserChart = (payload) => ({
    type: GET_USER_CHART,
    payload: { ...payload },
});

export const getUserChartSuccess = (userChart) => ({
    type: GET_USER_CHART_SUCCESS,
    payload: { ...userChart },
});

export const getUserChartError = (message) => ({
    type: GET_USER_CHART_ERROR,
    payload: { message },
});

export const addUser = (payload) => ({
    type: ADD_USER,
    payload,
  });

export const updateUser = (payload) => ({
    type: UPDATE_USER,
    payload,
});