import {
  GET_REPORT_DATA_SUCCESS,
  GET_TOOL_CHART_DATA_SUCCESS,
  GET_USER_LOGIN_CHART_DATA_SUCCESS
} from "redux/actions";

const INIT_STATE = {
  reportData: [],
  totalRecord: 0,
  totalPage: 0,
  toolClassesImage: [],
  toolClassesVideo: [],
  toolOrgImage: [],
  toolOrgVideo: [],
  userLogChart: [],
  platformChart: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REPORT_DATA_SUCCESS: {
      const { reportData, totalRecord, totalPage } = action.payload;
      return {
        ...state,
        reportData,
        totalRecord,
        totalPage
      };
    }
    case GET_TOOL_CHART_DATA_SUCCESS: {
      const {
        toolClassesImage,
        toolClassesVideo,
        toolOrgImage,
        toolOrgVideo,
      } = action.payload;
      return {
        ...state,
        toolClassesImage,
        toolClassesVideo,
        toolOrgImage,
        toolOrgVideo,
      };
    }
    case GET_USER_LOGIN_CHART_DATA_SUCCESS: {
      const { userLogChart, platformChart } = action.payload;
      return {
        ...state,
        userLogChart,
        platformChart
      }
    }
    default:
      return state;
  }
};
