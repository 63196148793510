import {
  ACTION_ERROR,
  GET_LIST_PROJECT_DETAIL,
  GET_LIST_PROJECT_DETAIL_SUCCESS,
  GET_LIST_PROJECT_DETAIL_ERROR,
  SET_CURRENT_PROJECT,
  CREATE_NEW_DATASET,
  CREATE_NEW_DATASET_SUCCESS,
  CREATE_NEW_DATASET_ERROR,
  GET_SUMMARY_PROJECTS,
  GET_SUMMARY_PROJECTS_SUCCESS,
  GET_SUMMARY_PROJECTS_ERROR,
  GET_EMAIL_LIST,
  GET_ROLE_LIST,
  GET_ASSIGN_MEMBER_LIST,
  GET_EMAIL_LIST_SUCCESS,
  GET_ROLE_LIST_SUCCESS,
  GET_ASSIGN_MEMBER_LIST_SUCCESS,
  GET_EMAIL_LIST_ERROR,
  GET_ROLE_LIST_ERROR,
  GET_ASSIGN_MEMBER_LIST_ERROR,
  CREATE_NEW_PROJECT,
  CREATE_NEW_PROJECT_SUCCESS,
  GET_PROJECT_BY_ID,
  GET_PROJECT_BY_ID_SUCCESS,
  GET_PROJECT_BY_ID_ERROR,
  GET_FIRST_DATASET_EXIST_SOURCE,
  GET_FIRST_DATASET_EXIST_SOURCE_SUCCESS,
  GET_FIRST_DATASET_EXIST_SOURCE_ERROR,
  GET_CURRENT_PROJECT_MEMBERS,
  GET_CURRENT_PROJECT_MEMBERS_SUCCESS,
  GET_CURRENT_PROJECT_MEMBERS_ERROR,
  ASSIGN_TASK_TO_MEMBER,
  ASSIGN_TASK_TO_MEMBER_SUCCESS,
  ASSIGN_TASK_TO_MEMBER_ERROR,
  UNASSIGN_TASK_TO_MEMBER,
  UNASSIGN_TASK_TO_MEMBER_SUCCESS,
  UNASSIGN_TASK_TO_MEMBER_ERROR,
  GET_PROJECT_LABELS,
  GET_PROJECT_LABELS_SUCCESS,
  GET_PROJECT_LABELS_ERROR,
  GET_MODULE_BY_ROLE_IN_PROJECT,
  GET_MODULE_BY_ROLE_IN_PROJECT_SUCCESS,
  GET_MODULE_BY_ROLE_IN_PROJECT_ERROR,
  ADD_CLASS,
  ADD_CLASS_SUCCESS,
  UPDATE_CLASS,
  UPDATE_CLASS_SUCCESS,
  DELETE_CLASS_SUCCESS,
  DELETE_CLASS,
  DELETE_PROJECT_BY_ID,
  DELETE_PROJECT_BY_ID_SUCCESS,
  EXPORT_DATASET,
  EXPORT_DATASET_SUCCESS,
  EXPORT_DATASET_ERROR,
  GET_EXPORT_DATASET,
  GET_EXPORT_DATASET_SUCCESS,
  GET_EXPORT_DATASET_ERROR,
  PREPARE_CUSTOMER_DATA,
  PREPARE_CUSTOMER_DATA_SUCCESS,
  PREPARE_CUSTOMER_DATA_ERROR,
  GET_SOURCE_STATUS,
  GET_SOURCE_STATUS_SUCCESS,
  GET_SOURCE_STATUS_ERROR,
  DATASET_ADD_MORE_DATA,
  DATASET_ADD_MORE_DATA_SUCCESS,
  DATASET_ADD_MORE_DATA_ERROR,
  INVITE_MEMBERS_TO_PROJECT,
  INVITE_MEMBERS_TO_PROJECT_SUCCESS,
  RESET_SOURCE_STATUS,
  RESET_SOURCE_STATUS_SUCCESS,
  RESET_SOURCE_STATUS_ERROR,
  GET_TOTAL_APPROVE_ANNOTATION_EACH_CLASS_IN_PROJECT,
  GET_TOTAL_APPROVE_ANNOTATION_EACH_CLASS_IN_PROJECT_SUCCESS,
  GET_TOTAL_APPROVE_ANNOTATION_EACH_CLASS_IN_PROJECT_ERROR,
  GET_TOTAL_APPROVE_IMG_CLASS_IN_PROJECT,
  GET_TOTAL_APPROVE_IMG_CLASS_IN_PROJECT_SUCCESS,
  GET_TOTAL_APPROVE_IMG_CLASS_IN_PROJECT_ERROR,
  GET_LABELER_STATUS_IN_PROJECT,
  GET_LABELER_STATUS_IN_PROJECT_SUCCESS,
  GET_LABELER_STATUS_IN_PROJECT_ERROR,
  SET_CURRENT_LABEL,
  ADD_NEW_LABEL_ATTRIBUTE,
  ADD_NEW_LABEL_ATTRIBUTE_SUCCESS,
  ADD_NEW_LABEL_ATTRIBUTE_ERROR,
  GET_LABEL_ATTRIBUTE,
  GET_LABEL_ATTRIBUTE_SUCCESS,
  GET_LABEL_ATTRIBUTE_ERROR,
  DELETE_ATTRIBUTE,
  DELETE_ATTRIBUTE_SUCCESS,
  DELETE_ATTRIBUTE_ERROR,
  UPDATE_ATTRIBUTE,
  UPDATE_ATTRIBUTE_SUCCESS,
  UPDATE_ATTRIBUTE_ERROR,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  CLONE_DATASETS,
  CLONE_DATASETS_SUCCESS,
  GET_VERSIONS_DATASETS_SOURCE,
  PUBLIC_DATASET,
  GET_TAGS_BY_PROJECT_ID,
  CREATE_NEW_TAG_SOURCE,
  EDIT_MEMBERS_TO_PROJECT,
  EDIT_MEMBERS_TO_PROJECT_SUCCESS,
  DELETE_MEMBERS_TO_PROJECT,
  DELETE_MEMBERS_TO_PROJECT_SUCCESS,
  DELETE_DATASET_DETAILS,
  GENERATE_MODEL,
  GET_PROJECT_CLASSIFICATIONS,
  GET_PROJECT_CLASSIFICATIONS_SUCCESS,
  GET_PROJECT_CLASSIFICATIONS_ERROR,
  ADD_NEW_CLASSIFICATION,
  ADD_NEW_CLASSIFICATION_ERROR,
  ADD_NEW_CLASSIFICATION_SUCCESS,
  UPDATE_CLASSIFICATION,
  UPDATE_CLASSIFICATION_SUCCESS,
  UPDATE_CLASSIFICATION_ERROR,
  DELETE_CLASSIFICATION,
  DELETE_CLASSIFICATION_SUCCESS,
  ADD_CLASS_FAILURE,
  DELETE_CLASS_FAILURE,
  UPDATE_CLASS_FAILURE,
  GET_LABEL_LIST,
  GET_LABEL_LIST_SUCCESS,
  GET_LABEL_LIST_FAILURE,
  IS_LOADING,
  SET_ERROR_ASSIGN_TASK,
  ASSIGN_TASK_TO_MEMBER_BY_DATASET,
  UNASSIGN_TASK_TO_MEMBER_BY_DATASET
} from "redux/actions";

export const generateModel = (payload) => ({
  type: GENERATE_MODEL,
  payload,
});

export const deleteDatasetDetails = ({payload, callback}) => ({
  type: DELETE_DATASET_DETAILS,
  payload,
  callback
});

export const actionError = (message) => ({
  type: ACTION_ERROR,
  payload: { message },
});

export const getListProjectsDetail = (selectedPageSize, currentPage) => ({
  type: GET_LIST_PROJECT_DETAIL,
  payload: { selectedPageSize, currentPage },
});
export const getListProjectsDetailSuccess = (
  totalPage,
  totalItem,
  projects,
) => ({
  type: GET_LIST_PROJECT_DETAIL_SUCCESS,
  payload: { totalPage, totalItem, projects },
});
export const getListProjectsDetailError = (message) => ({
  type: GET_LIST_PROJECT_DETAIL_ERROR,
  payload: { message },
});

export const setCurrentProject = (project) => ({
  type: SET_CURRENT_PROJECT,
  payload: { project },
});



export const createNewDataset = (
  projectId,
  datasetName,
  description,
  thumbnailPath,
  dataSource,
  classifyData,
) => ({
  type: CREATE_NEW_DATASET,
  payload: {
    projectId,
    datasetName,
    description,
    thumbnailPath,
    dataSource,
    classifyData,
  },
});
export const createNewDatasetSuccess = (status, datasetId, message) => ({
  type: CREATE_NEW_DATASET_SUCCESS,
  payload: { status, datasetId, message },
});
export const createNewDatasetError = (message) => ({
  type: CREATE_NEW_DATASET_ERROR,
  payload: { message },
});

export const getSummaryProjects = (projectId) => ({
  type: GET_SUMMARY_PROJECTS,
  payload: { projectId },
});
export const getSummaryProjectsSuccess = (totalItem, summary) => ({
  type: GET_SUMMARY_PROJECTS_SUCCESS,
  payload: { totalItem, summary },
});
export const getSummaryProjectsError = (message, messageId) => ({
  type: GET_SUMMARY_PROJECTS_ERROR,
  payload: { message, messageId },
});

export const getEmailList = () => ({
  type: GET_EMAIL_LIST,
  payload: {},
});

export const getEmailListSuccess = (mails) => ({
  type: GET_EMAIL_LIST_SUCCESS,
  payload: { mails },
});

export const getEmailListError = (message, messageId) => ({
  type: GET_EMAIL_LIST_ERROR,
  payload: { message, messageId },
});

export const getRoleList = () => ({
  type: GET_ROLE_LIST,
  payload: {},
});

export const getRoleListSuccess = (roles) => ({
  type: GET_ROLE_LIST_SUCCESS,
  payload: { roles },
});

export const getRoleListError = (message, messageId) => ({
  type: GET_ROLE_LIST_ERROR,
  payload: { message, messageId },
});

export const getAssignMemberList = (projectId) => ({
  type: GET_ASSIGN_MEMBER_LIST,
  payload: { projectId },
});

export const getAssignMemberListSuccess = (members, message) => ({
  type: GET_ASSIGN_MEMBER_LIST_SUCCESS,
  payload: { members, message },
});

export const getAssignMemberListError = (message, messageId) => ({
  type: GET_ASSIGN_MEMBER_LIST_ERROR,
  payload: { message, messageId },
});

export const createNewProject = (payload) => ({
  type: CREATE_NEW_PROJECT,
  payload,
});

export const createNewProjectSuccess = (data, message) => ({
  type: CREATE_NEW_PROJECT_SUCCESS,
  payload: { data, message },
});

export const getProjectById = (projectId) => ({
  type: GET_PROJECT_BY_ID,
  payload: { projectId },
});
export const getProjectByIdSuccess = (project, message) => ({
  type: GET_PROJECT_BY_ID_SUCCESS,
  payload: { project, message },
});
export const getProjectByIdError = (message, messageId) => ({
  type: GET_PROJECT_BY_ID_ERROR,
  payload: { message, messageId },
});

export const getFirstDatasetExistSource = (projectId) => ({
  type: GET_FIRST_DATASET_EXIST_SOURCE,
  payload: { projectId },
});
export const getFirstDatasetExistSourceSuccess = (datasetId, message) => ({
  type: GET_FIRST_DATASET_EXIST_SOURCE_SUCCESS,
  payload: { datasetId, message },
});
export const getFirstDatasetExistSourceError = (message, messageId) => ({
  type: GET_FIRST_DATASET_EXIST_SOURCE_ERROR,
  payload: { message, messageId },
});

export const getCurrentProjectMembers = (projectId, roleCode) => ({
  type: GET_CURRENT_PROJECT_MEMBERS,
  payload: { projectId, roleCode },
});
export const getCurrentProjectMembersSuccess = (users, message) => ({
  type: GET_CURRENT_PROJECT_MEMBERS_SUCCESS,
  payload: { users, message },
});
export const getCurrentProjectMembersError = (message, messageId) => ({
  type: GET_CURRENT_PROJECT_MEMBERS_ERROR,
  payload: { message, messageId },
});

export const assignTaskToMember = (projectId, assignMembers) => ({
  type: ASSIGN_TASK_TO_MEMBER,
  payload: { projectId, assignMembers },
});
export const assignTaskToMemberByDataset = (projectId, assignMembers) => ({
  type: ASSIGN_TASK_TO_MEMBER_BY_DATASET,
  payload: { projectId, assignMembers },
});
export const assignTaskToMemberSuccess = (message) => ({
  type: ASSIGN_TASK_TO_MEMBER_SUCCESS,
  payload: { message },
});
export const assignTaskToMemberError = (message, messageId, errorAssignTask = []) => ({
  type: ASSIGN_TASK_TO_MEMBER_ERROR,
  payload: { message, messageId, errorAssignTask },
});

export const unassignTaskToMember = (projectId, assignMembers) => ({
  type: UNASSIGN_TASK_TO_MEMBER,
  payload: { projectId, assignMembers },
});

export const unassignTaskToMemberByDataset = (projectId, assignMembers) => ({
  type: UNASSIGN_TASK_TO_MEMBER_BY_DATASET,
  payload: { projectId, assignMembers },
});

export const unassignTaskToMemberError = (message, messageId, errorAssignTask = []) => ({
  type: UNASSIGN_TASK_TO_MEMBER_ERROR,
  payload: { message, messageId, errorAssignTask },
});

export const unassignTaskToMemberSuccess = (message) => ({
  type: UNASSIGN_TASK_TO_MEMBER_SUCCESS,
  payload: { message },
});

export const getModuleByRoleInProject = (projectId) => ({
  type: GET_MODULE_BY_ROLE_IN_PROJECT,
  payload: { projectId },
});
export const getModuleByRoleInProjectSuccess = (moduleRole, message) => ({
  type: GET_MODULE_BY_ROLE_IN_PROJECT_SUCCESS,
  payload: { moduleRole, message },
});
export const getModuleByRoleInProjectError = (message, messageId) => ({
  type: GET_MODULE_BY_ROLE_IN_PROJECT_ERROR,
  payload: { message, messageId },
});

export const getProjectLabels = (projectId) => ({
  type: GET_PROJECT_LABELS,
  payload: { projectId },
});
export const getProjectLabelsSuccess = (payload) => ({
  type: GET_PROJECT_LABELS_SUCCESS,
  payload,
});
export const getProjectLabelsError = (message) => ({
  type: GET_PROJECT_LABELS_ERROR,
  payload: { message },
});

export const getLabelList = (payload) => ({
  type: GET_LABEL_LIST,
  payload,
});

export const getLabelListSuccess = (payload) => ({
  type: GET_LABEL_LIST_SUCCESS,
  payload,
});

export const getLabelListFailure = (payload) => ({
  type: GET_LABEL_LIST_FAILURE,
  payload,
});

export const deleteProjectById = (payload) => ({
  type: DELETE_PROJECT_BY_ID,
  payload: payload,
});
export const deleteProjectByIdSuccess = (projectId) => ({
  type: DELETE_PROJECT_BY_ID_SUCCESS,
  payload: { projectId },
});
export function addClass(payload) {
  return {
    type: ADD_CLASS,
    payload,
  };
}

export function addClassSuccess(payload) {
  return {
    type: ADD_CLASS_SUCCESS,
    payload,
  };
}

export function addClassFailure(payload) {
  return {
    type: ADD_CLASS_FAILURE,
    payload,
  };
}

export function deleteClass(payload) {
  return {
    type: DELETE_CLASS,
    payload,
  };
}

export function deleteClassSuccess(payload) {
  return {
    type: DELETE_CLASS_SUCCESS,
    payload,
  };
}

export function deleteClassFailure(payload) {
  return {
    type: DELETE_CLASS_FAILURE,
    payload,
  };
}

export function updateClass(payload) {
  return {
    type: UPDATE_CLASS,
    payload,
  };
}

export function updateClassSuccess(payload) {
  return {
    type: UPDATE_CLASS_SUCCESS,
    payload,
  };
}

export function updateClassFailure(payload) {
  return {
    type: UPDATE_CLASS_FAILURE,
    payload,
  };
}
export const exportDataset = (payload, callback) => ({
  type: EXPORT_DATASET,
  payload,
  callback
});
export const exportDatasetSuccess = (exportDatasetId, message, messageId) => ({
  type: EXPORT_DATASET_SUCCESS,
  payload: { exportDatasetId, message, messageId },
});
export const exportDatasetError = (message) => ({
  type: EXPORT_DATASET_ERROR,
  payload: { message },
});

export const getExportDataset = (
  projectId,
  selectedPageSize,
  currentPage,
  status,
) => ({
  type: GET_EXPORT_DATASET,
  payload: { projectId, selectedPageSize, currentPage, status },
});
export const getExportDatasetSuccess = (
  exportDatasetList,
  totalPage,
  totalItem,
  message,
) => ({
  type: GET_EXPORT_DATASET_SUCCESS,
  payload: { exportDatasetList, totalPage, totalItem, message },
});
export const getExportDatasetError = (message) => ({
  type: GET_EXPORT_DATASET_ERROR,
  payload: { message },
});

export const prepareCustomerData = (projectId) => ({
  type: PREPARE_CUSTOMER_DATA,
  payload: { projectId },
});
export const prepareCustomerDataSuccess = (data, message) => ({
  type: PREPARE_CUSTOMER_DATA_SUCCESS,
  payload: { data, message },
});
export const prepareCustomerDataError = (message, messageId) => ({
  type: PREPARE_CUSTOMER_DATA_ERROR,
  payload: { message, messageId },
});

export const getSourceStatus = () => ({
  type: GET_SOURCE_STATUS,
  payload: {},
});
export const getSourceStatusSuccess = (sourceStatus, message) => ({
  type: GET_SOURCE_STATUS_SUCCESS,
  payload: { sourceStatus, message },
});
export const getSourceStatusError = (message, messageId) => ({
  type: GET_SOURCE_STATUS_ERROR,
  payload: { message, messageId },
});

export const datasetAddMoreData = (
  projectId,
  datasetId,
  dataSource,
  classifyData,
) => ({
  type: DATASET_ADD_MORE_DATA,
  payload: { projectId, datasetId, dataSource, classifyData },
});
export const datasetAddMoreDataSuccess = (message, sourcesError) => ({
  type: DATASET_ADD_MORE_DATA_SUCCESS,
  payload: { message, sourcesError },
});
export const datasetAddMoreDataError = (message, sourcesError = []) => ({
  type: DATASET_ADD_MORE_DATA_ERROR,
  payload: { message, sourcesError },
});

export const inviteMembersToProject = (projectId, assignMembers, cb) => ({
  type: INVITE_MEMBERS_TO_PROJECT,
  payload: { projectId, assignMembers, cb },
});
export const inviteMembersToProjectSuccess = (projectId, data) => ({
  type: INVITE_MEMBERS_TO_PROJECT_SUCCESS,
  payload: { projectId, data },
});
export const editMembersToProject = (projectId, memberId, roleCode, cb) => ({
  type: EDIT_MEMBERS_TO_PROJECT,
  payload: { projectId, memberId, roleCode, cb },
});
export const editMembersToProjectSuccess = (projectId, data) => ({
  type: EDIT_MEMBERS_TO_PROJECT_SUCCESS,
  payload: { projectId, data },
});
export const deleteMembersToProject = (projectId, memberId, cb) => ({
  type: DELETE_MEMBERS_TO_PROJECT,
  payload: { projectId, memberId, cb },
});
export const deleteMembersToProjectSuccess = (projectId, data) => ({
  type: DELETE_MEMBERS_TO_PROJECT_SUCCESS,
  payload: { projectId, data },
});
export const resetSourceStatus = (
  projectId,
  datasetId,
  oldStatus,
  newStatus,
  sourceIds,
) => ({
  type: RESET_SOURCE_STATUS,
  payload: { projectId, datasetId, oldStatus, newStatus, sourceIds },
});
export const resetSourceStatusSuccess = (message) => ({
  type: RESET_SOURCE_STATUS_SUCCESS,
  payload: { message },
});
export const resetSourceStatusError = (message, messageId) => ({
  type: RESET_SOURCE_STATUS_ERROR,
  payload: { message, messageId },
});

export const getTotalApproveAnnotationEachClassInProject = (projectId) => ({
  type: GET_TOTAL_APPROVE_ANNOTATION_EACH_CLASS_IN_PROJECT,
  payload: { projectId },
});
export const getTotalApproveAnnotationEachClassInProjectSuccess = (
  project,
  message,
) => ({
  type: GET_TOTAL_APPROVE_ANNOTATION_EACH_CLASS_IN_PROJECT_SUCCESS,
  payload: { project, message },
});
export const getTotalApproveAnnotationEachClassInProjectError = (message, messageId) => ({
  type: GET_TOTAL_APPROVE_ANNOTATION_EACH_CLASS_IN_PROJECT_ERROR,
  payload: { message, messageId },
});

export const getTotalApproveImgClassInProject = (projectId, datasetIds, status) => ({
  type: GET_TOTAL_APPROVE_IMG_CLASS_IN_PROJECT,
  payload: { projectId, datasetIds, status },
});
export const getTotalApproveImgClassInProjectSuccess = (project, message) => ({
  type: GET_TOTAL_APPROVE_IMG_CLASS_IN_PROJECT_SUCCESS,
  payload: { project, message },
});
export const getTotalApproveImgClassInProjectError = (message) => ({
  type: GET_TOTAL_APPROVE_IMG_CLASS_IN_PROJECT_ERROR,
  payload: { message },
});

export const getLabelerStatusInProject = (projectId, labelerId, isLabeler) => ({
  type: GET_LABELER_STATUS_IN_PROJECT,
  payload: { projectId, labelerId, isLabeler },
});
export const getLabelerStatusInProjectSuccess = (labelerStatus, message) => ({
  type: GET_LABELER_STATUS_IN_PROJECT_SUCCESS,
  payload: { labelerStatus, message },
});
export const getLabelerStatusInProjectError = (message) => ({
  type: GET_LABELER_STATUS_IN_PROJECT_ERROR,
  payload: { message },
});

export const setCurrentLabel = (label) => ({
  type: SET_CURRENT_LABEL,
  payload: { label },
});

export const addNewLabelAttribute = (
  projectId,
  labelId,
  name,
  dataType,
  defaultValue,
) => ({
  type: ADD_NEW_LABEL_ATTRIBUTE,
  payload: { projectId, labelId, name, dataType, defaultValue },
});
export const addNewLabelAttributeSuccess = (data, message) => ({
  type: ADD_NEW_LABEL_ATTRIBUTE_SUCCESS,
  payload: { data, message },
});
export const addNewLabelAttributeError = (message) => ({
  type: ADD_NEW_LABEL_ATTRIBUTE_ERROR,
  payload: { message },
});

/* classification func */
export const getProjectClassifications = (projectId) => ({
  type: GET_PROJECT_CLASSIFICATIONS,
  payload: { projectId },
});
export const getProjectClassificationsSuccess = (classifications, message) => ({
  type: GET_PROJECT_CLASSIFICATIONS_SUCCESS,
  payload: { classifications, message },
});
export const getProjectClassificationsError = (message) => ({
  type: GET_PROJECT_CLASSIFICATIONS_ERROR,
  payload: { message },
});

export const addNewClassification = (projectId, name, dataType, items) => ({
  type: ADD_NEW_CLASSIFICATION,
  payload: { projectId, name, dataType, items },
});
export const addNewClassificationSuccess = (data, message) => ({
  type: ADD_NEW_CLASSIFICATION_SUCCESS,
  payload: { data, message },
});
export const addNewClassificationError = (message) => ({
  type: ADD_NEW_CLASSIFICATION_ERROR,
  payload: { message },
});

export const updateClassification = (
  projectId,
  classificationId,
  name,
  dataType,
  items,
) => ({
  type: UPDATE_CLASSIFICATION,
  payload: { projectId, classificationId, name, dataType, items },
});
export const updateClassificationSuccess = (data, message) => ({
  type: UPDATE_CLASSIFICATION_SUCCESS,
  payload: { data, message },
});
export const updateClassificationError = (message) => ({
  type: UPDATE_CLASSIFICATION_ERROR,
  payload: { message },
});

export const deleteClassification = (classificationId) => ({
  type: DELETE_CLASSIFICATION,
  payload: { classificationId },
});
export const deleteClassificationSuccess = (classificationId, message) => ({
  type: DELETE_CLASSIFICATION_SUCCESS,
  payload: { classificationId, message },
});

/* end - classification func */

export const getLabelAttribute = (labelId) => ({
  type: GET_LABEL_ATTRIBUTE,
  payload: { labelId },
});
export const getLabelAttributeSuccess = (data, message) => ({
  type: GET_LABEL_ATTRIBUTE_SUCCESS,
  payload: { data, message },
});
export const getLabelAttributeError = (message, messageId) => ({
  type: GET_LABEL_ATTRIBUTE_ERROR,
  payload: { message, messageId },
});

export const deleteAttribute = (projectId, labelId, attributeId) => ({
  type: DELETE_ATTRIBUTE,
  payload: { projectId, labelId, attributeId },
});
export const deleteAttributeSuccess = (labelId, attributeId, message) => ({
  type: DELETE_ATTRIBUTE_SUCCESS,
  payload: { labelId, attributeId, message },
});
export const deleteAttributeError = (message) => ({
  type: DELETE_ATTRIBUTE_ERROR,
  payload: { message },
});

export const updateAttribute = (
  projectId,
  attributeId,
  name,
  dataType,
  defaultValue,
) => ({
  type: UPDATE_ATTRIBUTE,
  payload: { projectId, attributeId, name, dataType, defaultValue },
});
export const updateAttributeSuccess = (attributeData, message) => ({
  type: UPDATE_ATTRIBUTE_SUCCESS,
  payload: { attributeData, message },
});
export const updateAttributeError = (message) => ({
  type: UPDATE_ATTRIBUTE_ERROR,
  payload: { message },
});

export const updateProject = (payload) => ({
  type: UPDATE_PROJECT,
  payload,
});
export const updateProjectSuccess = (data, message) => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload: { data, message },
});

export const cloneDatasets = (payload) => ({
  type: CLONE_DATASETS,
  payload,
});
export const cloneDatasetsSuccess = (data, message) => ({
  type: CLONE_DATASETS_SUCCESS,
  payload: { data, message },
});


export const getVersionsDataSetSource = (payload, callback) => ({
  type: GET_VERSIONS_DATASETS_SOURCE,
  payload,
  callback,
});
export const publicDataset = (payload) => ({
  type: PUBLIC_DATASET,
  payload,
});
export const getListTagByProjectId = (payload, callback) => ({
  type: GET_TAGS_BY_PROJECT_ID,
  payload,
  callback,
});
export const createNewTagSource = (payload, callback) => ({
  type: CREATE_NEW_TAG_SOURCE,
  payload,
  callback,
});

export const isLoading = (payload) => {
  return {
    type: IS_LOADING,
    payload,
  };
};

export const setErrorAssignTask = (payload) => ({
  type: SET_ERROR_ASSIGN_TASK,
  payload,
});


