import { initialState } from "./reducer";

// Selectors
export const selectNotifications = (state) =>
  state.notification.notifications || initialState.notifications;

export const selectPushNotifications = (state) =>
  state.notification.pushNotifications || initialState.notifications;

export const selectTotalNewNotifications = (state) =>
  state.notification.totalNewNotifications;

export const selectCurrentNotiPage = (state) => state.notification.currPage;

export const selectNotiFetchMore = (state) => state.notification.fetchMore;

export const selectNotiPageSize = (state) => initialState.pageSize;