import {
  START_CHANNEL,
  STOP_CHANNEL,
  AUTHENTICATED_SOCKET,
  UNAUTHORIZED_SOCKET,
  START_GENERATE_MODEL,
} from "./constants";

export const startGenerateModel = (payload) => ({
  type: START_GENERATE_MODEL,
  payload
});

export const startChannel = () => ({ type: START_CHANNEL });
export const stopChannel = () => ({ type: STOP_CHANNEL });

export const authenticatedSocket = () => ({ type: AUTHENTICATED_SOCKET });
export const unauthorizedSocket = () => ({ type: UNAUTHORIZED_SOCKET });
