import {
  SHOW_NOTI,
  REMOVE_NOTI,
  CLEAR_NOTI
} from 'redux/actions';

export const showNoti = (payload) => {
    return ({
    type: SHOW_NOTI,
    payload
})};

export const removeNotification = (payload) => ({
    type: REMOVE_NOTI,
    payload
});

export const clearNoti = () => ({
  type: CLEAR_NOTI
})

export const delayRemoveNotification = (payload) => {
  console.log("wait 2 seconds and remove notification");
  const timer = setTimeout(function () {
    removeNotification(payload);
  }, 700);

  clearTimeout(timer);
};
