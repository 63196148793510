import {
  SHOW_NOTI,
  REMOVE_NOTI,
  CLEAR_NOTI,
} from "redux/actions";

const initialState = {
  currNotiId: "",
  notifications: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NOTI: {
      const id = Date.now();
      return {
        currNotiId: id,
        notifications: [
          { ...action.payload, id },
          ...state.notifications,
        ],
      };
    }
    case REMOVE_NOTI:
      return {
        notifications: state.notifications.filter(
          (item) => item.id !== action.payload
        ),
      };
    case CLEAR_NOTI:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
export const selectNotifications = (state) =>
  state.notificationModal.notifications || initialState.notifications;
