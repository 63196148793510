import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
//import { baseUrl } from "constants/defaultValues";
import packageJson from "../package.json";
import { isProduction,gtmId } from './constants/defaultValues';
import { deleteAllCookies, getCookie } from "helpers/Utils";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TagManager from 'react-gtm-module'
import { GoogleOAuthProvider } from '@react-oauth/google';




const App = React.lazy(() => import(/* webpackChunkName: "App" */'./App'));

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register(`${baseUrl}/firebase-messaging-sw.js`) //'https://salf-admin.blueeye.ai/firebase-messaging-sw.js'
//     .then(function (registration) {
//       console.log("Registration successful, scope is:", registration.scope);
//     })
//     .catch(function (err) {
//       console.log("Service worker registration failed, error:", err);
//     });
// }


isProduction && gtmId && TagManager.initialize({ gtmId })


const refreshCacheAndReload = () => {
  const token = getCookie("Bearer");

  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => Promise.all(names.map(name => caches.delete(name))));
    console.log("caches cleared");
  }
  if (token) {
    localStorage.setItem('log', "AppRender l35");
    //deleteAllCookies();
  }

  // // delete browser cache and hard reload
  window.location.reload(true)
};
if (isProduction) {
  fetch("/meta.json")
    .then((response) => response.json())
    .then((meta) => {
      console.log("packageJson.buildDate", packageJson.buildDate);
      console.log("meta", meta);
      if (meta.buildDate !== packageJson.buildDate) {
        console.log("clear cache");
        refreshCacheAndReload();
      }
    });
}

ReactDOM.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<div className="loading" />}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <GoogleOAuthProvider clientId="389597180630-job4fibrsgrut1sdodpjgsdd8hheagro.apps.googleusercontent.com">
          <App />
        </GoogleOAuthProvider>
      </LocalizationProvider>
    </Suspense>
  </Provider>,
  document.getElementById("root"),
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.register();
