import {
  GET_MODEL_LIST,
  GET_MODEL_LIST_SUCCESS,
  GET_MODEL_LIST_FAILURE,
  GET_CUSTOM_MODEL_LIST,
  GET_CUSTOM_MODEL_LIST_SUCCESS,
  GET_CUSTOM_MODEL_LIST_FAILURE,
  CLOSE_MODEL_NOTI,
  GENERATE_MODEL_SUCCESS,
  GENERATE_MODEL_RESPONSE,
  GENERATE_MODEL_FAILURE,
  TRAINING_MODEL_SUCCESS,
  TRAINING_MODEL_RESPONSE,
  TRAINING_MODEL_FAILURE,
  SET_CURRENT_MODEL,
  GET_MODEL_IMAGE_URL,
  GET_MODEL_IMAGE_URL_SUCCESS,
  GET_DEFAULT_MODEL_LIST,
  GET_DEFAULT_MODEL_LIST_SUCCESS,
  GET_DEFAULT_MODEL_LIST_FAILURE,
  SET_OPEN_CHANGE_MODEL_STATUS_MODEL,
  UPDATE_CUSTOM_MODEL_STATUS,
  SET_OPEN_RETRAIN_MODEL,
  RETRAIN_MODEL,
  RETRAIN_MODEL_SUCCESS,
  RETRAIN_MODEL_FAILURE,
  GET_ACTIVE_MODEL_LIST,
  GET_ACTIVE_MODEL_LIST_SUCCESS,
  GET_ACTIVE_MODEL_LIST_FAILURE,
  AUTOMATIC_ANNOTATION,
  AUTOMATIC_ANNOTATION_SUCCESS,
  AUTOMATIC_ANNOTATION_ERROR,
  GET_LABELBOX_COUNTER,
  GET_LABELBOX_COUNTER_SUCCESS,
  GET_LABELBOX_COUNTER_ERROR,
  ADD_GENERATION_MODEL,
  ADD_GENERATION_MODEL_SUCCESS,
  ADD_GENERATION_MODEL_ERROR,
  DELETE_MODEL_SUCCESS
} from "redux/actions";

export const setCurrentModel = (payload) => ({
  type: SET_CURRENT_MODEL,
  payload,
});

export const generateModelResponse = (payload) => ({
  type: GENERATE_MODEL_RESPONSE,
  payload,
});

export const generateModelSuccess = (payload) => ({
  type: GENERATE_MODEL_SUCCESS,
  payload,
});

export const generateModelFailure = (payload) => ({
  type: GENERATE_MODEL_FAILURE,
  payload,
});

export const trainingModelResponse = (payload) => ({
  type: TRAINING_MODEL_RESPONSE,
  payload,
});

export const trainingModelSuccess = (payload) => ({
  type: TRAINING_MODEL_SUCCESS,
  payload,
});

export const trainingModelFailure = (payload) => ({
  type: TRAINING_MODEL_FAILURE,
  payload,
});

export const getModelList = (payload) => ({
  type: GET_MODEL_LIST,
  payload,
});

export const getCustomModelList = (payload) => ({
  type: GET_CUSTOM_MODEL_LIST,
  payload,
});

export const getModelListSuccess = (payload) => ({
  type: GET_MODEL_LIST_SUCCESS,
  payload,
});

export const getCustomModelListSuccess = (payload) => ({
  type: GET_CUSTOM_MODEL_LIST_SUCCESS,
  payload,
});

export const getModelListFailure = (payload) => ({
  type: GET_MODEL_LIST_FAILURE,
  payload,
});

export const getCustomModelListFailure = (payload) => ({
  type: GET_CUSTOM_MODEL_LIST_FAILURE,
  payload,
});

export const getDefaultModelList = (payload) => ({
  type: GET_DEFAULT_MODEL_LIST,
  payload,
});

export const getDefaultModelListSuccess = (payload) => ({
  type: GET_DEFAULT_MODEL_LIST_SUCCESS,
  payload,
});

export const getDefaultModelListFailure = (payload) => ({
  type: GET_DEFAULT_MODEL_LIST_FAILURE,
  payload,
});

export const closeModelNoti = (payload) => ({
  type: CLOSE_MODEL_NOTI,
  payload,
});

export const getModelImageUrl = (payload) => ({
  type: GET_MODEL_IMAGE_URL,
  payload,
});

export const getModelImageUrlSuccess = (payload) => ({
  type: GET_MODEL_IMAGE_URL_SUCCESS,
  payload,
});

export const setOpenChangeModelStatusModel = (payload = {}) => ({
  type: SET_OPEN_CHANGE_MODEL_STATUS_MODEL,
  payload,
});

export const updateCustomModelStatus = ({ payload, callback } = {}) => ({
  type: UPDATE_CUSTOM_MODEL_STATUS,
  payload,
  callback,
});

export const setOpenRetrainModel = (payload = {}) => ({
  type: SET_OPEN_RETRAIN_MODEL,
  payload,
});

export const retrainModel = ({ payload, callback } = {}) => ({
  type: RETRAIN_MODEL,
  payload,
  callback,
});

export const retrainModelSuccess = (payload) => ({
  type: RETRAIN_MODEL_SUCCESS,
  payload,
});

export const retrainModelFailure = (payload) => ({
  type: RETRAIN_MODEL_FAILURE,
  payload,
});

export const getActiveModelList = (payload) => ({
  type: GET_ACTIVE_MODEL_LIST,
  payload,
});

export const getActiveModelListSuccess = (payload) => ({
  type: GET_ACTIVE_MODEL_LIST_SUCCESS,
  payload,
});

export const getActiveModelListFailure = (payload) => ({
  type: GET_ACTIVE_MODEL_LIST_FAILURE,
  payload,
});

export const automaticAnnotation = ({ payload, callback }) => {
  return {
    type: AUTOMATIC_ANNOTATION,
    payload,
    callback
  };
};

export const automaticAnnotationSuccess = (payload) => {
  return {
    type: AUTOMATIC_ANNOTATION_SUCCESS,
    payload,
  };
};

export const automaticAnnotationError = ({ payload, callback }) => {
  return {
    type: AUTOMATIC_ANNOTATION_ERROR,
    payload,
    callback
  };
};

export const getLabelboxCounter = ({ payload, callback }) => {
  return {
    type: GET_LABELBOX_COUNTER,
    payload,
    callback
  };
};


export const getLabelboxCounterSuccess = (payload) => {
  return {
    type: GET_LABELBOX_COUNTER_SUCCESS,
    payload,
  };
};

export const getLabelboxCounterError = (payload) => {
  return {
    type: GET_LABELBOX_COUNTER_ERROR,
    payload,
  };
};

export const addGenerationModel = ({ payload, callback }) => ({
  type: ADD_GENERATION_MODEL,
  payload,
  callback
});

export const addGenerationModelSuccess = (payload) => ({
  type: ADD_GENERATION_MODEL_SUCCESS,
  payload,
});

export const addGenerationModelError = (payload) => ({
  type: ADD_GENERATION_MODEL_ERROR,
  payload,
});

export const deleteModelSuccess = (payload) => ({
  type: DELETE_MODEL_SUCCESS,
  payload,
});


