import axios from "axios";
import { authEndpoint, coreToolServerURL } from "constants/defaultValues";
import {
  browserName,
  isBrowser,
  isAndroid,
  isWinPhone,
  isIos
} from "react-device-detect";

import { setCookie, getCookie } from "../helpers/Utils";
import { environment } from "../constants/defaultValues";

fetch("https://api.ipify.org/?format=json").then(response => response.json()).then(data => {
  setCookie('localIP', data.ip);
});

const platform = () => {
  if (isBrowser) {
    return "Browser";
  }
  if (isAndroid) {
    return "Android";
  }
  if (isWinPhone) {
    return "Win Phone";
  }
  if (isIos) {
    return "Ios";
  }
}

export default axios.create({
  baseURL: `${authEndpoint}`,
  withCredentials: true,
  responseType: "json",
  headers: {
    device: `${browserName}`,
    env: environment,
    type: 'admin',
    platform: platform(),
    ip: getCookie('localIP'),
    locale: localStorage.getItem('currentLanguage'),
  }
});

export const coreToolAxios = axios.create({
  baseURL: `${coreToolServerURL}`,
  withCredentials: true,
  responseType: "json",
  headers: {
    device: `${browserName}`,
    env: environment,
    type: 'admin',
    platform: platform(),
    ip: getCookie('localIP'),
    locale: localStorage.getItem('currentLanguage'),
  },
}) 