import AppstoreOutlined from "assets/icons/AppstoreOutlined";
import UnorderedList from "assets/icons/UnorderedList";
import React from "react";
import { getCookie } from "helpers/Utils";
import IntlMessages from "helpers/IntlMessages";

// import  from "../../serviceConfig.json";
const configDev =
  process.env.BRANCH_SOURCE_DEPLOY === "master"
    ? require("../../serviceConfig.json")
    : process.env.BRANCH_SOURCE_DEPLOY === "dev"
    ? require("../../serviceConfig_dev.json")
    : require("../../serviceConfig.json");

// config
// const configDev = require("./serviceConfig_dev.json");

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" },
];

const env = process.env.NODE_ENV;

let config = configDev;
const { projectConfig } = configDev;

if (env === "production") {
  config = configDev;
} else {
  config = { ...configDev, baseUrl: window.location.origin };
}

// export const firebaseConfig = {
//   apiKey: "AIzaSyC7oudwev3Bi3hLxxGczzB8DLNxJ7aC1oM",
//   authDomain: "seta-salf.firebaseapp.com",
//   databaseURL: "https://seta-salf.firebaseio.com",
//   projectId: "seta-salf",
//   storageBucket: "seta-salf.appspot.com",
//   messagingSenderId: "840010130280",
//   appId: "1:840010130280:web:be227f73f40e3e7201acdd",
//   measurementId: "G-VXHJZ7HFK1",
// };

export const sentryDNS =
  "https://1441987614154fce921d203fde45fa2d@sentry.blueeye.ai/2";

export const amazonS3Config = config.amazonS3Config;
export const minioConfig = config.minioConfig;
export const GATrackingId = config.GATrackingId;
export const gtmId = config.gtmId;

export const authEndpoint = config.authEndpoint;
export const token = getCookie("Bearer");
export const commonHeader = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
};

export const baseUrl = config.baseUrl;
export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";
export const videoLabelingUrl = config.videoLabelingUrl;
export const imageLabelingUrl = config.imageLabelingUrl;
export const defaultDomain = config.defaultDomain;
export const docUrl = "https://docs.blueeye.ai/#/";
export const socketServerURL = config.socketServerURL;
export const coreToolServerURL = config.coreToolServerURL;

export const datasetPageSizes = [8, 20, 40, 100];
export const datasetDetailPageSizes = [20, 50, 100, 200];

export const datasetDetailsViewTypes = [
  { value: "list", icon: UnorderedList },
  { value: "grid", icon: AppstoreOutlined },
];

/*
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const themeColorStorageKey = "__theme_color";
export const isMultiColorActive = true;
export const defaultColor = "light.blue";
export const isDarkSwitchActive = true;
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";

export const mainLogo = projectConfig.LOGO;

export const mapboxAccessToken =
  "pk.eyJ1IjoiY2h1dGI2Nzk2IiwiYSI6ImNrZ2x6cGJ1cDBuMXIzMW1uOGh3bmQydnYifQ._ZcolVw99u-_DaP6eHNb3A";

export const logStashUrl = configDev.logStashUrl;
export const environment = configDev.env;

export const isProduction = env === "production";
export { projectConfig };

export const packagesName = ["FREE", "STARTER", "PRO", "ENTERPRISE"];

export const socketOption = {
  reconnect: true,
  transports: ["websocket"],
};

export const sampleClassificationAnnotation = [
  "buf_1.jpg, buffalo",
  "rhi_2.jpg, rhino",
  "rhi_3.jpg, rhino",
  "img_4.jpg, rhino,buffalo",
];

export const sampleYoloAnnotation = [
  "0 0.256835 0.546859 0.562551 0.235664",
  "2 0.354446 0.522233 0.846542 0.333562",
  "4 0.946123 0.123456 0.583612 0.999368",
  "2 0.633549 0.556222 0.336558 0.256487",
];

export const sampleObjNames = ["dog", "cat", "pig", "monkey", "duck"];

export const sampleObjectDetectionAnnotation = {
  info: {
    year: 2021,
    version: "1.0.0",
    description: "production by seta-international",
    contributor: null,
    url: null,
    date_created: 1637466678694,
  },
  images: [
    {
      id: 1,
      width: 640,
      height: 427,
      file_name: "file_1.jpg",
      license: 0,
      flickr_url: null,
      coco_url: "https://abc.com/file_1.jpg",
      date_captured: 1637466678694,
    },
    {
      id: 2,
      width: 591,
      height: 640,
      file_name: "file_1.jpg",
      license: 0,
      flickr_url: null,
      coco_url: "https://abc.com/file_1.jpg",
      date_captured: 1637466678694,
    },
  ],
  annotations: [
    {
      id: 0,
      image_id: 1,
      category_id: 0,
      segmentation: null,
      bbox: [155.01, 171.22, 432.03, 466.57],
      iscrowd: 0,
    },
    {
      id: 1,
      image_id: 2,
      category_id: 1,
      segmentation: null,
      bbox: [0.74, 1.4, 285.52, 275.24],
      iscrowd: 0,
    },
  ],
  categories: [
    {
      id: 0,
      name: "Elephant",
      color: "rgb(208, 2, 27)",
    },
    {
      id: 1,
      name: "Rhino",
      color: "rgb(189, 16, 224)",
    },
  ],
  licenses: [
    {
      id: 0,
      name: "product by seta-international",
      url: null,
    },
  ],
};

export const sampleTagsJson = [
  {
    imageName: "image1.jpg",
    tags: ["dog", "animals"],
  },
  {
    imageName: "image2.jpg",
    tags: ["cat", "animals"],
  },
];

export const attributeOptions = (intl) => [
  {
    value: "text",
    label: intl.formatMessage({ id: "classes.text_attribute" }),
  },
  {
    value: "array",
    label: intl.formatMessage({ id: "classes.array_attribute" }),
  },
  {
    value: "number",
    label: intl.formatMessage({ id: "classes.number_attribute" }),
  },
  {
    value: "date",
    label: intl.formatMessage({ id: "classes.date_attribute" }),
  },
];

export const statusList = [
  {
    value: "open",
    label: <IntlMessages id="issue-status.open" />,
  },
  {
    value: "resolved",
    label: <IntlMessages id="issue-status.resolved" />,
  },
];

export const baseModelList = [
  {
    label: "Yolo V5",
    value: "yolo_v5",
    key: "yolo_v5",
  },
];

export const benchmarkFilterList = [
  {
    value: null,
    label: "All",
  },
  {
    value: false,
    label: "No benchmark",
  },
  {
    value: true,
    label: "Benchmark",
  },
];
