import {
    GET_ISSUE_LIST,
    GET_ISSUE_LIST_SUCCESS,
    GET_ISSUE_LIST_ERROR
} from "redux/actions";

export const getIssueList = (payload) => ({
    type: GET_ISSUE_LIST,
    payload: { ...payload },
});

export const getIssueListSuccess = (payload) => ({
    type: GET_ISSUE_LIST_SUCCESS,
    payload
});

export const getIssueListError = (message) => ({
    type: GET_ISSUE_LIST_ERROR,
    payload: { message },
});


