/* eslint-disable */
import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import get from "lodash/get";
import moment from "moment";

import { showNoti } from "redux/actions";

import {
    GET_USER_LIST,
    GET_USER_CHART,
    ADD_USER,
    UPDATE_USER,
    GET_USER_REPORT,
} from "redux/actions";

import {
    getUserListSuccess,
    getUserListError,
    getUserChartSuccess,
    getUserChartError,
    getUserReportSuccess,
    getUserReportFailure,
} from './actions'

import {
    SUCCESS_CASE,
    ERROR_CASE,
  } from "components/notificationModal/constants";

import {
    getUserListApi,
    getUserChartApi,
    addNewUser,
    updateUser,
    getUserReportApi,
} from './api';

import {
    getUserLoginChartData,
    getAllLoginData,
} from "redux/dashboard/api";

import {
    selectLoginData,
} from "./selectors";

export function* watchGetUserList() {
    yield takeEvery(GET_USER_LIST, getUserListData);
}

function* getUserListData({ payload }) {
    const { selectedPageSize, currentPage, status, uniqueId, email, startTime, endTime } = payload;
    try {
        const response = yield call(getUserListApi, selectedPageSize, currentPage, status, uniqueId, email, startTime, endTime);
        const statusCode = get(response, "status", "");
        if (statusCode === 200) {
            let result = [];
            const { users, totalItem, totalPage } = get(response,"data.data")
            users.map((user, index) => {
                result.push({
                    index,
                    ...user
                });
            });
            yield put(getUserListSuccess({userList: result, totalItem, totalPage}));
        } else {
            yield put(getUserListError("Get Users Error!"));
        }
    } catch (error) {
        yield put(getUserListError("Get Users Error!"));
    }
}

const templateSmallChart =(title, labels, total, data)=>{
    return {
        labels,
        total,
        title,
        datasets: [
            {
                pointRadius: 0,
                pointBorderWidth: 2,
                pointHoverRadius: 0,
                backgroundColor: '#E9F2F7',
                borderColor: '#6BAFD9',
                fill: true,
                borderWidth: 2,
                data,
                datalabels: {
                    align: "end",
                    anchor: "end",
                },
            },
        ],
    }
}

const templatePieChart = (data)=>{
    if(data.reduce((previousValue, currentValue) => previousValue + currentValue) == 0) return ""
    return {
        labels: ['Total Active User', 'Total Inactive User'],
        color: 'rgba(255, 255, 255, 1)',
        datasets: [
          {
            label: '# of Votes',
            data,
            backgroundColor: [
              'rgb(68, 114, 196)',
              'rgb(237, 128, 53)',
            ],
            borderColor:[
              'rgba(255, 255, 255, 1)',
              'rgba(255, 255, 255, 1)',
            ],
            borderWidth: 0,
            datalabels: {
                color: 'rgb(255, 255, 255)'
            }
          },
        ],
      };
}
const prepareChart = (intl, type, totalUserPreviousStartTime, newUserChartData, statusUserChartData)=>{
    let labels = []
    switch(type){
        case 'date':
            labels = newUserChartData.map(item => moment(item.the_date).format('MM/DD/YYYY'))
            break;
        case 'month':
            labels = newUserChartData.map(item => moment(item.the_date).format('MM/YYYY'))
            break;
        default:
            labels = newUserChartData.map(item => moment(item.the_date).format('H[h] MM/DD/YYYY'))
            break;
    }
    let dataNewUser = []
    let dataActiveUser = []
    let dataInactiveUser = []

    let totalUser = 0;
    let totalNewUser = 0;
    let totalActiveUser = 0;
    let totalInactiveUser = 0;
    let totalUserData = [];

    totalUserPreviousStartTime.forEach(element => {
        if(!element.is_verified) totalInactiveUser += +element.total_user
        if(element.is_verified) totalActiveUser += +element.total_user
        totalUser += +element.total_user
    });
    for (const item of newUserChartData) {
        
        let inactiveUser = statusUserChartData.find(statusItem => statusItem.the_date === item.the_date && !statusItem.is_verified)
        let activeUser = statusUserChartData.find(statusItem => statusItem.the_date === item.the_date && statusItem.is_verified)
        let currentActiveUser = activeUser?(activeUser.total_user?activeUser.total_user:0):0
        let currentInactiveUser = inactiveUser?(inactiveUser.total_user?inactiveUser.total_user:0):0
        let currentUser = item.total_user?+item.total_user:0

        totalUser += currentUser
        totalNewUser += currentUser
        totalActiveUser += +currentActiveUser
        totalInactiveUser += +currentInactiveUser

        // Line Chart
        dataInactiveUser.push(totalInactiveUser)
        dataActiveUser.push(totalActiveUser)
        dataNewUser.push(currentUser)
        // small chart
        totalUserData.push(totalUser)
    }
    const datasetNewUser = {
        label: 'New User',
        data: dataNewUser,
        fill: false,
        backgroundColor: 'rgb(0, 255, 64)',
        borderColor: 'rgb(0, 255, 64)',
        yAxisID: 'y-axis-1',
    }
    const datasetActiveUser = {
        label: 'Total Active User',
        data: dataActiveUser,
        fill: false,
        backgroundColor: 'rgb(68, 114, 196)',
        borderColor: 'rgb(68, 114, 196)',
        yAxisID: 'y-axis-1',
    }
    const datasetInactiveUser = {
        label: 'Total Inactive User',
        data: dataInactiveUser,
        fill: false,
        backgroundColor: 'rgb(237, 128, 53)',
        borderColor: 'rgb(237, 128, 53)',
        yAxisID: 'y-axis-1',
    }

    const smallLineChartTotalUserData = templateSmallChart("Total User",labels,totalUser,totalUserData)
    const smallLineChartNewUserData = templateSmallChart("New User",labels,totalNewUser,dataNewUser)
    const smallLineChartActiveUserData = templateSmallChart("Total Active User",labels,totalActiveUser,dataActiveUser)
    const smallLineChartInactiveUserData = templateSmallChart("Total Inactive User",labels,totalInactiveUser,dataInactiveUser)
    const pieChartUserData = templatePieChart([totalActiveUser, totalInactiveUser])
    const datasets = [datasetNewUser, datasetActiveUser, datasetInactiveUser]
    const lineChartData = {labels, datasets}
    return {lineChartData, smallLineChartTotalUserData, smallLineChartNewUserData, smallLineChartActiveUserData, smallLineChartInactiveUserData, pieChartUserData}
}

export function* watchGetUserChart() {
    yield takeEvery(GET_USER_CHART, getUserChartData);
}

function* getUserChartData({ payload }) {
    const { intl, type, startTime, endTime } = payload;
    try {
        const response = yield call(getUserChartApi, type, startTime, endTime);
        const statusCode = get(response, "status", "");
        if (statusCode === 200) {
            const { newUserChartData, statusUserChartData, totalUserPreviousStartTime } = get(response, "data.data")

            const {lineChartData,
                 smallLineChartTotalUserData,
                 smallLineChartNewUserData,
                 smallLineChartInactiveUserData,
                 smallLineChartActiveUserData,
                 pieChartUserData,
            } = prepareChart(intl, type, totalUserPreviousStartTime, newUserChartData, statusUserChartData);
            yield put(getUserChartSuccess({userChart: {lineChartData, smallLineChartTotalUserData, smallLineChartNewUserData, smallLineChartActiveUserData, smallLineChartInactiveUserData, pieChartUserData}}));
        } else {
            yield put(getUserChartError("Get Users Chart Error!"));
        }
    } catch (error) {
        yield put(getUserChartError("Get Users Chart Error!"));
    }
}

function* addUserSaga(action) {
    const { payload, callback, failedCallback } = action.payload;
    try {
        const response = yield call(addNewUser, { ...payload });
        const status = get(response, ['data', 'status']);
        const message = get(response, ['data', 'message'], '');
        if (response.status === 200 && status) {
            if (callback) callback(response.data);
                yield put(showNoti({
                    type: SUCCESS_CASE,
                    message: `Add user successfully`,
                    messageId: "notification.add-user-success",
                }));
        } else {
            failedCallback && failedCallback();
            yield put(showNoti({
                type: ERROR_CASE,
                message: `Add user error! ${message}`,
                messageId: "notification.add-user-error",
                intlValues: {
                    message
                }
            }));
        }
    }
    catch(error) {
        failedCallback && failedCallback();
        yield put(showNoti({
            type: ERROR_CASE,
            message: `Add user error!`,
            messageId: "notification.add-user-error",
            intlValues: {
                message: ""
            }
        }));
    }
  }

  function* updateUserSaga(action) {
    const { payload, callback } = action.payload;
    try {
        const response = yield call(updateUser, { ...payload });
        const status = get(response, ['data', 'status']);
        const message = get(response, ['data', 'message'], '');
        if (response.status === 200 && status) {
            if (callback) callback(response.data);
                yield put(showNoti({
                    type: SUCCESS_CASE,
                    message: `Update user successfully`,
                    messageId: "notification.update-user-success",
                }));
        } else {
            yield put(showNoti({
                type: ERROR_CASE,
                message: `Update user error! ${message}`,
                messageId: "notification.update-user-error",
                intlValues: {
                    message
                }
            }));
        }
    }
    catch(error) {
        yield put(showNoti({
            type: ERROR_CASE,
            message: `Update user error!`,
            messageId: "notification.update-user-error",
            intlValues: {
                message: "",
            },
        }));
    }
  }

function* getUserReportSaga({ payload }) {
    const { startTime, endTime } = payload;
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);
    try {
        // if we save preparedLoginData locally in first load, it will be hard to detect if the date filter changed or not
        const loginResponse = yield call(getUserLoginChartData);
        const totalLogin = get(loginResponse, ['data', 'hits', 'total', 'value'], 0);
        const allLoginResponse = yield call(getAllLoginData, totalLogin);
        const loginData = get(allLoginResponse, ['data', 'hits', 'hits'], []);
        let preparedLoginData = loginData.reduce((acc, data) => {
            const currentData = acc.find(_acc => _acc.email === data._source.userData.email);
            if (!currentData) {
                const currentLoginData = loginData.filter(_data => {
                    const currentTimestamp = get(_data, ['_source', '@timestamp']);
                    const currentDate = new Date(currentTimestamp);
                    return _data._source.userData.email === data._source.userData.email &&
                    startDate <= currentDate && endDate >= currentDate
                });
                const lastLogin = get(currentLoginData, [0, '_source', '@timestamp']);
                acc.push({
                    last_login: lastLogin,
                    total_login: currentLoginData.length,
                    email: data._source.userData.email,
                });
            }
            return acc;
        }, []);

        // step 1: sort by conditions
        // if (sortByLastLogin && sortByTotalLogin) {
        //     // prepapredLoginData = 
        // }
        // step 2: take data from login data
        // step 3: get userReport from email list in login data
       
        const userReportResponse = yield call(getUserReportApi, {...payload});
        let userReport = get(userReportResponse, ['data', 'data', 'userList'], []);
        const totalPage = get(userReportResponse, ['data', 'data', 'totalPage'], 0);
        const totalItem = get(userReportResponse, ['data', 'data', 'totalItem'], 0);
        const status = get(userReportResponse, ['data', 'status']);
        const message = get(userReportResponse, ['data', 'message'], '');
        
        if (userReportResponse.status === 200 && status) {


            userReport = userReport.map(user => {
                const email = get(user, 'email');
                const currentLoginData = preparedLoginData.find(data => data.email === email);
                if (currentLoginData) {
                    user.last_login = currentLoginData.last_login;
                    user.total_login = currentLoginData.total_login;
                } else {
                    user.total_login = 0;
                }
                return user;
            });
            
            yield put(getUserReportSuccess({
                userReport,
                totalPage,
                totalItem,
            }));
        } else {
            yield put(showNoti({
                type: ERROR_CASE,
                message: `Fetch user error! ${message}`,
                messageId: "notification.fetch-user-error",
                intlValues: {
                    message
                }
            }));
            yield put(getUserReportFailure());
        }
    }
    catch (error) {
        yield put(showNoti({
            type: ERROR_CASE,
            message: `Fetch user error!`,
            messageId: "notification.fetch-user-error",
            intlValues: {
                message: ""
            }
        }));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchGetUserList),
        fork(watchGetUserChart),
        takeEvery(ADD_USER, addUserSaga),
        takeEvery(UPDATE_USER, updateUserSaga),
        takeEvery(GET_USER_REPORT, getUserReportSaga),
    ]);
}
