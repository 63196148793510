import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import get from "lodash/get";
import { setCookie, getCookie } from "helpers/Utils";
import parseJson from "parse-json";

import {
  GET_LIST_MEMBERS,
  MAKE_INACTIVE_MEMBER,
  DELETE_MEMBER,
  RESTORE_ACTIVE_MEMBER,
  GET_LIST_PROJECT,
  GET_LIST_ROLE,
  ASIGN_PROJECT_TO_MEMBER,
  GET_MEMBER_BY_EMAIL,
  UPDATE_MEMBER,
  ADD_MEMBER,
  GET_LIST_SITE_ADMIN_MEMBERS,
  DELETE_MEMBER_FROM_PROJECT,
  GET_ALL_MEMBER
} from "redux/actions";

import {
  getListMembersSuccess,
  getListMembersError,
  makeInactiveMemberSuccess,
  makeInactiveMemberError,
  restoreActiveMemberSuccess,
  restoreActiveMemberError,
  deleteMemberSuccess,
  deleteMemberError,
  getListProjectSuccess,
  getListProjectError,
  getListRoleSuccess,
  getListRoleError,
  assignProjectToMemberSuccess,
  assignProjectToMemberError,
  getMemberByEmailSuccess,
  getMemberByEmailError,
  getAllMemberSuccess,
  getAllMemberFailed,
} from "./actions";

import {
  getListMembers,
  makeInactiveMember,
  restoreActiveMember,
  deleteMember,
  getListProject,
  getListRole,
  assignProjectToMember,
  getMemberByEmail,
  addNewMember,
  getAllSiteAdmin,
  updateMember,
  deleteMemberFromProject,
  getAllUsers,
} from "./api";

import {
  SUCCESS_CASE,
  ERROR_CASE,
} from "components/notificationModal/constants";
import { showNoti } from "redux/actions";

import {
  updateUserInforSuccess,
} from "redux/auth/actions";
export function* watchGetListMembers() {
  yield takeEvery(GET_LIST_MEMBERS, getListMembersInOrganization);
}

function* getListMembersInOrganization({ payload }) {
  const { selectedPageSize, currentPage, selectedStatus, projectId } = payload;
  try {
    const response = yield call(getListMembers, selectedPageSize, currentPage, selectedStatus, projectId);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { totalPage, totalItem, members } = get(
        response,
        "data.data",
        {
          totalItem: 0,
          totalPage: 0,
          members: [],
        },
      );
      yield put(getListMembersSuccess(totalPage, totalItem, members));
    } else {
      yield put(getListMembersError("Get Members Error!"));
      return;
    }
  } catch (error) {
    yield put(getListMembersError("Get Members Error!"));
    return;
  }
}

function* getListSiteAdminMembersSaga() {
  try {
    const response = yield call(getAllSiteAdmin);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { totalPage, totalItem, members } = get(
        response,
        "data.data",
        {
          totalItem: 0,
          totalPage: 0,
          members: [],
        },
      );
      yield put(getListMembersSuccess(totalPage, totalItem, members));
    } else {
      yield put(getListMembersError("Get Members Error!"));
      return;
    }
  } catch (error) {
    yield put(getListMembersError("Get Members Error!"));
    return;
  }
}

function* getAllMembersSaga() {
  try {
    const response = yield call(getAllUsers);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const data = get(
        response,
        "data.data",
        [],
      );
      yield put(getAllMemberSuccess(data));
    } else {
      yield put(getAllMemberFailed("Get Members Error!"));
      return;
    }
  } catch (error) {
    yield put(getAllMemberFailed("Get Members Error!"));
    return;
  }
}

export function* watchMakeInactiveMember() {
  yield takeEvery(MAKE_INACTIVE_MEMBER, makeInactiveMemberByMemberIds);
}

function* makeInactiveMemberByMemberIds({ payload }) {
  const { memberIds } = payload;
  try {
    const response = yield call(makeInactiveMember, memberIds);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data", { status: false, message: "" });
      if (status === true) {
        yield put(makeInactiveMemberSuccess(message));
      } else {
        yield put(makeInactiveMemberError(message));
      }
    } else {
      yield put(makeInactiveMemberError("Inactive Member Error!"));
    }
  } catch (error) {
    yield put(makeInactiveMemberError("Inactive Member Error!"));
  }
}

export function* watchRestoreActiveMember() {
  yield takeEvery(RESTORE_ACTIVE_MEMBER, restoreActiveMemberByMemberIds);
}

function* restoreActiveMemberByMemberIds({ payload }) {
  const { memberIds } = payload;
  try {
    const response = yield call(restoreActiveMember, memberIds);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data", { status: false, message: "" });
      if (status === true) {
        yield put(restoreActiveMemberSuccess(message));
      } else {
        yield put(restoreActiveMemberError(message));
      }
    } else {
      yield put(makeInactiveMemberError("Restore Active Member Error!"));
    }
  } catch (error) {
    yield put(makeInactiveMemberError("Restore Active Member Error!"));
  }
}

export function* watchDeleteMember() {
  yield takeEvery(DELETE_MEMBER, deleteMemberByMemberIds);
}

function* deleteMemberByMemberIds({ payload }) {
  const { id, callback } = payload;
  try {
    const response = yield call(deleteMember, id);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data", { status: false, message: "" });
      if (status === true) {
        yield put(deleteMemberSuccess(message));
        callback && callback();
      } else {
        yield put(deleteMemberError(message));
      }
    } else {
      yield put(deleteMemberError("Delete Member Error!"));
    }
  } catch (error) {
    yield put(deleteMemberError("Delete Member Error!"));
  }
}

export function* watchGetListProject() {
  yield takeEvery(GET_LIST_PROJECT, getListProjectInOrganization);
}

function* getListProjectInOrganization({ payload }) {
  try {
    const response = yield call(getListProject);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(
        response,
        "data",
      );
      if (status === true) {
        const { projects } = get(
          response,
          "data.data",
          {
            projects: [],
          },
        );
        yield put(getListProjectSuccess(message, projects));
      } else {
        yield put(getListProjectError(message));
      }
    } else {
      yield put(getListProjectError("Error when get list projects"));
    }
  } catch (error) {
    yield put(getListProjectError("Error when get list projects"));
  }
}

export function* watchGetListRole() {
  yield takeEvery(GET_LIST_ROLE, getListRoleInOrganization);
}

function* getListRoleInOrganization({ payload }) {
  try {
    const response = yield call(getListRole);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(
        response,
        "data",
      );
      if (status === true) {
        const { data: roles } = get(
          response,
          "data",
          {
            roles: [],
          },
        );
        yield put(getListRoleSuccess(message, roles));
      } else {
        yield put(getListRoleError(message));
      }
    } else {
      yield put(getListRoleError("Error when get list roles"));
    }
  } catch (error) {
    yield put(getListRoleError("Error when get list roles"));
  }
}

export function* watchAssignProjectToMember() {
  yield takeEvery(ASIGN_PROJECT_TO_MEMBER, assignProjectToMemberInOrganization);
}

function* assignProjectToMemberInOrganization({ payload }) {
  const { memberId, data } = payload;
  try {
    const response = yield call(assignProjectToMember, memberId, data);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(
        response,
        "data",
      );
      if (status === true) {
        yield put(assignProjectToMemberSuccess(message));
      } else {
        yield put(assignProjectToMemberError(message));
      }
    } else {
      yield put(assignProjectToMemberError("Error when assign project to member"));
    }
  } catch (error) {
    yield put(assignProjectToMemberError("Error when assign project to member"));
  }
}

export function* watchgetMemberByEmail() {
  yield takeEvery(GET_MEMBER_BY_EMAIL, getMemberByEmailSaga);
}

function* getMemberByEmailSaga({ payload }) {
  const { email } = payload;
  try {
    const response = yield call(getMemberByEmail, email);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { status, message } = get(response, "data");
      if (status) {
        const { data: member } = get(
          response,
          "data",
          {
            member: null,
          },
        );
        yield put(getMemberByEmailSuccess(member, message));
      } else {
        yield put(getMemberByEmailError(message));
      }
    } else {
      yield put(getMemberByEmailError("Get Member By Email Error!"));
    }
  } catch (error) {
    yield put(getMemberByEmailError("Get Member By Email Error!"));
  }
}

function* updateMemberSaga(action) {
  const { id, payload, callback } = action.payload;
  // handle this after had api
  const response = yield call(updateMember, { userId: id, ...payload });
  const status = get(response, ['data', 'status']);
  const message = get(response, ['data', 'message'], '');

  if (response.status === 200 && status) {
    if (callback) callback(response.data);
    const currentUserInfo = parseJson(getCookie("userInfo"));
    payload.fullName = payload.fullname;
    payload.phoneNumber = payload.phone_number;
    delete payload.fullname;
    delete payload.phone_number;
    if (id == currentUserInfo.userId) {
      setCookie(
          "userInfo",
          JSON.stringify({...currentUserInfo, ...payload}),
          7,
      );
      yield put(updateUserInforSuccess({ userInfo: payload}));
    }
    yield put(showNoti({
      type: SUCCESS_CASE,
      message: "Update member successfully",
      messageId: "notification.update-member-success",
    }));
  } else {
    yield put(showNoti({
      type: ERROR_CASE,
      message: `Update member error! ${message}`,
      messageId: "notification.update-member-error",
      intlValues: {
        message
      }
    }));
  }

}

function* addMemberSaga(action) {
  const { payload, callback } = action.payload;
  const response = yield call(addNewMember, { ...payload });
  const status = get(response, ['data', 'status']);
  const message = get(response, ['data', 'message'], '');

  if (response.status === 200 && status) {
    if (callback) callback(response.data);
  } else {
    yield put(showNoti({
      type: ERROR_CASE,
      message: `Add member error! ${message}`,
      messageId: "notification.add-member-error",
      intlValues: {
        message
      }
    }));
  }
}

function* watchDeleteMemberFromProject({ payload }) {
  const { memberId, projectId, callback } = payload;
  const response = yield call(deleteMemberFromProject, memberId, projectId)
  const status = get(response, ['data', 'status']);
  const message = get(response, ['data', 'message'], '');

  if (response.status === 200 && status) {
    if (callback) callback(response.data);
  } else {
    yield put(showNoti({
      type: ERROR_CASE,
      message: `Delete member error! ${message}`,
      messageId: "notification.delete-member-error",
      intlValues: {
        message
      }
    }));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchGetListMembers),
    fork(watchMakeInactiveMember),
    fork(watchRestoreActiveMember),
    fork(watchDeleteMember),
    fork(watchGetListProject),
    fork(watchGetListRole),
    fork(watchAssignProjectToMember),
    fork(watchgetMemberByEmail),
    takeEvery(UPDATE_MEMBER, updateMemberSaga),
    takeEvery(ADD_MEMBER, addMemberSaga),
    takeEvery(GET_LIST_SITE_ADMIN_MEMBERS, getListSiteAdminMembersSaga),
    takeEvery(DELETE_MEMBER_FROM_PROJECT, watchDeleteMemberFromProject),
    takeEvery(GET_ALL_MEMBER, getAllMembersSaga),
  ]);
}