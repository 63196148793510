import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import { GET_DATA_BY_ADVANCED_FILTERS } from "../actions";
import { getDataByAdvancedSearch } from "./api";
import { selectAdvancedSearch } from "./selectors";
import get from "lodash/get";
import {
  getDataByAdvancedSearchError,
  getDataByAdvancedSearchSuccess,
} from "./actions";
import { selectProjectId } from "../projects/selectors";

export function getSize(value, unit) {
  let returnValue = value;
  switch (unit) {
    case "KB":
      returnValue = value * 1024;
      break;
    case "MB":
      returnValue = value * 1024 * 1024;
      break;
    case "GB":
      returnValue = value * 1024 * 1024 * 1024;
      break;
    default:
      break;
  }
  return returnValue;
}

export function* watchGetDataByAdvancedSearch() {
  yield takeEvery(
    GET_DATA_BY_ADVANCED_FILTERS,
    watchGetDataByAdvancedSearchSaga,
  );
}

function* watchGetDataByAdvancedSearchSaga({ payload, callback }) {
  const { query = {}, pageSize, currentPage, isRefresh } = payload;
  const projectId = yield select(selectProjectId);
  const advancedFilters = yield select(selectAdvancedSearch);
  const {
    imageSize,
    datasetIds,
    labelIds,
    labelingSize,
    resolution,
    searchByText,
    status,
    selectedTags,
    typeTag,
  } = advancedFilters;
  let fromValue = getSize(imageSize.from.value || 0, imageSize.from.unit);
  let toValue = getSize(imageSize.to.value || 0, imageSize.to.unit);
  const { labeler, reviewer, status: queryStatus, benchmark } = query;

  try {
    const searchQuery = {
      search: [
        {
          field: "projectId",
          value: [projectId],
        },
        {
          field: "imageSize",
          value: {
            from: fromValue,
            to: toValue,
          },
        },
        datasetIds.length > 0
          ? {
              field: "datasetId",
              value: datasetIds.map((datasetId) => Number(datasetId)),
            }
          : {},
        labelIds.length > 0
          ? {
              field: "labelId",
              value: labelIds.map((labelId) => Number(labelId)),
            }
          : {},
        {
          field: "labelingSize",
          value: {
            from: Number(labelingSize.from),
            to: Number(labelingSize.to),
          },
        },
        {
          field: "resolution",
          value: resolution,
        },
        {
          field: "text",
          value: searchByText,
        },
        {
          field: "status",
          value: queryStatus ? [queryStatus.value] : status,
        },
        typeTag === "isNot"
          ? {
              field: "tag",
              value: {
                queryType: typeTag,
                tagIds: selectedTags.map((tag) => tag.tag_id),
              },
            }
          : {
              field: "tag",
              value: {
                tagIds: selectedTags.map((tag) => tag.tag_id),
              },
            },
      ],
      pageSize,
      currentPage,
    };
    if (labeler && labeler.value) {
      searchQuery.search.push({ field: "labeler", value: [labeler.value] });
    }
    if (reviewer && reviewer.value) {
      searchQuery.search.push({ field: "reviewer", value: [reviewer.value] });
    }
    if (benchmark) {
      searchQuery.search.push({ field: "benchmark", value: benchmark.value });
    }
    const response = yield call(getDataByAdvancedSearch, searchQuery);
    const statusCode = get(response, "status", "");
    if (statusCode === 200) {
      const { totalPage, totalItem, dataset_name, datasets_details } = get(
        response,
        "data.data",
        {
          totalItem: 0,
          totalPage: 0,
          dataset_name: "",
          datasets_details: [],
        },
      );
      yield put(
        getDataByAdvancedSearchSuccess({
          query: query,
          data: datasets_details,
          pagination: {
            totalPage,
            totalItem,
            pageSize,
            currentPage,
          },
          isRefresh,
        }),
      );
    }
  } catch (error) {
    yield put(getDataByAdvancedSearchError({ errorMessage: error.message }));
  }
  callback && callback();
}

export default function* rootSaga() {
  yield all([fork(watchGetDataByAdvancedSearch)]);
}
