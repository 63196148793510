/* eslint-disable */
import {
  GET_MODEL_CHECKPOINT_SUCCESS,
  GET_MODEL_SUCCESS
} from "redux/actions";

const INIT_STATE = {
  modelCheckPoints: [],
  models: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MODEL_CHECKPOINT_SUCCESS:
      return {
        ...state,
        modelCheckPoints: action.payload,
      };
    case GET_MODEL_SUCCESS:
      return {
        ...state,
        models: action.payload,
      };
    default:
      return { ...state };
  }
}